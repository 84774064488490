import { MenuItem, Select, ThemeProvider, createTheme, makeStyles } from "@material-ui/core";
import React from "react";

interface IProps {
    value?: unknown;
    name?: string;
    dataTestId?: string;
    placeholder?: string;
    defaultValue?: string;
    hasError?: boolean;
    dataList: { id: string | number; name: string }[];
    handleChange?: (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => void
    containerClassName?: string;
    dropdownPosition?: 'top' | 'bottom'
}

const useStyles = makeStyles({
    select: {
        marginTop: "5px",
        height: "40px",
        borderRadius: " 8px",
        border: '1px solid #CBD5E1',
        overflow: 'hidden',
        background: "#FFF",
        "&:focus":{
            background: "#FFF",
            backgroundColor: "#FFF",
        }
    },
    inactiveSelect: {
        "& .MuiSelect-select.MuiSelect-select": {
            color: "#64748B",
            background: "#fff"
        },
        '& .MuiSelect-select.Mui-focus': {
            color: "#64748B",
            background: "#fff"
        },
    },

    menuItem: {
        borderTop: '1px solid #CBD5E1',
        minHeight: '44px',
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '10px 12px',
        fontFamily: "Montserrat",
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: 1.5,
        color: '#000'
    },
});


const inlineStyle = {
    error: {
        borderColor: "#F87171"
    }
}

const theme = createTheme({
    overrides: {
        MuiInput:{
            
        },
        MuiSelect: {
            selectMenu:{
                height:'100%',
                width:'100%',
                display:'flex',
                alignItems:'center',
                padding: "0 8px !important",
            },
            select: {
                color: "#000",
                fontSize: '14px',
                fontWeight: 500,
                fontFamily: "Montserrat",
                background: "#fff",
                '& fieldset': {
                    borderColor: 'transparent',
                    height: "40px",
                },
                '&:hover fieldset': {
                    borderColor: 'transparent',
                },
                '&:focus':{
                    background:"#fff",
                    backgroundColor: "#FFF",
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                },
                '& .Mui-focus': {
                    color: "#000",
                    background: "#fff"
                },
            },
            icon: {
                color: "#000"
            },
            
        },
        MuiPaper: {
            elevation8: {
                boxShadow: 'none'
            }
        },
        MuiFormControl: {
          root: {
  
            '& .MuiAutocomplete-endAdornment': {
              display: 'none'
          },
              '& .MuiOutlinedInput-adornedEnd': {
                  padding: ('0 40px !important' as unknown as number),
              },
          }
      },
        MuiMenu: {
          paper: {
            borderBottomRightRadius: '8px',
            borderBottomLeftRadius: '8px',
            maxHeight: '220px',
            overflowX: 'hidden',
            overflowY: 'auto',
            border: '1px solid #CBD5E1',
        },
            list: {
                paddingTop: 0,
                paddingBottom: 0,
                borderBottomRightRadius: '8px',
                borderBottomLeftRadius: '8px',
            },
        }
    }
  });

const Dropdown = ({ dropdownPosition = 'bottom', containerClassName = '', dataTestId, value, name, handleChange, placeholder = "Select response", defaultValue = '', hasError = false, dataList = [] }: IProps) => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
        <Select
            fullWidth
            name={name}
            value={value}
            onChange={handleChange}
            displayEmpty
            defaultValue={defaultValue}
            disableUnderline
            MenuProps={{
                anchorOrigin: {
                    vertical: dropdownPosition,
                    horizontal: "left"
                },
                transformOrigin: {
                    vertical: dropdownPosition === 'bottom' ? "top" : "bottom",
                    horizontal: "left"
                },
                getContentAnchorEl: null
            }}
            placeholder={placeholder}
            inputProps={{ "aria-label": "company-industry" }}
            className={`${classes.select} ${!!value ? '' : classes.inactiveSelect} ${containerClassName}`}
            data-testid={dataTestId}
            style={!!hasError ? inlineStyle.error : undefined}
        >
            {dataList.map(item => (
                <MenuItem className={classes.menuItem} key={item.id} value={item.id}>
                    {item.name}
                </MenuItem>
            ))}
        </Select>
        </ThemeProvider>
    );
};

export default Dropdown;
