import React from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  FormHelperText
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AccountDetailController, { Props } from "./AccountDetailController";
import { ErrorMessage, Formik } from "formik";
import { accountSchema } from "../utils/schema";
import { styles } from "../utils/styles";
import Layout from "../../../components/src/Layout";
import CarouselDisplay from "../../../blocks/carouseldisplay/src/CarouselDisplay.web";
//Icons
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

export type Values = {
  name: string;
  cName: string;
  password: string;
};

const initialValues: Values = { name: "", cName: "", password: "" };

export class AccountDetail extends AccountDetailController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { email } = this.state;

    return (
      <Layout back={this.onBackCreatAccount} carousel={<CarouselDisplay type="individual" id="" navigation={{}} />}>
        <Box className={classes.layoutContainer} style={{marginTop:'70px'}}>
          <Box className={classes.viewBox}>
            <Typography className={classes.createAccount}>
              Create your account
            </Typography>
            <Typography className={classes.signingAs} >
              You are signing up as {email}{'.'}{" "}
            </Typography>

            <Formik
              data-testid="accountDetailsFormik"
              initialValues={initialValues}
              validationSchema={accountSchema}
              onSubmit={values => {
                this.createAccount(values);
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit} data-test-id="createAccount">
                  <Box className={classes.form1}>
                    <Box className={classes.inputWrapper}>
                      <Typography className={classes.title}>
                        Full Name
                      </Typography>
                      <TextField
                        data-test-id="txtInputName"
                        fullWidth
                        name="name"
                        id="name"
                        type="text"
                        variant="outlined"
                        value={values.name}
                        placeholder="Full name"
                        onChange={handleChange}
                        className={classes.input1}
                        style={{
                          borderColor: touched.name && errors.name ? "#F87171" : ""
                        }}
                      />
                     <ErrorMessage name="name" component="div" className={classes.errorResp} />
                    </Box>
                    <Box className={classes.inputWrapper}>
                      <Typography className={classes.title}>
                        Company Name
                      </Typography>
                      <TextField
                        data-test-id="txtInputCompanyName"
                        fullWidth
                        name="cName"
                        id="company"
                        type="text"
                        variant="outlined"
                        value={values.cName}
                        placeholder="Name"
                        onChange={handleChange}
                        className={classes.input1}
                        style={{
                          borderColor: touched.cName && errors.cName ? "#F87171" : ""
                        }}
                      />
                     <ErrorMessage name="cName" component="div" className={classes.errorResp} />
                    </Box>
                    <Box className={classes.inputWrapper}>
                      <Typography className={classes.title}>Password</Typography>
                      <TextField
                        data-test-id="txtInputPassword"
                        name="password"
                        fullWidth
                        id="password"
                        variant="outlined"
                        value={values.password}
                        placeholder="Enter Password"
                        onChange={handleChange}
                        className={classes.input1}
                        type={
                          this.state.enablePasswordField ? "password" : "text"
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              aria-label="toggle password visibility"
                              onClick={() => this.handleClickShowPassword()}
                              className={classes.eyeIcon}
                            >
                              {this.state.enablePasswordField ? (
                                 <VisibilityOffOutlinedIcon className={classes.pwdIconColor} />
                                 ) : (
                                   <VisibilityOutlinedIcon className={classes.pwdIconColor} />
                              )}
                            </InputAdornment>
                          )
                        }}
                        style={{
                          borderColor: touched.password && errors.password ? "#F87171" : ""
                        }}
                      />
                      <ErrorMessage name="password" component="div" className={classes.errorResp} />
                    </Box>
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      className={classes.buttonWrapper}
                    >
                      Continue
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Layout>
    );
  }
}

export default withStyles(styles)(AccountDetail);
