import React from "react";
import { BlockComponent } from "framework/src/BlockComponent";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // handleCloseEditMail:() => void;
  handleCloseMainModule: () => void;
  openEmail: boolean;
  checkIcon: string;
  handleCloseCurrentModule: () => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  mailText: string;
  success: string;
  paymentStatus: any;
  cancel: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

// Customizable Area End

export class PaymentStatus extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      mailText: "Payment",
      success: "successfully done",
      cancel: "Failed",
      paymentStatus: null,
    };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const url = new window.URL(window.location.href);
    let status = url.searchParams.get("status");
    this.setState({ paymentStatus: status });
  }

  handelRedirection() {
    if (this.state.paymentStatus === "false") {
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "Subscription"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    } else {
      const redirectionMsg: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      redirectionMsg.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "LandingPage"
      );
      redirectionMsg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(redirectionMsg);
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { paymentStatus } = this.state;

    return (
      // Customizable Area Start
      <Dialog
        onClose={() => {}}
        aria-labelledby="simple-dialog-title"
        open={true}
        className={classes.dialogContainer}
      >
        <DialogContent>
          <CloseIcon
            onClick={() => {}}
            fontSize="small"
            className={classes.closeIcon}
          />
          <img src={this.props.checkIcon} className={classes.checkIconImg} />
          <Typography className={classes.mailText}>
            {this.state.mailText}
            {paymentStatus == "true" ? (
              <Typography className={classes.success} component={"div"}>
                {this.state.success}!
              </Typography>
            ) : (
              <Typography className={classes.cancel} component={"div"}>
                {this.state.cancel}!
              </Typography>
            )}
          </Typography>

          <Box className={classes.displayButton}>
            <Button
              variant="contained"
              className={classes.closeButton}
              onClick={() => {
                this.handelRedirection();
              }}
            >
              Okay
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      // Customizable Area End
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle: {} = () => ({
  closeIcon: {
    position: "absolute",
    right: "25px",
    cursor: "pointer",
  },
  dialogContainer: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "17px",
      width: "100%",
      padding: "20px 10px",
    },
    position: "relative",
    textAlign: "center",
  },
  checkIconImg: {
    width: "30px",
  },
  mailText: {
    margin: "20px 0",
    fontWeight: 700,
    fontSize: "1.8rem",
  },
  success: {
    color: "#059669",
    margin: "0 5px",
    fontWeight: 700,
    fontSize: "1.8rem",
  },
  cancel: {
    color: "red",
    margin: "0 5px",
    fontWeight: 700,
    fontSize: "1.8rem",
  },
  displayButton: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0",
    gap: "10px",
  },
  accountButtton: {
    borderRadius: "8px",
    fontWeight: 600,
    padding: "5px",
    textTransferm: "Capitalize",
    width: "95%",
    height: "min-content",
  },
  closeButton: {
    borderRadius: "8px",
    fontWeight: 600,
    background: "#000",
    color: "#fff",
    width: "95%",
    height: "min-content",
  },
});
export default withStyles(webStyle)(PaymentStatus);

// Customizable Area End
