import React from "react";
// Customizable Area Start
import CustomTextArea from "../../../components/src/CustomTextArea.web";
// Customizable Area End

import MissionStatementController, {
    Props,
    configJSON,
} from "./MissionStatementController";

export default class MissionStatement extends MissionStatementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {handleChange,values,errors,name} = this.props;
        return (
            // Customizable Area Start
            <CustomTextArea
            data-testid="text-field"
            title={configJSON.missionStatement}
            maxCharacters={configJSON.maxCharacters}
            onChange={(e:any) => handleChange(e)} 
            characterLimit={configJSON.characterLimit}
            placeholder={configJSON.missinPlaceholder}
            minRows={2}
            modalContent={configJSON.missionInfo}
            errors={errors}
            // value={this.props.textValue}
            value={values}
            name={name}
            />
           
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
