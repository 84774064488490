import * as Yup from "yup";

export const ForgotPwdSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email should not be blank")
});

export const ResetPwdSchema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/,
      "Password does not meet requirements"
    )
    .required("Password should not be blank"),
  confirmPassword: Yup.string()
    .required("Confirm password should not be blank")
    .oneOf(
      [Yup.ref("password"), null],
      "New password and confirm password must match"
    )
});

export const NewPwdSchema = Yup.object({
  newPassword: Yup.string()
    .required("New Password is required.")
    .min(6, "Password must be at least 6 characters.")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character."
    ),
  confirmPassword: Yup.string()
    .required("Confirm Password is required.")
    .oneOf([Yup.ref("newPassword"), ""], "Both Passwords must match.")
});
