import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Box, Typography, Container, IconButton, TextField, InputAdornment, Table, TableHead, TableRow, TableCell, TableBody, Dialog, Button } from "@material-ui/core";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});
// Customizable Area End

import MyLibraryController, {
    Props,
} from "./MyLibraryController";
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";
import { arrowDown, arrowUp, bookmarkRed, calender, clock, closeIcon, copy, copyText, download, edit, editField, emailGreen, marketing, pdf, reply, rightBar, searchIcon, sendEmail, timeBlue, timeClock, timeGreen, timeRed, unselect, word, workflowDelete } from "./assets";

export class MyLibrary extends MyLibraryController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <NavigationMenuComponent
                    id={this.props.id}
                    navigation={this.props.navigation}
                    activeIndex={1}
                    handleRightMenu={this.handleRightMenu}
                    rightMenu={this.state.rightMenu}
                    data-test-id="navigationMenu"
                    children={
                        <Container maxWidth="xl" className={classes.mainWorkFlowContainer}>
                            {!this.state.rightMenu &&
                                <IconButton
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    data-test-id="right-menu"
                                    onClick={this.handleRightMenu}
                                    style={webStyle.rightBar}
                                >
                                    <img
                                        src={rightBar}
                                        alt="right-bar"
                                    />
                                </IconButton>
                            }
                            <Typography style={webStyle.title}>
                                {this.state.title}
                            </Typography>
                            <Typography style={webStyle.pageSubheading}>
                                Your saved intelligence is conveniently stored in your library, accessible whenever you need it.
                            </Typography>
                            <Box style={webStyle.tableWrapper}>
                                <Box style={webStyle.containerTopbar}>
                                    <Box className={classes.tabsContainer}>
                                        <Box className={classes.activeChatChipcontainer}>
                                            <ul className={classes.activeChatChiptab}>
                                                <li data-testid="step1" className={this.styleCondition(this.state.activeStep === 1, "active", "")} onClick={() => this.handleSelect(1)}>{"Outreach"}</li>
                                                <li data-testid="step2" className={this.styleCondition(this.state.activeStep === 2, "active", "")} onClick={() => this.handleSelect(2)}>{"Copilot"}</li>
                                            </ul>
                                        </Box>
                                    </Box>
                                    <Box className={classes.searchContainer}>
                                        <Box>
                                            <TextField
                                                variant="outlined"
                                                placeholder="Search intelligence"
                                                InputProps={{
                                                    style: {
                                                        borderRadius: "8px",
                                                        width: "325px",
                                                        height: "40px",
                                                        border: "1px solid #94A3B8",
                                                    },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                edge="end"
                                                            >
                                                                <img src={searchIcon} alt="searchIcon" />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className={classes.tableContainer}>
                                    <Box>
                                        {this.state.activeStep === 1 && (
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {[
                                                            "Title & Number",
                                                            "Tag & Preview",
                                                            "Timestamp",
                                                            "Options",
                                                        ].map((head: any, index: any) => (
                                                            <TableCell className={classes.tableHeadCell} key={head} align={this.styleCondition(index !== 0, "center", "left")}>
                                                                {head}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.myLibraryOutreach.map((workflow: any, index: any) => (
                                                        <React.Fragment key={`outreach${index}`}>
                                                            <TableRow
                                                                key={workflow.id}
                                                                className={this.addDynamicClass(workflow.unique_id, classes.feedbackBorder, classes.postEventBorder, classes.marketingBorder)}
                                                            >
                                                                <TableCell data-test-id={`parameterWorkFlow-${index}`} style={{ width: "200px" }} >
                                                                    <Box className={classes.optionsworkFlowNameCell} data-test-id={`optionworkFlowNameCell-${index}`} >
                                                                        <Box
                                                                            data-test-id={`handleLibrarySelectedOutreach-${index}`}
                                                                            className={`${classes.lengthWrapper} ${this.addDynamicClass(workflow.unique_id, classes.feedback, classes.post, classes.marketing)}`}
                                                                            onClick={() => {
                                                                                this.handleSelectedOutreachItem({
                                                                                    selectedOutreachId: this.styleCondition(this.state.selectedOutreachId === workflow.id, null, workflow.id)
                                                                                })
                                                                            }}
                                                                        >
                                                                            <Typography className={classes.lengthText}>
                                                                                {workflow.nestedData.length}
                                                                            </Typography>
                                                                            <img
                                                                                src={this.styleCondition(this.state.selectedOutreachId === workflow.id, arrowDown, arrowUp)
                                                                                }
                                                                                alt="arrowUp"
                                                                                style={{
                                                                                    marginLeft: "5px",
                                                                                    height: "5px",
                                                                                    width: "5px",
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                        <Box style={{ padding: "0px 10px" }}>
                                                                            <Typography className={classes.sessionTitle}>
                                                                                <img
                                                                                    src={this.addDynamicClass(workflow.unique_id, timeBlue, timeRed, timeGreen)}
                                                                                    alt="time"
                                                                                    className={classes.icon}
                                                                                    style={{ marginRight: "5px" }}
                                                                                />
                                                                                {workflow.title}
                                                                            </Typography>
                                                                            <Typography className={classes.sessionDesc}>
                                                                                {workflow.description}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell className={""} >
                                                                    {workflow.unique_id && (
                                                                        <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                            {(() => {
                                                                                switch (workflow.unique_id) {
                                                                                    case "feedback":
                                                                                        return (
                                                                                            <Box className={classes.feedbackTag}>
                                                                                                {workflow.tag}
                                                                                            </Box>
                                                                                        );
                                                                                    case "post_event":
                                                                                        return (
                                                                                            <Box className={classes.postEventTag}>
                                                                                                {workflow.tag}
                                                                                            </Box>
                                                                                        );
                                                                                    case "marketing":
                                                                                        return (
                                                                                            <Box className={classes.marketingTag}>
                                                                                                {workflow.tag}
                                                                                            </Box>
                                                                                        );
                                                                                    default:
                                                                                        return "NA";
                                                                                }
                                                                            })()}
                                                                        </Box>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell className={""}>
                                                                    <Box className={classes.optionsTimeStampCell}>
                                                                        <Box>
                                                                            <Box className={classes.dFlex} >
                                                                                <img src={clock} alt="time" />
                                                                                <Typography className={classes.optionsTimeStampDateCell}>
                                                                                    {workflow.time}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box className={classes.dFlex} >
                                                                                <img src={calender} alt="date" />
                                                                                <Typography className={classes.optionsTimeStampDateCell} >
                                                                                    {workflow.date}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell className={""} >
                                                                    <Box className={classes.optionsCell}>
                                                                        <IconButton data-test-id={`deleteOutreachClick-${index}`} onClick={() => this.deleteOutreach(true)}>
                                                                            <img
                                                                                src={workflowDelete}
                                                                                alt="delete_icon"
                                                                                className={classes.icon}
                                                                            />
                                                                        </IconButton>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                            {workflow.nestedData &&
                                                                workflow.nestedData.length > 0 &&
                                                                workflow.nestedData.map(
                                                                    (element: any, nestedIndex: number) =>
                                                                        this.state.selectedOutreachId === workflow.id && (
                                                                            <TableRow key={element.id}
                                                                                className={this.addDynamicClass(workflow.unique_id, classes.feedback, classes.post, classes.marketing)}
                                                                            >
                                                                                <TableCell >
                                                                                    <Typography className={classes.sessionTitle}>
                                                                                        <img
                                                                                            src={emailGreen}
                                                                                            alt="emailGreen"
                                                                                            className={classes.icon}
                                                                                            style={{ marginRight: "5px" }}
                                                                                        />
                                                                                        {element.title}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                                        <Typography className={classes.sessionDesc}>
                                                                                            {element.description}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Box className={classes.optionsTimeStampCell}>
                                                                                        <Box>
                                                                                            <Box className={classes.dFlex}>
                                                                                                <img src={clock} alt="clock" />

                                                                                                <Typography className={classes.optionsTimeStampDateCell}>
                                                                                                    {element.time}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                            <Box className={classes.dFlex}>
                                                                                                <img src={calender} alt="calender" />
                                                                                                <Typography className={classes.optionsTimeStampDateCell}>
                                                                                                    {element.date}
                                                                                                </Typography>
                                                                                            </Box>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Box className={classes.optionsCell}>
                                                                                        <IconButton data-test-id={`editEmailClick-${nestedIndex}`} onClick={() => this.handleRedirection("EmailCreation")}>
                                                                                            <img
                                                                                                src={edit}
                                                                                                alt="edit"
                                                                                                width={"14px"}
                                                                                                height={"18px"}
                                                                                            />
                                                                                        </IconButton>
                                                                                        <IconButton data-test-id={`shareEmail-${nestedIndex}`} onClick={() => this.shareOutreachItem(true)}>
                                                                                            <img
                                                                                                src={reply}
                                                                                                alt="reply"
                                                                                                width={"14px"}
                                                                                                height={"18px"}
                                                                                            />
                                                                                        </IconButton>
                                                                                        <IconButton>
                                                                                            <img
                                                                                                src={bookmarkRed}
                                                                                                alt="bookmarkRed"
                                                                                                width={"14px"}
                                                                                                height={"18px"}
                                                                                            />
                                                                                        </IconButton>
                                                                                        <IconButton data-test-id={`deleteEmail-${nestedIndex}`} onClick={() => this.deleteOutreachItem(true)}>
                                                                                            <img
                                                                                                src={workflowDelete}
                                                                                                alt="delete_icon"
                                                                                                className={classes.icon}
                                                                                            />
                                                                                        </IconButton>
                                                                                    </Box>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ),
                                                                )}
                                                        </React.Fragment>
                                                    ))}

                                                </TableBody>
                                            </Table>
                                        )}
                                        {this.state.activeStep === 2 && (
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        {[
                                                            "Conversation",
                                                            "Intelligence",
                                                            "Timestamp",
                                                            "Options",
                                                        ].map((head: any, index: any) => (
                                                            <TableCell className={classes.tableHeadCell} key={head} align={this.styleCondition(index !== 0, "center", "left")}>
                                                                {head}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {this.state.myLibraryCopilot.map((workflow: any, index: any) => (
                                                        <React.Fragment key={`copilot${index}`}>
                                                            <TableRow key={workflow.id} className={classes.tableRowContainer}>
                                                                <TableCell data-test-id={`myLibraryCopilot-${index}`} style={{ width: "200px" }}>
                                                                    <Box className={classes.optionsworkFlowNameCell} data-test-id={`optionsworkFlowNameCell-${index}`}>
                                                                        <Box
                                                                            data-test-id={`handleSelectedCopilot-${index}`}
                                                                            className={`${classes.lengthWrapper} ${classes.copilot}`} onClick={() => {
                                                                                this.handleSelectedCopilotItem({
                                                                                    selectedCopilotId: this.styleCondition(this.state.selectedCopilotId === workflow.id, null, workflow.id)
                                                                                })
                                                                            }}
                                                                        >
                                                                            <Typography className={classes.lengthText}>{workflow.nestedData.length}</Typography>
                                                                            <img
                                                                                src={this.styleCondition(this.state.selectedCopilotId === workflow.id, arrowDown, arrowUp)}
                                                                                alt="arrowUp"
                                                                                style={{ marginLeft: "5px", height: "5px", width: "5px" }}
                                                                            />
                                                                        </Box>
                                                                        <IconButton>
                                                                            <img
                                                                                src={timeClock}
                                                                                alt="time"
                                                                                className={classes.icon}
                                                                            />
                                                                        </IconButton>
                                                                        <Box>
                                                                            <Typography className={classes.sessionTitle}>
                                                                                {workflow.title}
                                                                            </Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell className={""}>
                                                                </TableCell>
                                                                <TableCell className={""}>
                                                                    <Box className={classes.optionsTimeStampCell}>
                                                                        <Box>
                                                                            <Box className={classes.dFlex}>
                                                                                <img src={clock} alt="clock" />
                                                                                <Typography className={classes.optionsTimeStampDateCell}>
                                                                                    {workflow.time}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box className={classes.dFlex}>
                                                                                <img src={calender} alt="calender" />
                                                                                <Typography className={classes.optionsTimeStampDateCell}>
                                                                                    {workflow.date}
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                                <TableCell className={""} style={{ width: "170px", }} >
                                                                    <Box className={classes.optionsCell}>
                                                                        <IconButton data-test-id={`chatHistoryRedirect-${index}`} onClick={() => this.handleRedirection("ChatHistory")}>
                                                                            <img
                                                                                src={edit}
                                                                                alt="edit"
                                                                                width={"14px"}
                                                                                height={"18px"}
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton data-test-id={`shareConversation-${index}`} onClick={() => this.shareConversation(true)}>
                                                                            <img
                                                                                src={reply}
                                                                                alt="reply"
                                                                                width={"14px"}
                                                                                height={"18px"}
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton>
                                                                            <img
                                                                                src={bookmarkRed}
                                                                                alt="bookmark"
                                                                                width={"14px"}
                                                                                height={"18px"}
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton data-test-id={`deleteConversation-${index}`} onClick={() => this.deleteConversation(true)}>
                                                                            <img
                                                                                src={workflowDelete}
                                                                                alt="delete"
                                                                                className={classes.icon}
                                                                            />
                                                                        </IconButton>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                            {workflow.nestedData && workflow.nestedData.length > 0 && (
                                                                workflow.nestedData.map((ele: any, nestedIndex: number) => (
                                                                    this.state.selectedCopilotId === workflow.id && (
                                                                        <TableRow key={ele.id}>
                                                                            <TableCell>
                                                                                <Typography className={classes.sessionTitle}>
                                                                                    {ele.title}
                                                                                </Typography>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Box style={{ display: "flex", justifyContent: "center" }}>
                                                                                    <Typography className={classes.sessionDesc}>
                                                                                        {ele.description}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Box className={classes.optionsTimeStampCell}>
                                                                                    <Box>
                                                                                        <Box className={classes.dFlex}>
                                                                                            <img src={clock} alt="clock" />
                                                                                            <Typography className={classes.optionsTimeStampDateCell} >
                                                                                                {ele.time}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Box className={classes.dFlex}>
                                                                                            <img src={calender} alt="calender" />
                                                                                            <Typography className={classes.optionsTimeStampDateCell} >
                                                                                                {ele.date}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Box className={classes.optionsCell}>
                                                                                    <IconButton data-test-id={`editChat-${nestedIndex}`} onClick={() => this.editChatHistory(true, ele)}>
                                                                                        <img
                                                                                            src={edit}
                                                                                            alt="edit"
                                                                                            width={"14px"}
                                                                                            height={"18px"}
                                                                                        />
                                                                                    </IconButton>
                                                                                    <IconButton data-test-id={`deleteConversationItem-${nestedIndex}`} onClick={() => this.deleteConversationItem(true)}>
                                                                                        <img
                                                                                            src={workflowDelete}
                                                                                            alt="delete_icon"
                                                                                            className={classes.icon}
                                                                                        />
                                                                                    </IconButton>
                                                                                </Box>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ))
                                                                )
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        )}
                                        <Dialog maxWidth="md" fullWidth open={this.state.editChat} data-test-id={`editChatHistoryDialog`} onClose={() => this.editChatHistory(false)}>
                                            <Box className={classes.modal}>
                                                <Box className={classes.closeImg} data-test-id={`editChatHistoryBox`} onClick={() => this.editChatHistory(false)}>
                                                    <img
                                                        src={closeIcon}
                                                        alt="close"
                                                        className={classes.closeIcon}
                                                    />
                                                </Box>
                                                <Box className={classes.modalTitle}>
                                                    <img src={marketing} alt="marketing" style={{ marginRight: "8px" }} />
                                                    Marketing
                                                </Box>
                                                <Box className={classes.modaldesc}>
                                                    Edit or copy the answer in the field below
                                                </Box>
                                                <Box className={classes.inputContainer}>
                                                    <Typography className={classes.inputTitle}>Name</Typography>
                                                    <Box className={classes.nameInputContainer}>
                                                        <TextField
                                                            variant="outlined"
                                                            id="outlined-multiline-static"
                                                            value={"Test"}
                                                            InputProps={{ style: { ...webStyle.inputStyle } }}
                                                            style={{ flexGrow: 1 }}
                                                            name={"nameInput"}
                                                            className={classes.textInput}
                                                        />
                                                        <Button className={classes.editInputBtn} ><img src={editField} alt="copy" style={{ marginRight: "8px" }} /> Edit</Button>
                                                    </Box>
                                                </Box>
                                                <Box className={classes.inputContainer}>
                                                    <Typography className={classes.inputTitle}>Intelligence</Typography>
                                                    <Box className={classes.nameInputContainer}>
                                                        <TextField
                                                            variant="outlined"
                                                            multiline
                                                            minRows={6}
                                                            maxRows={12}
                                                            id="outlined-multiline-static"
                                                            value={"Intelligence"}
                                                            InputProps={{ style: { ...webStyle.inputStyle } }}
                                                            style={{ flexGrow: 1 }}
                                                            name={"intelligenceInput"}
                                                            className={classes.textInput}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalCancelBtn} data-test-id={`editCopyClick`} onClick={() => this.deleteConversation(false)}>Copy <img src={copy} alt="copy" style={{ marginLeft: "8px" }} /></Button>
                                                    <Button className={classes.modalRemoveBtn} data-test-id={`editSaveBtnClick`} onClick={() => this.deleteConversation(false, true)}>Save</Button>
                                                </Box>
                                            </Box>
                                        </Dialog>
                                        <Dialog maxWidth="md" fullWidth open={this.state.deleteOutreachPopup} data-test-id={`deleteOutreachDialog`} onClose={() => this.deleteOutreach(false)}>
                                            <Box className={classes.modal}>
                                                <Box className={classes.closeImg} data-test-id={`deleteOutreachBox`} onClick={() => this.deleteOutreach(false)}>
                                                    <img
                                                        src={closeIcon}
                                                        alt="close"
                                                        className={classes.closeIcon}
                                                    />
                                                </Box>
                                                <Box className={classes.modalTitle}>
                                                    Are you sure you want to delete “Christmas” from the Library?
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalCancelBtn} data-test-id={`deleteOutreachCancelClick`} onClick={() => this.deleteOutreach(false)}>Cancel</Button>
                                                    <Button className={classes.modalDeleteBtn} data-test-id={`deleteOutreachBtnClick`} onClick={() => this.deleteOutreach(false, true)}>Delete</Button>
                                                </Box>
                                            </Box>
                                        </Dialog>
                                        <Dialog maxWidth="md" fullWidth open={this.state.deleteOutreachItemPopup} data-test-id={`deleteOutreachItemDialog`} onClose={() => this.deleteOutreachItem(false)}>
                                            <Box className={classes.modal}>
                                                <Box className={classes.closeImg} data-test-id={`deleteOutreachItemBox`} onClick={() => this.deleteOutreachItem(false)}>
                                                    <img
                                                        src={closeIcon}
                                                        alt="close"
                                                        className={classes.closeIcon}
                                                    />
                                                </Box>
                                                <Box className={classes.modalTitle}>
                                                    Are you sure you want to delete selected document?
                                                </Box>
                                                <Box className={classes.modaldesc}>
                                                    Note that you'll <span style={{ color: "#DC2626" }}>delete</span> these documents forever and you will not be able to restore them.
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalCancelBtn} data-test-id={`deleteOutreachItemCancelClick`} onClick={() => this.deleteOutreachItem(false)}>Cancel</Button>
                                                    <Button className={classes.modalDeleteBtn} data-test-id={`deleteOutreachItemBtnClick`} onClick={() => this.deleteOutreachItem(false, true)}>Delete</Button>
                                                </Box>
                                            </Box>
                                        </Dialog>
                                        <Dialog maxWidth="md" fullWidth open={this.state.deleteConversationPopup} data-test-id={`deleteConversationDialog`} onClose={() => this.deleteConversation(false)}>
                                            <Box className={classes.modal}>
                                                <Box className={classes.closeImg} data-test-id={`deleteConversationBox`} onClick={() => this.deleteConversation(false)}>
                                                    <img
                                                        src={closeIcon}
                                                        alt="close"
                                                        className={classes.closeIcon}
                                                    />
                                                </Box>
                                                <Box className={classes.modalTitle}>
                                                    Are you sure you want to remove “UX design” from Library?
                                                </Box>
                                                <Box className={classes.modaldesc}>
                                                    Note that you'll be able to find this item only in History.
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalCancelBtn} data-test-id={`deleteConversationCancelClick`} onClick={() => this.deleteConversation(false)}>Cancel</Button>
                                                    <Button className={classes.modalRemoveBtn} data-test-id={`deleteConversationBtnClick`} onClick={() => this.deleteConversation(false, true)}>Remove</Button>
                                                </Box>
                                            </Box>
                                        </Dialog>
                                        <Dialog maxWidth="md" fullWidth open={this.state.deleteConversationItemPopup} data-test-id={`deleteConversationItemDialog`} onClose={() => this.deleteConversationItem(false)}>
                                            <Box className={classes.modal}>
                                                <Box className={classes.closeImg} data-test-id={`deleteConversationItemBox`} onClick={() => this.deleteConversationItem(false)}>
                                                    <img
                                                        src={closeIcon}
                                                        alt="close"
                                                        className={classes.closeIcon}
                                                    />
                                                </Box>
                                                <Box className={classes.modalTitle}>
                                                    Are you sure you want to delete selected answer?
                                                </Box>
                                                <Box className={classes.modaldesc}>
                                                    Note that you'll delete these items forever and you will not be able to restore them.
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalCancelBtn} data-test-id={`deleteConversationItemCancelClick`} onClick={() => this.deleteConversationItem(false)}>Cancel</Button>
                                                    <Button className={classes.modalDeleteBtn} data-test-id={`deleteConversationItemBtnClick`} onClick={() => this.deleteConversationItem(false, true)}>Delete</Button>
                                                </Box>
                                            </Box>
                                        </Dialog>
                                        <Dialog maxWidth="md" fullWidth open={this.state.shareOutreachItemPopup} data-test-id={`shareOutreachItemDialog`} onClose={() => this.shareOutreachItem(false)}>
                                            <Box className={classes.modal}>
                                                <Box className={classes.closeImg} data-test-id={`shareOutreachItemBox`} onClick={() => this.shareOutreachItem(false)}>
                                                    <img
                                                        src={closeIcon}
                                                        alt="close-img"
                                                        className={classes.closeIcon}
                                                    />
                                                </Box>

                                                <Box className={classes.modalTitle}>
                                                    Share file as
                                                </Box>

                                                <Box className={classes.modaldesc}>
                                                    {`How would you like to share your file(s)?`}
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalWordFileBtn}>
                                                        <Box style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                            <img src={word} alt="word-img" style={{ marginRight: "8px" }} />
                                                            Word file
                                                        </Box>
                                                        <img src={unselect} alt="select-img" style={{ marginRight: "8px" }} />
                                                    </Button>
                                                    <Button className={classes.modalWordFileBtn}>
                                                        <Box style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                            <img src={pdf} alt="pdf-img" style={{ marginRight: "8px" }} />
                                                            PDF file
                                                        </Box>
                                                        <img src={unselect} alt="select-img" style={{ marginRight: "8px" }} />
                                                    </Button>
                                                </Box>
                                                <Box style={{ display: "flex" }}>
                                                    <Button className={classes.modalWordFileBtn}>
                                                        <Box style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                            Copy Text
                                                            <img src={copyText} alt="pdf" style={{ marginLeft: "8px" }} />
                                                        </Box>
                                                    </Button>
                                                </Box>
                                                <Box className={classes.inputContainer}>
                                                    <Typography className={classes.inputTitle}>Target email</Typography>
                                                    <Box className={classes.nameInputContainer}>
                                                        <TextField
                                                            variant="outlined"
                                                            id="outlined-multiline-static"
                                                            value={"johndoe@gmail.com"}
                                                            InputProps={{style:{...webStyle.inputStyle, }}}
                                                            style={{ flexGrow: 1, }}
                                                            name={"emailIdInput"}
                                                            className={classes.textInput}

                                                        />
                                                    </Box>
                                                    <Typography className={classes.noteText}>Enter email address you would like to send the info to.</Typography>
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalCancelBtn}> <img src={download} alt="download-img" style={{ marginRight: "8px" }} />Download</Button>
                                                    <Button className={classes.modalCancelBtn}> <img src={sendEmail} alt="sendEmail-img" style={{ marginRight: "8px" }} />Send an email</Button>
                                                </Box>
                                            </Box>
                                        </Dialog>
                                        <Dialog maxWidth="md" fullWidth open={this.state.shareConversationPopup} data-test-id={`shareConversationModal`} onClose={() => this.shareConversation(false)}>
                                            <Box className={classes.modal}>
                                                <Box data-test-id={`shareConversationBox`} className={classes.closeImg} onClick={() => this.shareConversation(false)}>
                                                    <img
                                                        src={closeIcon}
                                                        alt="close"
                                                        className={classes.closeIcon}
                                                    />
                                                </Box>
                                                <Box className={classes.modalTitle}>
                                                    Share file as
                                                </Box>
                                                <Box className={classes.modaldesc}>
                                                    {`How would you like to share your file(s)?`}
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalWordFileBtn}>
                                                        <Box style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                            <img src={word} alt="word" style={{ marginRight: "8px" }} />
                                                            Word file
                                                        </Box>
                                                        <img src={unselect} alt="select" style={{ marginRight: "8px" }} />
                                                    </Button>
                                                    <Button className={classes.modalWordFileBtn}>
                                                        <Box style={{ display: "flex", flex: 1, justifyContent: "center" }}>
                                                            <img src={pdf} alt="pdf" style={{ marginRight: "8px" }} />
                                                            PDF file
                                                        </Box>
                                                        <img src={unselect} alt="select" style={{ marginRight: "8px" }} />
                                                    </Button>
                                                </Box>
                                                <Box className={classes.inputContainer}>
                                                    <Typography className={classes.inputTitle}>Target email</Typography>
                                                    <Box className={classes.nameInputContainer}>
                                                        <TextField
                                                            variant="outlined"
                                                            id="outlined-multiline-static"
                                                            value={"john@gmail.com"}
                                                            InputProps={{ style: { ...webStyle.inputStyle } }}
                                                            style={{ flexGrow: 1 }}
                                                            name={"emailInput"}
                                                            className={classes.textInput}
                                                        />
                                                    </Box>
                                                    <Typography className={classes.noteText}>Enter email address you would like to send the info to.</Typography>
                                                </Box>
                                                <Box className={classes.modalButtonContainer}>
                                                    <Button className={classes.modalCancelBtn}> <img src={download} alt="download" style={{ marginRight: "8px" }} />Download</Button>
                                                    <Button className={classes.modalCancelBtn}> <img src={sendEmail} alt="sendEmail" style={{ marginRight: "8px" }} />Send an email</Button>
                                                </Box>
                                            </Box>
                                        </Dialog>
                                    </Box>
                                </Box>
                            </Box>

                        </Container>
                    }
                />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    rightBar: {
        position: "absolute" as "absolute",
        top: "10px",
        right: "10px",
        zIndex: "9999",
        height: 'max-content'
    },
    title: {
        fontSize: "48px",
        fontWeight: 600,
        textAlign: "center" as "center",
    },
    pageSubheading: {
        fontSize: "16px",
        fontWeight: 500,
        color: "#475569",
        textAlign: "center" as "center",
        maxWidth: "467px",
        margin: "auto"
    },
    tableWrapper: {
        margin: "0px 50px"
    },
    containerTopbar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    removeSelectionText: {
        color: "#D93855",
        fontSize: "14px",
        fontWeight: 500,
    },
    buttonContainer: {
        display: "flex",
        gap: "4px"
    },
    imgStyle: {
        marginLeft: "4px"
    },
    inputStyle: {
        borderRadius: '8px',
        fontFamily: 'Montserrat',
        fontWeight: 500,
        lineHeight: '21px',
        fontSize: "14px",
    },
}

const styles: any = {
    tabsContainer: {},
    activeChatChipcontainer: {
        overflowX: "hidden",
        display: "flex",
        position: "relative",
        flexDirection: "column",
    },
    activeChatChiptab: {
        display: "flex",
        listStyle: "none",
        border: "1px solid #CBD5E1",
        padding: "2px",
        borderRadius: "80px",
        background: "#FFF",
        marginTop: "10px",
        "& li": {
            padding: "4px 12px",
            display: "flex",
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: "600",
            fontFamily: "Montserrat",
            lineHeight: "22px",
        },
        "& li.lastItem": {
            marginRight: 0,
        },
        "& li.active": {
            borderRadius: "80px",
            padding: "4px 12px",
            background: "#000",
            color: "#FFFFFF",
        },
    },
    searchContainer: {
        display: "flex",
        alignItems: "center",
    },
    lengthWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "8px",
        padding: "5px",
    },
    lengthText: {
        fontFamily: "Montserrat",
        fontSize: "16px",
        fontWeight: 600,
    },
    mainWorkFlowContainer: {
        position: "relative",
        padding: "16px 20px 16px 20px",
        height: "100vh",
    },
    pageSubheading: {
        fontFamily: "Montserrat",
        fontSize: "16px",
        textAlign: "center",
        fontWeight: 500,
        color: "#475569",
    },
    tableContainer: {},
    tableHeadCell: {
        borderBottom: "none",
    },
    useBtn: {
        borderRadius: "8px",
        textTransform: "none",
        border: "1px solid #CBD5E1",
        "&:hover": {
            borderColor: "#e7e7e7",
            color: "#FFFFFF",
            backgroundColor: "#000",
        },
    },
    optionsCell: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
    },
    optionsTimeStampDateCell: {
        color: "#475569",
        fontWeight: 500,
        fontSize: "12px",
        fontFamily: "Montserrat",
        marginLeft: "5px",
    },
    optionsTimeStampCell: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    optionsConversationCell: {
        display: "flex",
        alignItems: "center",
    },
    optionsConversationHeadingCell: {
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: 600,
        marginLeft: "5px",
    },
    optionsworkFlowNameCell: {
        display: "flex",
        alignItems: "center",
    },
    optionsworkFlowheadingCell: {},
    sessionDesc: {
        width: "250px",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        fontSize: "12px",
        fontFamily: "Montserrat",
        fontWeight: 600,
        lineHeight: "16px",
        color: "#475569",
        maxHeight: "32px",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        overflow: "hidden",
        webkitLineClamp: 2,
        lineClamp: 2,
    },
    sessionTitle: {
        fontWeight: 600,
        display: "flex",
        alignItems: "center"
    },
    icon: {
        height: "24px",
        cursor: "pointer",
        width: "24px",
    },
    rightBarIcon: {
        position: "absolute",
        right: "10px",
        top: "10px",
        zIndex: 4200,
    },
    dFlex: {
        display: "flex",
        alignItems: "center",
    },
    removeSelectionText: {
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: 500,
        cursor: "pointer",
        color: "#D93855",
    },
    selectAllText: {
        fontWeight: 500,
        fontSize: "14px",
        fontFamily: "Montserrat",
    },
    shareFeildContainer: {
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        marginRight: "10px",
    },
    tableRowContainer: {
        "&:hover": {
            borderBottom: '2px solid #A68B3D',
            backgroundColor: "#FFFBF4",
        },
    },
    postEventBorder: {
        "&:hover": {
            borderBottom: "2px solid #D93855",
            backgroundColor: "#f2e9ea",
        },
    },
    feedbackBorder: {
        "&:hover": {
            borderBottom: "2px solid #3A84C9",
            backgroundColor: "#eff4f9",
        },
    },
    marketingBorder: {
        "&:hover": {
            borderBottom: "2px solid #57BE99",
            backgroundColor: "#EAF7F2",

        },
    },
    postEventTag: {
        border: "1px solid #D93855",
        color: "#D93855",
        borderRadius: "26px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 500,
        fontSize: "14px",
        fontFamily: "Montserrat",
        width: "200px",
        padding: "5px 0px",

    },
    feedbackTag: {
        width: "200px",
        color: "#3A84C9",
        padding: "5px 0px",
        border: "1px solid #3A84C9",
        borderRadius: "26px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: 500,
    },
    marketingTag: {
        color: "#57BE99",
        padding: "5px 0px",
        borderRadius: "26px",
        border: "1px solid #57BE99",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Montserrat",
        fontSize: "14px",
        fontWeight: 500,
        width: "200px",

    },
    post: {
        backgroundColor: "#FEE2E2",
    },
    marketing: {
        backgroundColor: "#D1FAE5",
    },
    feedback: {
        backgroundColor: "#3A84C933",
    },
    copilot: {
        backgroundColor: "#ECE1CD",
    },
    modal: {
        padding: "40px",
        borderRadius: "20px",
        fontFamily: "Montserrat",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
    },
    closeIcon: {
        height: "14px",
        cursor: "pointer",
        width: "14px",
    },
    closeImg: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end"
    },
    modalTitle: {
        fontSize: "24px",
        fontWeight: 700,
        textAlign: "center",
        maxWidth: "75%",
        margin: "auto",
    },
    modaldesc: {
        fontSize: "16px",
        fontWeight: 600,
        textAlign: "center",
        maxWidth: "75%",
        margin: "auto",
        color: "#475569"
    },
    modalButtonContainer: {
        marginTop: "32px",
        display: "flex",
        flexDirection: "row",
        gap: "20px"
    },
    modalCancelBtn: {
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        fontSize: "16px",
        fontWeight: 600,
        flex: 1,
        textTransform: "inherit",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        }
    },
    modalRemoveBtn: {
        borderRadius: "8px",
        fontSize: "16px",
        fontWeight: 600,
        color: "#FFFFFF",
        background: "#000000",
        flex: 1,
        textTransform: "inherit",
        "&:hover": {
            backgroundColor: "#000000",
        }
    },
    modalDeleteBtn: {
        borderRadius: "8px",
        border: "1px solid #DC2626",
        fontSize: "16px",
        fontWeight: 600,
        color: "#DC2626",
        background: "#FFFFFF",
        flex: 1,
        textTransform: "inherit",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        }
    },
    inputTitle: {
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "19px",
    },
    editInputBtn: {
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "inherit",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        }
    },
    nameInputContainer: {
        display: "flex",
        gap: "8px"
    },
    textInput: {
        "& input": {
            padding: "12px"
        }
    },
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        marginTop: "32px"
    },
    modalWordFileBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        fontSize: "16px",
        fontWeight: 600,
        flex: 1,
        textTransform: "inherit",
        "&:hover": {
            backgroundColor: "#FFFFFF",
        }
    },
    noteText: {
        fontSize: "12px",
        fontWeight: 500,
        color: "#475569"
    },
}
// Customizable Area End

export default withStyles(styles)(MyLibrary);
