import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import {leftRock,rightRock,backImg,infoIcon,guidelineImg, pebbleLogo} from './assets'

import BeforeWeStartController,{Props} from './BeforeWeStartController'



export class BeforeWeStart extends BeforeWeStartController {
    constructor(props: Props) {
        super(props);
    }


    render() {
        const { classes } = this.props;

        return (
            <Box className={classes.container}>
                <img src={pebbleLogo} className={classes.logo}/>
                <Box className={classes.leftSection}>
                    <img src={leftRock} className={classes.leftRockImg}/>
                </Box>
                <Box className={classes.content}>
                    <Typography className={classes.title}>{'Before we start'}</Typography>
                    <Typography className={classes.subTitle}>{'Whenever you see the ‘i’ icon, take a moment to hover over it.\nThis will reveal essential context and guidance to enhance your experience with Pebbles Ai.'}</Typography>
                    <Box className={`${classes.circle4} ${classes.circle1}`}>
                        <Box className={`${classes.circle3} ${classes.circle1}`}>
                            <Box className={`${classes.circle2} ${classes.circle1}`}>
                                <Box className={`${classes.circle1}`}>
                                    <img src={guidelineImg} className={classes.guidelineStyle}/>
                                    <img src={infoIcon} className={classes.infoIcon}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Button data-testid='continueBtn' className={classes.button}
                    onClick={()=>this.aboutCompany()}
                    >{'Continue'}</Button>
                </Box>
                <Box className={classes.rightSection}>
                    <img src={rightRock} className={classes.rightRockImg}/>
                </Box>
                <img src={backImg} className={classes.backBtn} onClick={()=>this.ChooseYourPath()}
                data-testid='BackBtn'
                />
            </Box>
        );
    }
}

const styles = () =>
  createStyles({
    container:{
        background:'linear-gradient(130deg, #F9F9F9 -25.99%, #EBE2CF 77.55%)',
        height:'100%',
        width:'100%',
        position:'relative',
        display:'flex'
    },
    logo: {
        mixBlendMode: "multiply",
        position:'absolute',
        top:0,
        left:0,
        height:'25px',
        width:'125px',
        '@media (min-width: 600px)' : {
          height:'40px',
          width:'200px',
        },
      },
    backBtn:{
        position:'absolute',
        top:84,
        left:40,
        cursor: 'pointer',
    },
    leftRockImg:{
        position:'relative',
        left:0,
        top:141
    },
    rightRockImg:{
        
    },
    content:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:"center",
        flex:1,
    },
    leftSection:{
        display:'flex',
        flexDirection:'column',
        height:'100%',
        position:'relative'
    },
    rightSection:{
        display:'flex',
        flexDirection:'column',
        position:'relative',
        height:'100%',
        justifyContent:'flex-end'
    },
    title:{
        fontSize:'48px',
        lineHeight:1.2,
        fontFamily:'Montserrat',
        fontWeight:600,
    },
    subTitle:{
        fontSize:'18px',
        lineHeight:1.5,
        fontFamily:'Montserrat',
        fontWeight:500,
        whiteSpace: 'pre-line',
        textAlign:'center',
        marginTop:'16px',
        color:"#475569"
    },
    infoIcon:{
        width:'52px',
        height:'52px',
    },
    circle1:{
        borderWidth:1,
        border:"1px solid #3A84C9cc",
        padding:'26px',
        borderRadius:'500px',
        position:'relative',
    },
    circle2:{
        border:"1px solid #3a84c999",
    },
    circle3:{
        border:"1px solid #3A84C966",
    },
    circle4:{
        border:"1px solid #3A84C91a",
        marginTop:'50px'
    },
    guidelineStyle:{
        position:'absolute',
        top:0,left:'-310px'
    },
    button:{
      height: "40px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: 1.2,
      background: "#000",
      textTransform: "none",
      fontFamily: "Montserrat",
      color: "#fff",
      fontStyle: "normal",
      marginTop: "50px",
      width:'375px',
      "&:hover": {
        background: "#000"
      }
    }
  });

export default withStyles(styles)(BeforeWeStart);