import React from "react";

// Customizable Area Start

import {
    Box,
    Button,
    Typography,
    Paper,
    createStyles
} from "@material-ui/core";
import { successBg, successCheck } from "./assets";
import AboutController from "./AboutController";


export default class CompanySuccess extends AboutController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
            <Box style={CompanySuccessPageStyles.mainContainerStyle}>
                <Paper style={CompanySuccessPageStyles.mainform}>
                    <Box>
                        <img src={successCheck} alt="CompanyCheckIcon" />
                    </Box>
                    <Box>
                        <Box>
                            <Typography style={CompanySuccessPageStyles.companyMsg} align="center">
                               We’ll reach out as soon as possible.
                            </Typography>
                            <Typography style={CompanySuccessPageStyles.companyMsg2} align="center">
                            And while you await our response, take a moment to dive into Pebbles and discover its capabilities.
                            </Typography>
                        </Box>
                        <Box>
                            <Button style={CompanySuccessPageStyles.Aibutton} type="button" onClick={this.handleAiButton} fullWidth>
                              {this.getButtonTitle()}
                            </Button>
                            <Button />
                        </Box>
                    </Box>
                </Paper>
            </Box>
        );
    }
}

// Customizable Area Start

const CompanySuccessPageStyles: any = createStyles({
    mainContainerStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundImage: `url(${successBg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
    },
    companyMsg: {
        margin: "10px  0px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "30px"
    },
    companyMsg2: {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "18px",
        color: "rgba(71, 85, 105, 1)"
    },
    mainform: {
        width: "100%",
        height: "100%",
        maxWidth: "648px",
        maxHeight: "506px",
        borderRadius: "28px",
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: " space-around",
        alignItems: "center",
        background:
            "linear-gradient(0deg, rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 0.72))"
    },
    Aibutton: {
        margin: "25px 0px ",
        color: "white",
        backgroundColor: "black",
        height: "44px",
        marginRight: "5px",
        cursor: "pointer",
        borderRadius: "8px",
        border: " 1px solid rgba(203, 213, 225, 1)",
        textTransform: "none",
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "14px",
        alignItems:'center',
        justifyContent:'center',


    }
});

// Customizable Area End
