import React from "react";


// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Container,
    IconButton,
    Grid,
    Tooltip,
    Divider,
    TextField,
    InputAdornment,
    Modal
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";
import { rightBar, replyIcon, copy,sendIcon,addCircle,disableAdd,disableCopy,textStyle,replyDisableIcon } from "./assets";
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import EditIcon from '@material-ui/icons/Edit';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CustomToaster from "../../../components/src/ChatHistoryToaster.web";
import DeleteCampaign from "../../../components/src/DeleteCampaign.web";
import ShareFileModal from "./ShareFileModal.web";

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    }
});
const DeleteTooltip = withStyles({
    tooltip: {
        fontSize: "0.75rem",
        color: "#fff",
        backgroundColor: "#D93855",
        fontWeight:500,
        fontFamily:'Montserrat',
        padding:'10px'
    },
    arrow:{
        color:'#D93855',
        fontSize:'15px'
    }
})(Tooltip);

const CommonTooltip = withStyles({
    tooltip: {
        fontSize: "0.75rem",
        color: "#fff",
        backgroundColor: "#0B67BC",
        fontWeight: 500,
        fontFamily: 'Montserrat',
        padding: '10px'
    },
    arrow: {
        color: '#0B67BC',
        fontSize: '15px'
    }
})(Tooltip);

// Customizable Area End

import EmailCreationController, {
    Props,
    configJSON,
} from "./EmailCreationController";
import ParameterModal from "./ParameterModal.web";
import ParameterChangeDialog from "./ParameterChangeDialog.web";

export class EmailCreation extends EmailCreationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        return (
            <ThemeProvider theme={theme}>
                <NavigationMenuComponent
                    id={this.props.id}
                    navigation={this.props.navigation}
                    activeIndex={-1}
                    data-testid="nav-menu"
                    handleRightMenu={this.handleRightMenu}
                    rightMenu={this.state.rightMenu}>
                    <Container className={classes.mainContainer} data-testid="email-creation">
                    <CustomToaster  data-testid="CustomToaster" open={this.state.saveMessage} message={configJSON.saveMessage} onClose={this.handleSave}/>
                    <DeleteCampaign data-testid="delete-modal" deleteModalopen={this.state.deletePopup} handleDelteModal={this.handleDelete}/>
                    <ShareFileModal data-testid="share-modal" open={this.state.shareModal} onClose={this.handleShare} {...this.props}/>
                     <Box className={classes.firstBox}>
                             {!this.state.rightMenu &&
                                 <IconButton
                                     aria-controls="simple-menu"
                                     aria-haspopup="true"
                                     data-testid="right-menu"
                                     onClick={this.handleRightMenu}
                                     className={classes.rightBar}
                                 >
                                     <img
                                         src={rightBar}
                                         alt="right-bar"
                                     />
                                 </IconButton>
                             }
                             <Grid container alignItems="center">
                                 <Grid item xs={12} sm={6} md={4}>
                                     <Typography className={classes.title}>{this.state?.campDetails?.attributes?.campaign?.name}</Typography>
                                     <Typography className={classes.description}>{this.state?.campDetails?.attributes?.campaign?.description}</Typography>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={4} className={classes.marketingDisplay}>
                                     <Typography className={classes.marketingText}>{configJSON.customMarketing}</Typography>
                                 </Grid>
                                 <Grid item xs={12} sm={6} md={4} className={classes.iconCOntainer}>
                                     <Box className={classes.iconDisplay}>
                                         <CommonTooltip title={configJSON.editCamp} arrow>
                                             <EditIcon className={classes.iconColor} onClick={this.handleOpenParameterDialog} data-testid="edit-icon"/>
                                         </CommonTooltip>
                                         <Box className={classes.divider}></Box>
                                         <CommonTooltip title={configJSON.saveCamp} arrow onClick={this.handleSave}>
                                             <BookmarkBorderIcon className={classes.iconColor} />
                                         </CommonTooltip>
                                         <CommonTooltip title={configJSON.shareCamp} arrow>
                                             <img src={replyIcon} alt="reply" className={classes.iconHover} data-testid="share-icon" onClick={this.handleShare}/>
                                         </CommonTooltip>
                                         <Box className={classes.divider}></Box>
                                         <DeleteTooltip title={configJSON.deleteCamp} data-testid="delete-icon" arrow onClick={this.handleDelete}>
                                             <DeleteOutlineRoundedIcon color="error" className={classes.iconHover}/>
                                         </DeleteTooltip>
                                     </Box>
                                 </Grid>
                             </Grid>
                             <Box className={classes.displaySecondDiv}>
                                 <Button 
                                     variant="contained"
                                     endIcon={<SwapCallsIcon className={classes.iconColor}/>}
                                     className={classes.swapButton}
                                     onClick={this.handleOpenParameterDialog}
                                 >
                                     {configJSON.openParameter}
                                 </Button>
                             </Box>
                         </Box>
                         <Divider />
                         <Box className={classes.SecondBox}>
                             {this.state.generateMail &&
                                 <Box className={classes.mailMainBox}>
                                     {this.state.mailArray.map((data, index) =>
                                         <Box className={classes.mailArrayBox} key={index}>
                                             <Box className={classes.dayBox}>
                                                 <CalendarTodayIcon className={classes.calenderIcon} />
                                                 <Typography className={classes.dayText}>{data.day} {index + 1}</Typography>
                                             </Box>
                                             <Box className={this.state.mailActive === index + 1 ? classes.innerMailBoxActive : classes.innerMailBox} onClick={() => this.handleSelectMail(index + 1)} data-testid={"mail-select-" + index}>
                                                 <MailOutlineIcon className={classes.mailIcon} />
                                                 <Typography className={classes.mailText} data-testid={"mail-" + index}>{data.mail} {index + 1}</Typography>
                                             </Box>
                                         </Box>
                                     )}
                                     <Box>
                                         {this.state.mailArray.length > 6 ?
                                             <Button
                                                 variant="contained"
                                                 data-testid="disabled-mailbutton"
                                                 disabled
                                                 className={classes.buttonDisabled}
                                                 endIcon={<AddCircleOutlineIcon />}
                                             >{configJSON.addMail}</Button> :
                                             <Button
                                                 variant="contained"
                                                 className={classes.buttonEnabled}
                                                 onClick={this.handleAddMail}
                                                 data-testid="add-mail"
                                                 endIcon={<AddCircleOutlineIcon />}
                                             >{configJSON.addMail}</Button>}
                                     </Box>
                                 </Box>
                             }
                             <Box className={classes.subjectBox}>
                                 <Typography className={classes.subjectLineLabel}>{configJSON.subjectLine}</Typography>
                                 <TextField
                                     variant="outlined"
                                     name="subject"
                                     data-testid="subject-field"
                                     value={this.state.subject}
                                     fullWidth
                                     size="small"
                                     InputProps={{
                                         className: classes.inputProps
                                     }}
                                 />
                             </Box>
                             <img src={textStyle} className={classes.textStyle}/>
                             <TextField
                                 variant="outlined"
                                 name="mail"
                                 fullWidth
                                 data-testid="mail-textfield"
                                 placeholder={configJSON.mailPlaceholder}
                                 value={this.state.mail}
                                 multiline
                                 rows={18}
                                 onChange={this.handleMailChange}
                                 InputProps={{
                                     className: classes.mailBoxInputProps
                                 }}
                             />
                                 <Box className={classes.generatebuttonDisplay}>
                                     <Button
                                         variant="contained"
                                         data-testid="generate-button"
                                         className={classes.generateButton}
                                         onClick={this.handleGenerate}
                                         disabled={this.state.mail === ''}
                                         endIcon={this.state.mail === '' ? <img src={disableAdd} /> : <img src={addCircle} />}
                                     >{configJSON.generateMail}</Button>
                                     <Box className={classes.buttonEditDisplay}>
                                     <Button
                                         variant="outlined"
                                         className={classes.copyButton}
                                         data-testid="save-button"
                                         onClick={this.handleSave}
                                         disabled={this.state.mail === ''}
                                         endIcon={this.state.mail === '' ? <BookmarkBorderIcon style={{color:'#BDC1BD'}}/> : <BookmarkBorderIcon className={classes.iconColor} />}
                                     >{configJSON.saveLibrary}</Button>
                                     <Button
                                         variant="outlined"
                                         className={classes.copyButton}
                                         disabled={this.state.mail === ''}
                                         endIcon={this.state.mail === '' ? <img src={disableCopy}/> : <img src={copy} />}
                                     >{configJSON.copy}</Button>
                                     <Button
                                         variant="outlined"
                                         className={classes.copyButton}
                                         onClick={this.handleShare}
                                         disabled={this.state.mail === ''}
                                         endIcon={ this.state.mail === ''? <img src={replyDisableIcon}/>: <img src={replyIcon} />}
                                     >{configJSON.share}</Button>
                                     </Box>
                                 </Box> 
                         </Box>
                         <Box className={classes.ThirdBox}>
                             <TextField
                                 id="input-with-icon-textfield"
                                 variant="outlined"
                                 placeholder={configJSON.editMailPlaceholder}
                                 minRows={4}
                                 fullWidth
                                 InputProps={{
                                     endAdornment: (
                                         <InputAdornment position="end" className={classes.inputAdornment}>
                                             <img src={sendIcon} />
                                         </InputAdornment>
                                     ),
                                     className: classes.mailEditorInput
                                 }}
                             />
                             <Typography className={classes.pebbelsCopilot}>{configJSON.pebbelsCopilot}</Typography>
                         </Box>
                         <Modal
                            className={classes.modal}
                            data-testid="ope-para-modal"
                            open={this.state.parameterDialog}
                            onClose={this.handleCloseParameterDialog}
                        >
                            <ParameterModal
                                formValueData={this.state.formValueData}
                                formData={this.state.formData}
                                onCloseModal={this.handleCloseParameterDialog}
                                id="ParameterModal"
                                data-testid="parameter-modal"
                                navigation={this.props.navigation}
                                onApply={this.handleCloseParameterDialog}
                                onSave={this.handleOpenParameterDialogChange}
                            />
                        </Modal>
                        <ParameterChangeDialog 
                            open={this.state.parameterChangeDialog}
                            data-testid="parameter-change-dialog"
                            onApplyChange={this.handleCloseParameterDialogChange}
                            onSaveChange={this.handleCloseParameterDialogChange}
                            onClose={this.handleCloseParameterDialogChange}
                        />
                    </Container>
                </NavigationMenuComponent>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: {} = () => ({
    rightBar: {
        position: "absolute" as "absolute",
        right: "0",
        zIndex: "9999",
        height: 'max-content',
        top: '0'
    },
    mainContainer: {
        position: 'relative',
        padding: '0'
    },
    iconDisplay: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        flexWrap: 'wrap'
    },
    marketingText: {
        border: '1px solid #57BE99',
        borderRadius: '26px',
        padding: '4px 12px',
        color: '#57BE99',
        fontSize: '0.75rem',
        fontWeight: 500,
        width: 'max-content',
        textAlign: 'center'
    },
    title: {
        fontWeight: 600
    },
    description: {
        fontSize: '0.75rem',
        fontWeight: 600,
        color: '#475569'
    },
    iconColor: {
        color: "#3A84C9",
        cursor: 'pointer'
    },
    marketingDisplay: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    divider: {
        width: "0.1rem",
        height: '1.5rem',
        background: '#94A3B8'
    },
    iconHover: {
        cursor: 'pointer'
    },
    parameter: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    applyParameterText: {
        fontSize: '0.875rem',
        fontWeight: 500
    },
    displaySecondDiv: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'start',
        marginTop:'10px'
    },
    showParaDiv: {
        display: "flex",
        alignItems: "center",
        flexWrap: 'wrap'
    },
    showParamText: {
        color: "#3A84C9"
    },
    arrowDown: {
        height: "1rem",
        width: "1rem",
        color: "#3A84C9",
    },
    firstBox: {
        padding: '20px',
        background:'#F1F5F9'
    },
    SecondBox:{
        padding: '20px 10px',
    },
    mailMainBox: {
        display: 'flex',
        alignItems: 'end',
        gap: '10px',
        flexWrap: 'wrap'
    },
    mailArrayBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
    },
    dayBox: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        paddingLeft: '14px',
        flexWrap: 'wrap'
    },
    calenderIcon: {
        fontSize: '1.2rem',
        color: '#64748B'
    },
    dayText: {
        color: '#64748B',
        fontWeight: 500
    },
    innerMailBox: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        background: '#F1F5F9',
        width: 'maxContent',
        padding: '10px 30px 10px 10px',
        borderRadius: '8px',
        cursor: 'pointer'
    },
    innerMailBoxActive: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        background: '#D1FAE5',
        width: 'maxContent',
        padding: '10px 30px 10px 10px',
        borderRadius: '8px',
        cursor: 'pointer'
    },
    mailIcon: {
        color: '#475569'
    },
    mailText: {
        color: '#334155'
    },
    buttonDisabled: {
        background: '#CBD5E1',
        borderRadius: '8px',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        color: '#64748B',
        padding: '10px',
        textTransform: 'inherit',

    },
    buttonEnabled: {
        background: '#000',
        borderRadius: '8px',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        color: '#fff',
        padding: '10px',
        textTransform: 'inherit'
    },
    subjectLineLabel: {
        fontWeight: 600,
        color: '#000'
    },
    subjectBox: {
        margin: '20px 0 10px'
    },
    copyButton: {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
        textTransform: 'inherit',
        fontFamily: 'Montserrat',
        color: '#000',
        fontWeight: 600
    },
    buttonDisplay: {
        display: 'flex',
        gap: '10px',
        marginTop: '5px'
    },
    buttonEditDisplay:{
        display: 'flex',
        gap: '10px',
    },
    ThirdBox: {
        background: '#F8FAFC',
        padding: '10px'
    },
    inputAdornment: {
        alignItems: 'start'
    },
    inputProps: {
        borderRadius: '8px'
    },
    mailEditorInput: {
        fontSize: '0.75rem',
        color: '#64748B',
        fontFamily: 'Montserrat',
        fontStyle: 'italic',
        borderRadius: '8px',
        'input':{
            '& .MuiOutlinedInput-inputAdornedEnd-523':{
            padding: '10px 0 38px 10px'
            }
        }
    },
    pebbelsCopilot: {
        fontSize: '0.75rem',
        fontWeight: 500,
        color: '#475569',
        padding: '5px'
    },
    generateButton:{
        background:'#000',
        color:'#fff',
        fontWeight:600,
        borderRadius:'8px',
        textTransform: 'inherit'
    },
    generatebuttonDisplay:{
        display:'flex',
        justifyContent:'space-between',
        border: '1px solid #3A84C9',
        borderWidth: '0 1px 1px 1px',
        borderRadius: '0 0 8px 8px',
        padding: '5px',
        background: '#F1F5F9'
    },
    iconCOntainer:{
        zIndex:'9999'
    },
    swapButton:{
        border:'1px solid #CBD5E1',
        borderRadius:'8px',
        background:'#fff',
        color:'#000',
        fontSize:'0.875rem',
        fontWeight:500,
        boxShadow:'none',
        textTransform: 'inherit',
    },
    textStyle:{
        width:'100%',
        marginBottom:'5px'
    },
    mailBoxInputProps:{
        border: '1px solid #3A84C9',
        borderRadius:'8px 8px 0 0',
        fontSize: '0.875rem',
        fontWeight: 500,
        color: '#090A14'
    },
    modal:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%"
    }
});
export default withStyles(webStyle)(EmailCreation);

// Customizable Area End
