import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Divider, IconButton, Typography } from "@material-ui/core";
import {copy,thinkigRobo,
    dislike,
    like,
    bookmsrkGrey,
    selectedBookmark,
    answerReadyRobo,
    bookmark

} from "../../blocks/chatbot4/src/assets"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        messageRow: {
            display: "flex",
        },
        messageRowRight: {
            display: "flex",
            justifyContent: "flex-end",
        },
        messageRowRightThinking: {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "25px",
        },
        messageRecive: {
            position: "relative",
            padding: "10px",
            backgroundColor: "rgba(241, 245, 249, 1)",
            width: "55%",
            textAlign: "left",
            font: "400 .9em 'Montserrat', Montserrat",
            borderRadius: "10px",
            border: "1px solid #CBD5E1",
            height: "max-content"

        },
        messageSend: {
            position: "relative",
            padding: "10px",
            backgroundColor: "rgba(30, 41, 59, 1)",
            color: "#FFF",
            width: "55%",
            textAlign: "left",
            font: "400 .9em 'Montserrat', Montserrat",
            borderRadius: "10px",
            height: "max-content"

        },

        messageContent: {
            padding: 0,
            margin: 0,
            fontWeight: 400,
            fontFamily: "Montserrat",
            fontSize: "14px",
        },
        messageTimeStampRight: {
            position: "absolute",
            fontSize: ".85em",
            fontWeight: 300,
            marginTop: "10px",
            bottom: "-3px",
            right: "5px"
        },

        avatarNothing: {
            color: "transparent",
            backgroundColor: "transparent",
            width: theme.spacing(4),
            height: theme.spacing(4)
        },
        displayName: {
            marginLeft: "20px"
        },
        imageIconStyle: { height: "16px", width: "16px", cursor: "pointer", padding: "5px" },
        timeStamp: {
            fontWeight: 500,
            fontFamily: "Montserrat",
            fontSize: "12px",
        }
    })
);

export const MessageLeft = (props: any) => {
    const message = props.message ? props.message : "User experience design is the process of defining the experience a user would go through when interacting with a company, its services, and its products. Design decisions in UX design are often driven by research, data analysis, and test results rather than aesthetic preferences and opinions.";
    const timestamp = props.timestamp ? props.timestamp : "";
    const photoURL = props.photoURL ? props.photoURL : "";
    const displayName = props.displayName ? props.displayName : "Jon Doe";
    const classes = useStyles();
    return (
        <Box style={{
            marginBottom: "25px",
            marginLeft: "20px",
        }}>
        <Box className={classes.messageRow}>
            <Box style={{ display: "flex" }}>
                <Box className={classes.messageRecive}>
                    <Box>
                        <Typography className={classes.messageContent}>{message}</Typography>
                    </Box>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginLeft: "5px" }}>
                    <img src={copy}
                        className={classes.imageIconStyle}
                        onClick={() => {
                            navigator.clipboard.writeText("copied..!");
                        }}
                    />
                    <img src={bookmsrkGrey} className={classes.imageIconStyle} />
                    <Divider />
                    <img src={dislike} style={{ height: "14px", width: "14px", cursor: "pointer", padding: "5px" }} />
                    <img src={like} style={{ height: "14px", width: "14px", cursor: "pointer", padding: "5px" }} />
                </Box>

            </Box>
        </Box>
            <Typography align="left" className={classes.timeStamp}>12:10</Typography>
        </Box>
    );
};

export const MessageRight = (props: any) => {
    const classes = useStyles();
    const message = props.message ? props.message : "What is UX Design?";
    const timestamp = props.timestamp ? props.timestamp : "";
    return (
        <Box style={{
            marginBottom: "25px",
            marginRight: "20px",

        }}>
            <Box className={classes.messageRowRight}>
                <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", marginRight: "5px" }}>
                    <img src={copy} style={{ height: "16px", width: "16px", cursor: "pointer" }}
                        onClick={() => {
                            navigator.clipboard.writeText("copied..!");
                        }}
                    />
                    <img src={bookmsrkGrey} style={{ height: "16px", width: "16px", cursor: "pointer" }} />
                </Box>
                <Box className={classes.messageSend}>
                    <Typography className={classes.messageContent}>{message}</Typography>
                </Box>
            </Box>
            <Typography align="right" className={classes.timeStamp}>12:10</Typography>

        </Box>

    );
};
