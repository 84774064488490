import React from "react";
// Customizable Area Start
import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
import * as Yup from "yup";
import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Link,
  TextField,
  Divider,
  createStyles
} from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Layout from "../../../components/src/Layout";
import { GoogleImg } from "./assets";

// Customizable Area End

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Email address is not valid.")
    .required("Email address is required.")
});

const handleSubmit = () => {};

export default class SignUpProfessional extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  render() {
    return (
      <Layout carousel={null}>
        <Container style={pageStyles.containerStyle}>
          <Paper style={pageStyles.signupform}>
            <Typography
              align="center"
              style={pageStyles.typographyStyle1}
              variant="h6"
            >
              Sign up
            </Typography>
            <Typography
              align="center"
              style={pageStyles.typographyStyle2}
              variant="h6"
            >
              Create an account to access Pebbles Ai.
            </Typography>
            <Typography
              align="center"
              style={pageStyles.typographyStyle2}
              variant="h6"
            >
              Already have an account? <Link>Log in</Link>
            </Typography>
            <Box>
              <Formik
                initialValues={{
                  email: ""
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ errors, touched }) => (
                  <Form translate={undefined}>
                    <Box style={{ margin: "30px 0px" }}>
                      <Typography
                        align="left"
                        style={pageStyles.inputFeildText}
                        variant="h6"
                      >
                        Email
                      </Typography>
                      <Field
                        data-testid="emailFieldTestId"
                        variant="outlined"
                        fullWidth
                        placeholder="Provide an email address "
                        as={TextField}
                        InputProps={{
                          style: {
                            height: "40px",
                            borderRadius: 8
                          }
                        }}
                        error={!!(errors.email && touched.email)}
                        name="email"
                      />
                      <ErrorMessage
                        data-test-id="FormikErrorMessageDataTestId"
                        name="email"
                        component="div"
                        render={message => (
                          <div
                            style={{
                              color: "red",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                              fontSize: "12px",
                              margin: "5px"
                            }}
                          >
                            {message}
                          </div>
                        )}
                      />
                    </Box>
                    <Button
                      type="submit"
                      style={{
                        margin: "25px 0px ",
                        color: "white",
                        backgroundColor: "black",
                        height: "44px",
                        // width:"200px",
                        marginRight: "5px",
                        cursor: "pointer",
                        borderRadius: "8px",
                        border: " 1px solid rgba(203, 213, 225, 1)",
                        textTransform: "none",
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                        fontSize: "14px"
                      }}
                      onClick={() => {}}
                      fullWidth
                    >
                      Continue with email
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
            <Divider style={{ height: "1px" }} />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <Typography
                align="center"
                style={{
                  margin: "-12px",
                  width: "100px",
                  background: "#F8F5F0",
                  display: "inline"
                }}
              >
                or
              </Typography>
            </Box>
            <Button variant="contained" style={pageStyles.googlebtn}>
              <img src={GoogleImg} alt="googlelogo" />
              <Typography
                style={pageStyles.googleTxt}
                data-testid="googleLoginBtn"
              >
                Continue with Google
              </Typography>
            </Button>
          </Paper>
        </Container>
      </Layout>
    );
  }
}

const pageStyles: any = createStyles({
  containerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  },
  paperStyle: {
    padding: "20px",
    width: "520px",
    borderRadius: "28px",
    height: "273px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)"
  },
  typographyStyle1: {
    margin: "10px  0px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "30px"
  },
  typographyStyle2: {
    // margin: '16px  0px',
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    color: "rgba(71, 85, 105, 1)"
  },
  buttonStyle: {
    width: "100%",
    margin: "30px  0px"
  },
  loginButton: {
    margin: "5px 0px ",
    color: "white",
    backgroundColor: "black",
    height: "44px"
  },
  signInButton: {
    margin: "5px 0px ",
    color: "black",
    backgroundColor: "white",
    height: "44px"
  },

  chooseyouoptionStyle: {
    padding: "20px",
    width: "520px",
    borderRadius: "28px",
    height: "221px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)"
  },
  signupform: {
    width: "520px",
    height: "471px",
    borderRadius: "28px",
    padding: "40px",
    background: "#F8F5F0"
  },
  inputFeildText: {},
  googlebtn: {
    height: "45px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
    marginTop: "40px",
    border: "1px solid rgba(140, 140, 140, 0.20)",
    background: "#FFF",
    boxShadow: "none",
    width: "100%",
    "&:hover": {
      background: "#FFF"
    }
  },
  googleTxt: {
    color: "#000",
    marginLeft: "8px",
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily: "Montserrat",
    textTransform: "capitalize"
  }
});