import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import StorageProvider from "../../../framework/src/StorageProvider";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  name: string;
  description: string;
  onCloseModal: boolean;
  isModalOpen: boolean;
  isSaveWorkFlow:boolean;
  formData: any;
  radioValue: {
    [key:string]:{
      value: string;
      answerId: number;
    }
  };
  isSaveAndContinueModal:boolean;
  isBlurred:boolean;
  persuasionTacticsAnchorEl:{
    [index:number]:EventTarget & HTMLDivElement | null
  },
  persuasionTacticsAnchorElAnswer:{
    [index:string]:{
      answer: string,
      ans_id: number,
    }
  },
  communicationTacticsAnchorEl:{
    [index:number]:EventTarget & HTMLDivElement | null
  },
  communicationTacticsAnchorElAnswer:{
    [index:string]:{ 
      answer: string,
      ans_id: number,
    }
  },
  selectedChips: {
    [questionId: string]: {
      [chipsName: string]: string;
    };
  };
  campaignName: string;
  isResponseReady: boolean;
  textareaValue: {
    [key:string]:string
  };
  campaign: {
    [key:string]:string
  };
  campaignId: number;
  sliderValues: {
    [questionIcon: string]: string;
  };
  isLoading: boolean;
  campType:number;
  campaignCate:any;
  successMess:string;
  importDataModal:boolean;
  companyInfoData: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfdataintegrationviapromptengineering4Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  formCreationId: string='';
  campaignSubmissionId: string='';
  formSubmissionId: string='';
  campainInfo:any;
  tacticalSaveId:string = '';
  tacticalSubmitId:string = '';
  campainValidation:any={}
  campainValue:any={}
  createEmailContructId: string='';
  fetchCompanyInfoId: string = ""

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      campaignCate:{},
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      name: '',
      description: '',
      onCloseModal: true,
      isModalOpen: true,
      isSaveWorkFlow:true,
      formData: [],
      radioValue: {},
      campaign:{},
      isSaveAndContinueModal:false,
      isBlurred: true,
      persuasionTacticsAnchorEl:{},
      persuasionTacticsAnchorElAnswer:{},
      communicationTacticsAnchorEl:{},
      communicationTacticsAnchorElAnswer:{},
      selectedChips:{},
      campaignName: '',
      isResponseReady: false,
      textareaValue: {},
      campaignId: 1,
      sliderValues: {},
      isLoading: false,
      campType:0,
      successMess:"",
      importDataModal:false,
      companyInfoData: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.formCreationId) {
      this.handleGetDataResponse(responseJson.data)
    }
    if(apiRequestCallId === this.createEmailContructId){
      this.handleCreateEmailConstruct(responseJson.data)
    }
    if(apiRequestCallId === this.tacticalSaveId){
      this.handleSavedTactical(responseJson.data)
    }
    if(apiRequestCallId === this.tacticalSubmitId){
      this.handleSubmitTactical(responseJson.data)
    }
    if(apiRequestCallId === this.campaignSubmissionId) {
      this.setState({ campaignId: responseJson.data.attributes.id})
    }
    if(apiRequestCallId === this.formSubmissionId) {
      setTimeout(this.timer, 60000)
    }
    if(apiRequestCallId === this.fetchCompanyInfoId) {
      this.handleFetchCompanyInfo(responseJson)
    }
    this.handelCampTypeIds(message)
    // Customizable Area End
  }

  handelCampTypeIds = (message:any) => {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(
        getName(MessageEnum.SessionResponseData),
      );
      this.setState({ campType: responseData?.campaign_type })
    }
  }

  sortArray = (arr:any[])=>{
    return arr.sort((a:any,b:any)=>a.order-b.order)
  }
  handleCreateEmailConstruct=(response:any)=>{
    const paramGroup = response[0].attributes.param_group.id;
    StorageProvider.set("param_group_id",paramGroup)
    this.setState({isSaveAndContinueModal:true})
  }

  handleGetDataResponse = async(responseJson:any)=>{
      const tempValidation:any = {}
    const emailContruct = responseJson.find((i:any)=>i.attributes.section === "EMAIL CONSTRUCT");
    const firstSection = emailContruct.attributes.questions.find((question:any)=>question.question ===`You've chosen to generate a(n):`)
    const response = await this.getCampainType(firstSection.answers);
    const {chosenAnswer,nestedAnswer,nestedQuestion}=response
    this.setState({
      campaignCate:response
    })
    emailContruct.attributes.questions.map((item:any)=>{
      if(item.question ===`You've chosen to generate a(n):`){   
        this.campainValue[`emailContruct-${item.id}`] = `${firstSection.id}-${chosenAnswer.id}/${nestedQuestion.id}-${nestedAnswer.id}`
        tempValidation[`emailContruct-${item.id}`]=Yup.string()
        return;
      }
      if(item.question ===`What is your target company? (optional) `){   
        this.campainValue[`emailContruct-${item.id}`] = ''
        tempValidation[`emailContruct-${item.id}`]=Yup.string()
        return;
      }
      this.campainValue[`emailContruct-${item.id}`] = ''
      if(item.required){
        tempValidation[`emailContruct-${item.id}`]=Yup.string()
        .required("This field is required")
      }else {

      tempValidation[`emailContruct-${item.id}`]=Yup.string()
      }
    })
    ;
    this.campainValidation=Yup.object().shape(tempValidation)
    this.setState({ formData: responseJson });
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };



  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
  ? this.txtInputWebProps
  : this.txtInputMobileProps;
 
  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPresseds(),
  };


  doButtonPresseds() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };



  // Customizable Area Start

  async componentDidMount() {
    this.formCreationId = await this.apiCall(configJSON.validationApiMethodType, configJSON.formCreationEndpoint)
    const campaign = JSON.parse(await StorageProvider.get('campaign')||'{id:1,campaign_category_id:1}');
    this.setState({
      campaign
    })
  }

  fetchCompanyInfo = async (value: string) => {
    const token = await getStorageData("user-token")

    const header = {
      token,
    };

    const formData = new FormData();

    formData.append("url", value);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchCompanyInfoId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchCompanyInfoEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFetchCompanyInfo = (responseJson: any) => {
    if(!responseJson?.extracted_info) {
      this.setState({
      companyInfoData: "",
    })
    } else {
      this.setState({
        companyInfoData: responseJson.extracted_info,
      })
    }

  }

  openImportDataModal=(value: string)=>{
    this.fetchCompanyInfo(value)
    this.setState({
      importDataModal:true
    })
  }

  onCloseImportDataModal = ()=>{
    this.setState({
      importDataModal:false
    })
  }

  onSaveNewCompanyInfo = (value: string) => {
    this.setState({
      companyInfoData: value,
    });
  }

  handleSavedTactical = (response:any) => {
   this.setState({
    successMess:"Workflow saved successfully!"
   })
  }

  handleSubmitTactical = async (response:any) => {
    this.setState({
      isLoading:true
    })
    const groupId = await StorageProvider.get("param_group_id");
    const body = {
      "campaign_id": this.state.campaignId || 1,
      "param_group_id": groupId
    }
    this.apiCall(configJSON.formSubmissionMethod, configJSON.apiEndPointCreateMail, body)
    setTimeout(()=>{
      this.setState({
        isLoading:false,
        isResponseReady:true
      })
    },20000)
    setTimeout(()=>{
      const message: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "EmailCreation"
      );
      message.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(message);
    },25000)
  }

  getCampainType= async(list:any[])=>{
    try {
      const campaignCate = JSON.parse(await StorageProvider.get('campaignCate'));
      const chosenCampaignCate = list.find((item) => item.answer.toLocaleLowerCase().replaceAll(" ", '') == campaignCate.name.toLocaleLowerCase().replaceAll(" ", ''));
      const nestedQuestion = chosenCampaignCate.nested_questions[0];
      const campaign = JSON.parse(await StorageProvider.get('campaign')||'{id:1,campaign_category_id:1}');
      const nestedAnswer = nestedQuestion.answers.find((item: any) => item.answer.toLocaleLowerCase() == campaign.title.toLocaleLowerCase())
      return {
        chosenAnswer: chosenCampaignCate,
        nestedQuestion: nestedQuestion,
        nestedAnswer: nestedAnswer
      }
    } catch (error) {
      return {
        chosenAnswer: null,
        nestedQuestion: null,
        nestedAnswer: null
      }
    }
  }

  handleCloseModal = async() =>{
    this.setState({ isModalOpen: false });
  }

  handleOpenModal = () => {
    this.setState({ isModalOpen: true , description: '', campaignName: ''});
  };

  onSubmitTacticalData = async(
    type:"saved"|'submitted',
    values:any,
  )=>{
    const listdata = this.state.formData.find((i:any)=>i.attributes.section === 'PERSUASION TACTICS');
    const questionList:any[]= listdata.attributes.questions
    let params = questionList.map((item:any)=>{
      const key = 'persuasion'
      let _param:any = {
        "description": this.state.description,
        "name": this.state.campaignName,
        "question": item.question,
        "question_id": item.id,
        "campaign_id": this.state.campaignId,
      }
      if(item.type==='chips'){
        const valueBylist=values[`${key}-${item.id}`].split('-').filter((item:string)=>!!item);
        _param.answer_id = valueBylist
      } else {
        _param.answer_id = Number(values[`${key}-${item.id}`])
      }
      return _param;
    })
    const listdata2 = this.state.formData.find((i:any)=>i.attributes.section === 'COMMUNICATION  TACTICS');
    const questionList2:any[]= listdata2?.attributes?.questions ||[]
    let params2 = questionList2.map((item:any)=>{
      let _param:any = {
        "question": item.question,
        "campaign_id": this.state.campaignId,
        "name": this.state.campaignName,
        "question_id": item.id,
        "description": this.state.description,
      }
      const key = 'communication'
      if(item.type!=='chips'){
        _param.answer_id = Number(values[`${key}-${item.id}`])
      } else {
        const valueBylist=values[`${key}-${item.id}`].split('-')
        _param.answer_id = valueBylist
      }
      return _param;
    })
    const paramsGroup = await StorageProvider.get('param_group_id')
    const body = {
      "param_group": {"id": paramsGroup},
      "campaign":{
        "id": this.state.campaignId,
        "status": type
      },
      param:params.concat(params2)
    }
    if(type==='saved'){
      this.tacticalSaveId = await this.apiCall(configJSON.formSubmissionMethod, configJSON.createCampainEndpoint,body);
    }else {
     this.tacticalSubmitId = await this.apiCall(configJSON.formSubmissionMethod, configJSON.createCampainEndpoint,body)
    }
  }

  onCloseToast = ()=>{
    this.setState({
      successMess:''
    })
  }
  
  openSaveAndContinueModal = async(values:any,type:"saved"|'submitted')=>{
    const emailContruct = this.state.formData.find((i:any)=>i.attributes.section === "EMAIL CONSTRUCT");
    const questionList:any[]= emailContruct.attributes.questions
    let _subQuestion:any[] = []
    let params = questionList.map((item:any)=>{
      let _param:any = {
        "name": this.state.campaignName,
        "description": this.state.description,
        "question": item.question,
        "campaign_id": this.state.campaignId,
        "question_id": item.id
      }
      if(item.type==='textarea'){
        _param.answer = values[`emailContruct-${item.id}`]
      } else {
        const splitValue = values[`emailContruct-${item.id}`].split('/');
        if (splitValue.length > 1) {
          _subQuestion.push(item);
        }
        let _value = splitValue[0];
        const radioValue = _value.split('-');
        _param.answer_id = Number(radioValue.length > 1 ? radioValue[1] : values[`emailContruct-${item.id}`])
      }
      return _param;
    })
    const appendList = _subQuestion.map((quest)=>{
      const splitQuest = values[`emailContruct-${quest.id}`].split('/');
      const splitValue = splitQuest[1].split('-');
      let output: any={
        "name": this.state.campaignName,
        "description": this.state.description,
        "question": quest.question,
        "campaign_id": this.state.campaignId,
        "question_id": Number(splitValue[0])
      }
      const json = JSON.parse(splitValue[1]);
       if(!json.value){
        output.answer_id = Number(splitValue[1])
       } else {
        output.answer = json.value;
       }
      return output
    })
    
    const body = {
      "campaign":  {
        "id": this.state.campaignId,
        "status": type
      },
      param:params.concat(appendList)
    }
    this.createEmailContructId = await this.apiCall(configJSON.formSubmissionMethod, configJSON.createCampainEndpoint,body)
  }

  closeSaveAndContinueModal = async ()=>{
    this.setState({isSaveAndContinueModal:false});
  }

  handleSubmitCampaign = async (props:any) => {
    const body = {
      name: props.workflowName,
      description: props.workflowDescription,
      campaign_type_id: this.state.campaign.id||1,
      campaign_category_id:this.state.campaign.campaign_category_id||1,
    }
    this.setState({
      campaignName:props.workflowName,
      description:props.workflowDescription
    })
    
    this.campaignSubmissionId = await this.apiCall(configJSON.campaignSubmissionMethod, configJSON.campaignSubmissionEndpoint, body)
    this.handleCloseModal()
  }
  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  skipCampaignModal = () => {
    this.setState({campaignName: `Custom Campaign Name`, description: `Custom Description`})
    this.handleSubmitCampaign({workflowName:'Custom Campaign Name',workflowDescription:'Custom Description'})
  }

  timer = () => {
    this.setState({isLoading: false, isResponseReady: true})
  }

  apiCall = async(
    method: string,
    endPoint: string,
    body?: any
  ) => {
    const token=await getStorageData("user-token")
    const header = {
      token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  // Customizable Area End
}
