import React from "react";

import {
  ThemeProvider,
  createStyles,
  createTheme,
  withStyles
} from "@material-ui/core/styles";
import ShareAndEarnController, { Props } from "./ShareAndEarnController.web";
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  Snackbar
} from "@material-ui/core";
import ReferralCard from "./ReferralCard.web";
import { link3Icon, shareIcon, groupIcon, robotGift } from "./assets";
import { rightBar } from "../../navigationmenu/src/assets";
import clsx from "clsx";
import { Close } from "@material-ui/icons";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif"
  }
});

const styles = () =>
  createStyles({
    container: {
      position: "relative"
    },

    heading: {
      marginTop: 20,
      fontSize: 48,
      fontWeight: 600,
      lineHeight: "57.6px",
      textAlign: "center"
    },

    content: {
      padding: 20,
      borderRadius: 28,
      border: "1px solid #E2E8F0",
      marginTop: 40
    },

    bottomContent: {
      marginTop: 20
    },

    card: {
      borderRadius: 28,
      border: "1px solid #E3E3E3",
      padding: "16px 20px",
      height: "100%",
      boxSizing: "border-box"
    },

    bonus: {
      display: "flex",
      minHeight: 213
    },

    bonusContent: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 245
    },

    bonusTitle: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: "29px",
      letterSpacing: "-0.01em"
    },

    bonusDescription: {
      marginTop: "auto",
      fontSize: 14,
      lineHeight: "21px",
      color: "#475569",
      fontWeight: 500
    },

    bonusGift: {
      width: 212,
      height: 201,
      marginLeft: "auto"
    },

    other: {
      display: "flex",
      alignItems: "center",
      minHeight: 213
    },

    commingSoon: {
      width: "100%",
      color: "#94A3B8",
      textAlign: "center",
      fontSize: 24,
      lineHeight: "28.8px",
      fontWeight: 600,
      letterSpacing: "-0.005em"
    },

    referralDescription: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: "19.6px",
      color: "#475569"
    },

    referralSocial: {
      width: "100%",
      marginTop: 20,
      display: "flex",
      justifyContent: "space-between",
      gap: 12,
      flexWrap: "wrap"
    },

    referralSocialIcon: {
      width: 40,
      height: "auto",
      cursor: "pointer",
      borderRadius: "50%"
    },

    referralSocialIconSelected: {
      opacity: 0.5,
      background: "#000000"
    },

    rightBar: {
      position: "absolute",
      top: -20,
      right: -20,
      zIndex: 99999
    },

    snackbar: {
      top: 0,
      maxWidth: 808,
      width: "100%"
    },

    snackbarContent: {
      position: "relative",
      backgroundColor: "#D1FAE5",
      border: "1px solid #059669",
      padding: 16,
      borderRadius: "0px 0px 14px 14px",
      width: "100%",
      borderTop: "none"
    },

    snackbarDescription: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "14.4px",
      color: "#0F172A"
    },

    snackbarCloseIcon: {
      fontSize: 14,
      cursor: "pointer",
      position: "absolute",
      top: 14,
      right: 14
    }
  });

export class ShareAndEarn extends ShareAndEarnController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, navigation, id } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <NavigationMenuComponent
          id={id}
          navigation={navigation}
          activeIndex={0}
          rightMenu={this.state.rightMenu}
          handleRightMenu={this.toggleRightMenu}
        >
          <Container maxWidth="lg" className={classes.container}>
            <Typography className={classes.heading}>Share & Earn</Typography>

            {!this.state.rightMenu && (
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                data-test-id="right-menu-btn"
                onClick={this.toggleRightMenu}
                className={classes.rightBar}
              >
                <img src={rightBar} alt="right-bar" />
              </IconButton>
            )}

            <Box className={classes.content}>
              <Grid container spacing={3}>
                <Grid xs={12} sm={6} md={6} lg={4} xl={4} item>
                  <ReferralCard
                    dataTestId="3-friends"
                    themeColor="#3A84C9"
                    icon={link3Icon}
                    title="3 friends"
                    disableReferralLinkButton={!this.state.referral.code}
                    referral={this.state.referral.referralPoints}
                    totalReferral={3}
                    getReferralLink={() => this.getReferralLink(this.getReferralSignUpLink(this.state.referral.code))}
                  >
                    <Typography className={classes.referralDescription}>
                      Successfully refer <strong>3 friends</strong>, who sign up
                      beyond the 14-day trial, to receive{" "}
                      <strong>3 months</strong> of GTM Professional{" "}
                      <strong>free</strong>.
                    </Typography>
                  </ReferralCard>
                </Grid>

                <Grid xs={12} sm={6} md={6} lg={4} xl={4} item>
                  <ReferralCard
                    dataTestId="social-share"
                    disableReferralLinkButton={!this.state.selectedSocialMedia}
                    themeColor="#57BE99"
                    icon={shareIcon}
                    title="Social share"
                    getReferralLink={() => this.getReferralLink(this.state.selectedSocialMedia!.link)}
                  >
                    <>
                      <Typography className={classes.referralDescription}>
                        Share Pebbles Ai on your social media platforms and get
                        additional bonuses.
                      </Typography>

                      <Box className={classes.referralSocial}>
                        {this.state.socialMedia.map(social => (
                          <img
                            data-test-id={`social-${social.id}`}
                            key={social.id}
                            className={clsx(classes.referralSocialIcon, {
                              [classes.referralSocialIconSelected]:
                                this.state.selectedSocialMedia?.id === social.id
                            })}
                            src={this.getSocialIcon(social.category)}
                            onClick={() =>
                              this.handleSelectSocialMedia(social)
                            }
                          />
                        ))}
                      </Box>
                    </>
                  </ReferralCard>
                </Grid>

                <Grid xs={12} sm={6} md={6} lg={4} xl={4} item>
                  <ReferralCard
                    dataTestId="mass-share"
                    themeColor="#A68B3D"
                    icon={groupIcon}
                    title="Mass Referal"
                    titleColor="#000000"
                    disableReferralLinkButton={!this.state.massReferral.code}
                    referral={this.state.massReferral.referralPoints}
                    totalReferral={10}
                    getReferralLink={() => this.getReferralLink(this.getReferralSignUpLink(this.state.massReferral.code))}
                  >
                    <Typography className={classes.referralDescription}>
                      Successfully refer <strong>10 friends</strong>, who sign
                      up beyond the 14-day trial, to receive
                      <strong>1 year</strong> of GTM Professional{" "}
                      <strong>free</strong>.
                    </Typography>
                  </ReferralCard>
                </Grid>
              </Grid>
            </Box>

            <Box className={classes.bottomContent}>
              <Grid container spacing={3}>
                <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
                  <Box className={classes.card}>
                    <Box className={classes.bonus}>
                      <Box className={classes.bonusContent}>
                        <Typography
                          className={classes.bonusTitle}
                          component="h3"
                        >
                          INVITE FRIENDS & EARN REWARDS
                        </Typography>

                        <Typography
                          className={classes.bonusDescription}
                          component="span"
                        >
                          Invite 10 friends to join the platform and get a big
                          present.
                        </Typography>
                      </Box>

                      <img className={classes.bonusGift} src={robotGift} />
                    </Box>
                  </Box>
                </Grid>

                <Grid xs={12} sm={12} md={12} lg={6} xl={6} item>
                  <Box className={classes.card}>
                    <Box className={classes.other}>
                      <Typography className={classes.commingSoon}>
                        OTHER OFFERS
                        <br />
                        COMING SOON
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Snackbar
              className={classes.snackbar}
              anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
              open={this.state.snackbar}
            >
              <Box className={classes.snackbarContent}>
                <Typography className={classes.snackbarDescription}>
                  The referral link is copied! Send it to your friends to earn rewards.
                </Typography>

                <Close className={classes.snackbarCloseIcon} onClick={this.handleCloseSnackbar} data-test-id="snackbar-close-btn" />
              </Box>
            </Snackbar>
          </Container>
        </NavigationMenuComponent>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(ShareAndEarn);
