import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { parseErrors } from "../../utilities/src/Toast";
import firebase from "firebase/app";
import StorageProvider from '../../../framework/src/StorageProvider';

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
  email?: string;
}

export interface S {
  isAgreed: boolean;
  errors: any;
  isLoad: boolean;
  privacyPolicyLink:any,
  termAndConditionLink: any,
}

export interface SS {
  id: any;
}

export default class SignUpController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isAgreed: true,
      errors: "",
      isLoad: false,
      privacyPolicyLink:null,
      termAndConditionLink: null,
    };
  }

  async componentDidMount() {
    const token = await StorageProvider.get("user-token");
    const currentURL = window.location.href;
    if (!token && currentURL.includes('VerifySignUpCode')) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
    }

    this.getTncAndPrivacyPolicyLinks()
  }

  createAccountApiCallId: any;
  apiEmailSignupCallId: string = "";
  socialLoginMessageId: string = "";
  getPrivacyPolicyMessageId: string = "";
  getTermAndConditionMessageId: string = "";
  apiOtpVerificationCallId: string = "";
  resendOtpApiCallId: string = "";
  tncAndPrivacyPolicyApiCallId: string = "";


  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson1 = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (message.properties.RestAPIResponceDataMessage && responseJson1) {
        this.handleAPISignupResponse(apiRequestCallId, responseJson1);
        this.handleAPIOtpVerificationResponse(apiRequestCallId, responseJson1);
        this.handelResendOtp(apiRequestCallId, responseJson1);
        this.handelTncAndPrivacyResponse(apiRequestCallId, responseJson1);
        this.handleGoogleSignup(apiRequestCallId, responseJson1);
      }
    }
  }

  handleGoogleSignup=async(apiRequestCallId:string, responseJson1:any)=>{
    if (apiRequestCallId === this.socialLoginMessageId) {
      this.setState({ isLoad: false });
      if (!!responseJson1.data) {
        if(responseJson1.data.attributes.is_account_existed){
          this.setState({ errors:"Google email already exists." });
          return;
        }
        await StorageProvider.set('user-token',responseJson1.meta.token);
        const message: Message = new Message(getName(MessageEnum.NavigationMessage))
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          'CreateAccount'
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
        this.send(message);
      } else {
        const errors = parseErrors(responseJson1);
        this.setState({ errors });
      }
    }
  }
  //Google Login
  socialGoogleLogin = async () => {
      try {
        const auth = firebase.auth();
        const provider = new firebase.auth.GoogleAuthProvider();
        const result = await auth.signInWithPopup(provider) as any;
        const token = await result.credential;
        this.doLogin(token.idToken,result.user.email)
      } catch (error) {
        const errorMessage = (error as any).message;
        this.setState({ errors: errorMessage });
      }
  };

  doLogin = async (token: string, email: string) => {
    await StorageProvider.set('user-email',email);
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const httpsBody = {
      data: {
        type: "social_account",
        attributes: {
          unique_auth_id: token,
        },
      },
    };
    await StorageProvider.set('user-email',email);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.socialLoginMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.socialLoginAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  onClose = () => {
    this.setState({ errors: "" });
  };

  goToLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleCheck = () => {
    this.setState({ isAgreed: !this.state.isAgreed });
  };

  onBackChooseOption = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ChooseOption'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  onBackSignUp = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'SignUpEmail'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  async doSignup(email: string): Promise<boolean> {
    if (email === null || (email && email.length === 0)) {
      return false;
    }
    await StorageProvider.set('user-email',email);
    this.setState({ isLoad: true });

    const body = {
      email,
      "account_type":"professional"
    };

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const dataBody = {
      type: "email_account",
      attribute: body,
    };

    const httpsBody = {
      data: dataBody,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailSignupCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async resendOtp() {
    const email = await StorageProvider.get("user-email") || null;
    const body = {
      email,
    };

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const dataBody = {
      type: "email_account",
      attribute: body,
    };

    const httpsBody = {
      data: dataBody,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.resendOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendOTPApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async getTncAndPrivacyPolicyLinks(){

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.tncAndPrivacyPolicyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPrivacyPolicyApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  // Handel API Responses
  async handleSubmitVerificationCode(values: any) {
    if (values === null || values === undefined) {
      return false;
    }
    const body = {
      otp: values.signupCode,
    };
      const token = await StorageProvider.get("user-token") || null ;
    

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    console.log(header, body);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiOtpVerificationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiOtpVerificationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleAPISignupResponse(apiRequestCallId: string, responseJson1: any) {
    if (apiRequestCallId === this.apiEmailSignupCallId) {
      this.setState({ isLoad: false });
      if (!!responseJson1.data) {
        this.handleSuccessfulSignupResponse(responseJson1);
      } else {
        const errors = parseErrors(responseJson1);
        this.setState({ errors });
      }
    }
  }

  async handleSuccessfulSignupResponse(responseJson1: any) {
    if (responseJson1.meta && responseJson1.meta.token) {
    await StorageProvider.set("user-token", responseJson1.meta.token);
    const msg: Message = new Message(
      getName(MessageEnum.VerifySignUpCodeScreenMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
    }
  }

  async handleAPIOtpVerificationResponse(
    apiRequestCallId: string,
    responseJson1: any
  ) {
    if (apiRequestCallId === this.apiOtpVerificationCallId) {
      if (responseJson1.meta) {
        if (responseJson1.meta.token) {
          await StorageProvider.set("user-token", responseJson1.meta.token);
          this.props.navigation.navigate("CreateAccount");
        }
        this.setState({ errors: {email:responseJson1.meta.message }});
      } else {
        const errors = parseErrors(responseJson1);
        this.setState({ errors: { signupCode: errors } });
      }
    }
  }

  async handelResendOtp(apiRequestCallId: string, responseJson1: any) {
    if (apiRequestCallId === this.resendOtpApiCallId) {
      if (responseJson1.meta) {
        if (responseJson1.meta.token) {
          await StorageProvider.set("user-token", responseJson1.meta.token);
        }
      } else {
        const errors = parseErrors(responseJson1);
        this.setState({ errors:{signupCode:errors}});
      }
    }
  }

  handelTncAndPrivacyResponse(apiRequestCallId: string, responseJson1: any) {
    if (apiRequestCallId === this.tncAndPrivacyPolicyApiCallId) {
      if (responseJson1.data) {
          this.setState({
            privacyPolicyLink:responseJson1?.data?.privacy_policy?.link,
            termAndConditionLink:responseJson1?.data?.terms_and_condition?.link,
          })
      } 
    }
  }

}
