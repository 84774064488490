import { Box, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import React from "react";
import moment from "moment";
import { ArrowBackIos } from "@material-ui/icons";



export interface IPaymentHistory {
  id: string;
  name: string;
  price: string;
  paymentTime: Date | string;
}

interface Props extends WithStyles<any> {
  history: Array<IPaymentHistory>;

  onBack(): void;
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif",
  },
});

const styles = () =>
  createStyles({
    container: {
      position: "relative",
    },

    backBtn: {
      position: "absolute",
      top: -4,
      left: 0,
      borderRadius: "50%",
      backgroundColor: "transparent",
      padding: 12,
      border: "none",
      cursor: "pointer",
    },

    backBtnIcon: {
      fontSize: 16,
    },

    heading: {
      fontWeight: 700,
      fontSize: 30,
      lineHeight: "32px",
      letterSpacing: "-0.005em",
      textAlign: "center",
      color: "#000000",
    },

    historyList: {
      marginTop: 38,
    },

    historyListItem: {
      padding: "16px 0",
      borderBottom: "1px solid #94A3B8",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    historyListInner: {
      display: "flex",
      flexDirection: "column",
    },

    historyListItemName: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px",
      color: "#000000",
    },

    historyListItemTime: {
      marginTop: 4,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
      color: "#475569",
    },

    historyListItemPrice: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px",
      color: "#000000",
    },
  });

export class SubscriptionPaymentHistory extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, history, onBack } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.container}>
          <Typography component="h3" className={classes.heading}>
            Payments history
          </Typography>

          <button
            className={classes.backBtn}
            onClick={onBack}
            data-test-id="back-btn"
          >
            <ArrowBackIos className={classes.backBtnIcon} />
          </button>

          <Box className={classes.historyList}>
            {history.map((item) => (
              <Box className={classes.historyListItem} key={item.id}>
                <Box className={classes.historyListInner}>
                  <Typography
                    className={classes.historyListItemName}
                    component="span"
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    className={classes.historyListItemTime}
                    component="span"
                  >
                    {moment(item.paymentTime).format("DD.MM.yyyy")}
                  </Typography>
                </Box>

                <Typography
                  className={classes.historyListItemPrice}
                  component="span"
                >
                  {`£${Number(item.price)}/mth`}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(SubscriptionPaymentHistory);
