import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Grid, Button, Container } from "@material-ui/core";

const theme = createTheme({
    palette: {
        primary: {
            main: "#475569",
        },
        secondary: {
            main: "#3A84C9",
        },
    },
    typography: {
        fontFamily: "Montserrat",
    },
});
import * as Yup from "yup";
// Customizable Area End
import CompanyProgressHeaderWeb from "../../../components/src/CompanyProgressHeader.web";
import MustHave from "../../../components/src/MustHave";
import ProblemStatement from "./ProblemStatement.web";
import { accordup, accordupGreen, accordupYellow } from "./assets";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import OfferTheCompanyController, { initialOfferValues, Props } from "./OfferTheCompanyController";
import { Form, Formik } from "formik";
import Layout from "../../../components/src/Layout";
import CorporatePartners from "./CorporatePartners.web";
import ModularProducts from "./ModularProducts.web";
import { modalInfoContent } from "../utils/data";
import PrimaryQuestionWithFormik from "./PrimaryQuestionWithFormik.web";
import { ProblemStatementWithFormik } from "./ProblemStatementWithFormik.web";

// Customizable Area Start

type OfferFormValuesType = {
    whyExist: string[];
    what_is_it: string;
    performanceAnalystics: any;
    productTestimonials: any;
    corporatePartners: any;
    modularProducts: any;
    packagesAndPricing: any;
    theTeam: any;
    [key: string]: any;
};

// Customizable Area End

export default class Offering extends OfferTheCompanyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    offerSchema = Yup.object().shape({
        performanceAnalystics: Yup.array().of(
            Yup.string()
                .matches(/^[^\s].*$/, "Cannot start with spaces")
                .max(80, "Maximum 80 characters allowed")
        ),
        productTestimonials: Yup.array().of(
            Yup.string()
                .matches(/^[^\s].*$/, "Cannot start with spaces")
                .max(40, "Maximum 40 characters allowed")
        ),
        corporatePartners: Yup.array().of(
            Yup.object().shape({
                benifit: Yup.string()
                    .matches(/^[^\s].*$/, "Cannot start with spaces")
                    .max(200, "Maximum 200 characters allowed"),
                partner: Yup.array().of(
                    Yup.object().shape({
                        name: Yup.string()
                            .matches(/^[^\s].*$/, "Cannot start with spaces")
                            .max(30, "Maximum 30 characters allowed"),
                        description: Yup.string()
                            .matches(/^[^\s].*$/, "Cannot start with spaces")
                            .max(50, "Maximum 50 characters allowed"),
                        benifit: Yup.string()
                            .matches(/^[^\s].*$/, "Cannot start with spaces")
                            .max(50, "Maximum 50 characters allowed"),
                    })
                ),
            })
        ),
        productAndFeature: Yup.array().of(
            Yup.object().shape({
                offering: Yup.string()
                    .matches(/^[^\s].*$/, "Cannot start with spaces")
                    .max(30, "Maximum 30 characters allowed")
                    .required("This should not be blank."),
                description: Yup.string()
                    .matches(/^[^\s].*$/, "Cannot start with spaces")
                    .max(130, "Maximum 130 characters allowed")
                    .required("This should not be blank."),
            })
        ),
        offeringServices: Yup.array().of(
            Yup.object().shape({
                offering: Yup.string()
                    .matches(/^[^\s].*$/, "Cannot start with spaces")
                    .max(30, "Maximum 30 characters allowed")
                    .required("This should not be blank."),
                description: Yup.string()
                    .matches(/^[^\s].*$/, "Cannot start with spaces")
                    .max(130, "Maximum 130 characters allowed")
                    .required("This should not be blank."),
            })
        ),
        theTeam: Yup.array().of(
            Yup.object().shape({
                offering: Yup.string()
                    .matches(/^[^\s].*$/, "Cannot start with spaces")
                    .max(30, "Maximum 30 characters allowed"),
                description: Yup.string()
                    .matches(/^[^\s].*$/, "Cannot start with spaces")
                    .max(300, "Maximum 300 characters allowed"),
            })
        ),
        modularProducts: Yup.array().of(
            Yup.object().shape({
                name: Yup.string()
                    .matches(/^[^\s].*$/, "Cannot start with spaces")
                    .max(30, "Maximum 30 characters allowed"),
                description: Yup.string()
                    .matches(/^[^\s].*$/, "Cannot start with spaces")
                    .max(300, "Maximum 300 characters allowed"),
                price: Yup.number()
                    .typeError("Must be a number")
                    .test('decimal-places', 'Must have up to 3 decimal places', this.priceValidation),
                currency: Yup.string(),
            })
        ),
        packagesAndPricing: Yup.array().of(
            Yup.object().shape({
                offering: Yup.string()
                    .matches(/^[^\s].*$/, "Cannot start with spaces")
                    .max(30, "Maximum 30 characters allowed"),
                description: Yup.string()
                    .matches(/^[^\s].*$/, "Cannot start with spaces")
                    .max(300, "Maximum 300 characters allowed"),
                price: Yup.number()
                    .typeError("Must be a number")
                    .test('decimal-places', 'Must have up to 3 decimal places', this.priceValidation),
                currency: Yup.string(),
            }),
        ),
        whyExist: Yup.array().required("Min 10 and max 20 words")
            .min(10, "Minimum 10 words allowed")
            .max(20, "Maximum 20 words allowed"),
    });
    // Customizable Area End

    render() {
        const { alreadyFilledFormValues } = this.state;
        const haveInitData = Object.keys(alreadyFilledFormValues).length > 0
        const formikIntialValues =
            haveInitData
                ? alreadyFilledFormValues
                : initialOfferValues;
        return (
            // Customizable Area Start
            <Layout
                back={() => {
                    this.backButton();
                }}
                carousel={"hide"}
                poweredByLogo={false}
            >
                <ThemeProvider theme={theme}>
                    <Container>
                        <Grid container style={webStyle.servicesMainContainer}>
                            <CompanyProgressHeaderWeb
                                activeStep={this.state.headeractiveStep}
                                prevCompletedStep={this.state.headerprevCompletedStep}
                                time={this.state.progressHeaderTime}
                                primaryHeading={this.state.progressHeaderPrimaryHeading}
                                secondaryHeading={this.state.progressHeaderSecondaryHeading}
                                type={this.state.progressHeaderType}
                                substep={this.state.progressHeaderSubStep}
                            />
                            <Grid item style={webStyle.displayCol}>
                                <Formik
                                    data-testid="theCompanyOfferFormik"
                                    initialValues={formikIntialValues}
                                    validationSchema={this.offerSchema}
                                    enableReinitialize
                                    onSubmit={(values: any) => {
                                        this.saveAndContinueButton(values);
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        handleChange,
                                        setFieldValue,
                                        resetForm,
                                        touched,
                                    }) => (
                                        <Form translate={undefined}>
                                            <MustHave
                                                btnDisplay={false}
                                                data-test-id="offermustHaveTestId"
                                                handleChange={this.handleChange}
                                                addItem={this.addChipLabels}
                                                customlistTitle={"What is it? (semantic keywords)"}
                                                inputList={[]}
                                                textBoxDisplay={false}
                                                headerName={"MUST HAVE"}
                                                iconName={accordup}
                                                serviceArray={this.state.serviceArray1}
                                                labelsDisplay={true}
                                                formikValue={values.whyExist}
                                                mustHaveExpanded={this.state.mustHaveExpanded}
                                                chipLabels={this.state.chipLabels}
                                                handleExpand={this.handleExpand}
                                                deleteChipByName={this.deleteChipByName}
                                                onChangeChip={(updated: string[]) => {
                                                    setFieldValue("whyExist", updated);
                                                }}
                                                chipError={this.checkValidation(!!touched.whyExist, errors.whyExist as string)}
                                                name={"offer_why_do_we_exist"}
                                            />
                                            {this.state.mustHaveExpanded && (
                                                <>
                                                    <ProblemStatementWithFormik
                                                        data-test-id={"problemStatementTestId2"}
                                                        navigation={""}
                                                        id=""
                                                        title={"Products and Features"}
                                                        inputList={this.state.offeringsProductsInputList}
                                                        serviceArray={this.state.offeringsProductArray}
                                                        btnDisplay={true}
                                                        buttonName={"Add products"}
                                                        name={`productAndFeature`}
                                                        formikHandelChange={handleChange}
                                                        formikValue={values}
                                                        formikError={errors}
                                                        maxFeilds={9}
                                                    />
                                                    <ProblemStatementWithFormik
                                                        data-test-id={"problemStatementTestId"}
                                                        navigation={""}
                                                        id=""
                                                        title={"Services"}
                                                        inputList={this.state.offeringsServicesInputList}
                                                        serviceArray={this.state.offeringsServicesArray}
                                                        btnDisplay={true}
                                                        buttonName={"Add services"}
                                                        name={`offeringServices`}
                                                        formikHandelChange={handleChange}
                                                        formikValue={values}
                                                        formikError={errors}
                                                    />
                                                </>
                                            )}

                                            <MustHave
                                                btnDisplay={false}
                                                data-test-id="offershouldHaveTestId"
                                                customlistTitle={"The Team"}
                                                inputList={[]}
                                                textBoxDisplay={true}
                                                iconName={accordupGreen}
                                                headerName={"SHOULD HAVE"}
                                                labelsDisplay={false}
                                                serviceArray={this.state.shouldHaveServiceArray}
                                                mustHaveExpanded={this.state.shouldHaveExpanded}
                                                handleExpand={this.handleExpand1}
                                                chipLabels={[]}
                                                deleteChipByName={this.deleteChipByName}
                                                offeringText={true}
                                                formikHandelChange={handleChange}
                                                formikValue={values}
                                                formikError={errors}
                                                inputOfferingList={{
                                                    showListHeader: true,
                                                    inputList: this.state.offeringsTeamInputList,
                                                    name: "theTeam"
                                                }}
                                                name={"offershouldHave"}
                                            />
                                            {this.state.shouldHaveExpanded && (
                                                <>
                                                    <PrimaryQuestionWithFormik
                                                        data-test-id={"PrimaryQuestion5"}
                                                        navigation={""}
                                                        id=""
                                                        title={"Product Testimonials"}
                                                        inputList={this.state.productTestimonialInputList}
                                                        serviceArray={this.state.productTestimonial}
                                                        btnDisplay={true}
                                                        buttonName={"Add a Testimonial"}
                                                        name={`productTestimonials`}
                                                        formikHandelChange={handleChange}
                                                        formikValue={values}
                                                        formikError={errors}
                                                        setFieldValue={setFieldValue}
                                                        maxFeilds={3}
                                                    />

                                                    <PrimaryQuestionWithFormik
                                                        data-test-id={"PrimaryQuestion5"}
                                                        navigation={""}
                                                        id=""
                                                        title={"Performance Analytics"}
                                                        inputList={
                                                            this.state.performanceAnalyticsQuestionList
                                                        }
                                                        serviceArray={
                                                            this.state.performanceAnalyticsServiceArr
                                                        }
                                                        btnDisplay={true}
                                                        buttonName={"Add"}
                                                        name={`performanceAnalystics`}
                                                        formikHandelChange={handleChange}
                                                        formikValue={values}
                                                        formikError={errors}
                                                        setFieldValue={setFieldValue}
                                                        maxFeilds={5}
                                                    />
                                                </>
                                            )}

                                            <MustHave
                                                btnDisplay={true}
                                                buttonName={"Add packages"}
                                                maxFeilds={6}
                                                showBtnFromMustHave={false}
                                                data-test-id="offergoodToHaveTestId"
                                                customlistTitle={"Packages & Pricing"}
                                                inputList={[]}
                                                textBoxDisplay={true}
                                                iconName={accordupYellow}
                                                headerName={"GOOD TO HAVE"}
                                                labelsDisplay={false}
                                                serviceArray={this.state.goodToHaveServiceArray}
                                                mustHaveExpanded={this.state.goodToHaveExpanded}
                                                handleExpand={this.handleExpand2}
                                                chipLabels={[]}
                                                deleteChipByName={this.deleteChipByName}
                                                offeringText={true}
                                                formikHandelChange={handleChange}
                                                formikValue={values}
                                                formikError={errors}
                                                inputOfferingList={{
                                                    showListHeader: true,
                                                    inputList: this.state.offeringsgoodToHaveInputList,
                                                    handleChange: (e: any, index: number) => {
                                                        setFieldValue(
                                                            `offering${index + 1}`,
                                                            e.target.value
                                                        );
                                                        this.handleCommonObjectChange(
                                                            e,
                                                            index,
                                                            "offeringsgoodToHaveInputList",
                                                            "offering"
                                                        );
                                                    },
                                                    handleChange1: (event: any, index: number) => {
                                                        setFieldValue(
                                                            `description${index + 1}`,
                                                            event.target.value
                                                        );
                                                        this.handleCommonObjectChange(
                                                            event,
                                                            index,
                                                            "offeringsgoodToHaveInputList",
                                                            "description"
                                                        );
                                                    },
                                                    handleChange2: (event: any, index: number) => {
                                                        setFieldValue(
                                                            `price${index + 1}`,
                                                            event.target.value
                                                        );
                                                        this.handleCommonObjectChange(
                                                            event,
                                                            index,
                                                            "offeringsgoodToHaveInputList",
                                                            "price"
                                                        );
                                                    },
                                                    handleChange3: (e: any, index: number) => {
                                                        setFieldValue(
                                                            `currency${index + 1}`,
                                                            e.target.value
                                                        );
                                                        this.handleCommonObjectChange(
                                                            e,
                                                            index,
                                                            "offeringsgoodToHaveInputList",
                                                            "currency"
                                                        );
                                                    },
                                                    goodToHave: true,
                                                    name: "packagesAndPricing",
                                                }}
                                                name={"offergoodToHave"}
                                            />
                                            {this.state.goodToHaveExpanded && (
                                                <>
                                                    <ModularProducts
                                                        data-test-id="modularProducts"
                                                        title={"Modular Products & Services"}
                                                        id=""
                                                        navigation={""}
                                                        inputList={
                                                            this.state.offeringsModularProductsInputList
                                                        }
                                                        serviceArray={
                                                            this.state.offeringsModularProductsServiceArray
                                                        }
                                                        name="modularProducts"
                                                        formikHandelChange={handleChange}
                                                        formikValue={values.modularProducts}
                                                        formikError={errors.modularProducts}
                                                        tootipData={modalInfoContent.modularProducts}
                                                    />
                                                    <CorporatePartners
                                                        data-test-id="corporatePartners"
                                                        title={"Corporate Partners"}
                                                        id=""
                                                        navigation={""}
                                                        inputList={
                                                            this.state.offeringsCorporatePartnersInputList
                                                        }
                                                        inputList2={this.state.offeringsBenefits}
                                                        serviceArray={
                                                            this.state.offeringsCorporatePartnersServiceArray
                                                        }
                                                        name="corporatePartners"
                                                        formikHandelChange={handleChange}
                                                        formikValue={values}
                                                        formikError={errors}
                                                        tootipData={modalInfoContent.corporatePartners}
                                                    />
                                                </>
                                            )}

                                            <Grid item style={webStyle.displayRow}>
                                                <Button
                                                    variant="contained"
                                                    data-test-id="offerclearBtn"
                                                    style={webStyle.clrBtn}
                                                    onClick={() => {
                                                        resetForm();
                                                        this.setState({ alreadyFilledFormValues: {} });
                                                    }}
                                                    type="reset"
                                                >
                                                    Clear all data
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    style={webStyle.saveBtn}
                                                >
                                                    Save & continue
                                                </Button>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Container>
                </ThemeProvider>
            </Layout>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    saveBtn: {
        borderRadius: "8px",
        background: "#57BE99",
        width: "50%",
        textTransform: "none",
        fontWeight: 600
    } as CSSProperties,
    displayRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "10px",
    },
    clrBtn: {
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
        background: "#FFF",
        width: "50%",
        textTransform: "none",
        fontWeight: 600
    } as CSSProperties,
    servicesMainContainer: {
        justifyContent: "center",
        display: "flex",
        flexDirection: "column" as "column",
    },
    servicesContainer: {
        background: "#fff",
        borderRadius: "28px",
        padding: "32px 40px",
        height: "max-content",
    },
    offeringBox: {
        flexDirection: "column",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap" as "wrap",
        marginBottom: "2%",
    } as any,
    displayCol: {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
    } as CSSProperties,
};
// Customizable Area End
