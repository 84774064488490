import React from "react";
// Customizable Area Start
import CustomList from "../../../components/src/CustomList.web";
import CustomInput from "../../../components/src/CustomInput.web";

import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import {
    Grid,
    Box,
    Typography,
    InputAdornment
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';


const theme = createTheme({
    palette: {
        primary: {
            main: "#475569",
        },
        secondary: {
            main: "#3A84C9"
        }
    },
    typography: {
        fontFamily: 'Montserrat',
    },
});
// Customizable Area End

import ModularProductsController, {
    Props,
    configJSON,
} from "./ModularProductsController";
import { styles } from "../utils/styles";

export class ModularProducts extends ModularProductsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { title, inputList, serviceArray, showListHeader, processText, classes,
        formikError,formikValue,name,formikHandelChange,tootipData
        } = this.props
        const containerStyle = { ...webStyle.mainContainer, ...(this.styleCondition(processText, webStyle.servicesMainContainer, {})) };
        return (
            // Customizable Area Start

            <ThemeProvider theme={theme} >
                <Grid container style={this.styleCondition(!!showListHeader, {}, containerStyle)} >
                    <Grid item xs={12} style={{ ...webStyle.container, ...(this.styleCondition(processText, {}, webStyle.servicesContainer)) }}>
                        {!showListHeader && <CustomList
                            title={title}
                            modalContent={tootipData||""}
                            servicesList={serviceArray}
                        />}
                        <Box style={{...(this.styleCondition(processText, webStyle.processStyle, {})), ...webStyle.offeringBox}}>
                            {formikValue.map((data: any, index: any) => {
                                return (
                                    <Box style={this.styleCondition(processText, { width: '100%' }, { width: "32%" })} key={index}>
                                        <CustomInput
                                            placeholder={inputList[index].namePlaceholder}
                                            data-testid={"name-field-" + index}
                                            textLabel={this.styleCondition(processText, "", inputList[index].nameTitle)}                                  
                                            customeLabel={this.styleCondition(!processText, "", inputList[index].nameTitle.replace("N", index + 1))}
                                            labelCount={index}
                                            rowsCount={1}
                                            width="100%"
                                            maxCharacterLenght={inputList[index].charLength}
                                            name={`${name}.${index}.name`} 
                                            onChange={(
                                              e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => formikHandelChange(e, index)}
                                            value={formikValue?.[index]?.name} 
                                            error={formikError?.[index]?.name } 
                                        />
                                        <>
                                            <Typography style={webStyle.offering}>{inputList[index].priceTitle}</Typography>
                                            <div>
                                                <TextField
                                                    variant="outlined"
                                                    minRows={1}
                                                    multiline
                                                    placeholder={inputList[index].pricePlaceholder}
                                                    maxRows={12}
                                                    name={`${name}.${index}.price`} 
                                                    onChange={(
                                                      e: React.ChangeEvent<HTMLTextAreaElement>
                                                    ) => formikHandelChange(e, index)}
                                                    value={formikValue?.[index]?.price} 
                                                    InputProps={{
                                                        endAdornment: (
                                                            <div style={{width: '100%'}} className={classes.priceCurrencyInput} >
                                                                <InputAdornment position="end" >
                                                                    <FormControl variant="outlined" style={{ ...webStyle.inputProps1, width: "100%", }}>
                                                                        <Select
                                                                            id="currency"
                                                                            labelId="currency-label"
                                                                            data-testid={"currency-field-" + index}
                                                                            onChange={(e: any) =>formikHandelChange(e, index)}
                                                                            label="Currency"
                                                                            style={{
                                                                                ...webStyle.inputProps1,
                                                                                width: "100%",
                                                                                fontStyle: this.styleCondition(data.price, "normal", "italic"),
                                                                                color: this.styleCondition(data.price, '#000', "#94A3B8")
                                                                            }}

                                                                            name={`${name}.${index}.currency`} 
                                                                            value={formikValue?.[index].currency} 
                                                                            
                                                                        >
                                                                            <MenuItem value="null"> Choose </MenuItem>
                                                                            <MenuItem value="USD" > USD </MenuItem>
                                                                            <MenuItem value="EUR" > EUR </MenuItem>
                                                                            <MenuItem value="GBP" > GBP </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                </InputAdornment>
                                                            </div>
                                                        ),
                                                        style: {
                                                            ...webStyle.inputProps, width: "100%",
                                                            fontStyle: this.styleCondition(data.price, "normal", "italic"),
                                                            color: this.styleCondition(data.price, '#000', "#94A3B8")
                                                        }
                                                    }}
                                                    style={{...webStyle.inputProps1, width: "100%"}}
                                                    type="number"
                                                    data-testid={"price-field-" + index}
                                                    error={formikError?.[index]?.price}
                                                />
                                                {formikError?.[index]?.price&&<Typography style={webStyle.errorValidate}>{formikError?.[index]?.price}</Typography>}
                                            </div>
                                          
                                        </>
                                        <CustomInput
                                            textLabel={inputList[index].descriptionTitle}
                                            labelCount={index}
                                            placeholder={inputList[index].descriptionPlaceholder}
                                            rowsCount={2}
                                            data-testid={"description-field-" + index}
                                            maxCharacterLenght={inputList[index].charLengthDesc}
                                            width="100%"
                                            name={`${name}.${index}.description`} 
                                            onChange={(
                                              e: React.ChangeEvent<HTMLTextAreaElement>
                                            ) => formikHandelChange(e, index)}
                                            value={formikValue?.[index]?.description} 
                                            error={formikError?.[index]?.description } 
                                        />
                                    </Box>
                                )
                            }
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainContainer: {
        background: '#EEE7D9',
    },
    servicesMainContainer: {
        padding: '2% 0'
    },
    container: {
        borderRadius: '28px',
        background: '#fff',
        height: 'max-content'
    },
    servicesContainer: {
        padding: '32px 40px',
    },
    offeringBox: {
        marginBottom: '2%',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap' as 'wrap',
    },
    processStyle: {
        flexDirection: 'column' as 'column'
    },
    infoBox: {
        gap: '10px',
        display: 'flex',
        alignItems: 'center'
    },
    servicesHead: {
        fontWeight: 600
    },
    inputProps: {
        borderRadius: '8px',
        fontFamily: 'Montserrat',
        fontWeight: 400,
        lineHeight: '21px',
        padding: '10px',
    },
    inputProps1: {
        // padding: '10px',
    },
    offering: {
        fontSize: '0.87rem',
        fontWeight: 500,
        paddingLeft: "12px"
    },
    errorValidate: {
        fontFamily: "Montserrat",
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.2,
        color: "#DC2626",
        marginTop: "2px",
    }
}
// Customizable Area End

export default withStyles(styles)(ModularProducts);
