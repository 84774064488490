import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // handleCloseEditMail:() => void;
    handleCloseMainModule: () => void;
    openEmail: boolean;
    checkIcon: string;
    handleCloseCurrentModule: () => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    mailText: string;
    success: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}
import {
    Box,
    Button,
    Typography,
    Dialog,
    DialogContent
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';

// Customizable Area End

export class EmailChanged extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            mailText: "Email changed",
            success: "successfully"
        };
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        return (
            // Customizable Area Start
            <Dialog onClose={this.props.handleCloseCurrentModule}
                aria-labelledby="simple-dialog-title"  PaperProps={{
                    style:{padding:'40px', borderRadius:'20px',width:'670px'}
                  }}
                open={this.props.openEmail} className={classes.dialogContainer} style={{padding:'40px'}}>
                <DialogContent>
                    <CloseIcon onClick={this.props.handleCloseMainModule} fontSize="small" className={classes.closeIcon} />
                    <img src={this.props.checkIcon} className={classes.checkIconImg} />
                    <Typography className={classes.mailText}>{this.state.mailText}<span className={classes.success}>{this.state.success}</span>!</Typography>
                    <Box className={classes.displayButton}>
                        <Button variant="outlined" className={classes.accountButtton} onClick={this.props.handleCloseCurrentModule}>Back to account settings</Button>
                        <Button variant="contained" className={classes.closeButton} onClick={this.props.handleCloseMainModule}>Close</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: {} = () => ({
    closeIcon: {
        position: 'absolute',
        right: '25px',
        cursor: 'pointer'
    },
    dialogContainer: {
        '& .MuiDialog-paperWidthSm': {
            borderRadius: '17px',
            width: '100%',
            padding: '20px 10px'
        },
        position: 'relative',
        textAlign: 'center',
        '& .MuiPaper-elevation24': {
            boxShadow: 'none'
        }
    },
    checkIconImg: {
        width: '30px'
    },
    mailText: {
        margin: '20px 0',
        fontWeight: 700,
        fontSize: '1.8rem'
    },
    success: {
        color: '#059669',
        margin: '0 5px'
    },
    displayButton: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 0',
        gap: '10px'
    },
    accountButtton: {
        borderRadius: '8px',
        fontWeight: 600,
        padding: '5px',
        textTransform:'none',
        width: '95%',
        height: 'min-content'
    },
    closeButton: {
        borderRadius: '8px',
        fontWeight: 600,
        background: '#000',
        color: '#fff',
        width: '95%',
        height: 'min-content',
        textTransform: 'Capitalize'
    }
});
export default withStyles(webStyle)(EmailChanged);

// Customizable Area End
