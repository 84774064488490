import React, { useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarContent } from '@material-ui/core';
import {close} from "../../blocks/chatbot4/src/assets"

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: '#D1FAE5', 
    color: '#000', 
    width:"808px",
    borderRadius:"15px",
    position:"absolute",
    top:"-40px"
  },
}));

const CustomToaster = (props:any) => {
    const { open, message, onClose } = props
  const classes = useStyles();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={open}
      autoHideDuration={5000} 
      onClose={onClose}
      ContentProps={{
        'aria-describedby': 'message-id',
        className: classes.success, 
      }}
    >
       <SnackbarContent
         className={classes.success} 
     style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
      message={<span id="message-id">{message}</span>}
      action={[
        <IconButton key="close" color="inherit" onClick={onClose}  data-test-id="CustomToasterCloseBtn">
        <img src={close} style={{height:"16px", width:"16px"}} />
        </IconButton>,
      ]}
    />
    </Snackbar>
  );
};

export default CustomToaster;
