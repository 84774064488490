import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import { parseErrors } from "../../utilities/src/Toast";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCloseEditPass:() => void
  handleCloseMainModal:() => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken:string;
  passwordShow:boolean;
  openPassModule:boolean;
  oldPassValue:string;
  newPassValue:string;
  upperCaseCheck: boolean;
  numberCheck: boolean;
  lowerCaseCheck: boolean;
  lengthCheck: boolean;
  confirmValue:string;
  oldPassError:boolean;
  oldPassErrorMessage:string;
  newPassError:boolean;
  newPassErrorMessage:string;
  confirmPassError:boolean;
  confirmErrorMessage:string;
  errors:any;
  confirmValidError:boolean;
  confirmValidMessage:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingsChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  updatePasswordID:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.updatePasswordID =""
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      authToken:"",
      passwordShow:false,
      openPassModule:false,
      oldPassValue:"",
      newPassValue:"",
      upperCaseCheck: false,
      numberCheck: false,
      lowerCaseCheck: false,
      lengthCheck: false,
      confirmValue:"",
      oldPassError:false,
      oldPassErrorMessage:"",
      newPassError:false,
      newPassErrorMessage:"",
      confirmPassError:false,
      confirmErrorMessage:"",
      errors:"",
      confirmValidError:false,
      confirmValidMessage:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const requestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        )
        if (this.updatePasswordID === requestCallId ) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (!responseJson.errors) {
            this.handlePassModule()
          } else {
            const errors = responseJson?.errors
            this.setState({errors})
          }
        }
        
      }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({
        authToken: await StorageProvider.get("user-token")
      })
  }

  handleClickShowPassword = () => {
    this.setState({
      passwordShow: !this.state.passwordShow
    })
  }
  handleSetPass= () => {
    if(!this.state.oldPassValue || !this.state.newPassValue || !this.state.confirmValue){
      this.setState({
        oldPassError:true,
        oldPassErrorMessage:'Enter Old Password',
        newPassError:true,
        newPassErrorMessage:'Enter New Password',
        confirmPassError:true,
        confirmErrorMessage:'Enter Confirm Password'
      })
    }else if(!this.state.upperCaseCheck || !this.state.lowerCaseCheck || !this.state.numberCheck || !this.state.lengthCheck){
      return
    }else if(this.state.newPassValue !==  this.state.confirmValue){
      this.setState({
        confirmPassError:true,
        confirmValidMessage:'New password and confirm password must match.'
      })
    }else{
      this.updatePasswordApi()
      this.setState({
        oldPassError:false,
        oldPassErrorMessage:'',
        newPassError:false,
        newPassErrorMessage:'',
        confirmPassError:false,
        confirmErrorMessage:'',
        confirmValidError:false,
        confirmValidMessage:''

      })
    }
  }
  handleOldPass = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({oldPassValue:event.target.value})
    this.setState({
      oldPassError:false,
      oldPassErrorMessage:'',
    })
  }
  handlePasswordValidation = (password:string) => {
    const upperCaseRegex = /[A-Z]/;
    const lowerCaseRegex = /[a-z]/;
    const numberRegex = /\d/;

    const hasUpperCase = upperCaseRegex.test(password);
    const hasLowerCase = lowerCaseRegex.test(password);
    const hasNumber = numberRegex.test(password);
    const isLengthValid = password.length >= 8;

    this.setState({
      upperCaseCheck: hasUpperCase,
      numberCheck: hasNumber,
      lowerCaseCheck: hasLowerCase,
      lengthCheck: isLengthValid
    });

  }
  handleNewPass = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({newPassValue:event.target.value})
    this.handlePasswordValidation(event.target.value);
    this.setState({
      newPassError:false,
      newPassErrorMessage:''
    })
  }
  handleConfirmPass = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({confirmValue:event.target.value})
    this.setState({
      confirmPassError:false,
      confirmErrorMessage:'',
      confirmValidError:false,
      confirmValidMessage:''
    })
  }
  handlePassModule = () => {
    this.setState({openPassModule: !this.state.openPassModule})
  }

  updatePasswordApi = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    const body = {
      "old_password":this.state.oldPassValue,
      "new_password":this.state.newPassValue,
      "password_confirmation":this.state.confirmValue
    };
    this.updatePasswordID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointUpdatePass
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.pathTypeMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  onClose = () => {
    this.setState({errors:""});
  };
  // Customizable Area End
}
