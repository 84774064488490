import React from 'react'
// Customizable Area Start
import {
    Box, Typography,
  } from "@material-ui/core";
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
    robo1,
  } from "./assets";
// Customizable Area End


interface IProps {
  classes?: any;
}

export class BlankChatHistoryScreen extends React.Component<IProps, {}> {
  state = {
  };

  render(){
    const { classes } = this.props;

    return (
      <Box
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box style={{}}>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <img src={robo1} />
          </Box>
          <Typography className={classes.defaltLabel}>
            Generate intelligence here
          </Typography>
        </Box>
      </Box>
    );
    }
}


const styles = createStyles({
    defaltLabel: {
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "1.875rem",
      },
});


export default withStyles(styles)(BlankChatHistoryScreen);

