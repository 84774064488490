Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MultipageForms";
exports.labelBodyText = "MultipageForms Body";

exports.btnExampleTitle = "CLICK ME";

exports.successMessage = "UserDetails inserted successfully";
exports.errorsDataMsg = "Internal Server error";
exports.multiPageFormSubmitEndPoint = "/bx_block_multipageforms/user_profiles";
exports.getAllAPIKeysEndpoint = "bx_block_settings/api_keys";
exports.createAPIKeyEndpoint = "bx_block_settings/api_keys"
exports.postMethod = "POST"
exports.getMethod = "GET"
exports.Copilot = "Copilot"
exports.Emails = "Emails"
exports.noCamp = "No campaigns yet."
exports.chooseType = "Choose the type of email"
exports.campTactical = "campaign in tactical outreach"
exports.startCamp = "to start a campaign."
exports.createNewCamp = "Create new campaign"
exports.noConversation = "No conversations yet."
exports.startChating = "Start chating with copilot"
exports.startConversation = "to start a conversation."
exports.createNewChat = "Create new chat"
exports.uxDesign = "What is UX Design?"
exports.history = "History"
exports.library = "Library"
exports.uxDescription = "User experience design is the process of defining the experience a user wouldthe description of this saving (or its preview)."
exports.chat = "Chat"
exports.christmas = "Christmas"
exports.description = "This is the description of this campaign..."
exports.customMarketing = "custom marketing campaign"
exports.editCamp = "Edit campaign workflow"
exports.changeCamp = "Change campaign workflow"
exports.saveCamp = "Save campaign"
exports.shareCamp = "Share campaign"
exports.deleteCamp = "Delete campaign"
exports.applyParameters = "Apply parameters"
exports.showParameters = "Show Parameters"
exports.addMail = "Add email"
exports.subjectLine = "Generated Email Subject"
exports.copy = "Copy"
exports.share = "Share"
exports.editMailPlaceholder = "Edit your email by adding some features (i. e. “Add a dropdown”)"
exports.pebbelsCopilot = "Pebbles Copilot is still learning. Verify important information."
exports.generateMail = "Generate another email"
exports.changeParaWorkflow = "Change  parameters’ workflow"
exports.savePara = "Save parameters"
exports.saveMessage = 'Your campaign workflow has been successfully saved! Access it at any time under "My Workflow".'
exports.HideParameters = "Hide Parameter"
exports.shareFile = "Share file as"
exports.likeToshare = "How would you like to share your file(s)?"
exports.wordFile = "Word file"
exports.pdfFIleText = "PDF File"
exports.targetMail = "Target email"
exports.targetMailPlaceholder = "Enter target email"
exports.enterMailAdrress = "Enter email addresses, separating them with commas"
exports.downloadBtn = "Download"
exports.sendMailBtn = "Send to email"
exports.openParameter = "Open parameters"
exports.saveLibrary = "Save to Library"
exports.mailPlaceholder = "A new email will appear here"
exports.apiEndPointGetCamp = "bx_block_cfdataintegrationviapromptengineering4/generated_outputs"
exports.getAllParams = 'bx_block_automaticformcreation/form_creations'
exports.apiEndPointCreateMail = "bx_builder_chain/generate_prompt_for_email"
exports.formCreationEndpoint = '/bx_block_automaticformcreation/form_creations';
exports.apiEndPointgetParam = "bx_block_cfdataintegrationviapromptengineering4/params?param_group_id="
// Customizable Area End