import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { slider1,welcomeSlider , slider2, slider3,slider4,slider5,slider6,slider7 } from "./assets";

const welcomeSliderList = [
  {id:1,img:welcomeSlider,title:'Create Ripples',subTitle:'With your personal high impact\nGTMSai co-pilot.'},
  {id:1,img:welcomeSlider,title:'Supercharge productivity',subTitle:'By automating go to market\nworkflows.'}
]
const B2BSlider = [
  {
    id: 1,
    img: slider1,
    title:'Seamless AIaaS Integrations',
    subTitle:`Leverage our GTM intelligence to retain\n those high-value contracts.`
  },
  {
    id: 2,
    img: slider2,
    title:'Fully-branded White Labels',
    subTitle:`Present advanced GTM solutions as\nyour own and watch your agency soar.`
  },
  {
    id: 3,
    img: slider3,
    title:'Intelligent APIs',
    subTitle:`Convert your freemium sign-\nups into paying customers.`
  }
]


const B2BPlan = [
  {
    id: 2,
    img: slider2,
    title:'Create Ripples',subTitle:'With your personal high impact\nGTMSai co-pilot.'
  },
  {
    id: 3,
    img: slider3,
    title:'Supercharge productivity',subTitle:'By automating go to market\nworkflows.'
  }
]

const D2CSlider = [
  {
    id: 1,
    img: slider4,
    title:'Streamlined GTM Workflows',
    subTitle:`Eliminate go-to-market inefficiencies\nand unlock your business's growth potential.`
  },
  {
    id: 2,
    img: slider5,
    title:'Effortless High-impact Marketing',
    subTitle:`Save yourself from tedious tasks and craft\ncampaigns that truly captivate.`
  },
  {
    id: 3,
    img: slider6,
    title:'Stronger Sales Momentum',
    subTitle:`Reduce sales admin to free up more time\nand energy for impactful deal-making.`
  }
]

const PassSlider = [
  {
    id: 1,
    img: slider4,
    title:'Founders & C-Suite',
    subTitle:`Become a legendary leader by transforming your business into a profitable powerhouse with our AI-native, end-to-end GTM platform.`
  },
  {
    id: 2,
    img: slider3,
    title:'Marketing Professionals',
    subTitle:`Grow into a marketing mastermind by supercharging your marketing efforts with our intelligence and content generator.`
  },
  {
    id: 3,
    img: slider6,
    title:'Sales Professionals',
    subTitle:`Turn yourself into a sales superstar by converting each lead into a closed-won deal with our clever sales enablement tools.`
  }
]


const LoginSlider = [
  {
    id: 1,
    img: slider2,
    title:'Create Ripples',
    subTitle:`With your personal high impact\nGTMSai co-pilot`
  },
  {
    id: 2,
    img: slider7,
    title:'Supercharge productivity',
    subTitle:`By automating go to market\nworkflows.`
  }
]


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  type?:'company'|'individual'|'password'|'login'|'welcome'|'b2b-plan'
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeIdx: number;
  imageList: {id:number;img:any;title:string;subTitle:string}[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CarouselDisplayController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  swiper: any;
  interval:number|undefined = undefined
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    const slider = {
      'company':B2BSlider,
      'individual': D2CSlider,
      'password':PassSlider,
      'login':LoginSlider,
      'welcome':welcomeSliderList,
      'b2b-plan':B2BPlan
    }
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeIdx: 0,
      imageList: slider[this.props.type || 'company']
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidMount(): Promise<void> {
    this.interval = setInterval(()=>{
      this.setState(pre=>({
        ...pre,
        activeIdx:pre.activeIdx<pre.imageList.length-1?pre.activeIdx+1:0
      }))
    },4000) as unknown as number
    return Promise.resolve()
  }

  onClickPrevious = ()=>{
    this.updateInterval()
    this.setState(pre=>({
      ...pre,
      activeIdx:pre.activeIdx>0?pre.activeIdx-1:pre.imageList.length-1
    }))
  }

  onClickNext = ()=>{
    this.updateInterval()
    this.setState(pre=>({
      ...pre,
      activeIdx:pre.activeIdx<pre.imageList.length-1?pre.activeIdx+1:0
    }))
  }

  updateInterval = ()=>{
    !!this.interval&&clearInterval(this.interval)
    this.interval = setInterval(()=>{
      this.setState(pre=>({
        ...pre,
        activeIdx:pre.activeIdx<pre.imageList.length-1?pre.activeIdx+1:0
      }))
    },4000) as unknown as number
  }

  // Customizable Area End
}
