import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  link: string;
  content: string;

  onCloseModal: () => void;
  onSaveChange: (value: string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  content: string;
  isEdit: boolean;
  deleteDialog: boolean;
  closeDialog: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class InformationCollectedDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      content: "",
      isEdit: false,
      deleteDialog: false,
      closeDialog: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  onOpenEdit = () => {
    this.setState({
      isEdit: true,
      content: this.props.content,
    });
  };

  onSaveChange = () => {
    this.setState({
      isEdit: false,
    });

    this.props.onSaveChange(this.state.content);
  };

  onDismissChange = () => {
    this.onOpenDeleteDialog();
  };

  onSubmitDismissChange = () => {
    this.onCloseDeleteDialog();

    if (this.state.closeDialog) {
      this.props.onCloseModal();
    }

    this.onResetDialog();
  };

  onApplyInfo = () => {
    this.onCloseModal();
  };

  onCloseModal = () => {
    if (this.state.isEdit) {
      this.setState({
        closeDialog: true,
      });

      this.onDismissChange();
    } else {
      this.props.onCloseModal();
      this.onResetDialog();
    }
  };

  onChangeContent = (e: any) => {
    this.setState({
      content: e.target.value,
    });
  };

  onResetDialog = () => {
    this.setState({
      content: "",
      isEdit: false,
      closeDialog: false,
    });
  };

  onOpenDeleteDialog = () => {
    this.setState({
      deleteDialog: true,
    });
  };

  onCloseDeleteDialog = () => {
    this.setState({
      deleteDialog: false,
    });
  };
  // Customizable Area End
}
