import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  IconButton,
  Checkbox,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import moment from "moment";
import { withStyles, createStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import {
  arrowDown,
  arrowUp,
  bookmsrkGrey,
  selectedBookmark,
  swapCalls,
  checkedIcon,
  checkIcon,
  workflowDelete,
  calender,
  historyTime,
  openInNewBlue,
  chatBlue,
  bookmarkRed,
  clock,
  historyTimeRed,
  historyTimeBlue,
  historyTimeGreen,
  emailGreen,
  conversation
} from "../../blocks/chatbot4/src/assets";
// Customizable Area End

interface IProps {
  classes?: any;
  selectedFeilds?: any;
  handelFeildCheckBoxClick?: any;
  selectFeildEnable?: any;
  data: any;
  selectedHistoryId?: any;
  handelSelctedHistory?: any;
  tableHeader: any;
  activeTab: boolean;
}

class WorkflowTable extends React.Component<IProps, {}> {
  render() {
    const {
      classes,
      selectedFeilds,
      data,
      handelFeildCheckBoxClick,
      selectFeildEnable,
      selectedHistoryId,
      handelSelctedHistory,
      tableHeader,
      activeTab = 1,
    } = this.props;

    return (
        <Box className={classes.tableContainer}>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeader.map((head: any, index: any) => (
                  <TableCell className={classes.tableHeadCell} key={head}>
                    {head}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
                <TableBody>
                  {data.map((workflow: any,index:any) => (
                    <TableRow key={workflow.id} >
                      <TableCell key={workflow}  data-test-id={`parameterWorkflow-${index}`}>
                        <Box className={classes.optionsworkFlowNameCell}>
                          {selectFeildEnable && (
                            <Checkbox
                              checked={selectedFeilds.includes(workflow.id)}
                              checkedIcon={
                                <img
                                  src={checkedIcon}
                                  style={{ height: "20px", width: "20px" }}
                                  alt="checkedIcon"
                                />
                              }
                              icon={
                                <img
                                  src={checkIcon}
                                  style={{ height: "20px", width: "20px" }}
                                  alt="checkIcon"
                                />
                              }
                              onClick={(e) => {
                                handelFeildCheckBoxClick(e, workflow.id);
                              }}
                            />
                          )}
                          <IconButton>
                            <img
                              src={swapCalls}
                              alt="swapCalls"
                              className={classes.icon}
                            />
                          </IconButton>
                          <Box>
                            <Typography className={classes.sessionTitle}>
                              {workflow?.attributes?.name}
                            </Typography>
                            <Typography className={classes.sessionDesc}>
                              {workflow?.attributes?.description}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell key={`${workflow}<>`} className={""}>
                        <Box className={classes.optionsConversationCell}>
                          <Box>
                            {" "}
                            <img src={conversation} alt="conversation" />
                          </Box>
                          <Box>
                            {" "}
                            <Typography
                              className={
                                classes.optionsConversationHeadingCell
                              }
                            >
                              {
                                workflow.attributes.associated_question
                                  .field_name
                              }
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell key={workflow.attributes.created_at} className={""}>
                        <Box className={classes.optionsTimeStampCell}>
                          <Box className={classes.optionsClockCell}>
                            <img src={clock} alt="clock" />

                            <Typography
                              className={classes.optionsTimeStampDateCell}
                            >
                              {moment(
                                workflow.attributes.created_at
                              ).format("hh:mm A")}
                            </Typography>
                          </Box>
                          <Box className={classes.optionsCalenderCell}>
                            <img src={calender} alt="calender" />
                            <Typography
                              className={classes.optionsTimeStampDateCell}
                            >
                              {moment(
                                workflow.attributes.created_at
                              ).format("DD.MM.YYYY ")}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell
                        className={""}
                        style={{
                          width: "170px",
                        }}
                      >
                        <Box className={classes.optionsCell}>
                          <Button className={classes.useBtn}>Use</Button>
                          {
                            activeTab!==1&&
                            <IconButton>
                                <EditIcon   className={classes.icon} style={{color:"#3a84c9"}}/>
                            </IconButton>
                          }
                          <IconButton>
                            <img
                              src={selectedBookmark}
                              alt="selectedBookmark"
                              className={classes.icon}
                            />
                          </IconButton>
                          <IconButton>
                            <img
                              src={workflowDelete}
                              alt="delete_icon"
                              className={classes.icon}
                            />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
            
          </Table>
          {data.length===0 && (
              <Box>
                <Typography className={classes.nullTableData}>
                  No Data
                </Typography>
              </Box>
)}
        </Box>
      </Box>
    );
  }
}

const styles = createStyles({
  lengthWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    padding:"5px"
  },
  lengthText: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
  },
  mainWorkFlowContainer: {
    position: "relative",
    height: "100vh",
    padding: "16px 20px 16px 20px",
  },
  headingContainer: {},
  pageHeading: {
    fontFamily: "Montserrat",
    fontSize: "48px",
    fontWeight: 600,
    textAlign: "center",
  },
  subHeadingContainer: {},
  pageSubheading: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "center",
    color: "#475569",
  },
  parameterContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tabsContainer: {},
  searchContainer: {
    display: "flex",
    alignItems: "center",
  },
  tableContainer: {},
  activeChatChipcontainer: {
    // width: "206px",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  activeChatChiptab: {
    display: "flex",
    listStyle: "none",
    padding: "2px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    borderRadius: "80px",
    marginTop: "10px",
    "& li": {
      padding: "4px 12px",
      display: "flex",
      fontSize: "12px",
      cursor: "pointer",
      fontWeight: "600",
      lineHeight: "22px",
      fontFamily: "Montserrat",
      //   marginRight: "26px",
    },
    "& li.lastItem": {
      marginRight: 0,
    },
    "& li.active": {
      padding: "4px 12px",
      borderRadius: "80px",
      background: "#000",
      color: "#FFFFFF",
    },
  },
  selectField: {
    borderRadius: "8px",
    height: "40px",
    marginLeft: "10px",
    border: "1px solid #94A3B8",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 15px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#e7e7e7",
      borderColor: "#000",
    },
  },
  selectFieldText: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginRight: "5px",
  },
  tableHeading: {
    color: "#475569",
    fontSize: "12px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  tableHeadCell: {
    borderBottom: "none",
  },
  nullTableData: {
    color: "#475569",
    fontSize: "12px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  useBtn: {
    textTransform: "none",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    "&:hover": {
      borderColor: "#e7e7e7",
      backgroundColor: "#000",
      color: "#FFFFFF",
    },
  },
  optionsCell: {
    display: "flex",
    alignItems: "center",
      justifyContent:"center"
  },
  optionsTimeStampCell: {
    display: "flex",
      justifyContent:"center",
      flexDirection:"column"
  },
  optionsTimeStampDateCell: {
    color: "#475569",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
  optionsConversationCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsConversationHeadingCell: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
  optionsworkFlowNameCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsworkFlowheadingCell: {},
  sessionDesc: {
    width: "250px",
    //   overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#475569",
    lineHeight: "16px",
    maxHeight: "32px",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  sessionTitle: {
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    
  },
  icon: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },
  optionsClockCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsCalenderCell: {
    display: "flex",
    alignItems: "center",
  },
  rightBarIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    zIndex: 4200,
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
  },
  removeSelectionText: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: "#D93855",
    cursor: "pointer",
  },
  selectAllText: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  shareFeildContainer: {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    marginRight: "10px",
  },
  tableRowContainer: {
    "&:hover": {
      borderBottom: "2px solid #A68B3D",
      backgroundColor: "#FFFBF4",
    },
  },
  feedbackBorderBottom: {
    "&:hover": {
      borderBottom: "2px solid #3A84C9",
      backgroundColor: "#eff4f9",
    },
  },
  postEventBorderBottom: {
    "&:hover": {
      borderBottom: "2px solid #D93855",
      backgroundColor: "#f2e9ea",
    },
  },
  marketingBorderBottom: {
    "&:hover": {
      borderBottom: "2px solid #57BE99",
      backgroundColor: "#EAF7F2",

    },
  },
  postEventBox:{
    color:"#D93855",
    border:"1px solid #D93855",
    borderRadius:"26px",
    display: "flex",
    alignItems: "center",
    justifyContent:"center",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    padding:"5px 0px",
    width:"200px",

  },
  feedbackBox:{
    width:"200px",
    padding:"5px 0px",
    color:"#3A84C9",
    border:"1px solid #3A84C9",
    borderRadius:"26px",
    display: "flex",
    alignItems: "center",
    justifyContent:"center",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  marketingBox:{
    color:"#57BE99",
    padding:"5px 0px",
    border:"1px solid #57BE99",
    borderRadius:"26px",
    display: "flex",
    alignItems: "center",
    justifyContent:"center",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    width:"200px",

  },
  post:{
      backgroundColor: "#FEE2E2",
  },
  feedback:{
    backgroundColor: "#3A84C933",
},
marketing:{
    backgroundColor: "#D1FAE5",
},
copilot:{
    backgroundColor: "#ECE1CD",
},

});

export default withStyles(styles)(WorkflowTable);