import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";

import { parseErrors } from "../../utilities/src/Toast";
import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import firebase from "firebase/app";
import "firebase/auth";
import StorageProvider from "../../../framework/src/StorageProvider";

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
}

interface S {
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  errors: any;
  message: string;
  privacy: any;
  terms: any;
  toastErrors:any;
}

interface SS {
  id: any;
}

export default class EmailLoginController extends BlockComponent<Props, S, SS> {
  validationApiCallId: string = "";
  labelTitle: string = "";
  apiEmailLoginCallId: string = "";
  apiGoogleLoginCallId: string = "";
  getPrivacyMessageId: string = "";
  getTermsMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.redirectToSignUpPage = this.redirectToSignUpPage.bind(this);
    this.handleForgotPasswordClick = this.handleForgotPasswordClick.bind(this);

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      errors: { password: "", email: "" },
      message: "",
      privacy: {
        isModalVisible: false,
        loading: false,
        data: [],
      },
      terms: {
        isModalVisible: false,
        loading: false,
        data: [],
      },
      toastErrors:""
    };

    this.labelTitle = configJSON.labelTitle;

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
    },
    secureTextEntry: true,
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiEmailLoginCallId) {
        this.handelEmailLoginCallAPIResponse(responseJson);
      }
      if (apiRequestCallId == this.apiGoogleLoginCallId) {
        this.handelGoogleLoginAPIResponse(responseJson);
      }
    }
  }

  async handelEmailLoginCallAPIResponse(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      await StorageProvider.set("user-data", JSON.stringify(responseJson.data));
      await StorageProvider.set("user-token", responseJson.meta.token);
      await StorageProvider.set(
        "user-refresh-token",
        responseJson.meta.refresh_token
      );
      this.setState({ message: "Successfully logged in" });
      this.redirectToLandingPagePage()
    } else {
      responseJson.type === "email"
        ? this.setState({ errors: { email: responseJson.error } })
        : this.setState({ errors: { password: responseJson.error } });
    }
  }

  async handelGoogleLoginAPIResponse(responseJson: any) {
    if(!responseJson?.data?.attributes?.is_account_existed){
      await StorageProvider.set('user-email',responseJson?.meta?.email);
      await StorageProvider.set('user-token',responseJson?.meta?.token);
      const message: Message = new Message(getName(MessageEnum.NavigationMessage))
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'CreateAccount'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
      this.send(message);
    }
      else if (responseJson?.meta?.token) {
          await StorageProvider.set("user-data", JSON.stringify(responseJson.data));
          await StorageProvider.set("user-token", responseJson.meta.token);
          await StorageProvider.set(
            "user-refresh-token",
            responseJson.meta.refresh_token
          );
          this.redirectToLandingPagePage()
        } else {
          const errors = parseErrors(responseJson);
          this.setState({ toastErrors:errors });
        }
      
  }

  redirectToSignUpPage() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ChooseOption"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  async redirectToLandingPagePage() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    await StorageProvider.set('isEdit','true')
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleForgotPasswordClick() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  //Google Login
  initiateGoogleLogin = async () => {
    const auth = firebase.auth();
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = (await auth.signInWithPopup(provider)) as any;
      const token = result.credential.idToken;
      this.doGoogleLogin(token, result.user.email);
    } catch (error) {
      const errorMessage = (error as any).message;
      this.setState({ errors: errorMessage });
    }
  };

  doGoogleLogin = async (token: string, email: string) => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const httpsBody = {
      data: {
        type: "social_account",
        attributes: {
          // email: email,
          unique_auth_id: token,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGoogleLoginCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.googleLoginAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  onClose = () => {
    this.setState({ errors: "", message: "" });
  };

  forgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  doLogIn(emailId: string, pwd: string): boolean {
    if (emailId === null || emailId?.length === 0) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (pwd === null || pwd?.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const body = {
      email: emailId,
      password: pwd,
    };

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const dataBody = {
      type: "email_account",
      attributes: body,
    };

    const httpsBody = {
      data: dataBody,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

}
