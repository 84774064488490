import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    customTooltip: {
      backgroundColor: (props: any) => props.bgColor || 'red',
      color: (props: any) => props.color || 'white',
      fontSize: '14px',
      borderRadius: '8px',
      padding: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
      position: 'relative',
      top: '-5px', 
    },
    arrow: {
      color: (props: any) => props.bgColor || 'red',
    },
    innerText: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 500,
        fontSize: '12px',
      },
      
  });

const CustomTooltip = ({ color, label, bgColor, children, classes }: any) => {
  return (
    <Tooltip 
    title={<Typography className={classes.innerText}>{label}</Typography>}
    classes={{ tooltip: classes.customTooltip, arrow: classes.arrow }} 
    arrow>
      {children}
    </Tooltip>
  );
};

export default withStyles(styles, { name: 'CustomTooltip' })(CustomTooltip);
