import React from "react";
import {
  Box,
  Typography,
  Paper,
  Dialog,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { createStyles } from "@material-ui/core/styles";
import Clear from "@material-ui/icons/Clear";

import moment from "moment";
interface Props {
  privacyPolicy?: any;
  changePrivacyPolicyState: (email: string) => void;
}

const PrivacyPolicy = (props: Props) => {
  const { privacyPolicy, changePrivacyPolicyState } = props;
  const privarcyPolicyData = privacyPolicy?.data?.privacy_policy?.data;

  console.log("check",privacyPolicy)
 
  return (
    <Box>
      {privacyPolicy?.isModalVisible && (
        <Dialog
          open={true}
          PaperProps={{
            style: {borderRadius: "6px"},
          }}
        >
          <Box style={styles.PasswordModal}>
            <Box style={{ padding: "20px" }}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <Box>
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "22px",
                      fontWeight: 600,
                    }}
                  >
                    Privacy Policy
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ color: "#8C8C8C", fontWeight: 400 }}
                  >
                    Updated {
                        moment(privacyPolicy?.data?.updated_at).format("DD MMM YYYY")
                }
                  </Typography>
                </Box>
                <IconButton
                data-testid="closePrivacypolicyModalBtn"
                  style={{ padding: 0 }}
                  onClick={() => {
                    changePrivacyPolicyState({
                      ...privacyPolicy,
                      isModalVisible: false,
                      data:[]
                    });
                  }}
                >
                  <Clear />
                </IconButton>
              </Box>

              <Box
                style={{
                  maxHeight: "350px",
                  overflow: "auto",
                  marginRight: "10px",
                  marginTop:"5px"
                }}
                className="custom-scrollbar"
              >
                {privarcyPolicyData?.map((item: any) => (
                  <Box 
                  key={`${new Date()}`}
                  style={{ margin: "20px 0px" }}>
                    <Box style={styles.flexCenter}>
                      <Typography style={styles.dotStyle}></Typography>
                      <Typography style={styles.titleStyle}>
                        {item?.attributes?.title}
                      </Typography>
                    </Box>
                    <Typography style={styles.contentStyle}>
                    {item?.attributes?.body}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

export default PrivacyPolicy
const styles: any = createStyles({
  modal: {
    borderRadius: "50px",
  },
  PasswordModal: {
    height: "450px",
    width: "577px",
  },
  feild: {
    margin: "20px 0px",
  },
  buttonStyle: {
    background: "linear-gradient(90deg, #CAB354 -0.18%, #A68B3D 100.1%)",
    boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
    borderRadius: " 8px",
    color: "#FFF",
    margin: "20px 0px",
  },
  dotStyle: {
    backgroundColor: "#3A84C9",
    width: "8px",
    height: "8px",
    borderRadius: "50%",
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
  },
  titleStyle: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
    marginLeft: 8,
  },
  contentStyle: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400,
    color: "#8C8C8C",
    lineHeight: "20px",
    paddingRight: "20px",
    marginTop: "10px",
    textAlign: "justify",
  },
});
