import { Box, Typography, makeStyles } from "@material-ui/core";
import React from "react";

interface IProps {
  children: React.ReactNode;
  carousel?:React.ReactNode;
  hideBackBtn?:boolean;
  poweredByLogo?:boolean;
  back?:()=>void;
  backgroundImage?:any
}

const useStyles = makeStyles({
  container: {
    // display: "flex",
    height: "100vh",
    background: "linear-gradient(130deg, #F9F9F9 -25.99%, #EBE2CF 77.55%)",
    overflowY: "scroll",
    overflowX:'hidden',
  },
  container1: {
    backgroundImage:`url(${require('./background.png')})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow:'auto',
    height:'100vh'
  },
  logo: {
    mixBlendMode: "multiply", //for removing background white from image

    height:'25px',
    width:'125px',
    marginBottom:'36px',
    marginLeft:'12px',
    marginTop:'6px',
    '@media (min-width: 600px)' : {
      height:'40px',
      width:'200px',
    },
  },
  leftSection: {
    position:"relative",
    display: "flex",
    height:'100%',
    flexDirection:'column',
    alignItems:'flex-start',
    '@media (min-width: 600px)' : {
      width:'50%',
      // flex: ".5",
      display:'flex',
      height:'100%'
    },
  },
  leftSection2: {
    flex: "1",
    position:"relative",
    display: "flex",
    flexDirection:'column',
    alignItems:'flex-start',
    '@media (min-width: 600px)' : {
      display:'flex'
    },
  },
  leftContent: {
    display: "flex",
    flex:1,
    width:'100%',
    justifyContent: "center",
    alignItems: "center",
  },
  leftContent2: {
    display: "flex",
    // flex:1,
    width:'100%',
    marginTop:'50px',
    '@media (min-width: 600px)' : {
      marginTop:0,
      justifyContent: "center",
      alignItems: "center",
    },
  },
  iconBack:{
    width:'24px',
    height:'24px',
    paddingLeft:'12px',
    alignSelf:'flex-start',
    marginTop:'20px',
    '@media (min-width: 600px)' : {
      width:'24px',
      height:'24px',
      paddingLeft:'24px',
      marginTop:'20px',
    },
    '@media (min-width: 1300px)' : {
      width:'48px',
      height:'48px',
      paddingLeft:'40px'
    },
  },
  content: {
    display: "flex",
    justifyContent: "center",
    flexDirection:'column',
    flex:1,
  },
  rightSection: {
    display:'none',
    position: "relative",
    '@media (min-width: 600px)' : {
      flex: ".5",
      display:'flex'
    },
  },
  img: {
    height: "100%",
    position: "absolute",
    left: "-34px",
    right: 0,
    width: "calc(100% + 34px)"
  },
  poweredText:{
    color: "#000",
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "120%",
  },
  poweredBox:{
    marginTop:'36px',
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    width: "100%",
    position:"relative",
    paddingBottom:"28px",
  },
  canImg:{
    marginLeft:"8px",
    width:'100px',
    height:'17px'
  },
  tempImageWrapper:{
    boxSizing: "border-box",
    "-moz-box-sizing": "border-box",
    "-webkit-box-sizing": "border-box",
    width: "100%",
    height: "100%",
  },
  image:{
    width:"100%",
    height:'100%',
    objectFit:'cover'
  },
  poweredByLogo:{
    display:"none"
  },
  back:{
    width:'24px',
    height:'24px',
    cursor:'pointer',
    '@media (min-width: 600px)' : {
      width:'24px',
      height:'24px',
    },
    '@media (min-width: 1300px)' : {
      width:'48px',
      height:'48px',
    },
  },
  carouselWrapper:{
    position:'fixed',
    top:0,
    right:0,
    width:'50%',
    bottom:0
  }
});

const Layout = ({carousel,children,hideBackBtn,poweredByLogo=true,back=()=>{},backgroundImage=false}: IProps) => {
  const classes = useStyles();

  const onBack = ()=>{
    if(hideBackBtn) return;
    back();
  }

  return (
    <Box className={backgroundImage?classes.container1:classes.container}>
      <Box className={carousel !== 'hide'?classes.leftSection:classes.leftSection2}>
        <img src={require("./pebbleLogo.png")} alt="logo" className={classes.logo} />
        <Box className={carousel !== 'hide'?classes.leftContent:classes.leftContent2}>
          <Box data-test-id="layoutBackButton" className={classes.iconBack} onClick={onBack}>
            {!hideBackBtn&&<img src={require("./button_back.svg")} alt="back" className={classes.back} />}
          </Box>
          <Box className={classes.content}>
            {children}
          </Box>
          <Box className={classes.iconBack}/>
        </Box>
        <div className={poweredByLogo?classes.poweredBox:classes.poweredByLogo}>
          <Typography className={classes.poweredText}>Powered by</Typography>
          <img src={require("./bottomLogo.png")} alt="can-img" className={classes.canImg} />
        </div>
      </Box>
      {
        carousel !== 'hide' &&
      <Box className={classes.rightSection}>
           <Box className={classes.carouselWrapper}>
          {carousel || <Box className={classes.tempImageWrapper}><img className={classes.image} src="https://s3-alpha-sig.figma.com/img/754d/6c39/c180c1e1b1e7c40aa4b6dbc0959245ee?Expires=1700438400&Signature=nwca-ZzcbwIkxO8o6dnu9R8cp4zNTIAW22yNdiJKcaZjH6oi-Icpq6-Ga3sJfwbED1YHTZ22rzihawr27JziNtMewetqo98xASKtmOtEV8KUmXP9sSuCYRf0TEi~7ZZ0tCkw042RtV16iLAyVDGmDtlZfY~abCA4iGKOGTj26hbO4R5WCwFiJr~0sik0RLlbPZstLK9xlrkdW-xB4mUq93Yf1Gdbv9bd5tUdJXGnLpGziO4j6jwAKQhB-fKRLTXRbX68p13KICiDMidZYiNhgHUp1QedezFOCZ0ho7ePaA-QmnaCDaZj3cQdGoMAIdwdOijZGScaKs1kVRrsEVFbyw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"/></Box>}
        </Box>
      </Box>
      }
    </Box>
  );
};

export default Layout;
