export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const closeIcon = require("../assets/close.svg");

export const hintTooltip = require("../assets/hintIcon.svg")
export const saveWorkFlowIcon = require("../assets/bookmark.svg")
export const loading = require("../assets/loading.png")
export const ready = require("../assets/ready.png")
export const checkedIcon = require("../assets/checkbox_checked.svg")
export const arrowIcon = require("../assets/arrow.svg")
export const rightBar = require("../assets/right_bar_icon.svg");
export const searchIcon = require("../assets/searchIcon.png");
export const selectBtn = require("../assets/selectBtn.svg");
export const reply = require("../assets/reply.svg");
export const edit = require("../assets/edit.svg");
export const deleteImg = require("../assets/delete.svg");
export const calender = require("../assets/calender.svg");
export const clock = require("../assets/clock.svg");
export const workflowDelete = require("../assets/workflowDelete.svg");
export const bookmarkRed = require("../assets/bookmarkRed.svg");
export const chatBlue = require("../assets/chatBlue.svg");
export const openInNewBlue = require("../assets/openInNewBlue.svg");
export const timeClock = require("../assets/timeClock.svg");
export const arrowDown = require("../assets/arrowDown.svg");
export const arrowUp = require("../assets/arrowUp.svg");
export const timeRed = require("../assets/timeRed.svg");
export const timeBlue = require("../assets/timeBlue.svg");
export const timeGreen = require("../assets/timeGreen.svg");
export const emailGreen = require("../assets/emailGreen.svg");
export const marketing = require("../assets/marketing.svg");
export const copy = require("../assets/copy.svg");
export const editField = require("../assets/editField.svg");
export const sendEmail = require("../assets/sendEmail.svg");
export const download = require("../assets/download.svg");
export const copyText = require("../assets/copyText.svg");
export const word = require("../assets/word.svg");
export const pdf = require("../assets/pdf.svg");
export const unselect = require("../assets/unselect.svg");
