import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core";
import { ISubscriptionPlan } from "./SubscriptionPlan.web";
import { ICurrentSubscriptionPlan } from "../../customisableuserprofiles/src/ProfileController";
import StorageProvider from "../../../framework/src/StorageProvider";



export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}


interface S {
  currentPlan: ICurrentSubscriptionPlan | null;
  plans: Array<ISubscriptionPlan>;
  rightMenu: boolean;
}

interface SS {
  id: any;
}

export default class SubscriptionExploreUpgradeController extends BlockComponent<
  Props,
  S,
  SS
> {
  token: string = "";
  apiFetchPlansId: string = "";
  apiFetchMySubscriptionId: string = "";
  apiCreateCheckOutSessionCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

     this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];


    this.state = {
      currentPlan: null,
      plans: [],
      rightMenu: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.apiFetchMySubscriptionId:
        this.handleCurrentPlanResponse(responseJson)

        break;


      case this.apiFetchPlansId:
        this.handleFetchPlanResponse(responseJson);

        break;
    
      default:
        break;
    }
  }

  async componentDidMount() {
    const token = await StorageProvider.get("user-token");
    if (!token) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
      return;
    }

    this.token = token;

    this.fetchCurrentSubscription();
    this.fetchAllPlans();
  }

  onChoosePlan = async (plan: ISubscriptionPlan) => {
    await StorageProvider.set("new_plan", JSON.stringify(plan));
    await StorageProvider.set("change_subscription_status", "upgrading");

    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );

    message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message);
  };

  fetchAllPlans = async () => {
    const header = {
      token: this.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFetchPlansId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.plansEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFetchPlanResponse(response: any) {
    if (!response.data) {
      return;
    }
    this.setState({
      plans: response.data,
    });
  }

  fetchCurrentSubscription = async () => {
    const header = {
      token: this.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiFetchMySubscriptionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.mySubscriptionEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCurrentPlanResponse(response: any) {
    if (!response.data || !response.data[0]) return;
    const planData = response.data[0];
    this.setState({
      currentPlan: {
        id: planData.attributes.subscription.id,
        type: planData.type,
        start_date: planData.attributes.start_date,
        attributes: {
          name: planData.attributes.subscription.name,
          price: planData.attributes.subscription.price,
          description: planData.attributes.subscription.description,
          validity: planData.attributes.subscription.validity,
          level: planData.attributes.subscription.level,
          order: planData.attributes.subscription.order,
          old_price: planData.attributes.subscription.old_price,
          highlighted_feature: planData.attributes.subscription.highlighted_feature,
          unique_key: planData.attributes.subscription.unique_key,
          image_link: planData.attributes.subscription.image_link,
          features: planData.attributes.subscription.subscription_feature.map(
            (item: any) => ({
              feature: item.feature,
              checked: item.checked,
            })
          ),
        },
      },
    });
  }

  toggleRightMenu = () => {
    this.setState({ rightMenu: !this.state.rightMenu });
  };
}
