import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from '../../../framework/src/StorageProvider';
import { parseErrors } from "../../utilities/src/Toast";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken:string;
  pathDetails:{
      id: string,
      type: string,
      attributes: {
        path_name: string,
        duration: string,
        path_description:string
      },
      borderColor:string,
      color:string
  }[];
  successMessage:string; 
  errors:string | undefined;
  isLoad:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChooseYourPathController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  displayPathDetailsID : string = "";
  addPathDetailsID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      authToken:"",
      pathDetails:[],
      successMessage:"",
      errors:"",
      isLoad:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    super.componentDidMount();
    this.setState({
      authToken: await StorageProvider.get("user-token")
    }, () => {
      this.getPathDetails()
    })
    
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      
      await this.handlePathDetails(requestCallId, message);
      await this.handleAddPathDetails(requestCallId, message);
    }
    // Customizable Area End
  }

  async handlePathDetails(requestCallId: string, message: Message) {
    if (this.displayPathDetailsID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({
          pathDetails: responseJson.data
        });
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  
  async handleAddPathDetails(requestCallId: string, message: Message) {
    if (this.addPathDetailsID === requestCallId) {
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (response && response.message) {
        this.handleNextScreen();
      } else if (response && response.errors) {
        const errors = parseErrors(response);
        this.setState({ errors });
      }
    }
  }

  getPathDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.displayPathDetailsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointGetPath
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleBack = async () => {
    const isBusiness = await StorageProvider.get('isBusiness');
    const screen = !!isBusiness?'CompanyAbout':'About'
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      screen
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  generateBorderColor = (index:number) => {
    const colors = ['#A68B3D','#3A84C9', '#57BE99']; 
    return colors[index % colors.length]; 
  };
  
  generateFontColor = (index:number) => {
    const colors = ['transparent','#22507B', '#059669']; 
    return colors[index % colors.length];
  };

  handlePath = (id:string) => {
    const requestPathMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.addPathDetailsID = requestPathMessage.messageId;
    requestPathMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointGetPath + "/" + `${id}` + configJSON.apiEndPointAddPath
    );
    requestPathMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestPathMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethodType
    );
    runEngine.sendMessage(requestPathMessage.id, requestPathMessage);
  };

  onClose = () => {
    this.setState({errors:""});
  };

  handleNextScreen = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'BeforeWeStart'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }
  
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
