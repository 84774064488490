import {Snackbar,CircularProgress,Backdrop} from '@material-ui/core';
import React from 'react'
import {errorIcon} from './assets'

type Props={
  onClose:()=>void;
  message?:string;
  errors?:any;
  isLoad?:boolean;
}

export const parseErrors=(responseJson: any)=> {
    if (!responseJson?.errors) {
      return;
    }
    const errors: any[] = responseJson.errors;

    let allerrors:string = '';
    errors.map((object: string) => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          if (allerrors.length <= 0) {
            allerrors = value;
          } else {
            allerrors = `${allerrors}{\n}${value}`;
          }
        }
      });
    });

    return allerrors;
  }

  function getContent(content:any,isError:boolean){
    if(isError){
      return(
        <span style={{display:"flex",alignItems:"center"}}>
          <img src={errorIcon} style={{marginRight:"7px"}} />
          {content}
        </span>
      )
    }else{
      return <span>{content}</span>
    }
  }

  function Toast({onClose,message,errors,isLoad}:Props){
    const content=errors?errors:message
    return(
      <>
      <Snackbar
        ContentProps={{
          style:{background:"#fff",color:"#000000"}
        }}
        open={!!(errors||message)}
        {...{onClose}}
        message={getContent(content,!!errors)}
        autoHideDuration={4000}
      />
      {isLoad&&
        <Backdrop
          style={{ background: "#a9a9a924", zIndex: 10000 }}
          invisible={true}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
    }
      </>
    )
  }

  export default Toast