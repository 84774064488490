export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const ChatgptImg = require("../assets/chatgptImg.svg");
export const LogoImg = require("../assets/logo.svg");
export const GoogleImg = require("../assets/Google.svg");
export const RightSectionImg = require("../assets/RightSectionImage.svg");
export const UploadIcon = require("../assets/upload_icon.svg");
export const PdfIcon = require("../assets/pdf.svg");
export const checkedIcon = require("../assets/checkbox_checked.svg");
export const checkIcon = require("../assets/checkbox.svg");
export const locationIcon = require("../assets/location.png");
export const searchIcon = require("../assets/search.png");
export const accordup = require("../assets/accordup.svg");
export const accordupGreen = require("../assets/accordupGreen.svg");
export const accordupYellow = require("../assets/accorupYellow.svg");
export const infobtn = require("../assets/infobutton.svg");
export const successBg = require("../assets/view_bg_photo.png");
export const successCheck = require("../assets/done_outline_24px.svg");
export const countryFile = require("../assets/country.json");
