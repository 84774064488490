import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import { parseErrors } from "../../utilities/src/Toast";
// Customizable Area End

export const AccountconfigJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open: boolean;
  handleDeleteModal: () => void;
  userEmail:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  open: boolean;
  deleteModal: boolean;
  userEmail: string;
  currentMail: string;
  error: boolean;
  errorMessage: string;
  authToken:string;
  errors:string | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountDeletionModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  deleteAccountID : string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.deleteAccountID =""
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];
    


    this.state = {
      // Customizable Area Start
      open: false,
      deleteModal: false,
      userEmail: "",
      currentMail: "",
      error: false,
      errorMessage: "",
      authToken:"",
      errors:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (this.deleteAccountID === requestCallId ) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson) {
          if(!responseJson.errors){
            this.handleLoginNavigation()
          }else{
            const errors=parseErrors(responseJson)
            this.setState({errors})
          }
        }
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const userData = await StorageProvider.get("user-data")
    const temp = JSON.parse(userData)
    this.setState({ userEmail: temp.attributes.email })
    this.setState({
      authToken: await StorageProvider.get("user-token")
    })
  }
  handleDeleteOpen = () => {
    this.setState({ deleteModal: !this.state.deleteModal })
  }
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      currentMail: event.target.value
    })
    if (this.props.userEmail !== event.target.value) {
      this.setState({ error: true, errorMessage: "Invalid current email. Please, try again" })
    } else {
      this.setState({
        error: false,
        errorMessage: ""
      })
    }
  }
  handleLoginNavigation = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'Home'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  deleteAccountAPI = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": AccountconfigJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.deleteAccountID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      AccountconfigJSON.apiEndPointDeleteAccount + this.props.userEmail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      AccountconfigJSON.deleteApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeleteAccount = () => {
    if (!this.state.currentMail) {
      this.setState({
        error: true,
        errorMessage: "Email Id should not be blank"
      })
    }else if(this.props.userEmail !== this.state.currentMail) {
    return
    }else {
      this.setState({
        error: false,
        errorMessage: ""
      })
      this.deleteAccountAPI()
    }

  }
  onClose = () => {
    this.setState({errors:""});
  };
  // Customizable Area End
}
