import React from "react";

// Customizable Area Start
import CarouselDisplay from "../../../blocks/carouseldisplay/src/CarouselDisplay.web";
import Layout from "../../../components/src/Layout";
import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { NewPwdSchema } from "../utils/schema";
import ForgotPwdController from "./ForgotPwdController";
import Toast from "../../utilities/src/Toast";
import { createStyles, withStyles } from "@material-ui/core/styles";
//Icons
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

// Customizable Area End

export class NewPassword extends ForgotPwdController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    const { isLoad } = this.state;
    const { onClose } = this;
    const { classes } = this.props;

    return (
      <Layout
        carousel={<CarouselDisplay type="password" id="" navigation={{}} />}
        hideBackBtn
      >
        <Toast data-testid={"toastComponent"} {...{ onClose, isLoad }} />
        <Container className={classes.containerStyle}>
          <Paper className={classes.signupform}>
            <Typography
              align="center"
              className={classes.typographyStyle1}
              variant="h6"
            >
              New Password
            </Typography>
            <Box>
              <Formik
                initialValues={{
                  newPassword: "",
                  confirmPassword: ""
                }}
                validationSchema={NewPwdSchema}
                onSubmit={values => {
                  this.resetPasswordApi(
                    values.newPassword,
                    values.confirmPassword
                  );
                }}
              >
                {({ errors, touched }) => (
                  <Form translate={undefined}>
                    <Box className={classes.inputFeildWrapper}>
                      <Typography
                        align="left"
                        className={classes.inputFeildText}
                        variant="h6"
                      >
                        New Password
                      </Typography>
                      <Field
                        data-test-id="newPasswordFieldTestIdd"
                        variant="outlined"
                        fullWidth
                        placeholder="Your new password"
                        as={TextField}
                        className={classes.input}
                        InputProps={{
                          style: {
                            height: "40px",
                            borderRadius: 8
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                data-test-id="newPasswordDataTestId"
                                onClick={() =>
                                  this.setState({
                                    enablePasswordField: !this.state
                                      .enablePasswordField
                                  })
                                }
                              >
                                {this.state.enablePasswordField ? (
                                  <VisibilityOutlinedIcon
                                    className={classes.pwdIconColor}
                                  />
                                ) : (
                                  <VisibilityOffOutlinedIcon
                                    className={classes.pwdIconColor}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={!!(errors.newPassword && touched.newPassword)}
                        name="newPassword"
                        type={
                          this.state.enablePasswordField ? "text" : "password"
                        }
                      />
                      <ErrorMessage
                        data-test-id="FormikErrorMessageDataTestId2"
                        name="newPassword"
                        component="div"
                        render={(message: any) => (
                          <div className={classes.errorMsg}>{message}</div>
                        )}
                      />
                    </Box>
                    <Box className={classes.inputFeildWrapper}>
                      <Typography
                        align="left"
                        className={classes.inputFeildText}
                        variant="h6"
                      >
                        Confirm New Password
                      </Typography>
                      <Field
                        data-test-id="confirmPasswordFieldTestId"
                        variant="outlined"
                        fullWidth
                        placeholder="Re-enter your new password"
                        as={TextField}
                        InputProps={{
                          style: {
                            height: "40px",
                            borderRadius: 8
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                edge="end"
                                data-test-id="confirmPasswordDataTestId"
                                onClick={() =>
                                  this.setState({
                                    btnConfirmPasswordShowHide: !this.state
                                      .btnConfirmPasswordShowHide
                                  })
                                }
                              >
                                {this.state.btnConfirmPasswordShowHide ? (
                                  <VisibilityOutlinedIcon
                                    className={classes.pwdIconColor}
                                  />
                                ) : (
                                  <VisibilityOffOutlinedIcon
                                    className={classes.pwdIconColor}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        error={
                          !!(errors.confirmPassword && touched.confirmPassword)
                        }
                        name="confirmPassword"
                        type={
                          this.state.btnConfirmPasswordShowHide
                            ? "text"
                            : "password"
                        }
                        className={classes.input}
                      />
                      <ErrorMessage
                        data-test-id="FormikErrorMessageDataTestId"
                        name="confirmPassword"
                        component="div"
                        render={(message: any) => (
                          <div className={classes.errorMsg}>{message}</div>
                        )}
                      />
                      {this.state.errors && (
                        <Typography className={classes.errorMsg}>
                          {" "}
                          {this.state.errors}
                        </Typography>
                      )}
                    </Box>
                    <Button
                      type="submit"
                      className={classes.submitBtn}
                      fullWidth
                    >
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            </Box>
          </Paper>
        </Container>
      </Layout>
    );
  }
}

// Customizable Area Start
const newPwdPageStyles: any = createStyles({
  inputFeildWrapper: { marginTop: "30px" },
  errorMsg: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
    color: "#DC2626",
    marginTop: "2px",
    fontFamily: "Montserrat"
  },
  containerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  paperStyle: {
    padding: "20px",
    borderRadius: "28px",
    height: "273px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)"
  },
  typographyStyle1: {
    margin: "10px  0px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "30px"
  },
  typographyStyle2: {
    // margin: '16px  0px',
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    color: "rgba(71, 85, 105, 1)"
  },
  signupform: {
    width: "100%",
    height: "auto",
    maxWidth: "520px",
    maxHeight: "381px",
    borderRadius: "28px",
    padding: "40px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)"
    // background: 'linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)'
  },
  inputFeildText: {
    fontWeight: 600
  },

  submitBtn: {
    margin: "25px 0px ",
    color: "white",
    backgroundColor: "black",
    height: "44px",
    marginRight: "5px",
    cursor: "pointer",
    borderRadius: "8px",
    border: " 1px solid rgba(203, 213, 225, 1)",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      background: "#000"
    }
  },
  link: {
    cursor: "pointer",
    color: "#3A84C9"
  },
  subHeadingtypographyStyle1: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "12px"
  },
  inputFeilds: {
    margin: "15px 0px"
  },
  pwdIconColor: {
    color: "#94A3B8"
  },
  input: {
    height: "42px",
    marginTop: "4px",
    borderRadius: " 8px",
    border: "1px solid rgba(140, 140, 140, 0.20)",
    background: "#FFF",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
        height: "44px"
      },
      "&:hover fieldset": {
        borderColor: "transparent"
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent"
      },
      "& .MuiOutlinedInput-input": {
        padding: "0 12px",
        lineHeight: "21px",
        color: "#64748B",
        fontSize: "14px",
        fontWeight: 500
      },

      "& .MuiInputBase-input::placeholder": {
        opacity: 1
      },

      "& .MuiInputBase-input": {
        height: "44px"
      }
    }
  }
});
// Customizable Area End

export default withStyles(newPwdPageStyles)(NewPassword);
