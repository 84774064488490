import React from "react";

import { Formik } from "formik";
import {
  withStyles,
  TextField,
  Typography,
  Button,
  Box,
  createStyles,
  createTheme,
  ThemeProvider
} from "@material-ui/core";
import ForgotPwdController, { Props } from "./ForgotPwdController";
import { ForgotPwdSchema } from "../utils/schema";
import Toast from "../../utilities/src/Toast";
import Layout from "../../../components/src/Layout";
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay.web";

type Values = {
  email: string;
};

const initialValues: Values = { email: "" };

export class ForgotPassword extends ForgotPwdController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { errors, isLoad } = this.state;
    const { onClose } = this;

    return (
      <ThemeProvider theme={theme}>
        <Layout
          carousel={
            <CarouselDisplay
              type="individual"
              id=""
              navigation={this.props.navigation}
            />
          }
        >
          <Box className={classes.layoutContainer}>
            <Box className={classes.emailContainer}>
              <Toast
                data-testid={"toastComponent"}
                {...{ errors, onClose, isLoad }}
              />
              <Typography className={classes.titleText}>
                Forgot password?
              </Typography>
              <Typography className={classes.subTitle}>
                No worries, we'll send a recovery link to your email.
              </Typography>

              <Formik
                initialValues={initialValues}
                validationSchema={ForgotPwdSchema}
                data-testid="formikSubmit"
                onSubmit={values => {
                  this.forgotPasswordApi(values.email);
                }}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <form
                    onSubmit={handleSubmit}
                    data-testid="formSubmit"
                    noValidate
                    style={{ marginBottom: 0 }}
                  >
                    <Typography
                      className={classes.label}
                      data-testid="emailField"
                    >
                      Email
                    </Typography>
                    <TextField
                      type="text"
                      variant="outlined"
                      fullWidth
                      name="email"
                      data-testid="txtInputEmail"
                      id="email"
                      value={values.email}
                      placeholder="Enter your email address..."
                      onChange={handleChange}
                      className={classes.input}
                      inputProps={{
                        style: {
                          ...(touched.email && errors.email
                            ? style.textRed
                            : {}),
                          ...style.font
                        }
                      }}
                      style={
                        touched.email && errors.email
                          ? style.borderRed
                          : undefined
                      }
                    />
                    {!!(touched.email && !!errors.email) && (
                      <Typography className={classes.validateError}>
                        {errors.email}
                      </Typography>
                    )}
                    <Button
                      variant="contained"
                      fullWidth
                      type="submit"
                      data-testid={"btnEmailLogIn"}
                      className={classes.button}
                    >
                      Send a recovery link
                    </Button>
                  </form>
                )}
              </Formik>
              <Typography className={classes.backText}>
                Already have an account?{" "}
                <Typography
                  data-testid="loginBtn"
                  className={classes.logInText}
                  onClick={() => this.goToLogin()}
                >
                  Log in
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Layout>
      </ThemeProvider>
    );
  }
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif"
  }
});

const style = {
  borderRed: {
    borderColor: "#D93855"
  },
  textRed: {
    color: "#D93855"
  },
  font: {
    fontFamily: "Montserrat"
  }
};

const styles = () =>
  createStyles({
    layoutContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "center"
    },
    emailContainer: {
      margin: "0 40px",
      "@media (min-width: 600px)": {
        border: "1px solid #CBD5E1",
        background:
          "linear-gradient(145deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
        padding: "20px"
      },
      "@media (min-width: 1300px)": {
        margin: 0,
        width: "438px",
        padding: "40px"
      },
      display: "flex",
      flexDirection: "column",
      borderRadius: "28px"
    },
    titleText: {
      textAlign: "center",
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "6px",
      "@media (min-width: 1300px)": {
        marginBottom: "12px"
      },
      color: "black"
    },
    subTitle: {
      textAlign: "center",
      fontSize: "14px",
      lineHeight: 1.5,
      fontWeight: 500,
      color: "#475569",
      display: "inline-block"
    },
    errorText: {
      color: "rgb(179, 23, 23)",
      fontSize: "13px",
      marginTop: "2px"
    },
    validateError: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#DC2626",
      marginTop: "2px"
    },
    form: {
      margin: "0"
    },
    label: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      color: "black",
      marginTop: "16px",
      "@media (min-width: 1200px)": {
        marginTop: "32px"
      }
    },
    input: {
      height: "42px",
      marginTop: "4px",
      borderRadius: " 8px",
      border: "1px solid rgba(140, 140, 140, 0.20)",
      background: "#FFF",
      overflow: "hidden",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "transparent",
          height: "44px"
        },
        "&:hover fieldset": {
          borderColor: "transparent"
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent"
        },
        "& .MuiOutlinedInput-input": {
          fontFamily: "Montserrat,sans-serif",
          padding: "0 12px",
          lineHeight: "21px",
          color: "#64748B",
          fontSize: "14px",
          fontWeight: 500
        },

        "& .MuiInputBase-input::placeholder": {
          opacity: 1
        },

        "& .MuiInputBase-input": {
          height: "44px"
        }
      }
    },
    button: {
      height: "44px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: 1.2,
      background: "#000",
      textTransform: "none",
      color: "white",
      marginTop: "32px",
      fontFamily: "Montserrat,sans-serif",
      "&:hover": {
        background: "#000"
      }
    },
    backText: {
      fontSize: "12px",
      fontWeight: 500,
      width: "100%",
      textAlign: "center",
      marginTop: "16px",
      display: "inline-block",
      color: "#475569"
    },
    logInText: {
      display: "inline-block",
      textAlign: "center",
      fontSize: "14px",
      lineHeight: 1.5,
      fontWeight: 600,
      color: "#3A84C9",
      marginLeft: "4px",
      cursor: "pointer"
    }
  });

export default withStyles(styles)(ForgotPassword);
