import React from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import {
  ArrowBackIos,
  DeleteOutline,
  InsertDriveFile,
  SaveAlt,
} from "@material-ui/icons";
import clsx from "clsx";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Dropdown from "../../../components/src/Dropdown.web";
import Toast from "../../utilities/src/Toast";
import BulkUploadingCompanyInfoController, {
  Props,
} from "./BulkUploadingCompanyInfoController.web";
import BulkUploadingCompanyInfoDialog from "./BulkUploadingCompanyInfoDialog.web";
import BulkUploadingCompanyInfoHighlightIcon from "./BulkUploadingCompanyInfoHighlightIcon.web";
import { background, logo } from "./assets";
import { v4 as uuidv4 } from "uuid";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif",
  },
});

const styles = () =>
  createStyles({
    container: {
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: "100%",
      width: "100%",
      padding: "200px 0",
    },

    logo: {
      mixBlendMode: "multiply",
      position: "absolute",
      top: 20,
      left: 40,
    },

    backBtn: {
      position: "absolute",
      top: 84,
      left: 40,
      borderRadius: "50%",
      backgroundColor: "transparent",
      padding: 12,
      border: "1px solid #E2E8F0",
      cursor: "pointer",
    },

    backBtnIcon: {
      fontSize: 16,
    },

    inner: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      zIndex: 1,
    },

    form: {
      boxSizing: "border-box",
      padding: 40,
      borderRadius: 28,
      maxWidth: 848,
      width: "100%",
      background:
        "linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },

    formTop: {
      position: "relative",
    },

    formHeading: {
      fontWeight: 700,
      fontSize: 30,
      lineHeight: "32px",
    },

    formHeadingIcon: {
      position: "absolute",
      bottom: -12,
      right: -42,
    },

    formDescription: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
      textAlign: "center",
      marginTop: 12,
      maxWidth: 536,
    },

    fileSectionWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      marginTop: 32,
    },

    fileInput: {
      width: "100%",
    },

    dropfileWrapper: {
      borderRadius: 20,
      width: "100%",
      border: "1px solid #CBD5E1",
      background: "#3A84C90D",
      height: 136,
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
    },

    uploadIcon: {
      fontSize: 24,
      color: "#3A84C9",
    },

    uploadTitle: {
      marginTop: 8,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: "22px",
      display: "flex",
    },

    chooseFile: {
      color: "#3A84C9",
      textDecorationLine: "underline",
      fontWeight: 600,
      marginLeft: 4,
    },

    input: {
      display: "none",
    },

    fileNoted: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      padding: "0 6px",
      marginTop: 8,
    },

    noted: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "22px",
      color: "#8C8C8C",
    },

    action: {
      marginTop: 32,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },

    cancelBtn: {
      borderRadius: 8,
      border: "1px solid #CBD5E1",
      fontWeight: 600,
      fontSize: 16,
      lineheight: "19.2px",
      textTransform: "capitalize",
      padding: "10px 16px",
      width: "100%",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
    },

    submitBtn: {
      borderRadius: 8,
      backgroundColor: "#000000",
      marginLeft: 20,
      color: "#FFFFFF",
      fontWeight: 600,
      fontSize: 16,
      lineheight: "19.2px",
      textTransform: "capitalize",
      padding: "10px 16px",
      width: "100%",
      cursor: "pointer",
    },

    fileWrapper: {
      marginTop: 24,
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },

    itemWrapper: {
      minHeight: "46px",
      display: "flex",
      padding: "8px 0 8px 35px",
      marginRight: "22px",
      borderBottom: "1px solid #CBD5E1",
      alignItems: "center",
    },

    fileScrollview: {
      maxHeight: "142px",
      overflowY: "auto",
      overflowX: "hidden",
      marginRight: "13px",
      "&::-webkit-scrollbar": {
        width: "6px",
        borderRadius: 8,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#CBD5E1",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#64748B",
        borderRadius: 8,
      },
    },

    fileIcon: {
      width: 35,
      height: 35,
      color: "#E04E4E",
      marginRight: "15px",
    },

    fileContent: {
      display: "flex",
      alignItems: "center",
    },

    fileInfo: {
      width: 194,
    },

    font16: {
      fontSize: "16px",
      lineHeight: "22px",
    },

    font12: {
      fontSize: "12px",
      lineHeight: "22px",
      fontWeight: 400,
    },

    fontWeight600: {
      fontWeight: 600,
    },

    deleteBtn: {
      marginLeft: "auto",
    },

    dropdown: {
      marginTop: 0,
      width: 200,
      borderRadius: 8,
    },

    dropownInput: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
      color: "#22507B",
      padding: "8px 12px",
      border: "1px solid #CBD5E1",
      borderRadius: 8,
    },

    deleteBtnIcon: {
      fontSize: 16,
    },

    fileSelectInner: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 12,
    },

    errorMessage: {
      color: "#DC2626",
      marginTop: "2px",
      fontSize: "12px",
      lineHeight: 1.2,
      fontWeight: 500,
    },
  });

const validationSchema = Yup.object().shape({
  data: Yup.object().test(
    "has-non-empty-property",
    "Please choose file type",
    (object: { [key: string]: number }) => {
      const errors: any = {};

      Object.entries(object).forEach(([key, value]) => {
        if (value <= 0) {
          errors[key] = "Please choose file type";
        }
      });

      if (Object.keys(errors).length === 0) return true;

      throw new Yup.ValidationError(errors, object, "data");
    }
  ),
});

export class BulkUploadingCompanyInfo extends BulkUploadingCompanyInfoController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.container}>
          <img src={logo} className={classes.logo} />

          <Box className={classes.inner}>
            <Formik
              validateOnChange={false}
              initialValues={{
                data: {},
              }}
              validationSchema={validationSchema}
              onSubmit={this.uploadMultipleFile}
            >
              {({
                errors,
                values,
                submitCount,
                touched,
                setTouched,
                validateField,
                setFieldValue,
              }) => (
                <Box className={classes.form}>
                  <Box className={classes.formTop}>
                    <Typography component="h1" className={classes.formHeading}>
                      Upload your company information
                    </Typography>

                    <Box className={classes.formHeadingIcon}>
                      <BulkUploadingCompanyInfoHighlightIcon
                        highlight={this.state.highlightInfo}
                        onClick={this.toggleInfoDialog}
                        onSubmit={this.toggleHighlightInfo}
                      />
                    </Box>
                  </Box>

                  <Typography
                    component="span"
                    className={classes.formDescription}
                  >
                    This will train our proprietary AI engine, familiarising it
                    with your business context. The more you use Pebbles Ai, the
                    more powerful it becomes in providing you with intelligence,
                    guidance and content.
                  </Typography>

                  <Box className={classes.fileSectionWrapper}>
                    <label className={classes.fileInput} htmlFor="file-input">
                      <Box
                        onDragEnter={this.handleDrag}
                        onDragLeave={this.handleDrag}
                        onDragOver={this.handleDrag}
                        onDrop={this.handleDrop}
                        data-testid="dragArea"
                        className={classes.dropfileWrapper}
                      >
                        <SaveAlt className={classes.uploadIcon} />
                        <Typography className={classes.uploadTitle}>
                          Drag and Drop file here or
                          <Typography
                            component="span"
                            className={classes.chooseFile}
                          >
                            Choose file
                          </Typography>
                        </Typography>
                      </Box>
                    </label>

                    <input
                      data-testid="fileInput"
                      onChange={(e) => {
                        const files = e.target.files;
                        if (!files) return;
                        Array.from(files).forEach((file) => {
                          (file as any).id = uuidv4();
                        });
                        let data = { ...values.data };
                        Array.from(files).forEach((file: any) => {
                          data = { ...data, [file.id]: 0 };
                        });
                        this.onChangeFile(files);
                        setFieldValue("data", data);
                      }}
                      className={classes.input}
                      type="file"
                      id="file-input"
                      accept=".pdf, .docx"
                      multiple
                    />

                    <Box className={classes.fileNoted}>
                      <Typography component="span" className={classes.noted}>
                        Supported formats: PDF, DOCX
                      </Typography>
                      <Typography component="span" className={classes.noted}>
                        Maximum file size: 25 MB
                      </Typography>
                    </Box>
                  </Box>

                  <Form className={classes.fileWrapper} translate={undefined}>
                    <Box className={classes.fileScrollview}>
                      {this.state.webFiles.map((file) => {
                        return (
                          <Box
                            className={classes.itemWrapper}
                            key={file.id}
                            data-test-id={"file-" + file.id}
                          >
                            <InsertDriveFile
                              className={classes.fileIcon}
                              style={{
                                color: this.getColor(file.name),
                              }}
                            />
                            <Box className={classes.fileContent}>
                              <Box className={classes.fileInfo}>
                                <Typography
                                  className={clsx(
                                    classes.font16,
                                    classes.fontWeight600,
                                    classes.fileName
                                  )}
                                >
                                  {file.name}
                                </Typography>

                                <Typography className={classes.font12}>
                                  {this.getFileType(file.type) +
                                    " • " +
                                    this.convertByteToReadable(file.size)}
                                </Typography>
                              </Box>

                              <Box className={classes.fileSelectInner}>
                                <Dropdown
                                  containerClassName={classes.dropdown}
                                  name={file.id}
                                  dataTestId={`select-${file.id}`}
                                  value={(values.data as any)[file.id] || 0}
                                  dataList={this.state.fileOptions}
                                  hasError={
                                    errors.data &&
                                    (touched.data as any)[file.id]
                                      ? (errors.data as any)[file.id]
                                      : false
                                  }
                                  handleChange={(e) => {
                                    const data: any = values.data;
                                    data[file.id] = Number(e.target.value);
                                    setFieldValue("data", data);
                                    setTouched(
                                      {
                                        data: {
                                          ...touched.data,
                                          [file.id]: true,
                                        },
                                      },
                                      false
                                    );
                                    if (submitCount) {
                                      validateField("data");
                                    }
                                    this.handleChangeFileInfo(
                                      file.id,
                                      Number(e.target.value)
                                    );
                                  }}
                                />

                                {errors.data &&
                                (touched.data as any)[file.id] ? (
                                  <Typography
                                    data-test-id={`error-${file.id}`}
                                    component="span"
                                    className={classes.errorMessage}
                                  >
                                    {(errors.data as any)[file.id]}
                                  </Typography>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            </Box>

                            <IconButton
                              className={classes.deleteBtn}
                              data-test-id={"delete-file-" + file.id}
                              onClick={() => {
                                const data: any = values.data;
                                delete data[file.id];
                                setFieldValue("data", data);
                                this.removeFileWeb(file.id);
                              }}
                            >
                              <DeleteOutline
                                className={classes.deleteBtnIcon}
                              />
                            </IconButton>
                          </Box>
                        );
                      })}
                    </Box>

                    <Box className={classes.action}>
                      <button
                        className={classes.cancelBtn}
                        onClick={this.skipNow}
                        data-test-id="cancel-btn"
                      >
                        Do later
                      </button>
                      <button
                        type="submit"
                        data-test-id="submit-btn"
                        className={classes.submitBtn}
                      >
                        Save & continue
                      </button>
                    </Box>
                  </Form>
                </Box>
              )}
            </Formik>
          </Box>

          <button className={classes.backBtn} onClick={this.onBack}>
            <ArrowBackIos className={classes.backBtnIcon} />
          </button>
        </Box>

        <BulkUploadingCompanyInfoDialog
          open={this.state.infoDialog}
          onSubmit={this.toggleInfoDialog}
          onClose={this.toggleInfoDialog}
        />

        <Toast
          data-test-id="toast"
          errors={this.state.error}
          message={this.state.message}
          onClose={this.closeToast}
        />
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(BulkUploadingCompanyInfo);
