export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");

export const LogoImg = require("../assets/logo.svg");
export const pebbleLogo = require("../assets/pebbleLogo.png");
export const TransparentLogoImg = require("../assets/gtms_logo.svg");
export const leftRock = require("../assets/left-rock.svg");
export const rightRock = require("../assets/right-rock.svg");
export const backImg = require("../assets/button_back.svg");
export const infoIcon = require("../assets/info-icon.svg");
export const guidelineImg = require("../assets/guideline.svg");
export const bluePerson = require("../assets/bluePerson.svg");
export const quaterly = require("../assets/quaterly.svg");
export const bottomLogo = require("../assets/bottomLogo.png");
export const success = require("../assets/success.png");
export const rightBar = require("../assets/right_bar_icon.svg");
