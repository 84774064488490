import React from "react";

import {
    Grid,
    Button
} from "@material-ui/core";

// Customizable Area Start
import AddRoundedIcon from '@material-ui/icons/AddRounded';
interface IProps {
    onClick: () => void,
    buttonName: string
}
// Customizable Area End

export default class CustomButton extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Grid container>
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        style={webStyle.button}
                        startIcon={<AddRoundedIcon style={webStyle.icon} />}
                        onClick={this.props.onClick}
                    >
                        <span style={webStyle.txt}>
                            {this.props.buttonName}
                        </span>
                    </Button>
                </Grid>
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    button: {
        borderRadius: '20px',
        marginTop: '1%',
        textTransform: 'capitalize' as 'capitalize',
        border: "1px solid #3A84C9"
    },
    icon: { color: "#3A84C9", fontSize: "18px" },
    txt: { color: "#3A84C9", fontSize: "14px" }

};
// Customizable Area End
