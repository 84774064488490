import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "framework/src/Message";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    title: string;
    rightMenu: boolean;
    selectAll: boolean;
    activeStep: number;
    myLibraryOutreach: any;
    myLibraryCopilot: any;
    selectedCopilotId: any;
    selectedOutreachId: any;
    editChat: boolean;
    deleteOutreachPopup: boolean;
    deleteOutreachItemPopup: boolean;
    deleteConversationPopup: boolean;
    deleteConversationItemPopup: boolean;
    shareOutreachItemPopup: boolean;
    shareConversationPopup: boolean;
    editValue: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class MyLibraryController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            title: "My Library",
            rightMenu: false,
            selectAll: false,
            activeStep: 1,
            myLibraryOutreach: [
                {
                    id: 1,
                    number: 2,
                    title: "Christmas",
                    description: "This is the description of this saving (or its preview).",
                    unique_id: "marketing",
                    tag: "custom marketing email",
                    time: "10:20",
                    date: "18.10.2023",
                    nestedData: [
                        {
                            title: "My first email",
                            description: "This is the description of this saving (or its preview). This is the description of this saving (or its preview).",
                            time: "10:20",
                            date: "18.10.2023",
                        },
                        {
                            title: "My second email",
                            description: "This is the description of this saving (or its preview). This is the description of this saving (or its preview).",
                            time: "10:20",
                            date: "18.10.2023",
                        }
                    ]
                },
                {
                    id: 2,
                    number: 1,
                    title: "Halloween",
                    description: "This is the description of this saving (or its preview).",
                    unique_id: "post_event",
                    tag: "post-event email",
                    time: "10:20",
                    date: "18.10.2023",
                    nestedData: [
                        {
                            title: "My first email",
                            description: "This is the description of this saving (or its preview). This is the description of this saving (or its preview).",
                            time: "10:20",
                            date: "18.10.2023",
                        },
                    ]
                },
                {
                    id: 3,
                    number: 1,
                    title: "New Year",
                    description: "This is the description of this saving (or its preview).",
                    unique_id: "feedback",
                    tag: "gather feedback email",
                    time: "10:20",
                    date: "18.10.2023",
                    nestedData: [
                        {
                            title: "My first email",
                            description: "This is the description of this saving (or its preview). This is the description of this saving (or its preview).",
                            time: "10:20",
                            date: "18.10.2023",
                        },
                    ]
                },
            ],
            myLibraryCopilot: [
                {
                    id: 1,
                    number: 2,
                    title: "UX design",
                    description: "",
                    time: "10:20",
                    date: "18.10.2023",
                    nestedData: [
                        {
                            title: "UX definition",
                            description: " User experience design is something that is provided by the designer to better",
                            time: "10:20",
                            date: "18.10.2023",
                        },
                        {
                            title: "Spheres of UX usage",
                            description: " User experience design is something that is provided by the designer to better",
                            time: "10:20",
                            date: "18.10.2023",
                        }
                    ],
                },
                {
                    id: 2,
                    number: 2,
                    title: "Marketing",
                    description: "",
                    time: "10:20",
                    date: "18.10.2023",
                    nestedData: [
                        {
                            title: "UX definition",
                            description: " User experience design is something that is provided by the designer to better",
                            time: "10:20",
                            date: "18.10.2023",
                        },
                        {
                            title: "Spheres of UX usage",
                            description: " User experience design is something that is provided by the designer to better",
                            time: "10:20",
                            date: "18.10.2023",
                        }
                    ],
                },
                {
                    id: 3,
                    number: 2,
                    title: "Marketing",
                    description: "",
                    time: "10:20",
                    date: "18.10.2023",
                    nestedData: [
                        {
                            title: "UX definition",
                            description: " User experience design is something that is provided by the designer to better",
                            time: "10:20",
                            date: "18.10.2023",
                        },
                        {
                            title: "Spheres of UX usage",
                            description: " User experience design is something that is provided by the designer to better",
                            time: "10:20",
                            date: "18.10.2023",
                        }
                    ],
                }
            ],
            selectedCopilotId: null,
            selectedOutreachId: null,
            editChat: false,
            deleteOutreachPopup: false,
            deleteOutreachItemPopup: false,
            deleteConversationPopup: false,
            deleteConversationItemPopup: false,
            shareOutreachItemPopup: false,
            shareConversationPopup: false,
            editValue: null,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area End

    // Customizable Area Start
    handleSelect = (activeTab: number) => {
        this.setState({ activeStep: activeTab });
    };

    handleRightMenu = () => {
        this.setState({ rightMenu: !this.state.rightMenu });
    }

    handleSelectedCopilotItem = (id: any) => {
        this.setState({ selectedCopilotId: id.selectedCopilotId });
    }

    handleSelectedOutreachItem = (id: any) => {
        this.setState({ selectedOutreachId: id.selectedOutreachId });
    }

    addDynamicClass = (unique_id: string, condition1: any, condition2: any, condition3: any) => {
        if (unique_id === "feedback") {
            return condition1;
        } else if (unique_id === "post_event") {
            return condition2;
        } else {
            return condition3;
        }
    }

    styleCondition(condition: any, value1: any, value2: any) {
        if (condition) {
            return value1;
        } else {
            return value2;
        }
    }

    handleRedirection(route: string) {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            route
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }

    editChatHistory(value: boolean, ele: any= {}) {
        if(ele.title){
        this.setState({ editValue: ele })
        } else {
            this.setState({ editValue: null })
        }
        this.setState({ editChat: value })
    }

    deleteOutreach(value: boolean, isDelete = false) {
        if (isDelete) {
            console.log("Campaign deleted successfully.")
        }
        this.setState({ deleteOutreachPopup: value })
    }

    deleteOutreachItem(value: boolean, isDelete = false) {
        if (isDelete) {
            console.log("Selected email deleted successfully.")
        }
        this.setState({ deleteOutreachItemPopup: value })
    }

    deleteConversation(value: boolean, isSubmit = false) {
        if (isSubmit) {
            console.log("Conversation deleted successfully.")
        }
        this.setState({ deleteConversationPopup: value })
    }

    deleteConversationItem(value: boolean, isDelete = false) {
        if (isDelete) {
            console.log("Item deleted successfully.")
        }
        this.setState({ deleteConversationItemPopup: value })
    }

    shareOutreachItem(value: boolean) {
        this.setState({ shareOutreachItemPopup: value })
    }

    shareConversation(value: boolean) {
        this.setState({ shareConversationPopup: value })
    }
    // Customizable Area End
}
