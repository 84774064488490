import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End

export const privacyConfigJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  privacyTerms:string;
  handleBackPrivacy:() => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken:string;
  privacyDetails:{
    privacy_policy:{
        id:number,
        title:string,
        body:string,
        updated_at:string
    },
    terms_and_condition:{
        id:number,
        updated_at:string,
        description:string,
        title:string
    }
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacyTermsConditionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  displayPrivacyID : string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.displayPrivacyID = ""
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      authToken:"",
      privacyDetails:{
        privacy_policy:{
            id:0,
            title:"",
            body:"",
            updated_at:""
        },
        terms_and_condition:{
            id:0,
            updated_at:"",
            description:"",
            title:""
        }
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const requestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        )
        if (this.displayPrivacyID === requestCallId ) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (responseJson.data) {
            this.setState({
              privacyDetails: responseJson.data
            })
          } else {
            const errorMessage = message.getData(
              getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            this.parseApiCatchErrorResponse(errorMessage);
          }
        }
        
      }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({
        authToken: await StorageProvider.get("user-token")
      })
    this.getPrivacyDetails()
  }

  getPrivacyDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": privacyConfigJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.displayPrivacyID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      privacyConfigJSON.apiEndPointGetPrivacy
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      privacyConfigJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
