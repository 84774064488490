import { Box, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import moment from "moment";
import UnSubscription from "./Unsubscription.web";
import { ISubscriptionPlan } from "./SubscriptionPlan.web";
import { ICurrentSubscriptionPlan } from "../../customisableuserprofiles/src/ProfileController";

export interface Props extends WithStyles<any> {
  currentPlan: ICurrentSubscriptionPlan | null;
  otherPlans: Array<ISubscriptionPlan>;

  onUnsubscribe(): void;
  onViewAllPayments(): void;
  onViewDetails(): void;
  isUnSubscriptionOpen: boolean;
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif",
  },
});

const styles = () =>
  createStyles({
    container: {},

    heading: {
      fontWeight: 700,
      fontSize: 30,
      lineHeight: "32px",
      letterSpacing: "-0.005em",
      textAlign: "center",
      color: "#000000",
    },

    content: {
      marginTop: 40,
    },

    title: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: "24px",
    },

    currentPlan: {},

    currentPlanInner: {
      marginTop: 16,
      borderRadius: 20,
      padding: 20,
      border: "1px solid #D93855",
      display: "flex",
      gap: 12,
      justifyContent: "space-between",
    },

    currentPlanInnerCol: {
      display: "flex",
      flexDirection: "column",
    },

    currentPlanInfo: {
      display: "flex",
      alignItems: "center",
      marginTop: 4,
    },

    currentPlanName: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px",
      color: "#D93855",
    },

    currentPlanPrice: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: "19.2px",
      color: "#000000",
    },

    slash: {
      margin: "0 8px",
      color: "#94A3B8",
    },

    currentPlanTime: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
      color: "#475569",
    },

    currentPlanDetail: {
      marginTop: 12,
    },

    currentPlanDetailItem: {
      display: "flex",
      alignItems: "center",

      "& + &": {
        marginTop: 8,
      },
    },

    currentPlanDetailIcon: {
      fontSize: 12,
      color: "#D93855",
    },

    currentPlanDetailName: {
      marginLeft: 4,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: "16.8px",
      color: "#475569",
    },

    unsubscribeBtn: {
      borderRadius: 8,
      padding: "8px 16px",
      border: "1px solid #DC2626",
      backgroundColor: "#FFFFFF",
      color: "#DC2626",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "16.8px",
      cursor: "pointer",
      marginBottom: 8,
    },

    viewAllBtn: {
      borderRadius: 8,
      padding: "8px 16px",
      border: "1px solid #94A3B8",
      backgroundColor: "#FFFFFF",
      color: "#000000",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "16.8px",
      cursor: "pointer",
    },

    otherPlan: {
      marginTop: 40,
    },

    otherPlanList: {
      marginTop: 28,
    },

    otherPlanListItem: {
      padding: "16px 0",
      borderBottom: "1px solid #94A3B8",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    otherPlanListInner: {
      display: "flex",
      flexDirection: "column",
    },

    otherPlanListItemName: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px",
      color: "#000000",
    },

    otherPlanListItemPrice: {
      marginTop: 4,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
      color: "#475569",
    },

    viewDetailBtn: {
      borderRadius: 8,
      cursor: "pointer",
      backgroundColor: "#000000",
      padding: "8px 16px",
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "16.8px",
      border: "none",
      color: "#FFFFFF",
    },
  });

export class SubscriptionPlanAndBillingDetail extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      classes,
      currentPlan,
      otherPlans,
      onUnsubscribe,
      onViewAllPayments,
      onViewDetails,
      isUnSubscriptionOpen,
    } = this.props;

    return (
      <>
        <ThemeProvider theme={theme}>
          <Box className={classes.container}>
            <Typography component="h3" className={classes.heading}>
              Plan and Billing
            </Typography>

            <Box className={classes.content}>
              <Box className={classes.currentPlan}>
                <Typography component="h4" className={classes.title}>
                  Your subscription
                </Typography>

                <Box className={classes.currentPlanInner}>
                  <Box className={classes.currentPlanInnerCol}>
                    {currentPlan ? (
                      <>
                        <Typography
                          component="span"
                          className={classes.currentPlanName}
                        >
                          {currentPlan.attributes.name}
                        </Typography>

                        <Box className={classes.currentPlanInfo}>
                          <Typography
                            component="span"
                            className={classes.currentPlanPrice}
                          >
                            {`£${Number(currentPlan.attributes.price)}/mth`}
                          </Typography>

                          <span className={classes.slash}>|</span>

                          <Typography
                            component="span"
                            className={classes.currentPlanTime}
                          >
                            New plan starts from{" "}
                            {moment(currentPlan.start_date).format(
                              "DD.MM.yyyy"
                            )}
                            .
                          </Typography>
                        </Box>

                        <Box className={classes.currentPlanDetail}>
                          {currentPlan.attributes.features.map(
                            (item, index) => (
                              <Box
                                className={classes.currentPlanDetailItem}
                                key={index}
                              >
                                <CheckIcon
                                  className={classes.currentPlanDetailIcon}
                                />

                                <Typography
                                  component="span"
                                  className={classes.currentPlanDetailName}
                                >
                                  {item.feature}
                                </Typography>
                              </Box>
                            )
                          )}
                        </Box>
                      </>
                    ) : (
                      <Typography
                        component="span"
                        className={classes.currentPlanName}
                      >
                        There is no Subscriptions
                      </Typography>
                    )}
                  </Box>

                  <Box className={classes.currentPlanInnerCol}>
                    {currentPlan && (
                      <button
                        data-test-id="unsubscribe-btn"
                        className={classes.unsubscribeBtn}
                        onClick={onUnsubscribe}
                      >
                        Unsubscribe
                      </button>
                    )}

                    <button
                      data-test-id="view-all-payments"
                      className={classes.viewAllBtn}
                      onClick={onViewAllPayments}
                    >
                      View all payments
                    </button>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.otherPlan}>
                <Typography component="h4" className={classes.title}>
                  Other options
                </Typography>

                <Box className={classes.otherPlanList}>
                  {otherPlans.map((item) => (
                    <Box className={classes.otherPlanListItem} key={item.id}>
                      <Box className={classes.otherPlanListInner}>
                        <Typography
                          className={classes.otherPlanListItemName}
                          component="span"
                        >
                          {item.attributes.name}
                        </Typography>
                        <Typography
                          className={classes.otherPlanListItemPrice}
                          component="span"
                        >
                          {`£${Number(item.attributes.price)}/mth`}
                        </Typography>
                      </Box>

                      <button
                        data-test-id={`view-detail-btn-${item.id}`}
                        className={classes.viewDetailBtn}
                        onClick={onViewDetails}
                      >
                        View details
                      </button>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
        <UnSubscription
          navigation={undefined}
          classes={classes}
          onUnsubscribe={onUnsubscribe}
          isUnSubscriptionOpen={isUnSubscriptionOpen}
        />
      </>
    );
  }
}

export default withStyles(styles)(SubscriptionPlanAndBillingDetail);
