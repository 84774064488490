import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  productArray:string[];
  inputData:{
    productOffering:string;
    productDescription:string;
    offeringLabel: string;
    descriptionLabel: string;
    offerPlaceholder: string;
    descriptionPlaceholder: string;
  }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductsAndFeatureController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      productArray:['Min 3 products & features', 'Title length max 30 characters', 'Description length max 130 characters'],
      inputData:[
        {
            productOffering:'',
            productDescription:'',
            offeringLabel:'Offering',
            descriptionLabel:'Description',
            offerPlaceholder:'The Pebbles Ai Platform',
            descriptionPlaceholder:'An AI-native platform that supercharges your productivity, propels your business growth, and ensures your long-term success.'
        },
        {
            productOffering:'',
            productDescription:'',
            offeringLabel:'Offering',
            descriptionLabel:'Description',
            offerPlaceholder:'The Pebbles Ai Platform',
            descriptionPlaceholder:'An AI-native platform that supercharges your productivity, propels your business growth, and ensures your long-term success.'
        }
      ],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    
    this.handleAddProduct();
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
    // Customizable Area End

  // web events

  // Customizable Area Start
  handleProductChange = (event: React.ChangeEvent<HTMLTextAreaElement>,index:number) => {
    const { name, value } = event.target;
    const list:any = [...this.state.inputData];
    list[index][name] = value;
    if(name === "productOffering" && value.length <= 30){
            this.setState({ inputData: list})
    }
    if(name === "productDescription" && value.length <= 130){
            this.setState({ inputData: list })
    }
    
  }
  handleAddProduct = () => {
    this.setState({ 
        inputData: 
        [...this.state.inputData, 
            {
                productOffering:"",
                productDescription:"",
                offeringLabel:"Offering",
                descriptionLabel:"Description",
                offerPlaceholder:"The Pebbles Ai Platform",
                descriptionPlaceholder:"An AI-native platform that supercharges your productivity, propels your business growth, and ensures your long-term success."
            }
        ] 
    })
  }

  // Customizable Area End
}
