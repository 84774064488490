import React from "react";
import { Box, Button, Typography, TextField, Select, MenuItem, MenuProps } from "@material-ui/core";
import { ThemeProvider, createStyles, createTheme, withStyles } from "@material-ui/core/styles";

import AboutController, { Props, configJSON } from "./AboutController";
import { Formik } from "formik";
import Toast from "../../utilities/src/Toast";
import Layout from "../../../components/src/Layout";
import { locationIcon, searchIcon } from "./assets";
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import CarouselDisplay from "../../carouseldisplay/src/CarouselDisplay";
import * as Yup from "yup";
import { urlRegex } from "../utils/schema";
import Dropdown from "../../../components/src/Dropdown.web";

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: { name: string }) => option.name,
});

export const meunuProps:Partial<MenuProps> ={
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
}

export const aboutSchema = Yup.object().shape({
  role: Yup.string().required("Please select your role."),
  industry: Yup.string().required("Please select an industry."),
  size: Yup.string().required("Please select a company size."),
  website: Yup.string().matches(urlRegex, "This link is invalid.").required("Please enter company website."),
  location: Yup.string().required("Please select a country.")
});

type Values = {
  role?: string;
  industry?: string;
  size?: string;
  website?: string;
  location?: string;
};

// initial formik values
const initialValues: Values = { role: undefined, industry: undefined, size: undefined, website: undefined, location: undefined, };

export class About extends AboutController {
  constructor(props: Props) {
    super(props);
  }

  renderAutoComplete = (setFieldValue: any, touched: any, errors: any) => {
    const { classes } = this.props;
    return (
      <>
        <Typography className={classes.label}>
          In what country is the main office situated?
        </Typography>
        <div className={classes.locationSearch}>
          <Autocomplete
            options={this.state.locationList}
            getOptionLabel={(option: any) => option.name}
            data-testid='locationInput'
            filterOptions={filterOptions}
            onChange={(e: any, newVal: any) => {
              setFieldValue('location', newVal?.name || '')
            }}
            className={classes.autocomplete}
            classes={{
              option:classes.autocompleteOption,
              listbox:classes.autocompleteListBox
            }}
            id='location'
            renderInput={(params: any) => <TextField {...params}
              onBlur={()=>{}}
              style={touched.location && errors.location ? inlineStyle.error : undefined}
              placeholder="Search location"
              className={`${classes.input}`} variant="outlined" />}
          />
          <img src={locationIcon} className={classes.locationIcon} />
          <img src={searchIcon} className={classes.searchIcon} />
          {!!touched.location && !!errors.location && (
            <Typography className={classes.validateError}>
              {errors.location}
            </Typography>
          )}
        </div>
      </>
    )
  }

  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    const { onClose } = this;

    return (
      <ThemeProvider theme={theme}>
        <Layout
          back={this.onBackCreatAccount}
          carousel={
            <CarouselDisplay id="" navigation={this.props.navigation} type="individual"
            />
          }
        >
          <Box className={classes.layoutContainer}>
            <Toast {...{ errors, onClose }} />
            <Box className={classes.viewBox}>
              <Typography className={classes.createAccount}>
                {configJSON.aboutSelfTitle}
              </Typography>
              <Typography className={classes.signingAs}>
                {configJSON.aboutSelfSubTitle}
              </Typography>
              <Formik
                data-testid="signupEmailFormik"
                initialValues={initialValues}
                validationSchema={aboutSchema}
                onSubmit={values => {
                  this.postAbout(values.role + '', values.industry + '', values.size + '', values.website + '', values.location + '');
                }}
              >
                {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, submitForm }) => (
                  <form onSubmit={handleSubmit} noValidate data-testid="formSubmit" style={{
                    marginBottom: 0
                  }}>
                    <Box className={classes.form}>
                      <Typography className={classes.label}>
                        {configJSON.roleTitle}
                      </Typography>
                      <div>
                        <Dropdown
                        name="role"
                        value={values.role}
                        handleChange={handleChange}
                        dataTestId="roleInputField"
                        dataList={this.state.roleList}    
                        hasError={touched.role && !!errors.role}
                        />
                        {!!touched.role && !!errors.role && (
                          <Typography className={classes.validateError}>
                            {errors.role}
                          </Typography>
                        )}
                      </div>
                      <Typography className={classes.label}>
                        {configJSON.industryTitle}
                      </Typography>
                      <div>
                        <Dropdown
                          name="industry"
                          value={values.industry}
                          handleChange={handleChange}
                          dataTestId="industryField"
                          hasError={touched.industry && !!errors.industry}
                          dataList={this.state.industry.map((item)=>({
                            id:item.id,
                            name:item.industry_name
                          }))}
                        />
                        
                        {!!touched.industry && !!errors.industry && (
                          <Typography className={classes.validateError}>
                            {errors.industry}
                          </Typography>
                        )}
                      </div>
                      <Typography className={classes.label}>
                        {configJSON.sizeTitle}
                      </Typography>
                      <div>

                        <Dropdown
                          name="size"
                          value={values.size}
                          handleChange={handleChange}
                          dataTestId="sizeInput"
                          hasError={touched.size && !!errors.size}
                          dataList={this.state.companySize.map((item)=>({
                            id:item.id,
                            name:item.size
                          }))}
                        />
                        {!!touched.size && !!errors.size && (
                          <Typography className={classes.validateError}>
                            {errors.size}
                          </Typography>
                        )}
                      </div>
                      <Typography className={classes.label}>
                        {configJSON.webTitle}
                      </Typography>
                      <div>
                        <TextField
                          fullWidth
                          value={values.website}
                          onChange={handleChange}
                          name="website"
                          type="text"
                          variant="outlined"
                          placeholder="Insert link here"
                          className={classes.input}
                          data-testid="webInput"
                          style={touched.website && !!errors.website ? inlineStyle.error : undefined}
                        />
                        {!!touched.website && !!errors.website && (
                          <Typography className={classes.validateError}>
                            {errors.website}
                          </Typography>
                        )}
                      </div>
                      {this.renderAutoComplete(setFieldValue, touched, errors)}
                      <Button
                        variant="contained"
                        type="button"
                        onClick={submitForm}
                        className={classes.button}
                        data-testid="formSubmitBtn"
                      >
                        Continue
                      </Button>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Layout>
      </ThemeProvider>
    );
  }
}

const inlineStyle = {
  error: {
    borderColor: "#F87171"
  }
}


 export const theme = createTheme({
  overrides: {
    MuiFormControl: {
      root: {
        '& .MuiAutocomplete-endAdornment': {
          display: 'none'
        },
        '& .MuiOutlinedInput-adornedEnd': {
          padding: ('0 40px !important' as unknown as number),
        },
      }
    },
    MuiPaper: {
      elevation8: {
        boxShadow: 'none'
      }
    },
    MuiMenu: {
      paper: {
        border: '1px solid #CBD5E1',
        borderBottomRightRadius: '8px',
        maxHeight: '220px',
        overflowY: 'auto',
        borderBottomLeftRadius: '8px',
        overflowX: 'hidden',
      },
      list: {
        paddingTop: 0,
        borderBottomRightRadius: '8px',
        paddingBottom: 0,
        borderBottomLeftRadius: '8px',
      },
    }
  }
});

const styles = () =>
  createStyles({
    autocompleteOption:{
      fontFamily: "Montserrat",
      fontSize:'14px',
      fontWeight:500,
      width:'100%',
      padding:'10px 12px',
      borderBottom: '1px solid #CBD5E1'
    },
    autocompleteListBox:{
      padding:0,
      border: '1px solid #CBD5E1'
    },
    layoutContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      '@media (min-height: 1400px)': {
        marginTop:'-70px'
      },
    },
    viewBox: {
      margin: '0 40px',
      border: "1px solid #CBD5E1",
      background:
        "linear-gradient(145deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
      padding: "20px",
      '@media (min-width: 1300px)': {
        margin: 0,
        width: "438px",
        padding: "40px"
      },
      display: "flex",
      flexDirection: "column",
      borderRadius: "28px",
    },
    createAccount: {
      textAlign: "center",
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "6px",
      '@media (min-width: 600px) and (min-height: 800px)': {
        marginBottom: "8px",
      },
      '@media (min-height: 1000px)': {
        marginBottom: "12px",
      },
      color: "#000",
      fontFamily: "Montserrat"
    },
    validateError: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#DC2626",
      marginTop: "2px"
    },
    signingAs: {
      textAlign: "center",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      fontFamily: "Montserrat",
      color:"#475569"
    },
    form: {
      display: "flex",
      flexDirection: "column",
      margin: "0"
    },
    label: {
      margin: "0",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      color: "black",
      fontFamily: "Montserrat",
      marginTop: "16px",
      '@media (min-height: 1000px)': {
        marginTop: "32px",
      },
    },
    locationSearch: {
      position: 'relative',
    },
    input: {
      height: "40px",
      marginTop: "5px",
      borderRadius: " 8px",
      border: '1px solid #CBD5E1',
      background: "#FFF",
      overflow: 'hidden',
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "transparent",
          height: "40px",
          border: 'none'
        },
        "&:hover fieldset": {
          borderColor: "transparent"
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent"
        },
        "& .MuiOutlinedInput-input": {
          height: "40px",
          padding: "10px 33px 10px 8px",
          lineHeight: "22px",
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "Montserrat",
          color: "#000",
        },

        "& .MuiInputBase-input::placeholder": {
          opacity: 1,
          color:'#64748B'
        },

        "& .MuiInputBase-input": {
          height: "auto",
        }
      }
    },
    locationIcon: {
      width: '24px',
      height: '24px',
      position: 'absolute',
      left: '12px',
      top: '13px'
    },
    searchIcon: {
      width: '24px',
      height: '24px',
      position: 'absolute',
      right: '12px',
      top: '13px'
    },
    eyeIcon: {
      paddingRight: "7px",
      color: "#94A3B8",
      cursor: "pointer"
    },
    button: {
      height: "44px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      background: "#000",
      boxShadow: " 0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
      textTransform: "none",
      fontFamily: "Montserrat",
      color: "#fff",
      fontStyle: "normal",
      marginTop: "16px",
      '@media (min-height: 1000px)': {
        marginTop: "32px",
      },
      '&:hover':{
        background: "#000",
      }
    },
  });

export default withStyles(styles)(About);