import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Modal,
  Paper,
  TextField,
  // Customizable Area End
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { close, download, atSign, word, pdf } from "./assets";
import Backdrop from "@material-ui/core/Backdrop";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// Customizable Area End

interface IProps {
  classes?: any;
  open?: any;
  setOpen?: any;
  handelSubmitButton: any;
}

const initialValues: any = {
  email: "",
  fileTypes: [],
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email.")
    .required("Email should not be blank."),
  fileTypes: Yup.array().min(1, "Select at least one file type."),
});

export class SharePopup extends React.Component<IProps, {}> {
  state = {};

  render() {
    const { classes, open, setOpen, handelSubmitButton } = this.props;

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.shareModal}
        open={open}
        onClose={() => {
          setOpen({ shareModal: false });
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.sharePaper}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handelSubmitButton(values);
            }}
          >
            {({ errors, touched, values, setFieldValue, submitForm }) => (
              <Form translate={undefined}>
                <Box>
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontFamily: "Montserrat",
                      fontSize: "24px",
                    }}
                    align="center"
                  >
                    Share file as
                  </Typography>
                  <Typography
                    align="center"
                    style={{
                      fontWeight: 600,
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      color: "#475569",
                    }}
                  >
                    How would you like to share your file(s)?
                  </Typography>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      fullWidth
                      style={{
                        color: "#000",
                        marginRight: "15px",
                        backgroundColor: "rgba(58, 132, 201, 0.18)",
                        textTransform: "none",
                        position: "relative",
                      }}
                      data-test-id="addWordSElection"
                      onClick={() =>
                        setFieldValue("fileTypes", [
                          ...values.fileTypes,
                          "word",
                        ])
                      }
                    >
                      <img
                        src={word}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginRight: "5px",
                        }}
                      />
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        }}
                      >
                        Word file
                      </Typography>
                      {values.fileTypes?.includes("word") && (
                        <Box
                         className={classes.closeBtnWarpper}
                          data-test-id="removeWordSElection"
                          onClick={(e) =>
                            {
                            e.stopPropagation()
                            setFieldValue(
                              "fileTypes",
                              values.fileTypes.filter(
                                (item: any) => item !== "word"
                              )
                            )
                            }
                          }
                        >
                          <img
                            src={close}
                            style={{
                              height: "12px",
                              width: "12px",
                            }}
                          />
                        </Box>
                      )}
                    </Button>
                    <Button
                      fullWidth
                      style={{
                        color: "#000",
                        backgroundColor: "#FEE2E2",
                        textTransform: "none",
                        position: "relative",
                      }}
                      data-test-id="addPdfSElection"
                      onClick={() =>
                        setFieldValue("fileTypes", [...values.fileTypes, "pdf"])
                      }
                    >
                      <img
                        src={pdf}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginRight: "5px",
                        }}
                      />
                      <Typography
                        style={{
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        }}
                      >
                        {" "}
                        PDF File
                      </Typography>
                      {values.fileTypes?.includes("pdf") && (
                        <Box
                         className={classes.closeBtnWarpper}
                          data-test-id="removePdfSElection"
                          onClick={(e) => {
                            e.stopPropagation()
                            setFieldValue(
                              "fileTypes",
                              values.fileTypes.filter(
                                (item: any) => item !== "pdf"
                              )
                            );
                          }}
                        >
                          <img
                            src={close}
                            style={{
                              height: "12px",
                              width: "12px",
                            }}
                          />
                        </Box>
                      )}
                    </Button>
                    <ErrorMessage
                      data-test-id="FormikErrorMessageDataTestId"
                      name="fileTypes"
                      component="div"
                      render={(message) => (
                        <div
                          style={{
                            color: "red",
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                            fontSize: "12px",
                            margin: "5px",
                          }}
                        >
                          {message}
                        </div>
                      )}
                    />
                  </Box>
                  <Box
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 700,
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                      }}
                    >
                      Target email
                    </Typography>
                    <Field
                      data-testid="emailFieldTestId"
                      variant="outlined"
                      fullWidth
                      as={TextField}
                      error={!!(errors.email && touched.email)}
                      name="email"
                      InputProps={{
                        style: { borderRadius: "8px", height: "44px" },
                      }}
                      placeholder="Enter target email"
                    />
                    <ErrorMessage
                      data-test-id="FormikErrorMessageDataTestId"
                      name="email"
                      component="div"
                      render={(message) => (
                        <div
                          style={{
                            color: "red",
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                            fontSize: "12px",
                            margin: "5px",
                          }}
                        >
                          {message}
                        </div>
                      )}
                    />
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        marginTop: "5px",
                      }}
                    >
                      Enter email address you would like to send the info to.
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      fullWidth
                      style={{
                        border: "1px solid #CBD5E1",
                        color: "#000",
                        fontWeight: 700,
                        marginRight: "15px",
                        textTransform: "none",
                      }}
                      type="submit"
                    >
                      <img
                        src={download}
                        style={{
                          height: "24px",
                          width: "24px",
                          marginRight: "5px",
                        }}
                      />{" "}
                      Download
                    </Button>
                    <Button
                      fullWidth
                      style={{
                        border: "1px solid #CBD5E1",
                        color: "#000",
                        marginRight: "15px",
                        fontWeight: 700,
                        textTransform: "none",
                      }}
                      type="submit"
                    >
                      <img
                        src={atSign}
                        style={{
                          height: "20px",
                          width: "20px",
                          marginRight: "5px",
                        }}
                      />{" "}
                      Send to email
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Paper>
      </Modal>
    );
  }
}

const styles = createStyles({
  imgStyle: {
    height: "24px",
    width: "24px",
    padding: "5px",
  },
  selectStyles: {
    height: "40px",
    borderRadius: "8px",
    overflow: "hidden",
    color: "#64748B",
  },
  buttonWithIconStyle: {
    height: "32px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    marginRight: "5px",
  },
  fontStyle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  sharePaper: {
    backgroundColor: "white",
    padding: "40px",
    outline: "none",
    borderRadius: "20px",
    width: "670px",
  },
  shareModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeBtnWarpper:{
    marginRight: "5px",
    position: "absolute",
    right: "20px",
  }
});

export default withStyles(styles)(SharePopup);
