import { createStyles } from "@material-ui/core";

export const styles = () =>
  createStyles({
    forgotContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#3A84C90D",
    },
    logoImg: {
      position: "absolute",
      left: 0,
      top: 0,
      mixBlendMode: "multiply"
    },
    forgotBox: {
      width: "35%"
    },
    recoveryHeader: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      marginBottom: "35px"
    },
    forgotText: {
      color: "#0F172A",
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "26px",
      marginBottom: "5px"
    },
    noWorryText: {
      color: "#0F172A",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      marginBottom: "30px"
    },
    emailText: {
      color: "#334155",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      marginBottom: "3px"
    },
    input: {
      height: "40px",
      marginTop: "5px",
      borderRadius: " 8px",
      border: "1px solid rgba(140, 140, 140, 0.20)",
      background: "#FFF",
      marginBottom: "24px",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "transparent",
          height: "40px"
        },
        "&:hover fieldset": {
          borderColor: "transparent"
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent"
        },
        "& .MuiOutlinedInput-input": {
          padding: "10px 33px 10px 8px",
          lineHeight: "22px",
          color: "#64748B",
          fontSize: "12px",
          fontWeight: 400,
          fontFamily:"Montserrat",
        },

        "& .MuiInputBase-input::placeholder":{
          opacity:1
        },

        "& .MuiInputBase-input":{
          height:"auto"
        },
      },
      "& .MuiOutlinedInput-adornedEnd":{
        paddingRight:0
      }
    },
    eyeIcon:{
      paddingRight:"7px",
      color:"#94A3B8",
      cursor:"pointer"
    },
    forgotBtn: {
      borderRadius: "8px",
      background: "linear-gradient(90deg, #C8B153 0%, #A58A3C 100%)",
      boxShadow: "0px 15px 15px -3px rgba(0, 0, 0, 0.15)",
      color: "#FFF",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      marginBottom: "20px",
      marginTop: "18px",
      textTransform:"none",
      height:"45px",
    },
    backText: {
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px"
    },
    logInText: {
      color: "#3A84C9",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      textDecorationLine: "underline",
      cursor: "pointer"
    },
    errorText: {
      color: "rgb(179, 23, 23)",
      fontSize: "13px",
      marginTop: "2px"
    },
    sentText: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      marginBottom: "35px"
    },
    ruleBox: {
      display: "flex",
      alignItems: "center"
    },
    pwdText: {
      color: "#334155",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
    },
    infoText: {
      color: "#0F172A",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      marginBottom: "32px"
    },
    checkIcon: {
      height: "12px",
      width: "12px"
    },
    pwdInfo: {
      color: "#0F172A",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      marginBottom: "8px",
      marginTop: "24px",
    },
    ruleText: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "18px",
      marginLeft: "5px"
    },
    resetBtn: {
      borderRadius: "8px",
      height:"40px",
      background: "linear-gradient(90deg, #C8B153 0%, #A58A3C 100%)",
      boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
      color: "#FFF",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "24px",
      marginBottom: "22px",
      textTransform:"none",
    },
  });