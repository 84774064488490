import React from "react";
import ForgotPwdController from "./ForgotPwdController";
import {
  Box,
  Button,
  TextField,
  withStyles,
  InputAdornment,
  IconButton,
  Typography
} from "@material-ui/core";
import { checkOff, checkOn, gtmsLogo } from "./assets";
import { Formik } from "formik";
import { VisibilityOff, Visibility } from "@material-ui/icons";
import {ResetPwdSchema} from '../utils/schema'
import Toast from '../../utilities/src/Toast'
import {styles} from '../utils/styles'

type Values = {
  password: string;
  confirmPassword: string;
};

const initialValues: Values = { password: "", confirmPassword: "" };

const numericRegex = /\d/;

export class ResetPwd extends ForgotPwdController {
  render() {
    const { classes } = this.props;
    const { errors,isLoad } = this.state;
    const { onClose } = this;

    return (
      <div className={classes.forgotContainer} style={{overflow:"auto"}}>
        <Toast data-testid={"toastComponent"} {...{errors,onClose,isLoad}} />
        <img src={gtmsLogo} className={classes.logoImg} />
        <div className={classes.forgotBox}>
          <Typography className={classes.recoveryHeader} style={{marginTop: "140px"}}>
            New password
          </Typography>
          <Typography className={classes.infoText}>
            Enter a new password for {this.state.email}
          </Typography>
          <Formik
            initialValues={initialValues}
            validationSchema={ResetPwdSchema}
            onSubmit={values => {
              this.resetPasswordApi(values.password, values.confirmPassword);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit} style={{ marginBottom: 0 }}>
                <Box>
                  <Typography
                    variant="h4"
                    component="h2"
                    className={classes.pwdText}
                  >
                    New Password
                  </Typography>
                  <TextField
                    data-test-id="txtInputPassword"
                    name="password"
                    type={this.state.enablePasswordField ? "password" : "text"}
                    placeholder={"Your new password"}
                    fullWidth={true}
                    variant="outlined"
                    className={classes.input}
                    value={values.password}
                    onChange={e => {
                      handleChange(e);
                    }}
                    InputProps={{
                      endAdornment:(
                        <InputAdornment 
                            position="end" 
                            aria-label="toggle password visibility"
                            onClick={() => this.handleClickShowPassword(1)}
                            className={classes.eyeIcon}
                          >
                            {this.state.enablePasswordField ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                        </InputAdornment>
                      )
                    }}
                    inputProps={{
                      "data-testid": "txtInputPassword",
                      style: { 
                        fontFamily:"Montserrat",
                        color:touched.password && errors.password ? '#D93855':""
                    },
                    }}
                    style={{
                      marginBottom: "0",
                      borderColor:touched.password && errors.password ? '#D93855':""
                    }}
                  />
                </Box>
                <Box sx={{ paddingTop: "16px" }}>
                  <Typography
                    variant="h4"
                    component="h2"
                    className={classes.pwdText}
                  >
                    Confirm New Password
                  </Typography>
                  <TextField
                    data-test-id="txtInputConfirmPassword"
                    name="confirmPassword"
                    type={this.state.enablePasswordField1 ? "password" : "text"}
                    placeholder={"Confirm your new password"}
                    fullWidth={true}
                    variant="outlined"
                    className={classes.input}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment:(
                        <InputAdornment
                            position="end"
                            aria-label="toggle confirm password visibility"
                            onClick={() => this.handleClickShowPassword(2)}
                            className={classes.eyeIcon}
                          >
                            {this.state.enablePasswordField1 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                        </InputAdornment>
                      )
                    }}
                    inputProps={{
                      "data-testid": "txtInputConfirmPassword",
                      style: { 
                        fontFamily:"Montserrat",
                        color:touched.confirmPassword && errors.confirmPassword ? '#D93855':""
                    },
                    }}
                    style={{
                      marginBottom: "0",
                      borderColor:touched.confirmPassword && errors.confirmPassword ? '#D93855':""
                    }}
                  />
                </Box>
                <Typography className={classes.pwdInfo}>
                  Your password must contain
                </Typography>
                <div className={classes.ruleBox}>
                  {(values.password !== values.password.toLowerCase()) ? (
                    <img src={checkOn} className={classes.checkIcon} />
                  ) : (
                    <img src={checkOff} className={classes.checkIcon} />
                  )}
                  <Typography className={classes.ruleText}>
                    At least one capital letter
                  </Typography>
                </div>
                <div className={classes.ruleBox}>
                  {(values.password !== values.password.toUpperCase()) ? (
                    <img src={checkOn} className={classes.checkIcon} />
                  ) : (
                    <img src={checkOff} className={classes.checkIcon} />
                  )}
                  <Typography className={classes.ruleText}>
                    At least one lowercase letter
                  </Typography>
                </div>
                <div className={classes.ruleBox}>
                  {numericRegex.test(values.password) ? (
                    <img src={checkOn} className={classes.checkIcon} />
                  ) : (
                    <img src={checkOff} className={classes.checkIcon} />
                  )}
                  <Typography className={classes.ruleText}>
                    At least one number
                  </Typography>
                </div>
                  {
                  touched && errors &&
                    <Toast
                      errors={(touched.password && errors.password)||(touched.confirmPassword&&errors.confirmPassword)}
                      {...{onClose}}
                    />
                  }
                <div
                  className={classes.ruleBox}
                  style={{ marginBottom: "22px" }}
                >
                  {(values.password.length >= 8) ? (
                    <img src={checkOn} className={classes.checkIcon} />
                  ) : (
                    <img src={checkOff} className={classes.checkIcon} />
                  )}
                  <Typography className={classes.ruleText}>
                    Minimum character length is 8 characters
                  </Typography>
                </div>
                <Button
                  type="submit"
                  data-test-id="btnSetPassword"
                  data-testid="btnSetPassword"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.resetBtn}
                >
                  Set New Password
                </Button>
              </form>
            )}
          </Formik>
          <Typography className={classes.backText} style={{marginBottom:"20px"}}>
            Back to{" "}
            <span
              className={classes.logInText}
              onClick={() => this.goToLogin()}
            >
              Log in
            </span>
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ResetPwd);
