import React from "react";
import { Grid, Paper } from "@material-ui/core";
import {
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles
} from "@material-ui/core/styles";

import LandingList from "./LandingList.web";
import {
  bookBlue,
  bookGreen,
  bookRed,
  bookYellow,
  flowBlue,
  flowGreen,
  flowRed,
  flowYellow
} from "./assets";

export interface Props extends WithStyles<any> {
  onClick: () => void;
  onClick1: () => void;
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif"
  }
});

const styles = () =>
  createStyles({
    container: {
      padding: 20,
      borderRadius: 28,
      backgroundColor: "#F1F5F9"
    }
  });

const myLibraries = [
  {
    title: "Marketing emails",
    description: "Lorem ipsum explanation and details",
    icon: bookGreen
  },

  {
    title: "Sales emails",
    description: "Lorem ipsum explanation and details",
    icon: bookRed
  },

  {
    title: "Product emails",
    description: "Lorem ipsum explanation and details",
    icon: bookBlue
  },

  {
    title: "Strategy",
    description: "Lorem ipsum explanation and details",
    icon: bookYellow
  }
];

const myWorkFlows = [
  {
    title: "Marketing email workflow",
    description: "Lorem ipsum explanation and details",
    icon: flowGreen
  },

  {
    title: "Sales email workflow",
    description: "Lorem ipsum explanation and details",
    icon: flowRed
  },

  {
    title: "Product email workflow",
    description: "Lorem ipsum explanation and details",
    icon: flowBlue
  },

  {
    title: "Strategy workflow",
    description: "Lorem ipsum explanation and details",
    icon: flowYellow
  }
];

export class LandingLibraryAndWorkflow extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Paper className={classes.container} elevation={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <LandingList
                title="MY LIBRARY"
                listData={myLibraries}
                onClick={this.props.onClick1}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
              <LandingList
                title="MY WORKFLOWS"
                listData={myWorkFlows}
                onClick={this.props.onClick}
              />
            </Grid>
          </Grid>
        </Paper>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(LandingLibraryAndWorkflow);
