export const copyImg = require("../assets/copy.png");
export const openInNew = require("../assets/openInNew.svg");
export const delete2 = require("../assets/delete2.svg");
export const bookmark2 = require("../assets/bookmark2.svg");
export const chatBubble = require("../assets/chatBubble.svg");
export const plusIcon = require("../assets/plusIcon.svg");
export const rightBar = require("../assets/right_bar_icon.svg");
export const replyIcon = require("../assets/reply.svg");
export const copy = require("../assets/copy.svg");
export const sendIcon = require("../assets/send.svg");
export const addCircle = require("../assets/addCircle.svg");
export const fullScreenIcon = require("../assets/fullScreen.svg");
export const replay = require("../assets/replay.svg");
export const disableAdd = require("../assets/addCircleDisable.svg");
export const disableCopy = require("../assets/copyDisable.svg");
export const close = require("../assets/close.svg");
export const pdf = require("../assets/pdf.svg");
export const word = require("../assets/word.svg");
export const atSign = require("../assets/atSign.svg");
export const download = require("../assets/download.svg");
export const textStyle = require("../assets/text_style.svg");
export const replyDisableIcon = require("../assets/replyDisable.svg");
export const closeIcon = require("../assets/close.svg");
