import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rightDrawerEnable: any;
  selectFeildEnable: any;
  selectedFeilds: any;
  historyData:any;
  activeTab:any;
  historyOutreachData:any;
  selectedHistoryId:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class HistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      rightDrawerEnable: false,
      selectFeildEnable: false,
      selectedFeilds: [],
      historyData:[
          {
            "id": "737",
            "type": "param",
            "attributes": {
              "nestedData":[
                {id:56561,name:" What s UX?", description:" User experience design is something that is provided by the designer to better",created_at: "2024-01-24T07:34:46.137Z"},
                {id:56562,name:" What s UX?", description:" User experience design is something that is provided by the designer to better",created_at: "2024-01-24T07:34:46.137Z"},
                {id:56563,name:" What s UX?", description:" User experience design is something that is provided by the designer to better",created_at: "2024-01-24T07:34:46.137Z"},
                {id:56564,name:" What s UX?", description:" User experience design is something that is provided by the designer to better",created_at: "2024-01-24T07:34:46.137Z"},
                {id:56565,name:" What s UX?", description:" User experience design is something that is provided by the designer to better",created_at: "2024-01-24T07:34:46.137Z"},
                
                              ],
              "id": 737,
              "name": "History 2",
              "description": "Parameter Work flow 2 description",
              "favourited": true,
              "question": "Q2",
              "answer": "A2",
              "account": {
                "id": 1526,
                "first_name": null,
                "last_name": null,
                "full_phone_number": null,
                "country_code": null,
                "phone_number": null,
                "email": "anusharma@deqode.com",
                "activated": true,
                "device_id": null,
                "created_at": "2024-01-24T07:34:46.137Z",
                "updated_at": "2024-01-24T07:35:04.426Z",
                "user_name": null,
                "platform": null,
                "user_type": null,
                "app_language_id": null,
                "last_visit_at": null,
                "is_blacklisted": false,
                "suspend_until": null,
                "status": "regular",
                "role_id": null,
                "gender": null,
                "date_of_birth": null,
                "age": null,
                "stripe_id": null,
                "stripe_subscription_id": null,
                "stripe_subscription_date": null,
                "full_name": "Anurag",
                "company_name": "Gtmsai",
                "account_type": null,
                "role": null,
                "description": null,
                "user_path_id": null,
                "reset_password_token": null,
                "reset_password_token_sent_at": null,
                "account_path_id": null
              },
              "campaign": {
                "id": 128,
                "name": "Cam 1",
                "description": "Camp desc 1",
                "account_id": 1526,
                "favourited": false,
                "created_at": "2024-01-24T07:38:37.600Z",
                "updated_at": "2024-01-24T07:38:37.600Z",
                "status": "pending",
                "campaign_type_id": 14,
                "campaign_category_id": 2
              },
              "generated_outputs": [
                {
                  "id": 7,
                  "favourited": false,
                  "context": "TestData",
                  "prompt": "DummyPrompt",
                  "account_id": 1519,
                  "output_type": "email",
                  "param_id": 737
                }
              ],
              "associated_question": {
                "id": 20,
                "field_name": "Past Associations",
                "field_type": "radio",
                "form_creation_id": 1,
                "created_at": "2024-01-22T11:20:02.817Z",
                "updated_at": "2024-01-22T11:20:02.817Z",
                "hint": "",
                "archived": false,
                "required": false,
                "order": null,
                "info": "",
                "main_answer_id": 7,
                "is_sub": true
              },
              "associated_answer": {
                "id": 12,
                "question_id": 5,
                "answer": "German",
                "prompt_section_id": null,
                "created_at": "2023-12-22T10:45:54.124Z",
                "updated_at": "2023-12-22T10:45:54.124Z",
                "parent_id": null,
                "section": null,
                "is_sub": false,
                "hint": null
              }
            }
          }
        ],
        historyOutreachData:[
          {
            "id": "101",
            "attributes": {
              "nestedData":[
                {id:56561,name:" My first Email", description:" User experience design is something that is provided by the designer to better",created_at: "2024-01-24T07:34:46.137Z"},
                {id:56562,name:" My second Email", description:" User experience design is something that is provided by the designer to better",created_at: "2024-01-24T07:34:46.137Z"},
              ],
            name:"Christmas",
            description:"This is the description of this saving (or its preview).",
            created_at: "2023-12-22T10:45:54.124Z",
            unique_id:"marketing",
          tag:"custom marketing email"
        }
          },
          {
            "id": "102",
            "attributes": {
              "nestedData":[
                {id:56561,name:" My first Email 11", description:" User1 experience1 design is something1 that is provided by the designer to better",created_at: "2024-01-24T07:34:46.137Z"},
              ],
            name:"Christmas1",
            description:"This is the description 2.",
            created_at: "2023-10-22T10:45:54.124Z",
            unique_id:"post_event",
            tag:"post-event email"
          
          }
          },
          {
            "id": "103",
            "attributes": {
              "nestedData":[
                {id:56561,name:" My first Email 33", description:" User3 experience3 design is something3 that is provided3 by the designer3 to better",created_at: "2024-01-24T07:34:46.137Z"},
              ],
            name:"Christmas 3",
            description:"This is the description 3.",
            created_at: "2023-11-22T10:45:54.124Z",
            unique_id:"feedback",
            tag:"gather feedback email"
          }
          },

        ],
        activeTab:1,
        selectedHistoryId:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount()
  }
  handelSelectFeildBtn = () => {
    this.setState({ selectFeildEnable: true });
  };

  handelFeildCheckBoxClick = (e: any, id: any) => {
    let filterFromGivenId = this.state.historyData.map((pw:any)=>pw.id !== id)
   if(e.target.checked){
     this.setState({ selectedFeilds: [...this.state.selectedFeilds, `${id}`] });
    }else{
     this.setState({ selectedFeilds: filterFromGivenId });
    }
  };

  handelSelectAllWorkFLow = (e: any) => {
    let getAllParamFlowIds = this.state.historyData.map((pw:any)=>pw.id)
    if(e.target.checked){
      this.setState({ selectedFeilds:getAllParamFlowIds });
     }else{
      this.setState({ selectedFeilds: [] });
     }
  };
  // Customizable Area End
}
