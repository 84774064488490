import React from "react";
import { Box, Dialog, IconButton, Typography } from "@material-ui/core";
import {
    ThemeProvider,
    createStyles,
    createTheme,
    withStyles,
} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    DeleteOutline,
    InfoOutlined,
    InsertDriveFile,
    SaveAlt,
} from "@material-ui/icons";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Dropdown from "../../../components/src/Dropdown.web";
import Toast from "../../utilities/src/Toast";
import { v4 as uuidv4 } from "uuid";
// Customizable Area Start
import CompanyIntelController from "./CompanyIntelController";
import { UploadingCompanyInfoDialog } from "./UploadingCompanyInfoDialog.web";

const theme = createTheme({
    typography: {
        fontFamily: "Montserrat,sans-serif",
    },
});


const importValidationSchemas = Yup.object().shape({
    data: Yup.object().test(
        "has-non-empty-property",
        "",
        (objectDataValue: { [key: string]: number }) => {
            const errors: any = {};
            Object.entries(objectDataValue).forEach(([key, value]) => {
                if (value <= 0) {
                    errors[key] = "Please choose file type";
                }
            });
            if (Object.keys(errors).length === 0) return true;
            throw new Yup.ValidationError(errors, objectDataValue, "data");
        }
    ),
});

const styles = () =>
    createStyles({
        innerform: {
            height: '100%',
            overflowY: 'auto',
            width: '100%',
        },
        inner: {
            width: "855px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            overflow: "hidden",
        },
        formTop: {
            position: "relative",
        },
        formHeading: {
            fontWeight: 700,
            fontSize: 30,
            lineHeight: "32px",
        },
        formHeadingIcon: {
            position: "absolute",
            bottom: 0,
            right: -42,
        },
        form: {
            boxSizing: "border-box",
            padding: 40,
            borderRadius: 28,
            maxWidth: 855,
            width: "100%",
            background:
                "linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
        },
        fileSectionWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            marginTop: 32,
        },
        formDescription: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "21px",
            textAlign: "center",
            marginTop: 12,
            maxWidth: 536,
        },
        fileInput: {
            width: "100%",
        },
        uploadIcon: {
            fontSize: 24,
            color: "#3A84C9",
        },
        uploadTitle: {
            marginTop: 8,
            fontSize: 16,
            fontWeight: 500,
            lineHeight: "22px",
            display: "flex",
        },
        dropfileWrapper: {
            borderRadius: 20,
            width: "100%",
            border: "1px solid #CBD5E1",
            height: 136,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            cursor: "pointer",
        },
        chooseFile: {
            color: "#3A84C9",
            textDecorationLine: "underline",
            fontWeight: 600,
            marginLeft: 4,
        },
        fileNoted: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "0 6px",
            marginTop: 8,
        },
        inputImp: {
            display: "none",
        },
        noted: {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: "22px",
            color: "#8C8C8C",
        },
        cancelBtn: {
            borderRadius: 8,
            border: "1px solid #CBD5E1",
            fontWeight: 600,
            fontSize: 16,
            lineheight: "19.2px",
            textTransform: "capitalize",
            padding: "10px 16px",
            width: "100%",
            cursor: "pointer",
            backgroundColor: "#FFFFFF",
        },
        action: {
            marginTop: 32,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
        },
        submitBtn: {
            borderRadius: 8,
            backgroundColor: "#E2E8F0",
            marginLeft: 20,
            color: "#64748B",
            fontWeight: 600,
            fontSize: 16,
            lineheight: "19.2px",
            textTransform: "capitalize",
            padding: "10px 16px",
            width: "100%",
            cursor: "pointer",
            border: 'none'
        },
        itemWrapper: {
            height: "46px",
            display: "flex",
            padding: "8px 0 8px 35px",
            marginRight: "22px",
            borderBottom: "1px solid #CBD5E1",
            alignItems: "center",
        },
        fileWrapper: {
            marginTop: 24,
            width: "100%",
            display: "flex",
            flexDirection: "column",
        },
        fileScrollview: {
            maxHeight: "142px",
            overflowY: "auto",
            overflowX: "hidden",
            marginRight: "13px",
            "&::-webkit-scrollbar": {
                width: "6px",
                borderRadius: 8,
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "#CBD5E1",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: 8,
            },
        },
        fileContent: {
            display: "flex",
            alignItems: "center",
        },
        fileInfo: {
            width: 194,
        },
        fileIcon: {
            width: 35,
            height: 35,
            color: "#E04E4E",
            marginRight: "15px",
        },
        font16: {
            fontSize: "16px",
            lineHeight: "22px",
        },
        fontWeight600: {
            fontWeight: 600,
        },
        font11: {
            fontSize: "12px",
            lineHeight: "22px",
            fontWeight: 400,
        },

        deleteBtn: {
            marginLeft: "auto",
        },
        dropownInput: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "21px",
            color: "#22507B",
            padding: "8px 12px",
            border: "1px solid #CBD5E1",
            borderRadius: 8,
        },

        dropdown: {
            marginLeft: 12,
            marginTop: 0,
            width: 170,
            background: "#E2E8F0",
            borderRadius: 8,
            border: "none",
        },
        deleteBttonIcon: {
            fontSize: 16,
        },
        highlightIcon: {
            fontSize: 28,
            color: "#3A84C9",
            cursor: "pointer",
            zIndex: 3,
        },
        // info Classes
        title1: {
            position: "relative",
            padding: "44px 44px 0",
        },
        icon1: {
            fontSize: 48,
            color: "#3A84C9",
        },
        content1: {
            marginTop: 28,
            padding: "0 44px",
        },
        closeButton1: {
            position: "absolute",
            right: 32,
            top: 44,
        },
        description1: {
            fontWeight: 500,
            fontSize: 12,
            lineHeight: "16.8px",
        },
        list1: {
            marginTop: 20,
        },
        topContent1: {},
        listTitle1: {
            fontWeight: 700,
            fontSize: 16,
            lineHeight: "19.2px",
        },
        listItem1: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: "21px",
            color: "#0F172A",
        },
        listContent1: {
            margin: 0,
            marginTop: 8,
            paddingLeft: 12,
        },
        button: {
            padding: "10px 16px",
            borderRadius: 8,
            backgroundColor: "#000000",
            color: "#FFFFFF",
            fontWeight: 500,
            fontSize: 16,
            lineHeight: "19.2px",
            width: "100%",
        },
        action2: {
            marginTop: 40,
            padding: "0 44px 44px",
        },
        importDropdowns: {
            borderRadius: 8,
            width: 200,
            marginTop: 0,
        },

        importFileSelectInners: {
            marginLeft: 12,
            flexDirection: "column",
            display: "flex",
        },
        importFileWrappers: {
            display: "flex",
            flexDirection: "column",
            marginTop: 24,
            width: "100%",
        },
        importErrorMessages: {
            lineHeight: 1.2,
            color: "#DC2626",
            fontWeight: 500,
            fontSize: "12px",
            marginTop: "2px",
        },
    });

// Customizable Area End


export class UploadCompanyDocument extends CompanyIntelController {

    // Customizable Area Start
    // Customizable Area End    

    render() {
        const { classes, isOpen, isAboutCompany, handleOpenUploadClosePop } = this.props;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Dialog PaperProps={{
                    style: { borderRadius: '28px' }
                }} maxWidth='lg' open={isOpen} >
                    <Box className={classes.inner}>
                        <Box className={classes.innerform}>
                            <Formik
                                validateOnChange={false}
                                initialValues={{
                                    data: {},
                                }}
                                validationSchema={importValidationSchemas}
                                onSubmit={this.uploadMultipleCompanyFile}
                            >
                                {({
                                    errors,
                                    values,
                                    submitCount,
                                    touched,
                                    setTouched,
                                    validateField,
                                    setFieldValue,
                                }) => (
                                    <Box className={classes.form}>
                                        <Box className={classes.formTop}>
                                            <Typography component="h1" className={classes.formHeading}>
                                                {!isAboutCompany ? " Upload your company information" : "Upload your organisational intelligence"}
                                            </Typography>
                                            <Box className={classes.formHeadingIcon}>
                                                <InfoOutlined
                                                    data-test-id="highlight-icons"
                                                    className={classes.highlightIcon}
                                                    onClick={this.toggleInfoDialog}
                                                />

                                            </Box>
                                        </Box>

                                        <Typography component="span" className={classes.formDescription}>
                                            This will train our proprietary AI engine, familiarising it with
                                            your business context. The more you use Pebbles Ai, the more
                                            powerful it becomes in providing you with intelligence, guidance
                                            and content.
                                        </Typography>

                                        <Box className={classes.fileSectionWrapper}>
                                            <label className={classes.fileInput} htmlFor="file-input">
                                                <Box
                                                    onDragEnter={this.handleDrag}
                                                    onDragLeave={this.handleDrag}
                                                    onDragOver={this.handleDrag}
                                                    onDrop={this.handleDrop}
                                                    data-testid="dragArea"
                                                    className={classes.dropfileWrapper}
                                                >
                                                    <SaveAlt className={classes.uploadIcon} />
                                                    <Typography className={classes.uploadTitle}>
                                                        Drag and Drop file here or
                                                        <Typography
                                                            component="span"
                                                            className={classes.chooseFile}
                                                        >
                                                            Choose file
                                                        </Typography>
                                                    </Typography>
                                                </Box>
                                            </label>

                                            <input                                                
                                                onChange={(e) => {
                                                    const filesData = e.target.files;
                                                    if (!filesData) return;
                                                    Array.from(filesData).forEach((file) => {
                                                        (file as any).id = uuidv4();
                                                    });
                                                    let newDataValue = { ...values.data };
                                                    Array.from(filesData).forEach((file: any) => {
                                                        newDataValue = { ...newDataValue, [file.id]: 0 };
                                                    });
                                                    this.onChangeUploadFile(filesData);
                                                    setFieldValue("data", newDataValue);
                                                }}
                                                data-testid="fileInput"
                                                className={classes.inputImp}
                                                type="file"
                                                id="file-input"
                                                accept=".pdf, .docx"
                                                multiple
                                            />

                                            <Box className={classes.fileNoted}>
                                                <Typography component="span" className={classes.noted}>
                                                    Supported formats: PDF, DOCX
                                                </Typography>
                                                <Typography component="span" className={classes.noted}>
                                                    Maximum file size: 25 MB
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Form className={classes.importFileWrappers}
                                            translate={undefined}>
                                            <Box className={classes.fileWrapper}>
                                                <Box className={classes.fileScrollview}>
                                                    {Array.from(this.state.docFiles).map((file) => (
                                                        <Box
                                                            data-test-id={"file-" + file.id}
                                                            className={classes.itemWrapper}
                                                            key={file.id}

                                                        >
                                                            <InsertDriveFile
                                                                style={{
                                                                    color: this.getColor(file.name),
                                                                }}
                                                                className={classes.fileIcon}
                                                            />
                                                            <Box className={classes.fileContent}>
                                                                <Box className={classes.fileInfo}>
                                                                    <Typography
                                                                        className={clsx(
                                                                            classes.fontWeight600,
                                                                            classes.font16,
                                                                            classes.fileName
                                                                        )}
                                                                    >
                                                                        {file.name}
                                                                    </Typography>

                                                                    <Typography className={classes.font11}>
                                                                        {this.getFileType(file.type) +
                                                                            " • " +
                                                                            this.convertByteToReadable(file.size)}
                                                                    </Typography>
                                                                </Box>
                                                                <Box className={classes.importFileSelectInners}>
                                                                    <Dropdown
                                                                        containerClassName={classes.importDropdowns}
                                                                        name={file.id}
                                                                        dataTestId={`select-${file.id}`}
                                                                        value={(values.data as any)[file.id] || 0}
                                                                        dataList={isAboutCompany ? this.state.fileTypeTarget : this.state.fileTypeCompany}
                                                                           handleChange={(e) => {
                                                                            const newDataVal: any = values.data;
                                                                            newDataVal[file.id] = Number(e.target.value);
                                                                            setFieldValue("data", newDataVal);
                                                                            setTouched(
                                                                                {
                                                                                    data: {
                                                                                        ...touched.data,
                                                                                        [file.id]: true,
                                                                                    },
                                                                                },
                                                                                false
                                                                            );
                                                                            if (submitCount) {
                                                                                validateField("data");
                                                                            }
                                                                            this.handleChangeFileInfomation(
                                                                                file.id,
                                                                                Number(e.target.value)
                                                                            );
                                                                        }}
                                                                        hasError={
                                                                            errors.data &&
                                                                                (touched.data as any)[file.id]
                                                                                ? (errors.data as any)[file.id]
                                                                                : false
                                                                        }
                                                                    />

                                                                    {errors.data &&
                                                                        (touched.data as any)[file.id] ? (
                                                                        <Typography
                                                                            data-test-id={`error-${file.id}`}
                                                                            component="span"
                                                                            className={classes.importErrorMessages}
                                                                        >
                                                                            {(errors.data as any)[file.id]}
                                                                        </Typography>
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                </Box>
                                                            </Box>

                                                            <IconButton
                                                                data-test-id={"delete-file-" + file.id}
                                                                className={classes.deleteBtn}
                                                                onClick={() => {
                                                                    const newData: any = values.data;
                                                                    delete newData[file.id];
                                                                    setFieldValue("data", newData);
                                                                    this.removeFileWeb(file.id);
                                                                }}
                                                            >
                                                                <DeleteOutline className={classes.deleteBttonIcon} />
                                                            </IconButton>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Box>

                                            <Box className={classes.action}>
                                                <button
                                                    onClick={handleOpenUploadClosePop}
                                                    className={classes.cancelBtn}
                                                    data-test-id="cancel-btn"
                                                    type="button"
                                                >
                                                    Close
                                                </button>
                                                <button
                                                    type="submit"
                                                    data-test-id="submit-btn"
                                                    className={classes.submitBtn}
                                                >
                                                    Import files
                                                </button>
                                            </Box>
                                        </Form>
                                    </Box>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                    <UploadingCompanyInfoDialog
                        open={this.state.infoDialog}
                        onSubmit={this.toggleInfoDialog}
                        onClose={this.toggleInfoDialog}
                        classes={classes}
                    />
                    <Toast
                        data-test-id="toast"
                        errors={this.state.error}
                        message={this.state.showMessage}
                        onClose={this.closeToasts}
                    />
                </Dialog>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

export default withStyles(styles)(UploadCompanyDocument);
