import React from "react";

import { Backdrop, Box, Button, Container, Dialog, Modal, Paper, ThemeProvider, Typography, createStyles, createTheme, withStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";

interface IPropsPopup {
  handleCloseDeletePop: ()=> void;
  open?: any;
  classes?: any;
  deleteCompanyDocList: ()=> void;
}

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      color: '#000',
      textAlign: 'center',
      fontSize: ' 24px',
      fontStyle: 'normal',
      fontWeight: 700,
      marginRight: '35px'
    },
  },
});

const CompanyIntelDeletePopup = ({ classes, open, handleCloseDeletePop,deleteCompanyDocList}: IPropsPopup) => {
  return <>
    <ThemeProvider theme={theme}>
      <Dialog PaperProps={{
        style: { borderRadius: '20px',width:'500px',padding:'40px' }
      }} maxWidth='lg' open={open}>
        <div data-testid="closeModalBtn" className={classes.closeBtn} onClick={handleCloseDeletePop}>
          <Close />
        </div>
        <Container maxWidth={"sm"} classes={classes.mainContainer}>
          <Typography variant="h1">Are you sure you want to</Typography>
          <Typography variant="h1"> delete “Products & Services”?</Typography>
          <Box className={classes.btnContainer}>
            <Button fullWidth className={classes.canlBtn} onClick={handleCloseDeletePop} >
              Cancel
            </Button>
            <Button fullWidth onClick={(e)=>{
              e.preventDefault()
                deleteCompanyDocList()
                handleCloseDeletePop()
            }} className={classes.delBtn}>
              Delete
            </Button>
          </Box>
        </Container>

      </Dialog>
    </ThemeProvider>
  </>
}

const styles = createStyles({
  mainContainer: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
  },
  closeBtn: {
    position: 'absolute',
    right: '40px',
    top: '40px',
    cursor: 'pointer'
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  canlBtn: {
    marginRight: "15px",
    color: "#000",
    border: "1px solid #CBD5E1",
    textTransform: 'none' as 'none',
    fontSize:'16px',
    fontWeight:600
  },
  delBtn: {
    color: "#DC2626",
    border: "1px solid #DC2626",
    textTransform: 'none' as 'none',
    fontSize:'16px',
    fontWeight:600
  },

});

export default withStyles(styles)(CompanyIntelDeletePopup);