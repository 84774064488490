import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider";
export const baseURL = require("../../../framework/src/config.js").baseURL;

// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface WebFile extends File {
  id: string;
  information_type: number | string;
}

interface S {
  // Customizable Area Start
  webFiles: WebFile[];
  message: string;
  error: string;
  infoDialog: boolean;
  highlightInfo: boolean;
  fileOptions: Array<{
    name: string;
    id: number;
  }>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class BulkUploadingCompanyInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  uploadMutipleFileMessageId: string = "";
  importMutipleFileMessageId: string = "";
  getCompanyTypesMessageId: string = "";
  profileMessageId: string = "";
  maxFileSize = 26214400;
  token: string = "";
  accountId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webFiles: [],
      message: "",
      error: "",
      infoDialog: false,
      highlightInfo: true,
      fileOptions: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.uploadMutipleFileMessageId:
        this.handleFinishUploading(responseJson);

        break;

      case this.importMutipleFileMessageId:
        this.handleFinishImport(responseJson);

        break;

      case this.getCompanyTypesMessageId:
        this.handleGetCompanyTypes(responseJson);

        break;

      case this.profileMessageId:
        this.handleGetProfileData(responseJson);

        break;

      default:
        break;
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.token = await StorageProvider.get("user-token");

    if (!this.token) {
      const navigationMessage = new Message(
        getName(MessageEnum.NavigationMessage)
      );

      navigationMessage.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "WelcomePage"
      );

      navigationMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );

      this.send(navigationMessage);

      return;
    }

    this.getProfileData();
    this.fetchCompanyTypes();
  }

  getProfileData = () => {
    const endPointUrl = configJSON.userProfileApi;

    const headers = {
      "Content-Type": configJSON.jsonApiContentType,
      token: this.token,
    };

    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.profileMessageId = message.messageId;

    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPointUrl
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.profileGetMethod
    );

    runEngine.sendMessage(message.id, message);
  };

  fetchCompanyTypes = () => {
    const header = {
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCompanyTypesMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCompanyTypesApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.profileGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  onBack = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BeforeWeStart"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message);
  };

  handleError() {
    this.setState({
      error: "An error occurred, please try again",
    });

    setTimeout(() => {
      this.setState({
        error: "",
      });
    }, 2000);
  }

  handleGetProfileData(responseJson: any) {
    if (responseJson.errors) {
      this.handleError();

      return;
    }

    this.accountId = responseJson.data.attributes.account.id;
  }

  handleFinishUploading = async (responseJson: any) => {
    await StorageProvider.set("filledFormData", JSON.stringify({}));
    if (responseJson.errors) {
      this.handleError();

      return;
    }

    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AboutTheCompany"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    await StorageProvider.set("isEdit", "");

    this.send(message);
  };

  handleFinishImport = async (responseJson: any) => {
    await StorageProvider.set("filledFormData", JSON.stringify({}));
    if (responseJson.errors) {
      this.handleError();

      return;
    }

    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message);
  };

  handleGetCompanyTypes = (responseJson: any) => {
    if (!responseJson) {
      return;
    }

    const data = responseJson.map((type: any) => ({
      id: type.id,
      name: type.description,
    }));

    data.unshift({
      id: 0,
      name: "Choose file type",
    });

    this.setState({
      fileOptions: data,
    });
  };

  closeToast = () => {
    this.setState({
      message: "",
    });
  };

  skipNow = async () => {
    await StorageProvider.set("filledFormData", JSON.stringify({}));
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    await StorageProvider.set("isEdit", "");
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AboutTheCompany"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message);
  };

  uploadMultipleFile = () => {
    const files = this.state.webFiles;

    if (!files.length) {
      this.setState({
        error: "Please attach file.",
      });

      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);

      return;
    }

    const header = {
      token: this.token,
      Accept: "*/*",
    };

    const formData = new FormData();

    files.forEach((file) => {
      formData.append("files[]", file);
      formData.append("information_types[]", file.information_type.toString());
    });

    formData.append("id", this.accountId);

    const createBulkUploadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.uploadMutipleFileMessageId = createBulkUploadMsg.messageId;

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadMultipleFileApi
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    createBulkUploadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    runEngine.sendMessage(createBulkUploadMsg.id, createBulkUploadMsg);
  };

  importMultipleFile = () => {
    const importFiles = this.state.webFiles;

    if (!importFiles.length) {
      this.setState({
        error: "Please attach file.",
      });

      setTimeout(() => {
        this.setState({
          error: "",
        });
      }, 2000);

      return;
    }

    const header = {
      token: this.token,
      Accept: "*/*",
    };

    const formData = new FormData();

    importFiles.forEach((importFile) => {
      formData.append("files[]", importFile);
      formData.append(
        "information_types[]",
        importFile.information_type.toString()
      );
    });

    formData.append("id", this.accountId);

    const importMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.importMutipleFileMessageId = importMessage.messageId;

    importMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadMultipleFileApi
    );

    importMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    importMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    importMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createBulkUploadMethod
    );

    runEngine.sendMessage(importMessage.id, importMessage);
  };

  removeFileWeb = (fileId: string) => {
    this.setState({
      webFiles: this.state.webFiles.filter((_file) => _file.id !== fileId),
    });
  };

  onChangeFile = (files: FileList | null) => {
    let tempFile: WebFile[] = [];

    let list = Array.from(files as unknown as File[]);

    let hasError = false;

    const allowedFileTypes = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    list.forEach((_file) => {
      if (_file.size > this.maxFileSize) {
        this.setState({
          error: _file.name + configJSON.fileSizeErrorMsg,
        });

        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);

        hasError = true;
      } else if (!allowedFileTypes.includes(_file.type)) {
        // Check if the file type is allowed
        this.setState({
          error: "Invalid file type. Please upload only .pdf and .docx files.",
        });

        // Clear the error message after 2 seconds
        setTimeout(() => {
          this.setState({
            error: "",
          });
        }, 2000);

        // Set hasError to true
        hasError = true;
      } else {
        let willAddfile: any = _file;

        willAddfile.information_type = 0;
        tempFile.push(willAddfile as unknown as WebFile);
      }
    });

    if (hasError) {
      return;
    }

    const newList = [...this.state.webFiles, ...tempFile];

    if (tempFile.length > 0) {
      this.setState({
        webFiles: newList,
      });
    }
  };

  getFileType = (type: string) => {
    const splitType = type.split("/");
    if (splitType.length < 2) {
      return "UNKNOWN";
    }

    if (splitType[1].length > 10) {
      return splitType[1].substring(0, 10).toLocaleUpperCase() + "...";
    }

    return splitType[1].toLocaleUpperCase();
  };

  convertByteToReadable = (bytes: number) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (bytes >= kilobyte && bytes < megabyte) {
      return Math.round(bytes / kilobyte) + " KB";
    } else if (bytes >= megabyte) {
      return Math.round(bytes / megabyte) + " MB";
    } else {
      return bytes + " Bytes";
    }
  };

  handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // triggers when file is dropped
  handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      this.onChangeFile(e.dataTransfer.files);
    }
  };

  getColor = (name: string) => {
    if (name.includes(".pdf")) {
      return "#E04E4E";
    }

    if (name.includes(".docx")) {
      return "#3166ED";
    }

    return "#00B84A";
  };

  toggleInfoDialog = () => {
    this.setState({
      infoDialog: !this.state.infoDialog,
    });
  };

  toggleHighlightInfo = () => {
    this.setState({
      highlightInfo: !this.state.highlightInfo,
    });
  };

  onCancel = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message);
  };

  handleChangeFileInfo = (fileId: string, value: number) => {
    const index = this.state.webFiles.findIndex((file) => file.id === fileId);

    if (index < 0) {
      return;
    }

    const newWebFiles = [...this.state.webFiles];

    newWebFiles[index].information_type = value;

    this.setState({
      webFiles: newWebFiles,
    });
  };

  // Customizable Area End
}
