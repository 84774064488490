Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfdataintegrationviapromptengineering4";
exports.labelBodyText = "cfdataintegrationviapromptengineering4 Body";
exports.btnExampleTitle = "CLICK ME";

exports.formCreationEndpoint = '/bx_block_automaticformcreation/form_creations';
exports.createCampainEndpoint = 'bx_block_cfdataintegrationviapromptengineering4/params';
exports.campaignSubmissionMethod = 'POST';
exports.campaignSubmissionEndpoint = 'bx_block_catalogue/campaigns';

exports.formSubmissionEndpoint = '/bx_block_cfdataintegrationviapromptengineering4/params';
exports.formSubmissionMethod = 'POST';
exports.apiEndPointCreateMail = "bx_builder_chain/generate_prompt_for_email"
exports.fetchCompanyInfoEndpoint = '/bx_builder_chain/fetch_company_info';
exports.httpPostMethod = "POST";
// Customizable Area End