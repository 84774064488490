// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
// import TopNav from "../../components/src/TopNav";s

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfdataintegrationviapromptengineering4 from "../../blocks/cfdataintegrationviapromptengineering4/src/Cfdataintegrationviapromptengineering4";
import Chatgpt3 from "../../blocks/chatgpt3/src/Chatgpt3";
import { NavigationMenuComponent } from "../../blocks/navigationmenu/src/NavigationMenu.web";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Cfauditinganalytics3 from "../../blocks/cfauditinganalytics3/src/Cfauditinganalytics3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Documentstorage from "../../blocks/documentstorage/src/Documentstorage";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Chatbot4 from "../../blocks/chatbot4/src/Chatbot4";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Settings2 from "../../blocks/settings2/src/Settings2";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailLoginBlock from "../../blocks/email-account-login/src/EmailLoginBlock.web";
import WelcomePage from "../../blocks/email-account-login/src/WelcomePage.web";
import ChooseyourOptions from "../../blocks/email-account-login/src/ChooseyourOptions.web";
import Cffinetuningimplementationofchatgpt3 from "../../blocks/cffinetuningimplementationofchatgpt3/src/Cffinetuningimplementationofchatgpt3";
import ForgotPwd from "../../blocks/forgot-password/src/ForgotPwd";
import Success from "../../blocks/forgot-password/src/Success";
import ResetSuccess from "../../blocks/forgot-password/src/ResetSuccess";
import ResetPwd from "../../blocks/forgot-password/src/ResetPwd";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import Customform4 from "../../blocks/customform4/src/Customform4";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploadingCompanyInfo.web";
import BulkUploadingImport from "../../blocks/bulkuploading/src/BulkUploadingCompanyImport.web"
import Cfchatgpttemplates from "../../blocks/cfchatgpttemplates/src/Cfchatgpttemplates";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import SignUpEmail from "../../blocks/email-account-registration/src/SignUpEmail.web";
import About from "../../blocks/email-account-registration/src/About.web";
import CompanyAbout from "../../blocks/email-account-registration/src/CompanyAbout.web";
import TellBitMoreBusiness from "../../blocks/email-account-registration/src/TellBitMoreBusiness.web";
import Subscription from "../../blocks/customisableusersubscriptions/src/Subscription.web";
import BeforeWeStart from "../../blocks/customisableusersubscriptions/src/BeforeWeStart.web";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import SignUp from "../../blocks/email-account-registration/src/SignUp.web";
import LetsGetStarted from "../../blocks/email-account-registration/src/LetsGetStarted.web";
import AccountDetail from "../../blocks/email-account-registration/src/AccountDetail.web";
import CompanyDetail from "../../blocks/email-account-registration/src/CompanyDetail.web";
import DescribleCompanyPlan from "../../blocks/email-account-registration/src/DescribleCompanyPlan.web";
import Profile from "../../blocks/customisableuserprofiles/src/Profile.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import PasswordRecovery from "../../blocks/forgot-password/src/PasswordRecovery.web";
import SignUpProfessional from "../../blocks/email-account-registration/src/signUpProfessional.web";
import VerifySignUpCode from "../../blocks/email-account-registration/src/VerifySignUpCode.web";
import PasswordRecoverySuccess from "../../blocks/forgot-password/src/PasswordRecoverySuccess.web";
import CompanyProgressHeader from "../../components/src/CompanyProgressHeader.web";
import CompanySuccess from "../../blocks/email-account-registration/src/CompanySuccess.web";
import ChooseYourPath from "../../blocks/choose-your-path/src/ChooseYourPath.web"
import Services from "../../blocks/email-account-registration/src/Services.web";
import PrimaryQuestion from "../../blocks/email-account-registration/src/PrimaryQuestion.web";
import MissionStatement from "../../blocks/email-account-registration/src/MissionStatement.web";
import UniqueSelling from "../../blocks/email-account-registration/src/UniqueSelling.web";
import ProblemStatement from "../../blocks/email-account-registration/src/ProblemStatement.web";
import ProductAndFeature from "../../blocks/email-account-registration/src/ProductsAndFeature.web"
import AboutTheCompany from "../../blocks/email-account-registration/src/AboutTheCompany.web"
import OfferTheCompany from "../../blocks/email-account-registration/src/OfferTheCompany.web"
import AboutYourApproach from "../../blocks/email-account-registration/src/AboutYourApproach.web"
import PaymentStatus from "../../blocks/info-page/src/PaymentStatus.web"
import ChatHistory from '../../blocks/chatbot4/src/ChatHistory.web';
import CompanyIntel from '../../blocks/documentation/src/ComapnyIntel.web';
import Myworkflow from '../../blocks/chatbot4/src/Myworkflow.web';
import SubscriptionExploreUpgrade from '../../blocks/customisableusersubscriptions/src/SubscriptionExploreUpgrade.web'
import EmailCreation from '../../blocks/multipageforms/src/EmailCreation.web';
import History from '../../blocks/multipageforms/src/History.web'
import MyLibrary from '../../blocks/cfdataintegrationviapromptengineering4/src/MyLibrary.web';
import ShareAndEarn from "../../blocks/referrals/src/ShareAndEarn.web"

const routeMap = {
  Cfdataintegrationviapromptengineering4: {
    component: Cfdataintegrationviapromptengineering4,
    path: "/Cfdataintegrationviapromptengineering4"
  },
  Chatgpt3: {
    component: Chatgpt3,
    path: "/Chatgpt3"
  },
  NavigationMenuComponent: {
    component: NavigationMenuComponent,
    path: "/NavigationMenu"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  Cfauditinganalytics3: {
    component: Cfauditinganalytics3,
    path: "/Cfauditinganalytics3"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Documentstorage: {
    component: Documentstorage,
    path: "/Documentstorage"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  Chatbot4: {
    component: Chatbot4,
    path: "/Chatbot4"
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/tactical"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailLoginBlock,
    path: "/login"
  },
  Cffinetuningimplementationofchatgpt3: {
    component: Cffinetuningimplementationofchatgpt3,
    path: "/Cffinetuningimplementationofchatgpt3"
  },
  ForgotPassword0: {
    component: ForgotPwd,
    path: "/forgotpassword0"
  },
  Success: {
    component: Success,
    path: "/success"
  },
  ResetSuccess: {
    component: ResetSuccess,
    path: "/reset-success"
  },
  ResetPwd: {
    component: ResetPwd,
    path: "/resetpassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Customform4: {
    component: Customform4,
    path: "/Customform4"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  Subscription: {
    component: Subscription,
    path: "/plans"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  BulkUploadingImport: {
    component: BulkUploadingImport,
    path: "/BulkUploadingImport"
  },
  Cfchatgpttemplates: {
    component: Cfchatgpttemplates,
    path: "/Cfchatgpttemplates"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  SignUpEmail: {
    component: SignUpEmail,
    path: "/signup"
  },

  LetsGetStarted: {
    component: LetsGetStarted,
    path: "/letsgetstarted"
  },
  EmailAccountSigup: {
    component: SignUp,
    path: "/verify-code"
  },
  CreateAccount: {
    component: AccountDetail,
    path: "/create-account"
  },
  About: {
    component: About,
    path: "/about"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  WelcomePage: {
    component: WelcomePage,
    path: '/WelcomePage',
  },
  ChooseOption: {
    component: ChooseyourOptions,
    path: '/ChooseOption'
  },
  EmailAccountSigup: {
    component: SignUp,
    path: "/verify-code"
  },
  CreateAccount: {
    component: AccountDetail,
    path: "/create-account"
  },
  About: {
    component: About,
    path: "/about"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  WelcomePage: {
    component: WelcomePage,
    path: '/WelcomePage',
  },
  ChooseOption: {
    component: ChooseyourOptions,
    path: '/ChooseOption',
  },
  BeforeWeStart: {
    component: BeforeWeStart,
    path: "/before-start"
  },
  CompanyDetail: {
    component: CompanyDetail,
    path: "/company-describe"
  },
  DescribleCompanyPlan: {
    component: DescribleCompanyPlan,
    path: '/describle-business-plan'
  },
  CompanySuccess: {
    component: CompanySuccess,
    path: "/company-success"
  },
  CompanyAbout: {
    component: CompanyAbout,
    path: "/company-about"
  },
  TellBitMoreBusiness: {
    component: TellBitMoreBusiness,
    path: "/tell-more-business"
  },
  AboutTheCompany: {
    component: AboutTheCompany,
    path: "/about-the-company"
  },
  CompanyOffer: {
    component: OfferTheCompany,
    path: "/offering-the-company"
  },
  AboutYourApproach: {
    component: AboutYourApproach,
    path: "/about-your-approach"
  },
  Home: {
    component: WelcomePage,
    path: '/',
    exact: true
  },
  Profile: {
    component: Profile,
    path: '/Home',
  },
  InfoPage: {
    component: InfoPage,
    path: '*/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  PasswordRecovery: {
    component: PasswordRecovery,
    path: '/PasswordRecovery'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  SignUpProfessional: {
    component: SignUpProfessional,
    path: "/SignUpProfessional"
  },
  VerifySignUpCode: {
    component: VerifySignUpCode,
    path: "/VerifySignUpCode"
  },
  PasswordRecoverySuccess: {
    component: PasswordRecoverySuccess,
    path: "/PasswordRecoverySuccess"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  SignUpProfessional: {
    component: SignUpProfessional,
    path: "/SignUpProfessional"
  },
  VerifySignUpCode: {
    component: VerifySignUpCode,
    path: "/VerifySignUpCode"
  },
  PasswordRecoverySuccess: {
    component: PasswordRecoverySuccess,
    path: "/PasswordRecoverySuccess"
  },
  // AccountCreation:{
  //   component:AccountCreation,
  //  path:"/accountcreation"
  // },
  CompanyProgressHeader: {
    component: CompanyProgressHeader,
    path: "/CompanyProgressHeader"
  },
  VerifySignUpCode: {
    component: VerifySignUpCode,
    path: "/VerifySignUpCode"
  },
  PasswordRecoverySuccess: {
    component: PasswordRecoverySuccess,
    path: "/PasswordRecoverySuccess"
  },
  ProblemStatement: {
    component: ProblemStatement,
    path: "/problem-statements"
  },

  ChooseYourPath: {
    component: ChooseYourPath,
    path: "/chooseyourpath"
  },
  Services: {
    component: Services,
    path: "/services"
  },
  PrimaryQuestion: {
    component: PrimaryQuestion,
    path: "/PrimaryQuestion"
  },
  MissionStatement: {
    component: MissionStatement,
    path: "/missionstatement"
  },
  UniqueSelling: {
    component: UniqueSelling,
    path: "/uniqueselling"
  },
  ProductAndFeature: {
    component: ProductAndFeature,
    path: "/productsandfeature"
  },
  PaymentStatus: {
    component: PaymentStatus,
    path: "/PaymentStatus"
  },
  ChatHistory: {
    component: ChatHistory,
    path: '/ChatHistory'
  },
  CompanyIntel: {
    component: CompanyIntel,
    path: '/companyIntel'
  },
  Myworkflow: {
    component: Myworkflow,
    path: '/Myworkflow'
  },
  SubscriptionExploreUpgrade: {
    component: SubscriptionExploreUpgrade,
    path: "/SubscriptionExploreUpgrade"
  },
  EmailCreation: {
    component: EmailCreation,
    path: '/emailcreation'
  },
  History: {
    component: History,
    path: '/History'
  },
  ShareAndEarn: {
    component: ShareAndEarn,
    path: '/ShareAndEarn'
  },
  MyLibrary: {
    component: MyLibrary,
    path: '/myLibrary'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyC2N1MvMRtj3ZPFOc7zPxIG3D2rU5-WFZ8",
  authDomain: "gtmsai-edad3.firebaseapp.com",
  projectId: "gtmsai-edad3",
  storageBucket: "gtmsai-edad3.appspot.com",
  messagingSenderId: "608560252355",
  appId: "1:608560252355:web:6c224ba3673d2b3773a976",
  measurementId: "G-TD7TJ8WJVV"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
