import React from "react";
import { Box, Typography, Dialog, IconButton } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";

import moment from "moment";
interface Props {
  termAndConditionState?: any;
  changeTermAndConditionState: (obj: any) => void;
}

const TermAndCondition = (props: Props) => {
  const { termAndConditionState, changeTermAndConditionState } = props;
  const termAndConditionData =
    termAndConditionState?.data?.terms_and_condition?.data;
  console.log(props, "TD");
  return (
    <Box>
      {termAndConditionState.isModalVisible && (
        <Dialog
          open={true}
          PaperProps={{
            style: { borderRadius: "6px" },
          }}
        >
          <Box
            style={{
              height: "450px",
              width: "577px",
            }}
          >
            <Box style={{ padding: "20px" }}>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <Box>
                  <Typography
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "22px",
                      fontWeight: 600,
                    }}
                  >
                    Terms & Conditions
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ color: "#8C8C8C", fontWeight: 400 }}
                  >
                    Updated{" "}
                    {moment(termAndConditionData?.data?.updated_at).format(
                      "DD MMM YYYY"
                    )}
                  </Typography>
                </Box>
                <IconButton
                  data-testid="closeTermAndConditionModalBtn"
                  style={{ padding: 0 }}
                  onClick={() => {
                    changeTermAndConditionState({
                      ...termAndConditionState,
                      isModalVisible: false,
                      data:[]
                    });
                  }}
                >
                  <Clear />
                </IconButton>
              </Box>

              <Box
                style={{
                  maxHeight: "350px",
                  overflow: "auto",
                  marginRight: "10px",
                  marginTop: "5px",
                }}
                className="custom-scrollbar"
              >
                {termAndConditionData?.map((item: any) => (
                  <Box key={`${new Date()}`} style={{ margin: "20px 0px" }}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          backgroundColor: "#3A84C9",
                          width: "8px",
                          height: "8px",
                          borderRadius: "50%",
                        }}
                      ></Typography>
                      <Typography
                        style={{
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontWeight: 600,
                          marginLeft: 8,
                        }}
                      >
                        {item?.attributes?.title}
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        fontFamily: "Montserrat",
                        fontSize: "12px",
                        fontWeight: 400,
                        color: "#8C8C8C",
                        lineHeight: "20px",
                        paddingRight: "20px",
                        marginTop: "10px",
                        textAlign: "justify",
                      }}
                    >
                      {item?.attributes?.description}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

export default TermAndCondition;
