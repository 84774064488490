import React from "react";

// Customizable Area Start
import { Container, Box, Button, Typography, Paper } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./WelcomePage.scss";
import Layout from "../../../components/src/Layout";
import CarouselDisplay  from "../../../blocks/carouseldisplay/src/CarouselDisplay.web";

// Customizable Area End
import WelcomePageController from "./WelcomePageController.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class WelcomePage extends WelcomePageController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <Layout    
      carousel={<CarouselDisplay type="welcome" id="" navigation={{}}/>}
       hideBackBtn>
        <ThemeProvider theme={theme}>
          <Container maxWidth="sm" style={welcomePageStyles.mainContainerStyle}>
            <Paper style={welcomePageStyles.paperStyle}>
              <form>
                <Box>
                  <Typography
                    align="center"
                    style={welcomePageStyles.typographyStyleHeading}
                    variant="h6"
                  >
                    Welcome to Pebbles Ai
                  </Typography>
                  <Typography
                    align="center"
                    style={welcomePageStyles.typographyStyleLabel}
                    variant="h6"
                  >
                    Your go to market journey starts here.
                  </Typography>
                </Box>

                <Box style={welcomePageStyles.btnStyle}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{
                      ...welcomePageStyles.loginBtn,
                      textTransform: "none",
                    }}
                    data-test-id="gotoLoginPageDataTestId"
                    onClick={()=>this.goToLogin()}
                  >
                    Log in 
                  </Button>
                  <Button
                    style={{
                      ...welcomePageStyles.signInBtn,
                      textTransform: "none",
                    }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    data-test-id="signUpbtnDataTestId"
                    onClick={() => {
                      this.goToChooseYourOption();
                    }}
                  >
                    Sign up
                  </Button>
                </Box>
              </form>
            </Paper>
          </Container>
        </ThemeProvider>
      </Layout>
      // Customizable Area End
    );
  }
}

const welcomePageStyles = {
  mainContainerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paperStyle: {
    padding: "40px",
    maxWidth: "440px",
    // height: "193px",
    width: "100%",
    borderRadius: "28px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between",
  },
  typographyStyleHeading: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "32px",
  },
  typographyStyleLabel: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    color: "rgba(71, 85, 105, 1)",
    marginTop: "12px",
    lineHeight: 1.5,
  },
  btnStyle: {
    width: "100%",
    marginTop: "32px",
  },
  loginBtn: {
    color: "white",
    backgroundColor: "black",
    borderRadius: "8px",
    height: "44px",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    boxShadow: "none",
    "&:hover": {
      background: "#FFF",
    },
  },
  signInBtn: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
    marginTop: "8px",
    color: "black",
    backgroundColor: "white",
    borderRadius: "8px",
    height: "44px",
    boxShadow: "none",
    "&:hover": {
      background: "#FFF",
    },
    border: "1px solid #CBD5E1",
  },
};
