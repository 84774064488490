import React from "react";
import Layout from "../../../components/src/Layout";
import ForgotPwdController, { Props } from "./ForgotPwdController";

import * as Yup from "yup";
import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Link,
  TextField
} from "@material-ui/core";
import { Formik, Form } from "formik";
import CarouselDisplay from "../../../blocks/carouseldisplay/src/CarouselDisplay.web";
import { createStyles, withStyles } from "@material-ui/core/styles";

export class ForgotPassword extends ForgotPwdController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  validationSchema = Yup.object({
    email: Yup.string()
      .email("Please enter a valid email.")
      .required("Email should not be blank.")
  });

  render() {
    const { classes } = this.props;

    return (
      <Layout
        carousel={<CarouselDisplay type="password" id="" navigation={{}} />}
        back={this.goToLogin}
      >
        <Container className={classes.FormContainerStyle}>
          <Paper className={classes.forgotPwdForm}>
            <Typography
              align="center"
              className={classes.forgotPawdTitle}
              variant="h6"
            >
              Forgot password?
            </Typography>
            <Typography
              align="center"
              className={classes.forgotPwdSubtitle}
              variant="h6"
            >
              No worries, we'll send a recovery link to your email.
            </Typography>

            <Box>
              <Formik
                data-testid="EmailFormik"
                initialValues={{
                  email: ""
                }}
                validationSchema={this.validationSchema}
                onSubmit={values => {
                  this.forgotPasswordApi(values.email);
                }}
                validateOnChange={false}
              >
                {({ errors, touched, handleChange }) => (
                  <Form translate={undefined}>
                    <Box style={inlineStyle.box}>
                      <Typography
                        align="left"
                        className={classes.inputFeildText}
                        variant="h6"
                      >
                        Email
                      </Typography>
                      <TextField
                        data-testid="emailFieldTestId"
                        variant="outlined"
                        fullWidth
                        placeholder="Provide an email address "
                        onChange={e => {
                          handleChange(e);
                          this.setState({ errors: "" });
                        }}
                        style={
                          !!(errors.email && touched.email) ||
                          !!this.state.errors
                            ? inlineStyle.borderError
                            : undefined
                        }
                        error={
                          !!(errors.email && touched.email) ||
                          !!this.state.errors
                        }
                        name="email"
                        className={classes.input}
                      />
                      <div style={inlineStyle.error}>
                        {errors.email || this.state.errors}
                      </div>
                    </Box>
                    <Button
                      type="submit"
                      className={classes.submitBtn}
                      fullWidth
                    >
                      Send a recovery link
                    </Button>
                    <Box>
                      <Typography
                        align="center"
                        className={classes.forgotPwdSubtitle}
                        variant="h6"
                      >
                        Already have an account?{" "}
                        <Link
                          className={classes.link}
                          onClick={() => {
                            this.goToLogin();
                          }}
                        >
                          Log in
                        </Link>
                      </Typography>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Paper>
        </Container>
      </Layout>
    );
  }
}

const inlineStyle = {
  box: { marginTop: "30px" },
  input: {
    height: "44px",
    borderRadius: 8,
    overflow: "hidden"
  },
  error: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
    color: "#DC2626",
    marginTop: "2px",
    fontFamily: "Montserrat"
  },
  borderError: {
    borderColor: "#DC2626"
  }
};

const forgotpwdPageStyles: any = createStyles({
  FormContainerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  paperStyle: {
    padding: "20px",
    borderRadius: "28px",
    height: "273px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)"
  },
  forgotPawdTitle: {
    margin: "10px  0px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "30px"
  },
  forgotPwdSubtitle: {
    // margin: '16px  0px',
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    color: "rgba(71, 85, 105, 1)"
  },
  forgotPwdForm: {
    maxWidth: "520px",
    width: "100%",
    borderRadius: "28px",
    padding: "40px",
    background:
      "linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)"
  },
  inputFeildText: {
    fontWeight: 700
  },

  submitBtn: {
    margin: "25px 0px ",
    color: "white",
    backgroundColor: "black",
    height: "44px",
    marginRight: "5px",
    cursor: "pointer",
    borderRadius: "8px",
    border: " 1px solid rgba(203, 213, 225, 1)",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      background: "#000"
    }
  },
  link: {
    cursor: "pointer",
    color: "#3A84C9",
    fontWeight: 700
  },
  subHeadingtypographyStyle1: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "12px"
  },
  inputFeilds: {
    margin: "15px 0px"
  },
  input: {
    height: "42px",
    marginTop: "4px",
    borderRadius: " 8px",
    border: "1px solid rgba(140, 140, 140, 0.20)",
    background: "#FFF",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
        height: "44px"
      },
      "&.Mui-disabled fieldset": {
        borderColor: "transparent",
        opacity: 1
      },
      "&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
        opacity: 1
      },
      "&:hover fieldset": {
        borderColor: "transparent"
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent"
      },
      "& .MuiOutlinedInput-input": {
        padding: "0 12px",
        lineHeight: "21px",
        color: "#64748B",
        fontSize: "14px",
        fontWeight: 500
      },

      "& .MuiInputBase-input::placeholder": {
        opacity: 1
      },

      "& .MuiInputBase-input": {
        height: "44px"
      }
    }
  }
});

export default withStyles(forgotpwdPageStyles)(ForgotPassword);
