import React from "react";
// Customizable Area Start
import {
    Grid,
    Box,
    Typography,
    createStyles
} from "@material-ui/core";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
interface IProps {
    mailBox:string,
    onTouch: string,
    onTouchArray: {
          id: number;
          title: string;
          sub_title: string;
          description: string;
          setup: string;
          parameters: string;
          campaign_category_id: number;
          campaign_sub_category_id: number
    }[],
    hoveredCardIndex: number | null,
    onMouseOver: (id: number) => void,
    onMouseOut: () => void,
    setup:string,
    parameters:string,
    hoverIcon:string,
    greenIcon:string,
    color:string,
    onClick: (data:any) => void
}
// Customizable Area End

export default class TacticalCard extends React.Component<IProps, {}> {
    constructor(props: IProps) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            // Customizable Area Start
            <Box style={webStyle.mainBox}>
                <Box style={webStyle.mailBox}>
                    <img src={this.props.mailBox} />
                    <Typography style={webStyle.onTouch}>{this.props.onTouch}</Typography>
                </Box>
                <Grid container spacing={3}>
                    {this.props.onTouchArray.map((data, index) =>
                        <Grid item xs={12} md={6} lg={4} key={index}>
                            <Box 
                                style={{ 
                                    ...(this.props.hoveredCardIndex === data.id ? 
                                    { ...webStyle.boxHoverContainer, 
                                    background: this.props.color, 
                                    border: `1px solid ${this.props.color}` } : 
                                    { ...webStyle.boxContainer, 
                                    border: `1px solid ${this.props.color}` }) 
                                }} 
                                onMouseOver={() => this.props.onMouseOver(data.id)} 
                                onMouseOut={this.props.onMouseOut}
                                onClick={() => this.props.onClick(data)}
                                >
                                <Box style={webStyle.mailDisplay}>
                                    <MailOutlineIcon style={{ ...(this.props.hoveredCardIndex === data.id ? webStyle.whiteMailIcon : { color: this.props.color }) }} />
                                    <Typography style={{ ...webStyle.singleTouchText, color: this.props.hoveredCardIndex === data.id ? '#fff' : this.props.color }}>{data.sub_title}</Typography>
                                </Box>
                                <Typography style={{ ...webStyle.customEmailText }}>{data.title}</Typography>
                                <Typography style={this.props.hoveredCardIndex === data.id ? webStyle.subHoverText : webStyle.subText}>{data.description}</Typography>
                                <Box style={webStyle.setupDisplay}>
                                    <Box>
                                        <Typography style={this.props.hoveredCardIndex === data.id ? webStyle.setupHoverText : webStyle.setupText}>{this.props.setup}</Typography>
                                        <Typography style={{ ...webStyle.countNum,textTransform:'uppercase', color: this.props.hoveredCardIndex === data.id ? '#fff' : this.props.color }}>{data.setup}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography style={this.props.hoveredCardIndex === data.id ? webStyle.setupHoverText : webStyle.setupText}>{this.props.parameters}</Typography>
                                        <Typography style={{ ...webStyle.countNum, color: this.props.hoveredCardIndex === data.id ? '#fff' : this.props.color }}>{data.parameters}</Typography>
                                    </Box>
                                </Box>
                                {this.props.hoveredCardIndex === data.id ?
                                    <img src={this.props.hoverIcon} alt="green" style={webStyle.backgroundIcon} /> :
                                    <img src={this.props.greenIcon} alt="green" style={webStyle.backgroundIcon} />
                                }
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle : any = createStyles({
    mainBox:{
        background: '#F1F5F9',
        borderRadius: '15px',
        padding: '20px',
        margin:'10px 0'
    },
    mailBox:{
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        marginBottom: '10px'
    },
    onTouch:{
        fontSize:'1.5rem',
        fontWeight:600
    },
    boxContainer:{
        border: '1px solid #57BE99',
        borderRadius: '20px',
        position: 'relative',
        overflow: 'hidden',
        cursor:'pointer',
        height: '100%'
    },
    boxHoverContainer:{
        border: '1px solid #57BE99',
        borderRadius: '20px',
        position: 'relative',
        overflow: 'hidden',
        cursor:'pointer',
        color:'#fff',
        height: '100%'
    },
    mailDisplay:{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px'
    },
    whiteMailIcon:{
        color:'#fff'
    },
    singleTouchText:{
        fontSize: '0.75rem',
        fontWeight: 500,
    },
    customEmailText:{
        fontSize: '1.25rem',
        fontWeight: 600,
        textAlign:'start',
        padding: '0 20px'
    },
    subText:{
        fontSize: '0.75rem',
        lineHeight: '14.4px',
        fontWeight: 500,
        color: '#475569',
        textAlign: 'start',
        padding: '10px 20px'
    },
    subHoverText:{
        fontSize: '0.75rem',
        lineHeight: '14.4px',
        fontWeight: 500,
        textAlign: 'start',
        padding: '10px 20px',
        color:'#fff'
    },
    setupText:{
        fontSize: '0.75rem',
        lineHeight: '14.4px',
        fontWeight: 500,
        color: '#475569',
        textAlign: 'start',
        '&:hover':{
            color:'#fff'
        }
    },
    setupHoverText:{
        fontSize: '0.75rem',
        lineHeight: '14.4px',
        fontWeight: 500,
        textAlign: 'start',
        color:'#fff'
    },
    setupDisplay:{
        display: 'flex',
        padding: '20px 20px',
        gap: '20px',
        textAlign: 'start'
    },
    countNum:{
        fontWeight: 600
    },
    countHoverNum:{
        fontWeight: 600,
        color:'#fff'
    },
    backgroundIcon:{
        position: 'absolute',
        right: 0,
        bottom: 0
    }

});
// Customizable Area End
