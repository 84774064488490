  import React from "react";

  import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    TextField,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Modal,
    DialogActions,
    List,
    Slider
    // Customizable Area End
  } from "@material-ui/core";

  // Customizable Area Start
  import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
  import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
  import { closeIcon, loading, ready, checkedIcon } from "./assets";
  import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";


  const theme = createTheme({
    palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
    },
    typography: {
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    }
  });

    type Answer = {
      id:number,
      answer:string
      nested_questions?:Question[];
    }

    export interface Question {
      id: string;
      question: string;
      type: string;
      hint: string;
      answers: Answer[]
    }
    
    export interface FormData {
      id: string;
      type: string;
      attributes: {
        section: string;
        order: number;
        questions: Question[];
      };
    }

    export interface FormResponse {
      name: string;
      description: string;
      question: string;
      answer: string;
      campaign_id: number;
      answer_id: string;
      question_id: number;
    }

    type WebStyle = {
      [key: string]: {
        [key: string]: string | number | {
          [key:string]:string|number
        }
      };
    };



    import InfoIcon from "../../../components/src/InfoIcon.web";
    import InformationCollectedDialog from "./InformationCollectedDialog.web";

    import * as Yup from "yup";
    const workflowSchema = Yup.object().shape({
      workflowName: Yup.string()
      .required("This field is required")
      .trim('This field should not contain only spaces')
      .max(40, "Maximum 40 characters allowed"),
      workflowDescription: Yup.string()
      .required("This field is required")
      .trim('This field should not contain only spaces')
      .max(200, "Maximum 200 characters allowed")
      
    });
    import { Form, Formik } from "formik";
    import MutipleQuestion from "./MultipleQuestion.web";
    import TacticalModal from "./TacticalModal.web";
    import Toast from "../../utilities/src/Toast";

  // Customizable Area End

  import Cfdataintegrationviapromptengineering4Controller, {
    Props,
    configJSON,
  } from "./Cfdataintegrationviapromptengineering4Controller";


  const PrettoSlider = styled(Slider)({
    color: '#3a84c9',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
      height:"4px"
    },
    '& .MuiSlider-rail':{
      height:"4px"
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      top:"21%",
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    "& .PrivateValueLabel-circle-83":{
        display:'none'
    }
  });

  const StyledButton = styled(Button)({
    color: "#64748B",
    backgroundColor: "#E2E8F0",
    width: "100%",
    maxWidth: "800px",
    padding: "10px 16px",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "120%",
    '&:hover': {
      background: '#57be99',
      color: 'black'
    }
  });

  const StyledButtonContainer = styled(Box)({
    position: "relative",
    zIndex: 2000,
    margin: "24px 0 24px 96px",
    '@media(max-width: 768px)': {
      margin: "24px",
    },
    '@media(max-width: 420px)': {
      margin: "12px",
    }
  })

  const StyledContainer = styled(Box)({
    flexDirection: "column",
    fontFamily: "Montserrat",
    display: "flex",
    alignItems: "center",
    '@media(max-width: 768px)': {
      marginLeft: "24px",
      marginRight: "24px",
    },
    '@media(max-width: 420px)': {
      marginLeft: "12px",
      marginRight: "12px",
    }
  })

  const StyledModal = styled("div")({
    display: "flex",
    justifyContent: "center",
    gap: "8px",
    '@media(max-width: 1024px)': {
      flexDirection: "column",
      alignItems: "center",
      '& > div': {
        width: "95%",
      },
    },
  })

  const InnerContainer = styled("div")({
    width: "100%",
    '@media(max-width: 1024px)': {
      width: "50%",
    },
  })

  const ResponsiveContainer = styled("div")({
    width: "1158px",
    padding: "20px",
    borderRadius: "28px",
    background: "#FFF",
    height: "80vh",
    overflowX: "hidden",
    overflowY: "auto",
    '@media(max-width: 1024px)': {
      width: "90vw",
    },
  })

  const Content = styled("span")({
    fontSize: "48px",
    fontWeight: 500,
    textAlign: "center",
    '@media(max-width: 680px)': {
      fontSize: "32px",
    },
  })

  export default class Cfdataintegrationviapromptengineering4 extends Cfdataintegrationviapromptengineering4Controller {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }

    // Customizable Area Start
    customTooltip = (text: string) => (
      <div style={webStyle.tooltipStyle}>{text}</div>
    )

    renderConditionalComponent = () => {
      const { isLoading, isResponseReady } = this.state
      if (isLoading) {
        return <div style={webStyle.fullScreen}>
          <img src={loading} alt="Loading..." width={300} height={300} />
          <Content>Just a moment</Content>
          <span style={webStyle.note}>Your content will be ready in <span style={webStyle.noteHighlight}>1<span style={{ fontSize: "16px" }}>min</span></span></span>
        </div>
      } else if (isResponseReady) {
        return <div style={webStyle.fullScreen}>
          <img src={ready} alt="Content Ready" width={300} height={300} />
          <Content>Your content is ready!</Content>
        </div>
      } else return null;
    }

    renderApplyAISection=(question:any,index:number,setFieldValue:(type:string,value:string)=>void,values:any)=>{
      const currentValue = values[`emailContruct-${question.id}`] || ''
        return (
          <div key={index} style={webStyle.questionContainer}>
            <div style={webStyle.inforHeader}>
              <span style={{ fontSize: "16px", fontWeight: 600 }}>{question.question}</span>
              <div style={webStyle.textAreaHint}>
                <InfoIcon htmlContent={question.hint} />
              </div>
            </div>
            <div>
              <Input
                type="text"
                disableUnderline
                style={webStyle.textareaStyle}
                multiline
                placeholder="Enter URL of your target company here"
                data-test-id={`text-area-field-${question.id}`}
                onChange={(event) => setFieldValue(`emailContruct-${question.id}`, event.target.value)}
                value={currentValue}
              />
            </div>
            <Button data-test-id={`collect-info-btn-${question.id}`} disabled={!currentValue} onClick={() => this.openImportDataModal(currentValue)} style={{...webStyle.collectionInfoButton,...(!currentValue?webStyle.deactiveBtn:{})}}>{'Collect information'}</Button>
            <InformationCollectedDialog
              id="InformationCollectedDialog"
              navigation={this.props.navigation}
              link={currentValue}
              open={this.state.importDataModal}
              content={this.state.companyInfoData}
              onCloseModal={this.onCloseImportDataModal}
              onSaveChange={this.onSaveNewCompanyInfo}
            />
          </div>
        )
    }


    renderRadioButton = (question:any,index:number,setFieldValue:(type:string,value:string)=>void,values:any)=>{
      if (question.type === "radio" || question.type === "select"|| question.type === "checkbox") {
        return (
          <div key={index} style={webStyle.questionContainer}>
            <MutipleQuestion
              id={`emailContruct-${question.id}`}
              onChoose={(_value:string) => {
                setFieldValue(`emailContruct-${question.id}`,_value);
              }}
              question={question}
              value={values[`emailContruct-${question.id}`]}
              navigation={this.props.navigation}
            />
          </div>
        )
      }
      return null
    }

    renderFirstSection=(question:any)=>{
        return (
          <div key={question.id} style={webStyle.emailConstructContainer}>
            <div style={{ ...webStyle.questionContainer, marginBottom: '12px' }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '20px' }}>
                <span style={{ fontSize: "16px", fontWeight: 600 }}>{question.question}</span>
                <div style={{ background: '#D9E7F3', borderRadius: 500, padding: 6 }}>
                  <InfoIcon htmlContent={question.hint} />
                </div>
              </div>
              <div style={webStyle.campainTypeWrapper}>
                <div style={webStyle.campainProcess}>
                  <div style={webStyle.headPoint} />
                  <div style={webStyle.headLine} />
                </div>
                <div style={webStyle.inforWrapper}>
                  <Typography style={webStyle.campainName}>{this.state.campaignCate.chosenAnswer.answer}</Typography>
                  <Typography style={webStyle.campainTypeTitle}>{this.state.campaignCate.nestedQuestion.question}</Typography>
                  <div style={webStyle.campainTypeTextWrapper}>
                    <img src={checkedIcon} style={webStyle.checkedIconStyle} />
                    <Typography style={webStyle.campainTypeText}>{this.state.campaignCate.nestedAnswer.answer}</Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
    }
    // Customizable Area End

    render() {

      const {
        isSaveAndContinueModal,
        formData,
      } = this.state;

      return (
        // Customizable Area Start
        <ThemeProvider theme={theme}>
          <style>
            {`
            .MuiDialog-paperWidthSm {
                  border-radius: 28px;
                  max-width: 808px;
                  max-height: 417px;
                  padding: 32px 40px;
                  gap: 28px;
                  width: 100%;
                  height: 100%;
              } 
            .MuiInputBase-input:focus {
                  outline: none
              } 
            .MuiOutlinedInput-notchedOutline {
              border-width: 1px !important
              } 
            .MuiSlider-root {
              color:#3A84C9
            }
            `}
          </style>
          <Box style={{ background: 'var(--bg, linear-gradient(130deg, #F9F9F9 -25.99%, #EBE2CF 77.55%))' }}>
            {this.renderConditionalComponent() ??
            <NavigationMenuComponent
              id={'workflow'}
              navigation={this.props.navigation}
              activeIndex={1}
              children={
                  <div style={{display:'flex',flex:1, flexDirection:'column'}}>
                    <StyledButtonContainer 
                      style={webStyle.headerStyles}
                      role="button"
                    >
                      {!!this.state.campaignName&&<div style={webStyle.linkWrapper}>
                        <Typography style={webStyle.nameLink}>{this.state.campaignName}</Typography>
                        <Typography style={webStyle.nameDesc}>{'/  '+this.state.description}</Typography>
                        <KeyboardArrowDownIcon/>
                      </div>}
                      <button
                        data-test-id="create-campaign-modal"
                        style={{
                          padding: "5px",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontFamily: "Montserrat",
                          background: "white",
                          fontSize: "14px",
                          fontWeight: 600,
                          border: "1px solid #cbd5e1",
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                          height: "32px",
                        }}
                        onClick={this.handleOpenModal}>
                        Create new campaign <span style={{ fontSize: "30px", fontWeight: 100 }}>+</span>
                      </button>
                    </StyledButtonContainer>
                    <div style={webStyle.dialogContainer}>
                      <Modal open={this.state.isModalOpen} className="sampleClass" onClose={this.skipCampaignModal} >
                        <Box
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                         <Formik
                          data-testid="modalWorkflow"
                          initialValues={{
                            workflowName:'',
                            workflowDescription:''
                          }}
                          validationSchema={workflowSchema}
                          enableReinitialize
                          isInitialValid
                          onSubmit={(values:any) => {
                            this.handleSubmitCampaign(values)
                         }}
                         >
                          {({values, errors,touched, setFieldValue,handleChange,resetForm})=>( 
                          <Form>
                            <Box style={{
                            borderRadius: '28px',
                            maxWidth: '800px',
                            maxHeight: '417px',
                            padding: '32px 40px',
                            gap: '28px',
                            width: '80vw',
                            height: '100%',
                            color: 'rgba(0, 0, 0, 0.87)',
                            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: '#fff',
                            boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
                            position: 'relative',
                            overflowY: 'auto',
                          }}>
                            <Box display={"flex"} flexDirection={"column"} style={webStyle.nameAndContentHolder}>
                              <Typography style={webStyle.heading}>Name and describe your workflow
                                <span style={webStyle.closeIconSvg} ><img data-test-id="close-campaign" src={closeIcon} onClick={this.skipCampaignModal} /></span>
                              </Typography>
                              <List
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                                style={webStyle.nameHolder}
                              >
                                <ul style={webStyle.listContainer}>
                                  <li style={webStyle.fontProvider}>Name: max 40 characters</li>
                                  <li style={webStyle.fontProvider}>Description: max 200 characters</li>
                                </ul>
                              </List>
                            </Box>
                            <Box style={webStyle.inputContainer}>
                              <Box display={"flex"} flexDirection={"column"} >
                                <Typography style={webStyle.subHeading}>Name</Typography>
                                <TextField
                                  variant="outlined"
                                  autoFocus
                                  margin="dense"
                                  id="workflowName"
                                  name='workflowName'
                                  placeholder="Enter the name here"
                                  value={values.workflowName}
                                  onChange={handleChange}
                                  InputProps={{
                                    style: {
                                      fontFamily: 'Montserrat',
                                      fontWeight: 400,
                                      fontSize: "14px",
                                    },
                                  }}
                                />
                                <Box style={webStyle.errorWrapper}>
                                {errors.workflowName&&touched.workflowName&&<Typography style={webStyle.errorTex}>{errors.workflowName}</Typography>}
                                <Typography style={webStyle.alignRight}>{values.workflowName.length}/40 characters</Typography>
                                </Box>
                              </Box>
                              <Box display={"flex"} flexDirection={"column"} >
                                <Typography style={webStyle.subHeading}>Description</Typography>
                                <TextField
                                  variant="outlined"
                                  margin="dense"
                                  id="workflowDescription"
                                  name='workflowDescription'
                                  placeholder="Enter the description here"
                                  value={values.workflowDescription}
                                  onChange={handleChange}
                                  InputProps={{
                                    style: {
                                      fontWeight: 400,
                                      fontFamily: 'Montserrat',
                                      fontSize: "14px",
                                    },
                                  }}
                                />
                                <Box style={webStyle.errorWrapper}>
                                {errors.workflowDescription&&touched.workflowDescription&&<Typography style={webStyle.errorTex}>{errors.workflowDescription}</Typography>}
                                <Typography style={webStyle.alignRight}>{values.workflowDescription.length}/200 characters</Typography>
                                </Box>
                              </Box>
                            </Box>
                            <DialogActions style={webStyle.buttonContainer}>
                              <Button fullWidth variant="outlined"
                                data-test-id="skip-button"
                                style={{ color: "black", background: "white", textTransform: "none", fontFamily: "Montserrat", }}
                                onClick={this.skipCampaignModal}>
                                Skip for now
                              </Button>
                              <Button fullWidth type="submit" variant="contained" style={{ color: "white", background: "black", textTransform: "none", fontFamily: "Montserrat", }} 
                              // onClick={this.handleSubmitCampaign}
                                data-test-id="submit-campaign">
                                Submit
                              </Button>
                            </DialogActions>
                          </Box>
                          </Form>
                          )}
                         </Formik>
                        </Box>
                      </Modal>
                    </div>
                    <Modal
                      data-testid='modal-continue'
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%"
                      }}
                      open={isSaveAndContinueModal}
                      onClose={this.closeSaveAndContinueModal}
                    >
                      <>
                    <TacticalModal
                      onSubmit={(type,values)=>this.onSubmitTacticalData(type,values)}
                      formData={formData}
                      onCloseModal={this.closeSaveAndContinueModal}
                      id="TacticalModal"
                      navigation={this.props.navigation}
                    />
                    <Toast message={this.state.successMess} onClose={this.onCloseToast} />
                    </>
                    </Modal>
                    {!!this.state.formData.length&&<StyledContainer>
                      <Formik
                          data-testid="infoWorkflow"
                          initialValues={this.campainValue}
                          validationSchema={this.campainValidation}
                          enableReinitialize
                          isInitialValid={false}
                          onSubmit={(values:any) => {
                            this.openSaveAndContinueModal(values, 'saved')
                         }}
                         >
                          {({values, setFieldValue,isValid,})=>( 
                          <Form>
                      <div style={webStyle.emailContruct}>Email Contruct</div>
                      
                      {this.state.formData?.map((formData: FormData) => (
                        <div key={formData.id} style={webStyle.emailConstructContainer}>
                          {formData.attributes.section === "EMAIL CONSTRUCT" && this.sortArray(formData.attributes.questions).map((question: Question, index: number) => {
                                  if(question.question ===`You've chosen to generate a(n):`){
                                    return this.renderFirstSection(question);
                                  }else if (question.question ===`What is your target company? (optional) `){
                                    return this.renderApplyAISection(question,index,setFieldValue,values);
                                  } else if (question.type !=='textarea') {
                                    return this.renderRadioButton(question,index,setFieldValue,values);
                                  }
                            return (
                              <div key={index} style={webStyle.questionContainer}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",marginBottom:'20px' }}>
                                  <span style={{ fontSize: "16px", fontWeight: 600 }}>{question.question}</span>
                                  <div style={{background:'#D9E7F3', borderRadius:500, padding:6}}>
                                            <InfoIcon htmlContent={question.hint} />
                                          </div>
                                </div>
                                <div>{(question.type === "textarea") &&
                                  <Input
                                    data-test-id={`text-area-field-${question.id}`}
                                    style={webStyle.textareaStyle}
                                    type="text"
                                    disableUnderline
                                    placeholder="Enter the info here"
                                    multiline
                                    rows="3"
                                    value={values[`emailContruct-${question.id}`] || ''}
                                    onChange={(event) => setFieldValue(`emailContruct-${question.id}`,event.target.value)}
                                  />
                                }</div>
                              </div>)
                          })}
                        </div>
                      ))}
                      <Button
                        data-test-id="save-and-continue-modal"
                        type="submit"
                        style={{...webStyle.buttonSubmit,...(!isValid?webStyle.buttonSubmitDisable:{})}}
                      >
                        Save & continue
                      </Button>
                      <p style={webStyle.footerNote}>In order to continue you have to fill in all mandatory choices. </p>
                      </Form>
                          )}
                          </Formik>
                    </StyledContainer>
                    }
                  </div>
              }
            />
            }
          </Box>
        </ThemeProvider>
        // Customizable Area End
      );
    }
  }

  // Customizable Area Start
const webStyle: WebStyle = {
  errorInput:{
    border:"1px solid #DC2626"
  },
  buttonSubmitDisable:{
    background:'#E2E8F0',
    color:'#64748B'
  },
  textAreaHint:{ background: '#D9E7F3', borderRadius: 500, padding: 6 },
  inforHeader:{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: '20px' },
  buttonSubmit:{
    marginTop:'12px',
    color: 'black',
    background: '#57be99',
    width: "100%",
    maxWidth: "800px",
    padding: "10px 16px",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "120%",
  },
  campainTypeWrapper:{
    width:'100%',
    backgroundColor:'#f3f3f3',
    borderRadius:8,
    padding:'12px 8px',
    display:'flex',
  },
  collectionInfoButton:{
    borderRadius:'8px',
    color:"#fff",
    background:'#000',
    textTransform:"none",
    height:'40px',
    width:'180px',
    marginTop:'40px',
  },
  deactiveBtn: {
    background: '#E2E8F0',
    color: '#64748B'
  },
  campainTypeText:{
    flex:1,
    display:'flex',
    fontSize:'14px',
    fontWeight:500
  },
  nestedWrapper:{
    margin:'8px 0',
    marginLeft:'32px',
    columnGap:'16px',
    columnCount:2,
  },
  campainProcess:{
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    height:'100%',
  },
  headerStyles:{
    position: "relative",
    zIndex: 2000,
    margin: "24px 0",
    marginBottom:'60px',
    display:'flex',
    justifyContent:'center',
  },
  linkWrapper:{
    marginRight:'70px',
    display:'flex',
    alignItems:'center'
  },
  nameLink:{
    fontSize:"15px",
    fontWeight:600,
  },
  nameDesc:{
    marginRight:'6px',
    marginLeft:'10px',
    fontSize:"14px",
    fontWeight:500,
    color:'#64748B'
  },
  headPoint:{
    width:'8px',
    height:'8px',
    borderRadius:200,
    border:'6px solid #3A84C9'
  },
  headLine:{
    width:'2px',
    backgroundColor:'#3A84C9',
    borderRadius:500,
    marginTop:'2px',
    height:'100%',
    minHeight:'45px'
  },
  campainName:{
    fontSize:'16px',
    fontWeight:500,
    fontFamily:"Montserrat",
  },
  campainTypeTitle:{
    color:'#94A3B8',
    fontSize:'12px',
    fontWeight:600,
    fontFamily:"Montserrat",
    marginLeft:'12px'
  },
  campainTypeTextWrapper:{
    display:'flex',
    marginTop:'4px',
    width:'100%',
    marginLeft:'12px',
  },
  checkedIconStyle:{
    width:'20px',
    height:'20px',
    marginRight:'8px',
  },
  inforWrapper:{
    flex:1,
    display:'flex',
    flexDirection:'column',
    marginLeft:'4px',
  },
  errorWrapper:{
    display:'flex',
    justifyContent:'space-between',
  },
  errorTex: {
    lineHeight: 1.2,
    marginBottom:'16px',
    color: "#DC2626",
    marginTop: "2px",
    fontFamily:"Montserrat",
    fontSize: 12,
    fontWeight: 500,
  },
  heading: {
    display: "flex",
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    maxHeight: "19px",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  subHeading: {
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Montserrat",
  },
  inputContainer: {
    maxHeight: "192px",
    gap: "16px",
  },
  buttonContainer: {
    padding: "12px 0px",
    height: "44px",
    gap: "8px",
  },
  button: {
    width: "360px",
    height: "44px",
    borderRadius: "8px",
    gap: "4px"
  },
  dialogContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "417px",
    gap: "24px",
  },
  listContainer: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "flexStart",
    marginLeft: "20px",
  },
  nameAndContentHolder: {
    gap: '8px',
    maxHeight: "69px",
    FontFace: "Montserrat",
  },
  nameHolder: {
    maxHeight: '42px',
    padding: 0
  },
  paddingRemover: {
    padding: 0
  },
  fontProvider: {
    fontSize: "14px",
    color: "rgba(71, 85, 105, 1)",
    fontFamily: "Montserrat",
    fontWeight: "500",
  },
  alignRight: {
    textAlign: "right",
    fontSize: "12px",
    fontFamily: "Montserrat",
    color: "#475569",
    fontWeight: 500,
    flex:1,
  },
  closeIconSvg: {
    height: "24px",
    width: "24px",
  },
  emailContruct:{
    marginBottom: "20px", 
    fontSize: "38px", 
    fontWeight: 600, 
    textAlign:'left',
    width:'100%',
    maxWidth:'750px'
  },
  questionContainer: {
    backgroundColor: "white",
    borderRadius: "28px",
    padding: "32px 40px",
    display: "flex",
    flexDirection: "column",
  },
  tooltipStyle: {
    background: 'rgba(11, 103, 188, 0.88)',
    padding: '20px',
    borderRadius: '8px',
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 500,
    border: "none",
  },
  fullScreen: {
    background: "var(--bg, linear-gradient(130deg, #F9F9F9 -25.99%, #EBE2CF 77.55%))",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Montserrat",
    flexDirection: "column",
  },
  note: {
    color: "#475569",
    fontSize: "16px",
  },
  noteHighlight: {
    fontSize: "32px",
    color: "#a68b3d",
  },
  closeButton: {
    position: "absolute",
    top: "24px",
    right: "24px",
    cursor: "pointer",
    zIndex: 2000,
  },
  footerNote: {
    color: "#475569",
    fontFamily: "Montserrat",
    fontSize: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "48px",
    maxWidth: "800px",
  },
  textareaStyle: {
    padding: "12px",
    borderRadius: "8px",
    border: "1px solid #94a3b8",
    fontFamily: "Montserrat",
    fontSize: "14px",
    width: "100%",
    fontWeight: 400,
  },
  emailConstructContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    width: "100%",
    maxWidth: "800px",
  }
};
  // Customizable Area End
