import React from "react";
import {
  Container,
  Box,
  Typography,
  Modal,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
import { withStyles, createStyles, createTheme, ThemeProvider  } from "@material-ui/core/styles";
//Custom Components
import CustomToaster from "../../../components/src/ChatHistoryToaster.web";
import ChatHistoryAccordian from "../../../components/src/ChatHistoryAccordian.web";
import { MessageLeft, MessageRight } from "../../../components/src/Message.web";
import DeletePopup from "./DeletePopup.web"
import SharePopup from "./SharePopup.web"
import SaveWorkflow from "./SaveWorkflow.web"
import FooterSection from "./FooterSection.web"
import BlankChatHistoryScreen from "./BlankChatHistoryScreen.web"
import ChangeWorkflow from "./ChangeWorkflow.web"
import RightSideBar from "./RightSideBar.web"



//Assets
import {
  thinkigRobo,
  answerReadyRobo,
} from "./assets";
//Icons
// Customizable Area Start
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Chatbot4Controller from "./Chatbot4Controller";
import ParameterModal from "../../multipageforms/src/ParameterModal.web";
export class ChatHistory extends Chatbot4Controller {
  // Customizable Area Start

  // Customizable Area End

  render() {
    const { classes, id, navigation } = this.props;
    const { showParameters, deleteModal, shareModal, toasterMsg,saveWorkflowModal,changeWorkFlow,rightDrawer,parameterWorkflow,displayParameter,openParameter } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenuComponent
        data-test-id="navigationMenu"
          id={id}
          navigation={navigation}
          activeIndex={0}
          rightMenu={rightDrawer}
          handleRightMenu={()=>this.setState({rightDrawer:false})}
        >
          <Box style={rightDrawer ? { display: "flex", width: "100%" } : {}}>
             <Box className={classes.containerWrapper}>
             <CustomToaster  data-test-id="CustomToaster" open={toasterMsg} message={"Hello"} onClose={() => {this.setState({ toasterMsg: false });}}/>
             <ChatHistoryAccordian  data-test-id="ChatHistoryAccordian" 
             showParameters={showParameters} 
             setParameter={(obj: any) => { this.setState(obj) }}
             displayParameters={displayParameter}
             workflowParameters={parameterWorkflow}
             handelonChangeOnWorkFlow={this.handelonChangeOnWorkFlow}
             states={this.state}
             />
             <BlankChatHistoryScreen/>
             {/* <MiddleScreen classes={classes}/> */}
             <FooterSection/>
            </Box>
              <DeletePopup data-test-id="DeletePopupDataTestId" open={deleteModal} setOpen={(obj: any) => { this.setState(obj) }} />
             <SharePopup data-test-id="SharePopupDataTestId" open={shareModal} setOpen={(obj: any) => { this.setState(obj) }} handelSubmitButton={(obj:any)=>this.handelSubmitButton(obj)} />
             <SaveWorkflow data-test-id="SaveWorkflowDataTestId" open={saveWorkflowModal} setOpen={(obj: any) => { this.setState(obj) }} />
             <ChangeWorkflow data-test-id="ChangeWorkflowDataTestId"  open={changeWorkFlow} setOpen={(obj: any) => { this.setState(obj) }} 
             parameterWorkflow={parameterWorkflow}
             />
              <Modal
                            className={classes.modal}
                            data-testid="ope-para-modal"
                            open={openParameter}
                            onClose={()=>{this.setState({openParameter:false})}}
                        >
                            <ParameterModal
                                formData={[
                                
                                  {
                                    id: "5",
                                    type: "form_creation",
                                    attributes: {
                                      section: "PERSUASION TACTICS",
                                      order: 2,
                                      questions: [
                                        {
                                          id: 7,
                                          is_sub: false,
                                          question:
                                            "Which writing style suits your persona or archetype best?*",
                                          required: true,
                                          type: "select",
                                          hint: "If you are unsure about what your persona or archetype would prefer in terms of writing style, ask your Pebbles Copilot by going to Copilot",
                                          order: 1,
                                          info: "",
                                          answers: [
                                            {
                                              id: 22,
                                              answer: "A narrative writing style",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 43,
                                              answer: "A Dutch-direct writing style",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 4114,
                                              answer: "A persuasive writing style",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 4225,
                                              answer: "A descriptive writing style",
                                              is_sub: false,
                                              hint: null,
                                            },
                                            {
                                              id: 4226,
                                              answer: "A benefit-focused writing style",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 4777,
                                              hint: null,
                                              answer: " A technically-focused writing style",
                                              is_sub: false,
                                            },
                                            {
                                              id: 48,
                                              answer: "An expository writing style",
                                              hint: null,
                                              is_sub: false,
                                            },
                                          ],
                                        },
                                        {
                                          info: null,
                                          question:
                                            "Which part of the link would you like to focus on? (optional) ",
                                          required: false,
                                          type: "chips",
                                          is_sub: false,
                                          id: 8,
                                          hint: "Which part of the link would you like to focus on? (optional) ",
                                          order: 2,
                                          answers: [
                                            {
                                              id: 23,
                                              answer: "What is it",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 42,
                                              answer: "How it works",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 49,
                                              answer: "Why we exist",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 50,
                                              answer: "Who is it for",
                                              hint: null,
                                              is_sub: false,
                                            },
                                          ],
                                        },
                                        {
                                          info: null,
                                          is_sub: false,
                                          id: 9,
                                          question:
                                            "Which influence technique would you like to leverage to enhance the impact of your content? (optional) ",
                                          required: false,
                                          type: "chips",
                                          hint: "Which influence technique would you like to leverage to enhance the impact of your content? (optional) ",
                                          order: 2,
                                          answers: [
                                            {
                                              id: 24,
                                              answer: "Validation",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 25,
                                              answer: "Focal point",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 26,
                                              answer: "Join-the-crowd",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 51,
                                              answer: "Praise",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              hint: null,
                                              id: 542,
                                              answer: "Prominence",
                                              is_sub: false,
                                            },
                                            {
                                              answer: "Elevated self-view",
                                              hint: null,
                                              id: 543,
                                              is_sub: false,
                                            },
                                            {
                                              hint: null,
                                              is_sub: false,
                                              id: 544,
                                              answer: "Established order",
                                            },
                                          ],
                                        },
                                        {
                                          info: null,
                                          is_sub: false,
                                          id: 13,
                                          question:
                                            "With what kind of call to action would you like to end your email? (optional) ",
                                          required: false,
                                          type: "chips",
                                          hint: "With what kind of call to action would you like to end your email? (optional) ",
                                          order: 2,
                                          answers: [
                                            {
                                              id: 39,
                                              answer: "Bullet points",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 140,
                                              answer: "Validation",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 412,
                                              is_sub: false,
                                              answer: "Joinn-the-crowd",
                                              hint: null,
                                            },
                                          ],
                                        },
                                        {
                                          id: 1400,
                                          question:
                                            "Which framing method do you wish to apply to help attain your goal? (optional)",
                                          is_sub: false,
                                          required: false,
                                          type: "chips",
                                          hint: "",
                                          order: 5,
                                          info: null,
                                          answers: [
                                            {
                                              id: 55,
                                              answer: "Gain positioning",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 56,
                                              answer: "Loss positioning",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              hint: null,
                                              is_sub: false,
                                              id: 5557,
                                              answer: "Periodd positioning",
                                            },
                                            {
                                              id: 558,
                                              is_sub: false,
                                              hint: null,
                                              answer: "Feature-benefit positioning",
                                            },
                                            {
                                              is_sub: false,
                                              answer: "Comparative positioning",
                                              hint: null,
                                              id: 59,
                                            },
                                            {
                                              id: 60,
                                              answer: "Danger positioning",
                                              hint: null,
                                              is_sub: false,
                                            },
                                          ],
                                        },
                                        {
                                          order: 4,
                                          info: null,
                                          is_sub: false,
                                          id: 1500,
                                          question:
                                            "Whatt emotions do you like to trigger in your personaa or archetype? (optional)",
                                          required: false,
                                          type: "select",
                                          hint: "",
                                          answers: [
                                            {
                                              id: 66,
                                              answer: "Fear: losss, uncertainty and perceived threats",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 670,
                                              is_sub: false,
                                              hint: null,
                                              answer: "Joyy: achievement, recognition and discovery",
                                            },
                                            {
                                              is_sub: false,
                                              hint: null,
                                              answer: "Morall discomforts: guilt, regret and shame",
                                              id: 680,
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  },
                                  {
                                   
                                    attributes: {
                                    
                                      questions: [
                                        
                                        {
                                          id: 1100,
                                          required: false,
                                          question:
                                            "Which tactics would you like to include to enhance the email's appeal for readers?(optional/ 3max) ",
                                            order: 3,
                                            hint: "Which tactics would you like to include to enhance the email's appeal for readers?(optional/ 3max) ",
                                            type: "chips",
                                            is_sub: false,
                                            info: null,
                                          answers: [
                                            {
                                              id: 2128,
                                              answer: "Bullet points",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 2219,
                                              is_sub: false,
                                              hint: null,
                                              answer: "One point per paragraph",
                                            },
                                            {
                                              id: 3120,
                                              hint: null,
                                              answer: "White spaces",
                                              is_sub: false,
                                            },
                                            {
                                              id: 345451,
                                              hint: null,
                                              is_sub: false,
                                              answer: "Smart sentences",
                                            },
                                            {
                                              id: 3122,
                                              answer: "Smart paragraphs",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 355543,
                                              answer: "Subheadings",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 3534454,
                                              hint: null,
                                              is_sub: false,
                                              answer: "Active voice",
                                            },
                                            {
                                              answer: "Tabular structure",
                                              is_sub: false,
                                              id: 3453345,
                                              hint: null,
                                            },
                                          ],
                                        },
                                        {
                                          id: 120,
                                          hint: "Which tone of voice is most appropriate for email? (optional/1 max) ",
                                          required: false,
                                          type: "range",
                                          order: 3,
                                          info: null,
                                          question:
                                            "Which tone of voice is most appropriate for email? (optional/1 max) ",
                                          is_sub: false,
                                          answers: [
                                            {
                                              id: 360,
                                              answer: "Formal,Informal",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 370,
                                              answer: "Personable,Cold hard facts",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 38,
                                              answer: "Humorous,Polite",
                                              hint: null,
                                              is_sub: false,
                                            },
                                          ],
                                        },
                                        {
                                          id: 1540,
                                          required: true,
                                          type: "select",
                                          is_sub: false,
                                          order: 3,
                                          hint: "Which method of persuasion would you like to use?*",
                                          info: null,
                                          question: "Which method of persuasion would you like to use?*",
                                          answers: [
                                            {
                                              id: 27,
                                              answer: "Empathy & compassion",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 6165,
                                              answer: "Give first, take later",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 6245654,
                                              answer: "Llimited availability",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 6345654,
                                              answer: "Tthought leadership",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 456454,
                                              answer: "cConsistent behaviour",
                                              hint: null,
                                              is_sub: false,
                                            },
                                            {
                                              id: 6456455,
                                              answer: "Eevidence-based harmony",
                                              hint: null,
                                              is_sub: false,
                                            },
                                          ],
                                        },
                                      ],
                                      section: "COMMUNICATION  TACTICS",
                                      order: 3,
                                    },
                                    id: "6",
                                    type: "form_creation",
                                  },
                                
                                      ]}
                                onCloseModal={()=>{this.setState({openParameter:false})}}
                                id="ParameterModal"
                                data-testid="parameter-modal"
                                navigation={this.props.navigation}
                                onApply={()=>{console.log("onApply")}}
                                onSave={()=>{console.log("onSave")}}
                            />
                        </Modal>
            </Box>
        </NavigationMenuComponent>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const Thinking = () => {
  return (
    <Box>
      <img src={thinkigRobo} />
      <Typography
        style={{
          fontStyle: "italic",
          color: "rgba(100, 116, 139, 1)",
          fontFamily: "Montserrat",
          fontWeight: 500,
          fontSize: "0.875rem",
        }}
      >
        Thinking...
      </Typography>
    </Box>
  );
};

const AnswerReady = () => {

  return (
    <Box style={{ display: "flex", alignItems: "end", marginLeft: "20px" }}>
      <img src={answerReadyRobo} style={{ height: "37px", width: "46.26px" }} />
      <Typography
        style={{
          fontWeight: 500,
          fontFamily: "Montserrat",
          fontSize: "14px",
          color: "rgba(100, 116, 139, 1)",
        }}
      >
        Your answer is ready!
      </Typography>
    </Box>
  );
};


const MiddleScreen = (props:any)=>{
  const {classes} = props;
  return (
    <Container className={classes.chatContainer}>
    <Box
      style={{
        flex: 1,
        padding: "25px 25px 0px 25px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <MessageRight />
      <AnswerReady />
      <MessageLeft />
      <MessageRight
        message={"What are the features of UX Design?"}
      />
      <MessageLeft
        message={
          "User experience design is the process of defining the experience a user would go through when interacting with a company, its services, and its products. Design decisions in UX design are often driven by research, data analysis, and test results rather than aesthetic preferences and opinions. User experience design is the process of defining the experience a user would go through when interacting with a company, its services, and its products. Design decisions in UX design are often driven by research, data analysis, and test results rather than aesthetic preferences and opinions. User experience design is the process of defining the experience a user would go through when interacting with a company, its services, and its products. Design decisions in UX design are often driven by research, data analysis, and test results rather than aesthetic preferences and opinions. User experience design is the process of defining the experience a user would go through when interacting with a company, its services, and its products. Design decisions in UX design are often driven by research, data analysis, and test results rather than aesthetic preferences and opinions."
        }
      />
      <Thinking />
    </Box>
  </Container>
  )
}

const styles = createStyles({
  fontStyle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  containerWrapper: {
    flex:4,
    display: "flex",
    flexDirection: "column",
    height: " 100vh",
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#000",
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#000",
    },
  },
  chatContainer: {
    // maxHeight: "62vh",
    padding: "15px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(148, 163, 184, 1)",
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(148, 163, 184, 1)",
    },
  },
  modal:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
}
});

export default withStyles(styles)(ChatHistory);
// Customizable Area End