import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  List,
  Divider,
  Drawer,
  Toolbar,
  CssBaseline,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
  IconButton
} from "@material-ui/core";
import { ThemeProvider, createStyles, createTheme, withStyles } from '@material-ui/core/styles';
import {
  openNavigation,
  closeNavigation,
  companyLogo,
  companyName,
  pebbles,
  giftIcon,
  userProfile,
  settingIcon,
  pebbleHover,
  giftIconHover,
  rightBar
} from "./assets";
import clsx from 'clsx';
import  Profile  from "../../customisableuserprofiles/src/Profile.web";
import RightMenu from "../../multipageforms/src/RightMenu.web";


const drawerWidth = 240;

const styles = (theme: any) => createStyles({
  root: {
    display: 'flex',
    position:'relative'
  },
  appBar: {
    left:15,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 7,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 13,
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow:'hidden'

  },
  drawerOpen: {
    height: '100%',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: '#000',
    color: '#fff',
  },
  drawerClose: {
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '72px',
    background: '#000',
    color: '#fff',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // display:'flex',
    width:'100%'
  },
  companyBox: {
    display: 'flex',
    margintTop: '10px'
  },
  companyLogo: {
    display: 'flex',
    gap: '10px'
  },
  listIcon: {
    color:'#fff',
    '&:hover':{
      color:'#A68B3D'
    }
  },
  listGiftIcon: {
    '&.MuiListItemIcon-root': {
      minWidth: '26px'
    }
  },
  dashboardBox: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    cursor: 'pointer',
    background: 'linear-gradient(81.72deg, #5A4C22 -8.86%, #6E602F 44.84%, #5A4D22 98.55%)',
    borderRadius: '8px',
    margin: '0 8%',
    '&:hover': {
      background: 'linear-gradient(82.51deg, #A68B3D -3.47%, #CAB354 43.23%, #A68B3D 89.94%)',
      color: '#000'
    },
    '&:active': {
      background: '#000'
    }
  },
  dashboardBoxOpen: {
    padding: '12px 30px 12px 30px'
  },
  dashboardBoxClose: {
    padding: '12px 22px'
  },
  divider: {
    background: '#475569'
  },
  shareEarn: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    background: 'linear-gradient(82.51deg, #A68B3D -3.47%, #CAB354 43.23%, #A68B3D 89.94%)',
    borderRadius: '8px',
    marginTop: '16px',
    cursor:'pointer',
    color: '#000',
    '&:hover':{
      background:'#000',
      border:'1px solid #fff',
      color:'#fff'
    }
  },
  shareEarnOpen: {
    padding: '12px 30px 12px 30px'
  },
  shareEarnClose: {
    padding: '12px 22px'
  },
  cardContainer: {
    border: '1px solid #1E293B',
    borderRadius: '10px',
    padding: '8px',
    '&:hover':{
      background: 'linear-gradient(0deg, #1E293B, #1E293B), linear-gradient(0deg, #15181E, #15181E)'
    }
  },
  inforWrapper: {
    paddingRight: '6px',
    flex:1,
    display:'flex',
    flexDirection:'column',
    justifyContent:'flex-end'
  },
  listContainer: {
    paddingRight: '6px',
    fontSize:'2rem'
  },
  listText:{
    '&:hover':{
      color:'#A68B3D'
    }
  },
  settingIcon:{
    cursor:'pointer'
  },
  activeLink: {
    color:'#A68B3D'
  },
  avatar:{
    width:'40px',
    height:'40px',
    borderRadius:'20px'
  }

});
const theme = createTheme({
  overrides:{
    MuiListItemIcon:{
      root:{
        minWidth: '26px'
      }
    },
    MuiDrawer:{
      paperAnchorDockedLeft:{
        border: 'none'
      }
    }
  },
  typography: {
    fontFamily: 'Montserrat',
    body1: {
      fontWeight: 600,
    }
  }
});

// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes }: any = this.props;
    const { open } = this.state;
    const { children } = this.props;
    return (
      <div className={classes.root}>
        <ThemeProvider theme={theme}>
     
        <Box
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
        <Box>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                data-testid="open-drawer"
                onClick={this.handleDrawer}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <Box>
                  <img src={openNavigation} alt="openMenu" />
                </Box>
              </IconButton>
            </Toolbar>
          </Box>
        </Box>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <List className={classes.listContainer}>
            <Box className={classes.companyBox}>
              <ListItem button>
                <ListItemIcon className={classes.listIcon}><img src={companyLogo} /></ListItemIcon>
                <ListItemText 
                data-testid="company-name" 
                className={clsx({
                  [classes.hide]: !open
                })}><img src={companyName} /></ListItemText>
              </ListItem>
              <IconButton data-test-id="handelDrawer" onClick={this.handleDrawer}>
                {open && <img src={closeNavigation} alt="close-menu" />}
              </IconButton>
            </Box>
          </List>
          <List className={classes.listContainer}>
            <Box
              className={clsx(classes.dashboardBox, {
                [classes.dashboardBoxOpen]: open,
                [classes.dashboardBoxClose]: !open,
              })}
              data-testid="dashboard-button"
              onClick={this.handleDashboard}
              onMouseOver={this.handleImageHover}
              onMouseOut={this.handleImageHoverOut}
            >
              <ListItemIcon className={classes.listIcon}>
                {this.state.imageHover ?
                  <img src={pebbleHover} alt="Dashboard" data-testid="dashboard-hover"/> :
                  <img src={pebbles} alt="Dashboard" data-testid="dashboard-hover-out"/>
                }
              </ListItemIcon>
              <Typography 
                variant="body1"
                className={clsx({
                  [classes.hide]: !open
                  })}>
                  {configJSON.dashbord}
              </Typography>
            </Box>
          </List>
          <List className={classes.listContainer}>
          {this.state.menuItems.map((list,index) => {
            return(
              <Box key={index} className={clsx(classes.listText)}>
               <ListItem
              button
              onMouseOver={() => this.handleHover(list.name)}
              onMouseOut={() => this.handleHoverOut(list.name)}
              data-testid={"list-" + index}
              onClick={() => this.handleMenuRedirect(list.redirect,index)}
              selected={list.active}
              className={list.active ? classes.activeLink : classes.listText}
              >
              <ListItemIcon className={classes.listIcon}>
              {(this.state.menuHoverStates[list.name] || list.active) ? <img src={list.hoverIcon} alt={list.name} data-testid={"image-hover-" + index}/> : <img src={list.icon} alt={list.name} data-testid={"image-" + index}/>}
                </ListItemIcon>
              <ListItemText 
              className={clsx(classes.listText, {
                [classes.hide]: !open,
              })}
              >
                <Typography style={{fontSize: index > 6 ? '0.87rem': '1rem'}}>{list.name}</Typography></ListItemText>
            </ListItem>
            {list.drawer && <Divider className={classes.divider} />}
              </Box>
            )
          })}
          </List>
          <List className={classes.inforWrapper}>
            <Box
              className={clsx(classes.shareEarn, {
                [classes.shareEarnOpen]: open,
                [classes.shareEarnClose]: !open,
              })}
              onMouseOver={this.handleShareHover}
              onMouseOut={this.handleShareHoverOut}
              data-testid = "gift_listItem"
              onClick={() => this.handleMenuRedirect("ShareAndEarn", 0)}
            >
              <ListItemIcon className={classes.listGiftIcon}>
                {this.state.shareHover ? <img src={giftIconHover} alt="Share" data-testid = "gift_hover"/> : <img src={giftIcon} alt="Share" data-testid= "gift_hover_out"/>}</ListItemIcon>
              <ListItemText 
              className={clsx({
                  [classes.hide]: !open
                  })}>{configJSON.shareEarn}</ListItemText>
            </Box>
          </List>
          <List className={classes.listContainer}>
            <Grid onClick={this.openProfileModal} data-testid="settings" container justifyContent="space-between" alignItems="flex-end" className={classes.cardContainer}>
                <Grid item xs={12} sm={7} 
                className={clsx({
                  [classes.hide]: !open
                  })}>
                  <img src={this.state.avatar||userProfile} alt="userImage" className={classes.avatar} />
                  <Typography>{this.state.profileDetails?.attributes?.account?.full_name}</Typography>
                  <Typography>{this.state.firstEmailPart}</Typography>
                  <Typography>{this.state.secondEmailPart}</Typography>
                </Grid>
              <Grid item xs={12} sm={2}>
                <img src={settingIcon} alt="setting" className={classes.settingIcon}/>
              </Grid>
            </Grid>
            <Profile {...this.props} onReloadData={this.getProfileDetails} visible={this.state.profileModalVisible} onClose={this.closeProfileModal} openDialogVisible={this.openProfileModal} data-testid="profile-modal"/>
          </List>
        </Drawer>
        {this.props.rightMenu ? 
        <Box style={{ display: "flex", width: "100%",zIndex:'9999' }}>
        <main className={classes.content}>
          {children}
        </main>
        <RightMenu data-testid="right-menu-open" {...this.props} handleCloseRightMenu={this.props.handleRightMenu}/> 
        </Box> :
         <main className={classes.content}>
         {children}
       </main>
        }
        
        </ThemeProvider>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const NavigationMenuComponent = withStyles(styles, { withTheme: true })(NavigationMenu);
// Customizable Area End
