import React from "react";
import ForgotPwdController from "./ForgotPwdController";
import { createStyles, withStyles, Typography } from "@material-ui/core";
import { checkOn, gtmsLogo } from "./assets";

const styles = () =>
  createStyles({
    successContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "#3A84C90D",
    },
    logoImg: {
      position: "absolute",
      left: 0,
      top: 0,
      mixBlendMode: "multiply"
    },
    successBox: {
      width: "35%"
    },
    resetHeader: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      marginBottom: "35px"
    },
    updatedBox: {
      padding: "10px 12px",
      borderRadius: "8px",
      background: "#FFF",
      boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
      marginBottom: "35px",
      display: "flex",
      alignItems: "center"
    },
    backText: {
      color: "#0F172A",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px"
    },
    logInText: {
      color: "#3A84C9",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      textDecorationLine: "underline",
      cursor: "pointer"
    },
    updatedText: {
      color: "#000",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px"
    },
    checkIcon: {
      height: "20.8px",
      width: "20.8px",
      marginRight: "10px"
    }
  });

class ResetSuccess extends ForgotPwdController {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.successContainer}>
        <img src={gtmsLogo} className={classes.logoImg} />
        <div className={classes.successBox}>
          <Typography className={classes.resetHeader}>
            Password reset successful
          </Typography>
          <div className={classes.updatedBox}>
            <img src={checkOn} className={classes.checkIcon} />
            <Typography className={classes.updatedText}>
              Awesome, You’ve successfully updated your password
            </Typography>
          </div>
          <Typography className={classes.backText}>
            Back to{" "}
            <span
              className={classes.logInText}
              onClick={() => this.goToLogin()}
            >
              Log in
            </span>
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(ResetSuccess);
