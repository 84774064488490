import * as Yup from "yup";

export const SignupSchema = Yup.object().shape({
  code: Yup.string().required("Code should not be blank"),
  termAndCondition: Yup.boolean()
    .required()
    .oneOf([true], "You must agree to our Terms of Use and Privacy Policy")
});


export const StartedSchema = Yup.object().shape({
  fullname:Yup.string().required("Full name should be entere"),
  Whatsyourrole:Yup.string().required("Select your role"),
  email: Yup.string()
    .email("Email address is not valid.")
    .required("Email should not be blank")

})
export const SignupEmailSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email address is not valid.")
    .required("Email should not be blank.")
});

export const accountSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your full name."),
  cName: Yup.string().required("Please enter your company name."),
  password: Yup.string()
    .matches(
       /^(?=.*[0-9])(?=.*[!@#$%^&*()_+])(?!.*\s).{8,}$/,
      "Password is too weak. Include at least 1 number and 1 special character."
    )
    .required("Password should not be blank.")
});

export const urlRegex = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;


export const companyAboutSchema = Yup.object().shape({
  size: Yup.string().required("Please select a company size."),
  aboutUs:Yup.string().required("Please select hear about us."),
  selectedCheckboxIds: Yup.array().min(1, 'Please select GTM challenge.'),
});


export const verifySignUpCodeSchema = Yup.object().shape({
  email: Yup.string()
  .email("Email address is not valid.")
  .required("Email address is required."),
signupCode: Yup.string()
  .required("Signup code should not be blank."),termsAndConditions: Yup.boolean()
  .oneOf([true], 'Please accept the Privacy Policy and Terms of Use.') 
  .required('Please accept the Privacy Policy and Terms of Use.'),
});

export const CompanySchema = Yup.object().shape({
  describes: Yup.string().required("Please select best describes your company."),
  location: Yup.string().required("Please select a country."),
  website: Yup.string().matches(urlRegex,"This link is invalid").required("Please enter company website."),
});

export const LetgetSchema = Yup.object({
  fullname: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, 'Please enter only alphabets.')
    .required('Please enter full name.'),
  role: Yup.string().required('Please select a role.'),
  email: Yup.string()
    .email('Please enter a valid email.')
    .required('Email should not be blank.'),
  password: Yup.string()
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*()_+])(?!.*\s).{8,}$/,
      "Password is too weak. Include at least 1 number and 1 special character."
    )
    .required("Password should not be blank")
});

export const aboutTheCompanySchema = Yup.object().shape({
  formType:Yup.string(),
  missionStatement: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(150, "Maximum 150 characters allowed")
    .required("This should not be blank."),
  usp: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(200, "Maximum 200 characters allowed")
    .required("This should not be blank."),
  primaryFearQuestion1: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(50, "Maximum 50 characters allowed")
    .required("This should not be blank."),
  primaryFearQuestion2: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(50, "Maximum 50 characters allowed")
    .required("This should not be blank."),
  primaryFearQuestion3: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(50, "Maximum 50 characters allowed")
    .required("This should not be blank."),
  offering1: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(30, "Maximum 30 characters allowed")
    .required("This should not be blank."),
  description1: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(350, "Maximum 350 characters allowed")
    .required("This should not be blank."),
  offering2: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(30, "Maximum 30 characters allowed")
    .required("This should not be blank."),
  description2: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(350, "Maximum 350 characters allowed")
    .required("This should not be blank."),
  offering3: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(30, "Maximum 30 characters allowed")
    .required("This should not be blank."),
  description3: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(350, "Maximum 350 characters allowed")
    .required("This should not be blank."),
  companyAchivements: Yup.array()
  .max(3, "You can't add more than 3 fields.")
  .of(Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
  .max(40, "Maximum 40 characters allowed")),
  keyCompanyFacts: Yup.array().of(
    Yup.string()
    .matches(/^[^\s].*$/, 'Cannot start with spaces')
      .max(50, "Maximum 50 characters allowed")
  ),
  companyEvalutionTime: Yup.array().of(
    Yup.string()
    .matches(/^[^\s].*$/, 'Cannot start with spaces')
      .max(300, "Maximum 300 characters allowed")
  ),
  companyHeadquarters: Yup.array().of(
    Yup.string()
    .matches(/^[^\s].*$/, 'Cannot start with spaces')
      .max(150, "Maximum 150 characters allowed")
  ),
  mqlStatement: Yup.array().of(
    Yup.string()
    .matches(/^[^\s].*$/, 'Cannot start with spaces')
      .max(200, "Maximum 200 characters allowed")
  ),
  contributeToSocity: Yup.array().of(
    Yup.string()
    .matches(/^[^\s].*$/, 'Cannot start with spaces')
      .max(200, "Maximum 200 characters allowed")
  ),
  primaryUtilityHook: Yup.array().of(
    Yup.string()
    .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(160, "Maximum 160 characters allowed"),
  ),
  whyExist: Yup.array().required("Min 10 and max 20 words")
        .min(10, "Minimum 10 words allowed")
        .max(20, "Maximum 20 words allowed"),
  companyValue1: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
  .max(40, "Maximum 40 characters allowed"),
  companyDescription1: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
  .max(140, "Maximum 140 characters allowed"),
  companyValue2: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
  .max(40, "Maximum 40 characters allowed"),
  companyDescription2: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
  .max(140, "Maximum 140 characters allowed"),
  companyValue3: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
  .max(40, "Maximum 40 characters allowed"),
  companyDescription3: Yup.string()
  .matches(/^[^\s].*$/, 'Cannot start with spaces')
  .max(140, "Maximum 140 characters allowed"),
  visionStatement:Yup.array().of(
    Yup.string()
    .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(150, "Maximum 150 characters allowed"),
  ),
  companyTestimonial:Yup.array().of(
    Yup.string()
    .matches(/^[^\s].*$/, 'Cannot start with spaces')
    .max(40, "Maximum 40 characters allowed"),
    )
 
});
