import { Box, Button, Typography, TextField } from "@material-ui/core";
import React from "react";
import SignUpController, { Props } from "./SignUpController";
import { Formik } from "formik";
import { SignupEmailSchema } from "../utils/schema";
import {
  ThemeProvider,
  createStyles,
  createTheme,
  withStyles
} from "@material-ui/core/styles";
import Layout from "../../../components/src/Layout";
import CarouselDisplay  from "../../../blocks/carouseldisplay/src/CarouselDisplay.web";
import { GoogleImg } from "./assets";

const styles = () =>
  createStyles({
    layoutContainer: {
      display: "flex",
      flexDirection: "column",
      flex:1,
      '@media (min-width: 600px)' : {
        justifyContent: "center",
        alignItems: "center",
      },
    },
    emailContainer: {
      margin:'0 40px',
      border: "1px solid #CBD5E1",
      background:
        "linear-gradient(145deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
        padding:'20px',
      '@media (min-width: 600px)' : {
        padding: "20px",
        width: "60%",
      },
      '@media (min-width: 1300px)' : {
        margin: 0,
        width: "438px",
        padding: "40px"
      },
      display: "flex",
      flexDirection: "column",
      borderRadius: "28px",
    },
    sigupText: {
      textAlign: "center",
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "6px",
      '@media (min-width: 1300px)' : {
        marginBottom: "12px",
      },
      color: "black"
    },
    subTitle: {
      textAlign: "center",
      fontSize: "14px",
      lineHeight: 1.5,
      fontWeight: 500,
      color: "#475569",
      display: "inline-block"
    },
    loginText: {
      display: "inline-block",
      textAlign: "center",
      fontSize: "14px",
      lineHeight: 1.5,
      fontWeight: 700,
      color: "#3A84C9",
      marginLeft: "4px",
      cursor: "pointer",
      fontFamily: "Montserrat,sans-serif",
      "&:hover":{
        textDecorationLine:'underline',
      }
    },
    form: {
      margin: "0"
    },
    label: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      color: "black",
      marginTop: "16px",
      '@media (min-width: 1300px)' : {
        marginTop: "32px",
      },
    },
    input: {
      height: "42px",
      marginTop: "4px",
      borderRadius: " 8px",
      border: "1px solid rgba(140, 140, 140, 0.20)",
      overflow:'hidden',
      background: "#FFF",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "transparent",
          height: "44px"
        },
        "&:hover fieldset": {
          borderColor: "transparent"
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent"
        },
        "& .MuiOutlinedInput-input": {
          padding: "0 12px",
          lineHeight: "21px",
          color: "#64748B",
          fontSize: "14px",
          fontWeight: 500
        },

        "& .MuiInputBase-input::placeholder": {
          opacity: 1
        },

        "& .MuiInputBase-input": {
          height: "44px"
        }
      }
    },
    button: {
      height: "44px",
      borderRadius: "8px",
      fontWeight: 600,
      fontSize: "12px",
      '@media (min-width: 600px)' : {
        fontSize: "16px"
      },
      lineHeight: 1.2,
      background: "#000",
      textTransform: "none",
      color: "white",
      margin: "16px 0",
      '@media (min-width: 1300px)' : {
        margin: "32px 0",
      },
      "&:hover": {
        background: "#000"
      }
    },
    divider: {
      width: "100%",
      display: "flex",
      height: 21,
      justifyContent: "center",
      position: "relative",
      alignItems: "center"
    },
    dividerTextWrapper: {
      padding: "0 37.5px",
      position: "relative"
    },
    dividerText: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#475569"
    },
    hr: {
      background: "#CBD5E1",
      height: "1px",
      flex: 1
    },
    googlebtn: {
      minHeight: "42px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: 1.2,
      marginTop: "16px",
      '@media (min-width: 1300px)' : {
        marginTop: "32px",
      },
      textTransform: "none",
      border: "1px solid rgba(140, 140, 140, 0.20)",
      background: "#FFF",
      boxShadow: "none",
      width: "100%",
      "&:hover": {
        background: "#FFF"
      },
    },
    googleTxt: {
      color: "#000",
      marginLeft: "8px",
      fontWeight: 600,
      fontStyle: "normal",
      fontSize: "12px",
      '@media (min-width: 600px)' : {
        fontSize: "16px"
      },
    },
    terms: {
      display: "flex",
      alignItems: "center",
      marginTop: "30px",
      marginBottom: "3%"
    },
    checkbox: {
      width: "20px",
      height: "20px",
      "& .MuiCheckbox-root": {
        color: "#64748B"
      }
    },
    agreement: {
      color: "#0F172A",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      fontStyle: "normal",
      fontFamily: "Inter"
    },
    link: {
      color: "#3A84C9",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      textDecoration: "underline",
      paddingBottom: "3px",
      cursor: "pointer"
    },
    errorText: {
      color: "rgb(179, 23, 23)",
      fontSize: "13px",
      marginTop: "2px"
    },
    validateError: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#F87171",
      marginTop: "2px"
    }
  });

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif"
  }
});

type Values = {
  email: string;
  termAndCondition: boolean;
};

// initial formik values
const initialValues: Values = { email: "", termAndCondition: false };

export class SignUpEmail extends SignUpController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Layout
        back={this.onBackChooseOption}
        carousel={<CarouselDisplay type="individual" id="" navigation={{}}/>}
          children={
            <Box className={classes.layoutContainer}>
              <Box className={classes.emailContainer}>
                
                <Typography className={classes.sigupText}>Sign up</Typography>
                <Typography className={classes.subTitle}>
                  Create an account to access Pebbles Ai.
                </Typography>
                <Typography className={classes.subTitle}>
                  Already have an account?
                  <span data-test-id='loginBtn' onClick={()=>this.goToLogin()} className={classes.loginText}>
                    {` Log in`}
                  </span>
                </Typography>
                <Formik
                  data-testid="signupEmailFormik"
                  initialValues={initialValues}
                  validationSchema={SignupEmailSchema}
                  onSubmit={values => {
                    this.doSignup(values.email);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      data-test-id="formSubmit"
                      style={{ marginBottom: 0 }}
                    >
                      <Typography
                        className={classes.label}
                        data-testid="emailField"
                      >
                        Email
                      </Typography>
                      <TextField
                        data-test-id="txtInputEmail"
                        id="email"
                        type="text"
                        variant="outlined"
                        fullWidth
                        value={values.email}
                        onFocus={this.onClose}
                        onChange={handleChange}
                        placeholder="Provide an email address"
                        className={classes.input}
                        inputProps={{
                          style: {
                            fontFamily: "Montserrat",
                          }
                        }}
                        style={{
                          borderColor:
                            this.state.errors|| (touched.email && errors.email) ? "#F87171" : ""
                        }}
                      />
                      {!!touched.email && !!errors.email && (
                        <Typography className={classes.validateError}>
                          {errors.email}
                        </Typography>
                      )}
                      {!!this.state.errors && (
                        <Typography className={classes.validateError}>
                          {this.state.errors}
                        </Typography>
                      )}
                      <Button
                        variant="contained"
                        fullWidth
                        type="submit"
                        className={classes.button}
                      >
                        Continue with email
                      </Button>
                      <Box className={classes.divider}>
                        <div className={classes.hr} />
                        <Box className={classes.dividerTextWrapper}>
                          <Typography className={classes.dividerText}>
                            or
                          </Typography>
                        </Box>
                        <div className={classes.hr} />
                      </Box>
                      <Button variant="contained" className={classes.googlebtn}>
                        <img src={GoogleImg} alt="googlelogo" />
                        <Typography
                          className={classes.googleTxt}
                          data-testid="googleLoginBtn"
                          onClick={this.socialGoogleLogin}
                        >
                        Continue with Google
                        </Typography>
                      </Button>
                    </form>
                  )}
                </Formik>
              </Box>
            </Box>
          }
        />
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(SignUpEmail);
