import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
import {
  copilot,
  tactical,
  library,
  workflow,
  history,
  explor,
  companyIntel,
  copilotHover,
  tacticalHover,
  libraryHover,
  workflowHover,
  historyHover,
  explorHover,
  companyIntelHover,
  edit,
  editHovered,
  upload,
  uploadHovered
} from "./assets";
interface MenuState {
  [key: string]: boolean;
  // Define other menu item hover states as needed
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  children: React.ReactNode;
  activeIndex:number;
  handleRightMenu?:() => void;
  rightMenu?:boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  drawerItems: any;
  activeTab:number;
  avatar:string;
  open: boolean;
  imageHover:boolean;
  shareHover:boolean;
  profileModalVisible:boolean;
  userDetails:{
    id:string,
    type:string,
    attributes:
    {
      activated:boolean,
      email:string,
      full_name:string,
      company_name:string
    }
  };
  menuItems:{
    name:string,
    icon:string,
    hoverIcon:string,
    drawer:boolean,
    redirect:string,
    active:boolean
  }[];
  menuHoverStates: MenuState;
  firstEmailPart:string
  secondEmailPart:string;
  authToken:string;
  profileDetails:{
    id: string,
    type: string,
    attributes:{
      account:{
        full_name:string,
        email:string
      }
    }
  };
  anchorEl:null | HTMLElement;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  apiMyProfileMessageId:string='';
  displayProfileDetails : string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.displayProfileDetails = "";
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      firstEmailPart:"",
      secondEmailPart:"",
      webDrawer: false,
      drawerItems: [],
      activeTab:0,
      avatar:"",
      open:false,
      imageHover:false,
      shareHover:false,
      profileModalVisible:false,
      userDetails:{
        id:"",
        type:"",
        attributes:
        {
          activated:false,
          email:"",
          full_name:"",
          company_name:""
        }
      },
      menuItems:[
        {
          name:"Pebbles Copilot",
          icon:copilot,
          hoverIcon:copilotHover,
          drawer:false,
          redirect:'ChatHistory',
          active:false
        },
        {
          name:"Tactical Outreach",
          icon:tactical,
          hoverIcon:tacticalHover,
          drawer:true,
          redirect:'Catalogue',
          active:false
        },
        {
          name:"My Library",
          icon:library,
          hoverIcon:libraryHover,
          drawer:false,
          redirect:'MyLibrary',
          active:false
        },
        {
          name:"My Workflows",
          icon:workflow,
          hoverIcon:workflowHover,
          drawer:false,
          redirect:'Myworkflow',
          active:false
        },
        {
          name:"History",
          icon:history,
          hoverIcon:historyHover,
          drawer:true,
          redirect:'History',
          active:false
        },
        {
          name:"Explore Upgrades",
          icon:explor,
          hoverIcon:explorHover,
          drawer:false,
          redirect:'SubscriptionExploreUpgrade',
          active:false
        },
        {
          name:"Company Intel",
          icon:companyIntel,
          hoverIcon:companyIntelHover,
          drawer:true,
          redirect:'CompanyIntel',
          active:false
        },
        {
          name:"Upload new docs",
          icon:upload,
          hoverIcon:uploadHovered,
          drawer:false,
          redirect:'BulkUploadingImport',
          active:false
        },
        {
          name:"Edit onboarding",
          icon:edit,
          hoverIcon:editHovered,
          drawer:true,
          redirect:'AboutTheCompany',
          active:false
        }
      ],
      menuHoverStates: {},
      authToken:"",
      profileDetails:{
        id:"",
        type:"",
        attributes:
        {
          account:{
            full_name:"",
            email:"",
          }
          
        }
      },
      anchorEl:null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if (this.displayProfileDetails === requestCallId ) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson.data) {
          const atIndex = responseJson.data.attributes.account.email.indexOf('@');
        const firstPart = responseJson.data.attributes.account.email.slice(0, atIndex);
        const secondPart = responseJson.data.attributes.account.email.slice(atIndex);
        const photo = responseJson.data.attributes.profile_photo;
          this.setState({
            profileDetails: responseJson.data,
            firstEmailPart:firstPart,secondEmailPart:secondPart,
            avatar:photo
          })
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    if(window.innerWidth > 900){
      this.setState({open:true})
    }
    this.setState({
      authToken: await StorageProvider.get("user-token")
    })
    this.getProfileDetails();
    this.handleActiveMenu(this.props.activeIndex)
  } 

  handleDrawer = () => {
    this.setState({ open: !this.state.open });
  };
 
  handleDashboard = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'LandingPage'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }
  handleImageHover = () =>{
    this.setState({imageHover:true})
  }
  handleImageHoverOut = () => {
    this.setState({imageHover:false})
  }
  handleShareHover = () => {
    this.setState({shareHover:true})
  }
  handleShareHoverOut = () => {
    this.setState({shareHover:false})
  }

  closeProfileModal=()=>{
    this.setState({profileModalVisible:false})
  }

  openProfileModal=async()=>{
    this.setState({profileModalVisible:true})
    await StorageProvider.set("user_email", this.state.profileDetails.attributes.account.email);
  }
  
  handleHover = (itemName: string) => {
    this.setState((prevState) => ({
      menuHoverStates: {
        ...prevState.menuHoverStates,
        [itemName]: true,
      },
    }));
  };
  handleHoverOut = (itemName: string) => {
    this.setState((prevState) => ({
      menuHoverStates: {
        ...prevState.menuHoverStates,
        [itemName]: false,
      },
    }));
  };
  handleMenuRedirect = async (redirect:string,index:number) => {
    await StorageProvider.set("isEdit",'true');
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      redirect
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
    this.handleActiveMenu(index)
  }
  handleActiveMenu = (index:number) => {
    const updatedMenuItems = this.state.menuItems.map((item, i) => {
      if (i === index) {
        return { ...item, active: true };
      } else {
        return { ...item, active: false };
      }
    });
    this.setState({ menuItems: updatedMenuItems });
  }
  getProfileDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      "token": this.state.authToken
    };
    this.displayProfileDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.getMyProfileAPiEndPointURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRightMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({anchorEl : event.currentTarget});
  }

  handleCloseRIghtMenu = () => {
    this.setState({anchorEl:null})
  }
  // Customizable Area End
}
