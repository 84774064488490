import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";

export const configJSON = require("./config");
import { Values } from "./AccountDetail.web";
import { CompanyValues } from "./CompanyDetail.web";
import StorageProvider from "../../../framework/src/StorageProvider";
import {countryFile} from './assets'

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
  email?: string;
}

export interface S {
  isAgreed: boolean;
  enablePasswordField: boolean;
  email: string|null;
  errors:any;
  isLoad:boolean;
  companyDes:any[];
  locationList: any[];
}

export interface SS {
  id: any;
}

export default class AccountDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  createAccountId: string = "";
  createCompanyAccountId: string = "";
  fetchDescribesCompanyId: string = "";
  fetchCompanyLocationId: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    this.receive = this.receive.bind(this);
    const countries = this.jsonToCompanyArray(countryFile)
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isAgreed: false,
      enablePasswordField: true,
      email:"",
      errors:"",
      isLoad:false,
      companyDes: [{ id: "", name: "Select response" }],
      locationList:countries
    };
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {

        switch (apiRequestCallId) {
          case this.createAccountId:
            this.setState({isLoad:false})         
            const msg: Message = new Message(
              getName(MessageEnum.NavigationAccountAboutMessage)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
            break;
          case this.createCompanyAccountId:
            this.setState({isLoad:false})
            if (!responseJson.error) {
              const message: Message = new Message(getName(MessageEnum.NavigationMessage))
              message.addData(
                getName(MessageEnum.NavigationTargetMessage),
                'CompanyAbout'
              );
              message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
              this.send(message);             
            }

            break;
          case this.fetchDescribesCompanyId:
            this.handleCompanyDesAPI(responseJson);         
            break;
            case this.fetchCompanyLocationId:
              this.handleCompanyLocationListAPI(responseJson);
              break;
        }

    }
  }
}

  async componentDidMount(){
    const token = await StorageProvider.get("user-token")
    if (!token) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
    }
    const email=window.localStorage.getItem("user-email")
    this.setState({email})
    this.fetchCompanyDesAbout()
    this.fetchCompanyLocationList()
  }


  onBackCreatAccount = ()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'VerifySignUpCode'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleCompanyDesAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data) {
        let newArr: any[] = []
        responseJson.data.forEach((companyDes: any) => {
          newArr.push(companyDes)
        })
        this.setState({ companyDes: [...this.state.companyDes, ...newArr] })
      }
    } 
  }

  onClose = () => {
    this.setState({errors:""});
  };

  handleClickShowPassword() {
    this.setState({ enablePasswordField: !this.state.enablePasswordField });
  }

  fetchCompanyDesAbout(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchDescribesCompanyId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDescribesCompanyEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleCompanyLocationListAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data) {
        this.setState({locationList:this.jsonToCompanyArray(countryFile)})
      }
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);
      this.setState({ errors })
    }
  }
  
  jsonToCompanyArray=(jsonString:{[key:string]:string})=> {
      const jsonObject = jsonString;
      const idNamePairs =  Object.values(jsonObject).map((key,index) => ({ id: index, name: key }));      
      return idNamePairs;
  }


  fetchCompanyLocationList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchCompanyLocationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


 async createAccount(values: Values) {
    const { cName, name, password } = values;
    if (!name || !cName || !password) return false;
    const token = await StorageProvider.get("user-token")
    this.setState({isLoad:true})

    const body = {
      full_name: name,
      company_name: cName,
      password,
      password_confirmation:password
    };

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAccountId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

 async createCompanyAccount(values: CompanyValues) {
    const { describes, location, website } = values;
    if (!describes || !location || !website) return false;

    const token = await StorageProvider.get("user-token")
    this.setState({isLoad:true})
    const body = {
      description: {
        describe: describes,
        location: location,
        website: website
      }
    };
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCompanyAccountId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createDescriptionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
}
