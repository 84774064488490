import { WithStyles } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import {parseErrors} from '../../utilities/src/Toast'
import firebase from "firebase/app";
import "firebase/auth";

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
  email?: string;
}

export const configJSON = require("./config");

export interface S {
  isAgreed: boolean;
  email:string|null;
  errors:any;
  message:any;
  isLoad: boolean;
  privacyPol:any;
  termAndCond:any;
}

export interface SS {
  id: any;
}

export default class SignUpOtpController extends BlockComponent<Props, S, SS> {
  createAccountApiCallId: any;

  apiOtpSignupCallId: string = "";
  apiResendOtp: string = "";
  apiGoogleLoginCallId: string = "";
  getPrivacyPolMessageId: string = "";
  getTermAndCondMessageId: string = "";

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isAgreed: false,
      email:"",
      errors:"",
      message:"",
      isLoad:false,
      privacyPol:{
        isModalVisible:false,
        loading:false,
        data:[]
      },
      termAndCond:{
        isModalVisible:false,
        loading:false,
        data:[]
      }
    };
  }

  async componentDidMount(){
    const email=window.localStorage.getItem("user-email")
    this.setState({email})
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiOtpSignupCallId) {
          this.setState({isLoad:false})
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.NavigationCreateAccountMessage)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            if (responseJson.data) {
              window.localStorage.setItem("user-data", JSON.stringify(responseJson.data));
              window.localStorage.setItem("user-token", responseJson.meta.token);
            }

            this.send(msg);
          } else {
            //Check Error Response
            const errors=parseErrors(responseJson)
            this.setState({errors})
          }
        }
        
        if(message.properties.RestAPIResponceDataMessage ===
          this.getPrivacyPolMessageId){
            if (!responseJson.errors) {
              this.setState({privacyPol:{...this.state.privacyPol,loading:false,data:message.properties.RestAPIResponceSuccessMessage.data}})
            }
      }
      if(message.properties.RestAPIResponceDataMessage ===
        this.getTermAndCondMessageId){
          if (!responseJson.errors) {
            this.setState({termAndCond:{...this.state.termAndCond,loading:false,data:message.properties.RestAPIResponceSuccessMessage.data}})
          }
    }
      }
      
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiResendOtp) {
          if (!responseJson.errors) {
            this.setState({message:"Otp resent"})
          } else {
            //Check Error Response
            const errors=parseErrors(responseJson)
            this.setState({errors})
          }
        }
      }
      if (apiRequestCallId === this.apiGoogleLoginCallId) {
         
        if (responseJson && responseJson.meta && responseJson.meta.token) {
          window.localStorage.setItem("user-token", responseJson.meta.token);
          this.props.navigation.navigate("Profile");
        } else {
          const errors = parseErrors(responseJson);
          this.setState({ errors: errors ? errors : responseJson.error });
        }
      }
    }
  }

    //Google Login
    initiateGoogleLogin = async () => {
      const auth = firebase.auth();
      const provider = new firebase.auth.GoogleAuthProvider();
      try {
        const result = await auth.signInWithPopup(provider) as any;
        const token = await result.user.getIdToken() ;
        this.doGoogleLogin(token,result.user.email)
      } catch (error) {
        const errorMessage = (error as any).message;
        this.setState({ errors: errorMessage });
      }
    };
  
    doGoogleLogin = async (token: string, email: string) => {
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail
      };
  
      const httpsBody = {
        data: {
          type: "social_account",
          attribute: {
            email: email,
            unique_auth_id: token
          }
        }
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiGoogleLoginCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.googleLoginAPIEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpsBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
  
      return true;
    };

  handleCheck = () => {
    this.setState({ isAgreed: !this.state.isAgreed });
  };

  onClose = () => {
    this.setState({errors:"",message:""});
  };


  validateOtp(code: string): boolean {
    if (!code) return false

    const token = window.localStorage.getItem("user-token");
    const body = {
      otp: code
    };
    
    this.setState({isLoad:true})

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiOtpSignupCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.validateApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  
  getPrivacyPolData=()=>{
    const endPoint = configJSON.getPrivacyPolicyApiEndPoint
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const apiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPrivacyPolMessageId = apiMessage.messageId;

    apiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(apiMessage.id, apiMessage);
  }

  resendOtp=()=>{
    const {email}=this.state

    if(!email) return false

    window.localStorage.setItem(
      "user-email",
      email
    );

    const body = {
      email
    };

    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    const dataBody = {
      type: "email_account",
      attribute: body
    };

    const httpsBody = {
      data: dataBody
    }; 
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage) ); 
    
    this.apiResendOtp = requestMessage.messageId;

    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage), 
    configJSON.signupApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpsBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  getTermAndCondData=()=>{
    this.setState({termAndCond:{...this.state.termAndCond,loading:true}})

    const endPoint = configJSON.getTermAndConditionApiEndPoint
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const apiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTermAndCondMessageId = apiMessage.messageId;

    apiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(apiMessage.id, apiMessage);
  }
}
