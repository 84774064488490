import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { AboutValues } from "./CompanyAbout.web";
import StorageProvider from '../../../framework/src/StorageProvider';
import { countryFile } from './assets'
export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
  email?: string;
}

export interface S {
  companySize: any[];
  industry: any[];
  roleList: any[];
  locationList: any[];
  errors: any;
  isLoad: boolean;
  hereAbout: any[];
  gtmChallenge: any[];
  gtmChallengeValue: any[];
  displayCount: number;
  initialDisplayCount: number;
}

export interface SS {
  id: any;
}

export default class AccountDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchCompaniesId: string = "";
  fetchLocationId: string = "";
  fetchIndustriesId: string = "";
  apiPostAccountId: string = "";
  apiCompanyAboutPost: string = "";
  fetchCompanyHereAboutId: string = "";
  fetchGtmChallengeId: string = "";
  roleMessageId:string = '';
  targetScreen:string='';

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    const countries = this.jsonToArray(countryFile)
    this.state = {
      companySize: [{ id: "", size: "Select response" }],
      industry: [{ id: "", industry_name: "Select response" }],
      hereAbout: [{ id: "", name: "Select response" }],
      errors: "",
      isLoad: false,
      roleList: [
        { id: "", name: "Select response" },
      ],
      locationList: countries,
      gtmChallenge: [],
      gtmChallengeValue: [],
      initialDisplayCount: 2,
      displayCount: 2,

    };
  }


  onBackCreatAccount = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'CreateAccount'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleRoleListAPI = (responseJson: any) => {
    if (responseJson.errors) {
      const errors = this.parseApiErrorResponse(responseJson);
      this.setState({ errors })
    } else if(responseJson?.data?.roles) {
        this.setState({ roleList: [...this.state.roleList,...responseJson?.data?.roles] })
    }
  }

  componentDidMount = async () => {
    const token = await StorageProvider.get("user-token");
    if (!token) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );

      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

      this.send(msg);
      return;
    }
    const targetScreen = await StorageProvider.get("target-screen");
    this.targetScreen=targetScreen;
    this.fetchCompanySize()
    this.fetchIndustries()
    this.fetchLocationList()
    this.fetchCompanyHereAbout()
    this.fetchGtmChallenges()
    this.fetchRoleList();
  }

  async receive(from: string, message: Message) {
    this.setState({ isLoad: false })
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchCompaniesId:
        this.handleCompanyAPI(responseJson);
        break;
      case this.fetchIndustriesId:
        this.handleIndustyAPI(responseJson);
        break;
      case this.fetchCompanyHereAboutId:
        this.handleCompanyHereAPI(responseJson);
        break;
      case this.apiPostAccountId:
        if (!responseJson.errors) {
          StorageProvider.set("isBusiness","");
          const message: Message = new Message(getName(MessageEnum.NavigationMessage))
          message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'ChooseYourPath'
          );
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
          this.send(message);
        } else {
          //Check Error Response
          const errors = this.parseApiErrorResponse(responseJson);
          this.setState({ errors })
        }
        break;
      case this.fetchLocationId:
        this.handleLocationListAPI(responseJson);
        break;
      case this.roleMessageId:
        this.handleRoleListAPI(responseJson);
        break;
      case this.fetchGtmChallengeId:
        this.handleGtmChallengesListAPI(responseJson);
        break;
      case this.apiCompanyAboutPost:
        if (!responseJson.errors) {
          const message: Message = new Message(getName(MessageEnum.NavigationMessage))
              message.addData(
                getName(MessageEnum.NavigationTargetMessage),
                'CompanySuccess'
              );
              message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
              this.send(message);
        } else {
          //Check Error Response
          const errors = this.parseApiErrorResponse(responseJson);
          this.setState({ errors })
        }
        break;
    }
  }

  fetchRoleList(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.roleMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.roleListEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleCheckboxChange = (id: any) => {
    this.setState((prevState) => {
      const gtmChallengeValue = prevState.gtmChallengeValue.includes(id)
        ? prevState.gtmChallengeValue.filter((checkboxId) => checkboxId !== id)
        : [...prevState.gtmChallengeValue, id];

      return { gtmChallengeValue };
    });

    const updatedGtmChallengeValue = this.state.gtmChallengeValue.includes(id)
      ? this.state.gtmChallengeValue.filter((checkboxId) => checkboxId !== id)
      : [...this.state.gtmChallengeValue, id];

    return updatedGtmChallengeValue

  };

  handleToggleDisplay = () => {
    this.setState((prevState) => ({
      displayCount:
        prevState.displayCount === this.state.initialDisplayCount
          ? this.state.gtmChallenge.length
          : this.state.initialDisplayCount,
    }));
  };

  handleCompanyAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data) {
        let newArr: any[] = []
        responseJson.data.forEach((detail: any) => {
          newArr.push(detail)
        })
        this.setState({ companySize: [...this.state.companySize, ...newArr] })
      }
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);
      this.setState({ errors })
    }
  }

  handleLocationListAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data) {
        this.setState({ locationList: this.jsonToArray(countryFile) })
      }
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);
      this.setState({ errors })
    }
  }
  handleGtmChallengesListAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data) {
        this.setState({ gtmChallenge: responseJson.data })
      }
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);
      this.setState({ errors })
    }
  }

  getButtonTitle = ()=>{
   return !!this.targetScreen ?'Go to Pebbles Ai':'Try Pebbles Ai'
  }

  jsonToArray = (jsonString: { [key: string]: string }) => {
    const jsonObject = jsonString;
    const idNamePairs = Object.values(jsonObject).map((key, index) => ({ id: index, name: key }));
    return idNamePairs;
  }

  handleAiButton = async() => {
    StorageProvider.set('target-screen','')
    StorageProvider.set("isBusiness","true");
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      this.targetScreen||'ChooseYourPath'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleIndustyAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data) {
        this.setState({ industry: [...this.state.industry, ...responseJson.data] })
      }
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);
      this.setState({ errors })
    }
  }

  handleCompanyHereAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data) {
        let newArr: any[] = []
        responseJson.data.forEach((hereAbout: any) => {
          newArr.push(hereAbout)
        })
        this.setState({ hereAbout: [...this.state.hereAbout, ...newArr] })
      }
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);
      this.setState({ errors })
    }
  }

  fetchCompanySize(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchCompaniesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companySizeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  fetchGtmChallenges(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchGtmChallengeId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.gtmChallengesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  fetchLocationList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchLocationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  fetchIndustries(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchIndustriesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.industriesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  fetchCompanyHereAbout(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchCompanyHereAboutId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companytHearAboutEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  onClose = () => {
    this.setState({ errors: "" });
  };


  async postAbout(role: string, industry: string, size: string, link: string, location: string) {
    const token = await StorageProvider.get("user-token")
    this.setState({ isLoad: true })
    const formData = new FormData()
    const chosenRole = this.state.roleList.find(i => i.id == role);
    formData.append("profile_role", chosenRole.name)
    formData.append("industry_id", industry)
    formData.append("gtm_team_size_id", size)
    formData.append("web_link", link)
    formData.append("country_name", location)

    const header = {
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostAccountId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpAboutEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true
  }

  async comapnyAboutPost(value: AboutValues) {
    const token = await StorageProvider.get("user-token")
    this.setState({ isLoad: true })
    const formData = new FormData()  
    formData.append("gtm_team_size_id", value.size)
    formData.append("hear_about_us_id", value.aboutUs)
    formData.append("gtm_challenges_ids[]",this.state.gtmChallengeValue as any);
    const header = {
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCompanyAboutPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companytellUsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true
  }
}
