import React from "react";


// Customizable Area Start
import {
    Box,
    Typography,
    IconButton
} from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import {
    openInNew,
    delete2,
    bookmark2,
    chatBubble,
} from "./assets";
const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    },
});
// Customizable Area End

import CopilotMenuController, {
    Props,
    configJSON,
} from "./CopilotMenuController";

export class CopilotMenu extends CopilotMenuController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box className={classes.mainWrapper}>
                    <Box className={classes.activeChatSessionWrapper}>
                        <Box className={classes.activeChatSession}>
                            <Box>
                                <Typography data-testid="copilot-text" className={classes.uxText}>{configJSON.uxDesign}</Typography>
                            </Box>
                            <Box>
                                <Box className={classes.imgDisplay}>
                                    <IconButton className={classes.uxImg}>
                                        <img
                                            src={openInNew}
                                            className={classes.activeChatSessionImages}
                                        />
                                    </IconButton>
                                    <IconButton className={classes.uxImg}>
                                        <img
                                            src={bookmark2}
                                            className={classes.activeChatSessionImages}
                                        />
                                    </IconButton>
                                    <IconButton className={classes.uxImg}>
                                        <img
                                            src={delete2}
                                            className={classes.activeChatSessionImages}
                                        />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={classes.activeChatChipcontainer}>
                            <ul className={classes.activeChatChiptab}>
                                <li className={"active"}>{configJSON.history}</li>
                                <li className={""}>{configJSON.library}</li>
                            </ul>
                        </Box>
                    </Box>
                    <Box className={classes.sessionContainer}>
                        {[1, 2, 3].map((item) => (
                            <Box key={item} className={classes.sessionWrapper}>
                                <Box className={classes.sessionTitleWrapper}>
                                    <Typography className={classes.sessionTitle}>
                                    {configJSON.uxDesign}
                                    </Typography>
                                    <Box className={classes.historyImg}>
                                        <img
                                            src={chatBubble}
                                            className={classes.chatBubbleImg}
                                        />
                                        <img
                                            src={bookmark2}
                                            className={classes.activeChatSessionImages}
                                        />
                                    </Box>
                                </Box>
                                <Typography className={classes.sessionDesc}>
                                {configJSON.uxDescription}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box className={classes.chatSessionConatiner}>
                        {[1, 2, 3].map((chat) => (
                            <Box key={chat} className={classes.chatSession}>
                                    <Typography className={classes.chatText}>{configJSON.uxDesign}</Typography>
                                    <Box>
                                        <img
                                            src={openInNew}
                                            className={classes.activeChatSessionImages}
                                        />
                                        <img
                                            src={bookmark2}
                                            className={classes.activeChatSessionImages}
                                        />
                                        <img
                                            src={delete2}
                                            className={classes.activeChatSessionImages}
                                        />
                                    </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: {} = () => ({
    activeChatSessionWrapper: {
        borderLeft: "3px solid #D39E26",
        borderRadius: "8px",
        height: "75px",
        backgroundColor: "#FFF0CE",
        padding: "10px 6px",
        marginTop: "25px",
        marginRight:'15px'
    },
    activeChatSession: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    activeChatSessionImages: { 
        height: "24px", 
        width: "24px", 
        cursor: "pointer" 
    },
    activeChatChipcontainer: {
        width: "95%",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        position: "relative",
    },
    activeChatChiptab: {
        display: "flex",
        listStyle: "none",
        padding: "2px",
        border: "1px solid #F5D687",
        background: "#FFF",
        borderRadius: "80px",
        marginTop: "10px",
        "& li": {
          padding: "4px 12px",
          display: "flex",
          fontSize: "0.75rem",
          cursor: "pointer",
          fontWeight: "600",
          lineHeight: "22px",
          fontFamily: "Montserrat",
          marginRight: "26px",
          color: "#5B4409",
        },
        "& li.lastItem": {
          marginRight: 0,
        },
        "& li.active": {
          padding: "4px 23px",
          borderRadius: "80px",
          background: "#F5D687",
          color: "#5B4409",
          width: "40%",
        },
    },
    sessionContainer: {
        marginTop: "5px",
    },
    sessionWrapper: {
        border: "1px solid #CBD5E1",
        padding: "10px",
        borderRadius: "8px",
        margin: "5px 13px 5px 0",
    },
    sessionTitleWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    sessionTitle: {
        fontWeight: 600,
    },
    chatBubbleImg: { 
        height: "16px", 
        width: "16px", 
        cursor: "pointer" 
    },
    sessionDesc: {
        width: " 200px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize:'0.75rem',
        color: '#475569'
    },
    chatSessionConatiner:{
        marginTop: "12px",
    },
    chatSession: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderLeft: "3px solid #D39E26",
        borderRadius: "8px",
        height: "41px",
        backgroundColor: "#FFF",
        padding: "0px 8px",
        margin:"5px 0px",
        "&:hover": {
          background: "#FFF0CE"
        }
    },
    mainWrapper:{
        overflow:'auto scroll',
        height:'70vh'
    },
    uxImg:{
        padding:'0'
    },
    uxText:{
        fontSize:'0.875rem',
        fontWeight:600,
        width:'144px'
    },
    imgDisplay:{
        display:'block ruby'
    },
    historyImg:{
        display:'flex',
        alignItems:'center',
        gap:'4px'
    },
    chatText:{
        fontSize:'0.875rem',
        fontWeight:600
    }

});
export default withStyles(webStyle)(CopilotMenu);

// Customizable Area End
