import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { FormData } from "./Cfdataintegrationviapromptengineering4.web";

// Customizable Area Start
import * as Yup from "yup";
import { QuestionFormat } from "./MultipleQuestionController";
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    formData: FormData[]
    onCloseModal: () => void;
    onSubmit:(type:'saved'|'submitted',values:any)=>void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    openGuideModal:boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class TacticalModalController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    rangeTouched=0;
    isSaved=false;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            openGuideModal:false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    handleConditionalStyle = (condition: boolean, trueStyle: any, falseStyle?: any) => {
        return condition ? trueStyle : (falseStyle || null)
    }

    async componentDidMount() {
        super.componentDidMount();
        const isOpenedGuideLine = await StorageProvider.get('tacticalFirstTime');
        if(!isOpenedGuideLine){
            this.setState({
                openGuideModal:true
            })
        }
    }

    onCloseGuideModal = async() =>{
        this.setState({
            openGuideModal:false
        })
        await StorageProvider.set('tacticalFirstTime','true')
    }

    checkDisableField = (type:string,key:string,allValue:any) => {
        const keys = Object.keys(allValue);
        const totalChosen = keys.filter((i)=>i.includes(type) &&!!allValue[i]);
        return totalChosen.length===3 && !allValue[key]
    }

    getInitFormikData = () => {
        let initValue: any = {
            rangeChosen: '',
        }
        let tempValidation: any = {}
        const persuasion = this.props.formData.find((form) => form.attributes.section === 'PERSUASION TACTICS')?.attributes.questions || []
        const communication = this.props.formData.find((form) => form.attributes.section === 'COMMUNICATION  TACTICS')?.attributes.questions || []
        persuasion.forEach((item, index) => {
            if (index === 0) {
                tempValidation[`persuasion-${item.id}`] = Yup.string()
                    // .required("This field is required")
            }
            initValue[`persuasion-${item.id}`] = ''
        })
        communication.forEach((item, index) => {
            if (index === 0) {
                tempValidation[`communication-${item.id}`] = Yup.string()
                    // .required("This field is required")
            }
            initValue[`communication-${item.id}`] = ''
        })
        return {
            initValue,
            validation: Yup.object().shape(tempValidation)
        }
    }

    checkBlur=(value:any)=>{
        const persuasion = this.getPersuationList('PERSUASION TACTICS');
        if(!persuasion.length) return 'none'
        const required = persuasion[0];
        const isRequiredFieldHasValue = !!value[`persuasion-${required.id}`]
        return !isRequiredFieldHasValue?'blur(3px)': 'none'
    }

    checkActiveButton = (value:any)=>{
        const communication = this.getPersuationList('COMMUNICATION  TACTICS');
        if(!communication.length) return false
        const required = communication[0];
        const isRequiredFieldHasValue = !!value[`communication-${required.id}`]
        return !isRequiredFieldHasValue?true: false
    }

    checkDisableSlider = (id:number,chosenValue:string)=>{
        return !!chosenValue && !chosenValue.includes(id+'-');
    }

    onChangeChips = (
        currentValue: string[],
        type: string,
        setFieldValue: (type: string, value: string) => void,
        question: QuestionFormat,
        chosenId: string | number
    ) => {
        const formikKey = `${type}-${question.id}`;
        const isExisted = currentValue.findIndex((val) => val + '' === chosenId + '')!==-1;
        if(isExisted){
            const newArr = currentValue.filter((val) => val + '' !== chosenId + '')
            setFieldValue(formikKey,newArr.join('-'))
            return;
        }
        setFieldValue(formikKey,currentValue.join('-')+"-"+chosenId)
    }

    getPersuationList = (type: 'PERSUASION TACTICS' | 'COMMUNICATION  TACTICS') => {
        return (this.props.formData.find((form) => form.attributes.section === type)?.attributes.questions || []).sort((a: any, b: any) => a.order - b.order)
    }
    // Customizable Area End
}
