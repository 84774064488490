import { WithStyles } from "@material-ui/core/styles/withStyles";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
// Customizable Area End

export interface Props extends WithStyles<any> {
  // Customizable Area Start
  dataTestId?: string;
  themeColor: string;
  icon: string;
  subTitle?: string;
  title: string;
  titleColor?: string;
  referral?: number;
  disableReferralLinkButton?: boolean;
  totalReferral?: number;

  getReferralLink(): void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  buttonClicked: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReferralCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.state = {
      buttonClicked: false
    };
    // Customizable Area End
  }

  // Customizable Area Start
  getReferralLink = () => {
    this.setState({
      buttonClicked: true
    });

    this.props.getReferralLink();

    setTimeout(() => {
      this.setState({
        buttonClicked: false
      });
    }, 2000);
  };
  // Customizable Area End
}
