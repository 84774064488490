import React from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import WorkflowTable from "../../../components/src/WorkflowTable.web";

//Icons
import {
  searchIcon,
  clock,
  workflowDelete,
  swapCalls,
  selectedBookmark,
  conversation,
  selectBtn,
  calender,
  rightBar,
  checkedIcon,
  checkIcon,
  share,
  d1,
  bookmsrkGrey,
} from "./assets";
import Chatbot4Controller from "./Chatbot4Controller";
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";

export class MyWorkflow extends Chatbot4Controller {
  render() {
    const { classes, id, navigation } = this.props;
    const {
      parameterWorkflow,
      selectFeildEnable,
      selectedFeilds,
      rightDrawer,
      activeTab,
      selectedHistoryId,
      searchTextWorkflow
    } = this.state;
    return (
      <NavigationMenuComponent
        data-test-id="navigationMenu"
        id={id}
        navigation={navigation}
        activeIndex={4}
        rightMenu={rightDrawer}
        handleRightMenu={() => this.setState({ rightDrawer: false })}
      >
        <Container  className={classes.mainWorkFlowContainer}>
          <Box className={classes.rightBarIcon}>
            <IconButton
              data-test-id="rightBarBtnDataTetId"
              onClick={() => this.setState({ rightDrawer: true })}
            >
              <img src={rightBar} alt="rightBar" />
            </IconButton>
          </Box>
          <Box className={classes.headingContainer}>
            <Typography className={classes.pageHeading}>
              My Workflows
            </Typography>
            <Box className={classes.subHeadingContainer}>
              <Typography className={classes.pageSubheading}>
                Saved workflows in one location, conveniently streamlining
              </Typography>
              <Typography className={classes.pageSubheading}>
                your work routine with your preferred settings.
              </Typography>
            </Box>
          </Box>

          <Box className={classes.parameterContainer}>
            <Box className={classes.tabsContainer}>
              <Box className={classes.activeChatChipcontainer}>
                <ul className={classes.activeChatChiptab}>
                <li className={activeTab==0?"active":""} onClick={(e)=>this.setState({activeTab:0})}>{"Outreach"}</li>
                  <li className={activeTab==1?"active":""} onClick={(e)=>this.setState({activeTab:1})}>{"Copilot"}</li>
                </ul>
              </Box>
            </Box>
            <Box className={classes.searchContainer}>
              <Box>
                <TextField
                  variant="outlined"
                  placeholder="Search workflows"
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      width: "325px",
                      height: "40px",
                      border: "1px solid #94A3B8",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <img src={searchIcon} alt="searchIcon" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                value={searchTextWorkflow}
                onChange={(e)=>{this.handelSearchTextFlow(e.target.value)}}
                data-test-id="searchTxtFeildDatTestId"
                />
              </Box>
              {!selectFeildEnable && (
                <Button
                  className={classes.selectField}
                  data-test-id="selectFeildsDatatestIdBtn"
                  onClick={this.handelSelectFeildBtn}
                >
                  <Typography className={classes.selectFieldText}>
                    Select
                  </Typography>
                  <Box>
                    <img src={selectBtn} alt="selectBtn" />
                  </Box>
                </Button>
              )}
            </Box>
          </Box>
          {selectFeildEnable && (
            <Box className={classes.parameterContainer}>
              <Box className={classes.dFlex}>
                <Box className={classes.dFlex}>
                  <Typography className={classes.selectAllText}>
                    Select All
                  </Typography>
                  <Checkbox
                    data-test-id="selectAllFeildDataTestId"
                    checked={
                      selectedFeilds.length ===
                      parameterWorkflow.map((pw: any) => pw.id).length
                    }
                    onClick={(e) => {
                      this.handelSelectAllWorkFLow(e);
                    }}
                    checkedIcon={
                      <img
                        src={checkedIcon}
                        style={{ height: "20px", width: "20px" }}
                        alt="checkedIcon"
                      />
                    }
                    icon={
                      <img
                        src={checkIcon}
                        style={{ height: "20px", width: "20px" }}
                        alt="checkIcon"
                      />
                    }
                  />
                </Box>
                <Typography
                  className={classes.removeSelectionText}
                  data-test-id="selectFeildEnableDataTestId"
                  onClick={() => this.setState({ selectFeildEnable: false })}
                >
                  Remove Selection -
                </Typography>
              </Box>
              <Box className={classes.searchContainer}>
                <Button
                  className={classes.shareFeildContainer}
                  data-test-id="ShareFeildsDatatestIdBtn"
                >
                  <Typography className={classes.selectFieldText}>
                    Share
                  </Typography>
                  <Box>
                    <img src={share} alt="sharetBtn" />
                  </Box>
                </Button>
                <Button
                  className={classes.shareFeildContainer}
                  data-test-id="ShareFeildsDatatestIdBtn"
                >
                  <Typography className={classes.selectFieldText}>
                    Delete
                  </Typography>
                  <Box>
                    <img src={d1} alt="d1Btn" />
                  </Box>
                </Button>
              </Box>
            </Box>
          )}

          <WorkflowTable
            data-test-id="WorkflowTableDataTestID"
            data={parameterWorkflow}
            handelFeildCheckBoxClick={this.handelFeildCheckBoxClick}
            selectFeildEnable={selectFeildEnable}
            selectedFeilds={selectedFeilds}
            selectedHistoryId={selectedHistoryId}
            handelSelctedHistory={(obj: any) => {
              this.setState(obj);
            }}
            tableHeader={
              activeTab == 1
                ? [
                    "Workflow name & description",
                    "Conversation Name",
                    "Timestamp",
                    "Options",
                  ]
                : ["Workflow name ", "Email category", "Timestamp", "Options"]
            }
            activeTab={activeTab}
          />
        </Container>
      </NavigationMenuComponent>
    );
  }
}

const styles = createStyles({
  mainWorkFlowContainer: {
    position: "relative",
    height: "100vh",
    padding: "16px 20px 16px 20px",
  },
  headingContainer: {},
  pageHeading: {
    fontFamily: "Montserrat",
    fontSize: "48px",
    fontWeight: 600,
    textAlign: "center",
  },
  subHeadingContainer: {},
  pageSubheading: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontWeight: 500,
    textAlign: "center",
    color: "#475569",
  },
  parameterContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tabsContainer: {},
  searchContainer: {
    display: "flex",
    alignItems: "center",
  },
  tableContainer: {},
  activeChatChipcontainer: {
    // width: "206px",
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  activeChatChiptab: {
    display: "flex",
    listStyle: "none",
    padding: "2px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    borderRadius: "80px",
    marginTop: "10px",
    "& li": {
      padding: "4px 12px",
      display: "flex",
      fontSize: "12px",
      cursor: "pointer",
      fontWeight: "600",
      lineHeight: "22px",
      fontFamily: "Montserrat",
      //   marginRight: "26px",
    },
    "& li.lastItem": {
      marginRight: 0,
    },
    "& li.active": {
      padding: "4px 12px",
      borderRadius: "80px",
      background: "#000",
      color: "#FFFFFF",
    },
  },
  selectField: {
    borderRadius: "8px",
    height: "40px",
    marginLeft: "10px",
    border: "1px solid #94A3B8",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 15px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#e7e7e7",
      borderColor: "#000",
    },
  },
  selectFieldText: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginRight: "5px",
  },
  tableHeading: {
    color: "#475569",
    fontSize: "12px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  tableHeadCell: {
    borderBottom: "none",
  },
  nullTableData: {
    color: "#475569",
    fontSize: "12px",
    cursor: "pointer",
    fontWeight: 500,
    fontFamily: "Montserrat",
    textAlign: "center",
  },
  useBtn: {
    textTransform: "none",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    "&:hover": {
      borderColor: "#e7e7e7",
      backgroundColor: "#000",
      color: "#FFFFFF",
    },
  },
  optionsCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsTimeStampDateCell: {
    color: "#475569",
    fontSize: "12px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
  optionsConversationCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsConversationHeadingCell: {
    fontSize: "14px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
  optionsworkFlowNameCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsworkFlowheadingCell: {},
  sessionDesc: {
    width: "250px",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    fontSize: "12px",
    fontWeight: 600,
    fontFamily: "Montserrat",
    color: "#475569",
    lineHeight: "16px",
    maxHeight: "32px",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  },
  sessionTitle: {
    fontWeight: 600,
  },
  icon: {
    height: "24px",
    width: "24px",
    cursor: "pointer",
  },
  optionsClockCell: {
    display: "flex",
    alignItems: "center",
  },
  optionsCalenderCell: {
    display: "flex",
    alignItems: "center",
  },
  rightBarIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    zIndex: 4200,
  },
  dFlex: {
    display: "flex",
    alignItems: "center",
  },
  removeSelectionText: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
    color: "#D93855",
    cursor: "pointer",
  },
  selectAllText: {
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "Montserrat",
  },
  shareFeildContainer: {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    marginRight: "10px",
  },
});

export default withStyles(styles)(MyWorkflow);
