import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  Link
} from "@material-ui/core";
import Layout from "./Layout.web";
import { GoogleImg, checkIcon as rightIcon, checkedIcon } from "./assets";

import SignUpOtpController, { Props } from "./SignUpOtpController";
import { Formik } from "formik";
import { withStyles } from "@material-ui/core/styles";
import { SignupSchema } from "../utils/schema";
import React from "react";
import { styles } from "../utils/styles";
import Toast from "../../utilities/src/Toast";
import PrivacyPolicy from "../../customisableuserprofiles/src/PrivacyPolicy.web";
import TermAndCondition from "../../customisableuserprofiles/src/TermAndCondition.web";

type Values = {
  code: string;
  termAndCondition: boolean;
};

const initialValues: Values = { code: "", termAndCondition: false };

export class SignUp extends SignUpOtpController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { email, errors, message, isLoad,privacyPol,termAndCond } = this.state;
    const { onClose } = this;

    return (
      <Layout>
        <Toast {...{ errors, onClose, message }} />
        <Box className={classes.container}>
          <Typography className={classes.singupText}>
            Sign up to GTMSai
          </Typography>

          <Box className={classes.form}>
            <Typography className={classes.label}>Email</Typography>
            <TextField
              data-testid="emailInput"
              id="email"
              type="text"
              variant="outlined"
              fullWidth
              disabled
              value={email}
              className={classes.input}
              style={{ marginBottom: 0 }}
            />
          </Box>

          <Typography className={classes.sentSignupText}>
            We just sent you a temporary signup code.
            <br />
            Please check your inbox and paste the sign up
            <br />
            code below
          </Typography>

          <Formik
            data-testid="formikSubmit"
            initialValues={initialValues}
            validationSchema={SignupSchema}
            onSubmit={values => {
              this.validateOtp(values.code);
            }}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Typography className={classes.label}>Sign up code</Typography>
                <TextField
                  data-test-id="signupCode"
                  name="code"
                  id="signup"
                  type="text"
                  variant="outlined"
                  fullWidth
                  value={values.code}
                  onChange={handleChange}
                  placeholder="Please paste signup code"
                  className={classes.input1}
                  inputProps={{
                    style: {
                      fontFamily: "Montserrat",
                      color: touched.code && errors.code ? "#D93855" : ""
                    }
                  }}
                  style={{
                    marginBottom: "2px",
                    borderColor: touched.code && errors.code ? "#D93855" : ""
                  }}
                />
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  style={{ marginTop: "24px" }}
                  className={classes.button}
                >
                  Create new account
                </Button>

                <Typography className={classes.code}>
                  Didn’t receive code?{" "}
                  <span
                    data-testid={"resendOtpButton"}
                    className={classes.resend}
                    onClick={this.resendOtp}
                  >
                    Resend
                  </span>
                </Typography>

                <div className={classes.hr} />
                <Button
                  variant="contained"
                  className={classes.googlebtn}
                  data-testid="googleLoginBtn"
                  onClick={() => this.initiateGoogleLogin()}
                >
                  <img src={GoogleImg} alt="googlelogo" />
                  <Typography className={classes.googleTxt}>
                    Continue with Google
                  </Typography>
                </Button>
                {touched && errors && (
                  <Toast
                    {...{ isLoad }}
                    errors={
                      (touched.code && errors.code) ||
                      (touched.termAndCondition && errors.termAndCondition)
                    }
                    onClose={onClose}
                  />
                )}
                <Box className={classes.terms}>
                  <Checkbox
                    name="termAndCondition"
                    data-testid="checkBox"
                    className={classes.checkbox}
                    value={values.termAndCondition}
                    onChange={handleChange}
                    color="primary"
                    checkedIcon={
                      <img
                        src={checkedIcon}
                        alt="checked"
                        className={classes.checkbox}
                      />
                    }
                    icon={
                      <img
                        src={rightIcon}
                        alt="check"
                        className={classes.checkbox}
                      />
                    }
                  />
                  <Typography className={classes.agreement}>
                    I agree to{" "}
                    <Link
                    data-testid="privacyPolicyButton"
                    onClick={()=>{
                      this.getPrivacyPolData()
                      this.setState({privacyPol:{...privacyPol,isModalVisible:true}})
                    }} 
                    className={classes.link}>
                      Privacy Policy
                    </Link>{" "}
                    &{" "}
                    <Link 
                    data-testid="termsButton"
                    onClick={() => {
                      this.getTermAndCondData();
                      this.setState({termAndCond:{...termAndCond,isModalVisible:true}})
                    }} 
                    className={classes.link}>
                      Terms and Conditions
                    </Link>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
          <PrivacyPolicy
            data-testid="privacyPolicyModalTestId"
            privacyPolicy={privacyPol}
            changePrivacyPolicyState={(obj: any) => {
              this.setState({privacyPol :obj});
            }}
          />
          <TermAndCondition
            data-testid="termAndConditionModalTestId"
            termAndConditionState={termAndCond}
            changeTermAndConditionState={(obj: any) => {
              this.setState({termAndCond :obj});
            }}
          />
        </Box>
      </Layout>
    );
  }
}

export default withStyles(styles)(SignUp);
