import React from "react";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";

import DoneIcon from "@material-ui/icons/Done";

import SubscriptionController, { Props } from "./SubscriptionController";
import Layout from "../../../components/src/Layout";
import { quaterly, bluePerson, bottomLogo } from "./assets";
import Loader from "../../../components/src/Loader";

export class Subscription extends SubscriptionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    const { plans, loading } = this.state;

    const getCardClasses = (slug: any) => {
      if (slug == "flex_user") {
        return classes.card1;
      } else if (slug == "gtm_professional") {
        return classes.card2;
      } else {
        return classes.card3;
      }
    };
    const getIconClases = (slug: any) => {
      if (slug == "flex_user") {
        return classes.iconColor1;
      } else if (slug == "gtm_professional") {
        return classes.iconColor2;
      } else {
        return classes.iconColor3;
      }
    };
    const getPriceClases = (slug: any) => {
      if (slug == "flex_user") {
        return classes.priceValue;
      } else if (slug == "gtm_professional") {
        return classes.priceValue;
      } else {
        return classes.priceValue3;
      }
    };

    return (
      <Layout back={this.onBackButton} carousel={"hide"} poweredByLogo={false}>
        <Loader loading={loading} />
        <Container className={classes.container} maxWidth="lg">
          <Typography className={classes.plan}>
            Choose your flexible option
          </Typography>
          <Box className={classes.trialBox}>
            <Typography className={classes.trial}>
              Each pebble intelligently automates an aspect of the GTM workflow,
            </Typography>
            <Typography className={classes.trial}>
              equipped with its own apps tailored for marketing, sales, and
              product.
            </Typography>
          </Box>
          <Grid container spacing={3} className={classes.cardsContainer}>
            {plans.map((plan: any, index: number) => (
              <Grid item key={plan.id} xs={12} sm={6} md={4}>
                <Box className={getCardClasses(plan.attributes.unique_key)}>
                  <Box className={classes.planWrapper}>
                    <Typography
                      className={
                        plan.attributes.unique_key == "small_business"
                          ? classes.plan3
                          : classes.starter
                      }
                    >
                      {plan.attributes.name}
                    </Typography>

                    <Box className={classes.priceWrapper}>
                      <Typography className={classes.price}>
                        {plan.attributes.old_price !== null && (
                          <Box className={classes.cancelPrice}>
                            <span className={classes.cancelLine}></span>
                            {parseInt(plan.attributes.old_price)}
                          </Box>
                        )}
                        <span className={classes.currencySymbol}>£</span>
                        <span
                          className={getPriceClases(plan.attributes.unique_key)}
                        >
                          {parseInt(plan.attributes.price)}
                        </span>
                        <span className={classes.perMonth}>/mth.</span>
                      </Typography>
                    </Box>
                    {plan.attributes.unique_key == "flex_user" && (
                      <Box className={classes.addtionalBox1}>
                        <Box style={{ height: "24px", width: "24px" }}></Box>
                        <Typography
                          className={classes.addtionalBoxTypography}
                        ></Typography>
                      </Box>
                    )}
                    {plan.attributes.unique_key == "gtm_professional" && (
                      <Box className={classes.addtionalBox2}>
                        <Box style={{ height: "24px", width: "24px" }}>
                          <img
                            src={bluePerson}
                            style={{ height: "100%", width: "100%" }}
                          />
                        </Box>
                        <Typography className={classes.addtionalBoxTypography}>
                          {plan.attributes.highlighted_feature}
                        </Typography>
                      </Box>
                    )}
                    {plan.attributes.unique_key == "small_business" &&
                      plan.attributes.highlighted_feature && (
                        <Box className={classes.addtionalBox3}>
                          <Box style={{ height: "24px", width: "24px" }}>
                            <img
                              src={quaterly}
                              style={{ height: "100%", width: "100%" }}
                            />
                          </Box>
                          <Typography
                            className={classes.addtionalBoxTypography}
                          >
                            {plan.attributes.highlighted_feature}
                          </Typography>
                        </Box>
                      )}

                    <Typography className={classes.description}>
                      {plan.attributes.description}
                    </Typography>
                    <Box className={classes.features}>
                      {plan.attributes.features.map((item: any) => (
                        <Typography key={item} className={classes.featureItem}>
                          <DoneIcon
                            className={getIconClases(
                              plan.attributes.unique_key
                            )}
                          />
                          {item.feature}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      data-test-id={`selectPlansBtn${index}`}
                      className={classes.button}
                      onClick={() => {
                        this.intiatePayment(plan);
                      }}
                    >
                      Choose
                    </Button>
                  </Box>
                </Box>
              </Grid>
            ))}
            <div className={classes.poweredBox}>
              <Typography className={classes.poweredText}>
                Powered by
              </Typography>
              <img src={bottomLogo} alt="can-img" className={classes.canImg} />
            </div>
          </Grid>
        </Container>
      </Layout>
    );
  }
}

const styles = () =>
  createStyles({
    container: {},
    logo: {
      mixBlendMode: "multiply",
    },
    plan: {
      marginTop: "15px",
      color: "#000",
      textAlign: "center",
      fontSize: "48px",
      fontWeight: 600,
      lineHeight: "47px",
      fontFamily: "Montserrat",
    },
    button: {
      height: "45px",
      width: "221px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      background: "#000",
      boxShadow: "0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
      textTransform: "none",
      color: "white",
      // margin:"23px 0 41px",
      fontFamily: "Montserrat",
      "&:hover": {
        background: "#000",
      },
    },
    middlebutton: {
      marginBottom: "82px",
    },
    trialBox: {
      margin: "24px 0 56px",
    },
    trial: {
      color: "#000",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: "Montserrat",
    },
    cardsContainer: {
      width: "100%",
      margin: "0 auto",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "end",
      marginBottom: "50px",
      minHeight: "541px",
    },
    planWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },

    card1: {
      // width: "325px",
      height: "541px",
      // minHeight:"542px",
      borderRadius: "20px",
      border: "0.942px solid #CBD5E1",
      background: "#FFF",
      borderColor: "#D93855",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "25px",
    },
    card2: {
      height: "541px",
      borderRadius: "20px",
      border: "0.942px solid #CBD5E1",
      background: "#3A84C9",
      color: "#F1F5F9",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "25px",
    },
    card3: {
      height: "541px",
      borderRadius: "20px",
      border: "0.942px solid #CBD5E1",
      background: "#FFF",
      borderColor: "#A68B3D",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "25px",
    },
    iconColor1: { color: "#D93855" },
    iconColor2: { color: "#22507B" },
    iconColor3: {
      color: "#A68B3D",
    },
    middleCard: {
      width: "316px",
      borderRadius: "22px 22px 18.842px 18.842px",
      background: "#000",
      color: "white",
    },
    popular: {
      borderRadius: "18.842px 18.842px 0px 0px",
      background: "linear-gradient(180deg, #D0B33F 0%, #9B6F27 100%)",
      // textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "39.5px",
      color: "#FFF",
      fontSize: "15px",
      fontWeight: 600,
      lineHeight: "20px",
      fontFamily: "Montserrat",
    },
    description: {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Montserrat",
      textAlign: "left",
      padding: "10px 28px",
      cursor: "default",
    },

    starter: {
      // margin: "44px 0 37px",
      // textAlign: "center",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "20px",
      textTransform: "capitalize",
      fontFamily: "Montserrat",
      margin: "20px 0px",
      cursor: "default",
    },
    plan3: {
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "20px",
      textTransform: "capitalize",
      fontFamily: "Montserrat",
      margin: "20px 0px",
      color: "#A68B3D",
      cursor: "default",
    },
    priceWrapper: {
      margin: "20px 0px",
    },
    price: {
      fontSize: "45px",
      fontWeight: 600,
      lineHeight: "20px",
      display: "flex",
      justifyContent: "center",
      fontFamily: "Montserrat",
      cursor: "default",
    },
    currencySymbol: {
      fontSize: "14px", // Adjust the size as needed
      lineHeight: "1px",
      fontFamily: "Montserrat",
      cursor: "default",
    },
    priceValue: {
      fontSize: "45px",
      fontWeight: 600,
      lineHeight: "20px",
      fontFamily: "Montserrat",
    },
    priceValue3: {
      fontSize: "45px",
      fontWeight: 600,
      lineHeight: "20px",
      fontFamily: "Montserrat",
      color: "#A68B3D",
    },
    perMonth: {
      fontSize: "15px",
      fontWeight: 500,
      lineHeight: "40px",
      fontFamily: "Montserrat",
    },
    doneIcon: {
      color: "#3A84C9",
    },
    features: {
      // marginLeft: "45px",
    },
    featureItem: {
      display: "flex",
      gap: "7.5px",
      alignItems: "center",
      marginBottom: "16.6px",
      fontFamily: "Montserrat",
      fontWeight: 400,
    },
    addtionalBox1: {
      paddingRight: "5px",
      marginBottom: "20px",
    },
    addtionalBox2: {
      borderRadius: "80px",
      paddingRight: "5px",
      border: "1px solid #FFF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "20px",
      color: "#FFF",
    },
    addtionalBox3: {
      borderRadius: "80px",
      paddingRight: "5px",
      border: "1px solid #A68B3D",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "20px",
      color: "#000",
    },
    addtionalBoxTypography: {
      fontFamily: "Montserrat",
      fontWeight: 600,
      fontSize: "14px",
      marginLeft: "5px",
    },
    cancelPrice: {
      fontSize: "36px",
      fontWeight: 400,
      lineHeight: "36px",
      fontFamily: "Montserrat",
      color: "#F8FAFC",
      position: "relative",
      marginRight: "5px",
      opacity: "0.5",
    },
    cancelLine: {
      position: "absolute",
      transform: "rotate(-13deg)",
      background: "rgba(248, 250, 252, 1)",
      height: "3px",
      width: "60px",
      bottom: "17px",
      left: "-6px",
    },
    poweredText: {
      color: "#000",
      textAlign: "center",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "120%",
    },
    poweredBox: {
      marginTop: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      width: "100%",
      position: "relative",
      paddingBottom: "28px",
      marginRight: "80px",
    },
    canImg: {
      marginLeft: "8px",
      width: "100px",
      height: "17px",
    },
  });

export default withStyles(styles)(Subscription);
