// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";


import { WithStyles } from "@material-ui/core";
// Customizable Area End
export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id:any;
  // Customizable Area End
}

export default class WelcomePageController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

  // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
    ];

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  goToLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToChooseYourOption = () => {
    this.props.navigation.navigate("ChooseOption");
  }

  // Customizable Area End
}
