import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  title:string;
  inputList: any;
  serviceArray:any;
  // Customizable Area Start
  handleChange?: (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => void;
  handleChange1?: (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => void;
  handleChange3?: (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => void;
  handleChange2?: (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => void;
  processText?:boolean;
  goodToHave?:boolean;
  name1?:any;
  name2?:any;
  tooltipData?:string;
  classes?:any;
  offeringText?:any
  // Customizable Area End

  showListHeader?: boolean
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProblemStatementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
     
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  // Customizable Area End

  // web events

  // Customizable Area Start
  
  rowCount = ()=>{
    return this.props.processText?1:5
  }
  styleCondition(e: any, condition1: any, condition2: any) {
    if (e) {
      return condition1;
    } else {
      return condition2
    }
  }
  // Customizable Area End
}
