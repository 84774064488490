import React from "react";
// Customizable Area Start
import { Box, Button, Container, Typography,IconButton } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";
import { rightBar,mailIcon,greenIcon,hoverIcon,redIcon,redHoverIcon,blueIcon,blueIconOnHover } from "./assets";
import MarketingEmails from "./MarketingEmails.web";
export const configJSON = require("./config");
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <NavigationMenuComponent
          id={this.props.id}
          navigation={this.props.navigation}
          activeIndex={1}
          handleRightMenu={this.handleRightMenu}
          rightMenu={this.state.rightMenu}
          children={
            <Container style={webStyle.mainContainer}>
              {!this.state.rightMenu && 
                <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                data-testid="right-menu"
                onClick={this.handleRightMenu}
                style={webStyle.rightBar}
              >
                <img
                  src={rightBar}
                  alt="right-bar"
                />
              </IconButton>
              }
              <img src={mailIcon} alt="tactical" />
              <Typography style={webStyle.tacticalOutreach}>
                {this.state.tacticalOutreach}
              </Typography>
              <Box style={webStyle.mailsDisplay}>
                <Button
                  style={
                    this.state.activeStep === 1
                      ? webStyle.marketActive
                      : webStyle.marketMailText
                  }
                  onClick={() => this.handleSelect(1)}
                  data-testid="market-tab"
                >
                  {configJSON.MarketingEmail}
                </Button>
                <Button
                  style={
                    this.state.activeStep === 2
                      ? webStyle.saleActive
                      : webStyle.saleMailText
                  }
                  onClick={() => this.handleSelect(2)}
                  data-testid="sales-tab"
                >
                  {configJSON.SalesEmails}
                </Button>
                <Button
                  style={
                    this.state.activeStep === 3
                      ? webStyle.productActive
                      : webStyle.productMailText
                  }
                  onClick={() => this.handleSelect(3)}
                  data-testid="product-tab"
                >
                  {configJSON.ProductEmails}
                </Button>
              </Box>
              {this.state.activeStep === 1 && (
                <MarketingEmails
                  {...this.props}
                  color={"#57BE99"}
                  activeStep={this.state.activeStep}
                  backgroundIcon={greenIcon}
                  hoveredIcon={hoverIcon}
                  data-testid="market-component"
                />
              )}
              {this.state.activeStep === 2 && (
                <MarketingEmails
                  {...this.props}
                  color={"#D93855"}
                  activeStep={this.state.activeStep}
                  backgroundIcon={redIcon}
                  hoveredIcon={redHoverIcon}
                  data-testid="sales-component"
                />
              )}
              {this.state.activeStep === 3 && (
                <MarketingEmails
                  {...this.props}
                  color={"#3A84C9"}
                  activeStep={this.state.activeStep}
                  backgroundIcon={blueIconOnHover}
                  hoveredIcon={blueIcon}
                  data-testid="product-component"
                />
              )}
            </Container>
          }
        />
      </ThemeProvider>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    position: "relative" as "relative",
    textAlign: "center" as "center"
  },
  rightBar: {
    position: "absolute" as "absolute",
    right: "60px",
    zIndex: "9999",
    height: 'max-content'
  },
  tacticalOutreach: {
    fontSize: "2.5rem",
    fontWeight: 600
  },
  mailsDisplay: {
    display: "flex",
    borderRadius: "30px",
    border: "1px solid #CBD5E1",
    padding: "1px",
    margin: "10px auto 20px",
    justifyContent: "space-between",
    width:'80%'
  },
  marketMailText: {
    fontWeight: 600,
    color: "#57BE99",
    cursor: "pointer",
    textTransform: "none" as "none"
  },
  saleMailText: {
    fontWeight: 600,
    color: "#D93855",
    cursor: "pointer",
    textTransform: "none" as "none"
  },
  productMailText: {
    fontWeight: 600,
    color: "#3A84C9",
    cursor: "pointer",
    textTransform: "none" as "none"
  },
  marketActive: {
    color: "#fff",
    background: "#57BE99",
    fontWeight: 600,
    borderRadius: "30px",
    padding: "12px 30px",
    textTransform: "none" as "none"
  },
  saleActive: {
    color: "#fff",
    background: "#D93855",
    fontWeight: 600,
    borderRadius: "30px",
    padding: "12px 30px",
    textTransform: "none" as "none"
  },
  productActive: {
    color: "#fff",
    background: "#3A84C9",
    fontWeight: 600,
    borderRadius: "30px",
    padding: "12px 30px",
    textTransform: "none" as "none"
  }
};
// Customizable Area End
