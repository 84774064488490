import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { LogoImg } from "./assets";
import { loginBg } from "../../email-account-login/src/assets";

interface IProps {
  children: React.ReactNode;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    // justifyContent: 'space-between',
    height: "100%",
    // width: "1440px",
    // margin: "0 auto",
    background: "#3A84C90D",
    overflow: "auto"
  },
  logo: {
    mixBlendMode: "multiply" //for removing background white from image
  },
  leftSection: {
    flex: ".52"
    // height: "66px",
  },
  leftContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: 'calc(100% - 66px)'
    marginTop: "11%"
  },
  rightSection: {
    flex: ".48",
    background: "linear-gradient(180deg, #D0B33E 0%, #996D26 100%)",
    // backgroundRepeat: "no-repeat",
    // flex: ".5",
    // display: 'flex',
    // alignItems: 'center',
    position: "relative"
    // height: "100%"
  },
  img: {
    height: "100%",
    position: "absolute",
    left: "-34px",
    right: 0,
    width: "calc(100% + 34px)"
  }
});

const Layout = (props: IProps) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.leftSection}>
        <img src={LogoImg} alt="logo" className={classes.logo} />
        <Box className={classes.leftContent}>{children}</Box>
      </Box>
      <Box className={classes.rightSection}>
        <img src={loginBg} alt="chatgptimage" className={classes.img} />
      </Box>
    </Box>
  );
};

export default Layout;
