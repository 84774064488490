import React from "react";
import { Box, Button, Typography, Checkbox, FormGroup, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import TellBitMoreBusinessController, { Props } from "./TellBitMoreBusinessController";
import { ErrorMessage, Formik } from "formik";
import { styles } from '../utils/styles'
import Layout from "../../../components/src/Layout";
import CarouselDisplay from "../../../blocks/carouseldisplay/src/CarouselDisplay.web";
import { theme } from "./About.web";
import * as Yup from "yup";
import Dropdown from "../../../components/src/Dropdown.web";

export type AboutValues = {
    seat: string;
    aboutUs: string;
    selectedCheckboxIds: any
};


export const schema = Yup.object().shape({
    seat: Yup.number().typeError("Please enter a positive number").min(5,'Minimum 5 seats').required("Please enter seat."),
    aboutUs:Yup.string().required("Please select hear about us."),
    selectedCheckboxIds: Yup.array().min(1, 'Please select GTM challenge.'),
  });

// initial formik values
const defaultValue = { seat: '', aboutUs: "", selectedCheckboxIds: [] };



export class TellBitMoreBusiness extends TellBitMoreBusinessController {
    constructor(props: Props) {
        super(props);
    }

    checkedValue = () => {
        return this.state.gtmChallengeValue.some((value) => value);
    };

    render() {
        const { classes } = this.props;

        return (
            <ThemeProvider theme={theme}>
                <Layout back={this.onBack} carousel={<CarouselDisplay type="b2b-plan" id="" navigation={{}} />}>
                    <Box className={classes.layoutContainer}>
                        <Box className={classes.viewBox}>
                            <Typography className={classes.createAccount}>{'Tell us a bit more'}</Typography>
                            <Typography className={classes.signingAs}>{"Just a few more details and we’re set."}</Typography>
                            <Formik
                                data-testid="companyAboutFormik"
                                initialValues={defaultValue}
                                validationSchema={schema}
                                onSubmit={values => {
                                    this.submitData(values)
                                }}
                            >
                                {({ values, errors, touched, handleChange, handleSubmit, setFieldValue }) => (
                                    <form onSubmit={handleSubmit} data-testid={"formSubmits"}>
                                        <Box className={classes.form1}>
                                            <Typography className={classes.label}>{'How many seats are you looking to supercharge?'}</Typography>
                                            <div style={{ marginBottom: "24px" }}>
                                                <TextField
                                                    id="seat"
                                                    data-test-id='sizeInput'
                                                    variant="outlined"
                                                    value={values.seat}
                                                    name="seat"
                                                    fullWidth
                                                    onChange={handleChange}
                                                    className={classes.input1}
                                                    placeholder="min 5"
                                                    style={{
                                                        borderColor: touched.seat && errors.seat ? "#F87171" : ""
                                                    }}
                                                />
                                                <ErrorMessage name="seat" component="div" className={classes.errorResp} />
                                            </div>
                                            <Typography className={classes.label}>{'What are your biggest GTM challenges?'}</Typography>
                                            <div style={{ marginBottom: "24px" }}>
                                                <FormGroup style={{ marginTop: '12px' }}>
                                                    <Grid container spacing={2}>
                                                        {this.state.gtmChallenge.slice(0, this.state.displayCount).map((checkbox) => (
                                                            <Grid item md={6} xs={12} key={checkbox.id}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                        onChange={() => {
                                                                            let selectedCheckbox: any = this.onChangeCheckBox(checkbox.id)
                                                                            setFieldValue('selectedCheckboxIds', selectedCheckbox || [])
                                                                            }}
                                                                            checked={this.state.gtmChallengeValue.includes(checkbox.id)}
                                                                            style={{
                                                                                color: this.checkedValue() ? '#3A84C9' : '',
                                                                            }}
                                                                            name={"selectedCheckboxIds"}
                                                                            data-testid={'checkinput'}
                                                                        />
                                                                    }
                                                                    label={<Typography variant={"h6"} style={{ fontSize: '14px', fontWeight: 500 }}>{checkbox.name}</Typography>}
                                                                />
                                                            </Grid>
                                                        ))}
                                                        <Grid item xs={6}>
                                                            <Button onClick={this.onToggleShowAll} variant="text" style={{ color: "#3A84C9", fontSize: '14px', fontWeight: 600, textTransform: 'none' }}>
                                                                {this.state.initialDisplayCount===this.state.displayCount ? 'Show more' : 'Show less'}
                                                            </Button></Grid>
                                                    </Grid>
                                                    <Grid>
                                                        <ErrorMessage name="selectedCheckboxIds" component="div" className={classes.errorResp} />
                                                    </Grid>
                                                </FormGroup>
                                            </div>

                                            <Typography className={classes.label}>{'How did you hear about us?'}</Typography>
                                            <div style={{ marginBottom: "24px" }}>
                                                <Dropdown 
                                                    dropdownPosition="top"
                                                    name="aboutUs"
                                                    data-testid="aboutFields"
                                                    value={values.aboutUs}
                                                    containerClassName={`${
                                                      classes.dropdownWrapper
                                                    } ${this.checkErrStyle(
                                                      !!touched.aboutUs,
                                                      errors.aboutUs,
                                                      classes.errorBorderStyle
                                                    )}`}
                                                    handleChange={(event) =>
                                                        setFieldValue('aboutUs',event.target.value)
                                                    }
                                                    dataList={this.state.hereAbout}
                                                />
                                                <ErrorMessage name="aboutUs" component="div" className={classes.errorResp} />
                                            </div>
                                            <Button variant="contained" type="submit" className={classes.buttonWrapper} data-testid="formSubmits">Continue</Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                        </Box>
                    </Box>
                </Layout>
            </ThemeProvider>
        );
    }
}



export default withStyles(styles)(TellBitMoreBusiness);