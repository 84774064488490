import React from "react";
// Customizable Area Start
import CustomList from "../../../components/src/CustomList.web";
import CustomInput from "../../../components/src/CustomInput.web";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Grid, Box } from "@material-ui/core";

const theme = createTheme({
  palette: {
    primary: {
      main: "#475569",
    },
    secondary: {
      main: "#3A84C9",
    },
  },
  typography: {
    fontFamily: "Montserrat",
  },
});
// Customizable Area End

import PrimaryQuestionController, {
  Props,
  configJSON,
} from "./PrimaryQuestionController";
import CustomButton from "../../../components/src/CustomButton.web";
import { FieldArray } from "formik";
import {modalInfoContent} from "../utils/data"
export default class PrimaryQuestionWithFormik extends PrimaryQuestionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {
      inputList,
      serviceArray,
      title,
      btnDisplay = false,
      buttonName,
      name = "",
      formikHandelChange,
      formikValue,
      formikError,
      maxFeilds=1,
      touched,
      onAddNewItem,
    } = this.props;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container style={webStyle.servicesMainContainer}>
          <Grid item xs={12} style={webStyle.servicesContainer}>
            <CustomList
              title={title}
              servicesList={serviceArray}
              modalContent={modalInfoContent[name as keyof typeof modalInfoContent]}
            />
            <Box style={webStyle.offeringBox}>
              <FieldArray
              data-test-id="FormikFieldArray"
                name={name}
                render={(arrayHelpers: any) => (
                  <div>
                    {formikValue[name].map((field: any, index: any) => {
                      const _index= index>inputList.length-1?0:index
                      return(
                      <Box key={index}>
                        <CustomInput
                          width="100%"
                          textLabel={inputList[_index].label}
                          labelCount={index}
                          placeholder={inputList[_index].placeholder}
                          rowsCount={1}
                          data-testid={"offering-field-" + index}
                          name={`${name}.${index}`} 
                          onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => formikHandelChange(e, index)}
                          value={formikValue[name][index]} 
                          error={formikError[name]?.[index] && !!touched&& touched[name]?.[index]?formikError[name]?.[index]:'' } 
                          maxCharacterLenght={inputList[_index].charLength}
                        />
                      </Box>
                    )
                    })}
                       {btnDisplay && (formikValue[name].length < maxFeilds)&&(
                <CustomButton
              data-test-id="FormikFieldButton"
                onClick={() => {
                  arrayHelpers.push('');
                  onAddNewItem?.();
                }}
                  buttonName={buttonName}
                />
              )}
                  </div>
                )}
           />

            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  servicesMainContainer: {
    // background: "#EEE7D9",
    padding: "2% 0",
  },
  servicesContainer: {
    background: "#fff",
    borderRadius: "28px",
    padding: "32px 40px",
    height: "max-content",
  },
  offeringBox: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap" as "wrap",
    marginBottom: "2%",
  } as any,
};
// Customizable Area End
