import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import StorageProvider from "../../../framework/src/StorageProvider";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  showParameters: boolean;
  deleteModal: boolean;
  shareModal: boolean;
  toasterMsg: boolean;
  anchorEl: any;
  saveWorkflowModal: boolean;
  changeWorkFlow: boolean;
  rightDrawer: boolean;
  parameterWorkflow: any;
  displayParameter: boolean;
  selectedParameterWorkflowId: any;
  selectedParameterWorkflowData: any;
  selectFeildEnable: any;
  selectedFeilds: any;
  activeTab:any,
  selectedHistoryId:any,
  searchTextWorkflow:string;
  openParameter:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Chatbot4Controller extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiAllGetParameterWorkflowsCallId: string = "";
  apiGetParameterWorkflowsCallId: string = "";
  timer:any= null;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      showParameters: true,
      deleteModal: false,
      shareModal: false,
      toasterMsg: false,
      anchorEl: null,
      saveWorkflowModal: false,
      changeWorkFlow: false,
      rightDrawer: false,
      parameterWorkflow: [],
      displayParameter: false,
      selectedParameterWorkflowId: "0",
      selectedParameterWorkflowData: null,
      selectFeildEnable: false,
      selectedFeilds: [],
      activeTab:1,
      selectedHistoryId:null,
      searchTextWorkflow:"",
      openParameter:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.apiAllGetParameterWorkflowsCallId) {
        this.handelAllGetParameterWorkflowsAPIResponse(responseJson);
      }
      if (apiRequestCallId === this.apiGetParameterWorkflowsCallId) {
        this.handelGetParameterWorkflowsAPIResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidMount = async () => {
    const userToken = await StorageProvider.get("user-token");
    if (!userToken) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationWelcomeScreenMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
      return;
    }
    this.fetchAllParameterWorkFlows();
    console.log("Component Loaded")
  };

  handelSelectFeildBtn = () => {
    this.setState({ selectFeildEnable: true });
  };
  handelFeildCheckBoxClick = (e: any, id: any) => {
    let filterFromGivenId = this.state.parameterWorkflow.map((pw:any)=>pw.id !== id)
   if(e.target.checked){
     this.setState({ selectedFeilds: [...this.state.selectedFeilds, `${id}`] });
    }else{
     this.setState({ selectedFeilds: filterFromGivenId });
    }
  };

  handelSelectAllWorkFLow = (e: any) => {
    let getAllParamFlowIds = this.state.parameterWorkflow.map((pw:any)=>pw.id)
    if(e.target.checked){
      this.setState({ selectedFeilds:getAllParamFlowIds });
     }else{
      this.setState({ selectedFeilds: [] });
     }
  };

  handelSubmitButton = (values: any) => {
    console.log(values);
  };

  fetchAllParameterWorkFlows = async () => {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiAllGetParameterWorkflowsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getParameterWorkflowsApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handelonChangeOnWorkFlow = async (id: any) => {
    this.setState({ selectedParameterWorkflowId: id });
    const header = {
      "Content-Type": configJSON.loginApiContentType,
      token: await StorageProvider.get("user-token"),
    };
    const url = `${configJSON.getParameterWorkflowsApiEndpoint}/${id}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetParameterWorkflowsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handelAllGetParameterWorkflowsAPIResponse(responseJson: any) {
    if (responseJson?.data.length == 0) {
      this.setState({ displayParameter: true });
    }
    this.setState({
      parameterWorkflow: responseJson?.data,
      selectedParameterWorkflowId: responseJson?.data[0]?.id,
    });
  }
  handelGetParameterWorkflowsAPIResponse(responseJson: any) {
    this.setState({
      selectedParameterWorkflowData: responseJson?.data,
      displayParameter: true,
    });
  }
  handelSearchTextFlow(value: string) {
    this.setState({
      searchTextWorkflow: value,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      console.log(value)
    }, 500);
  }
  // Customizable Area End
}
