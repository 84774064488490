import React from "react";
// Customizable Area Start
import { Button, Typography, Slider } from "@material-ui/core";
export const configJSON = require("./config");

import ParameterModalController, {
  AnswerFormat,
  Props,
  QuestionFormat,
} from "./ParameterModalController.web";
import InfoIcon from "../../../components/src/InfoIcon.web";
import { Form, Formik } from "formik";
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import { close } from "./assets";
import Dropdown from "../../../components/src/Dropdown.web";
import BookmarkBorder from "@material-ui/icons/BookmarkBorder";

const theme = createTheme({
  overrides: {
    MuiTypography: {
      root: {
        fontFamily: "Montserrat",
      },
    },
    MuiRadio: {
      root: {
        padding: "4px 8px",
      },
    },
    MuiChip: {
      label: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiSlider: {
      thumb: {
        width: "14px",
        height: "14px",
        border: "2px solid #3A84C9",
        borderRadius: "20px",
        backgroundColor: "white",
      },
      rail: {
        height: "4px",
        backgroundColor: "#F1F5F9",
        borderRadius: "10px",
        overflow: "hidden",
        opacity: 1,
      },
      track: {
        height: "4px",
        backgroundColor: "#3A84C9",
        opacity: 1,
        borderRadius: "10px",
        overflow: "hidden",
      },
    },
  },
});

const ResponsiveContainer = styled("div")({
  width: "1158px",
  padding: "20px",
  height: "80vh",
  borderRadius: "28px",
  "@media(max-width: 1024px)": {
    width: "90vw",
  },
  background: "#FFF",
  position: "relative",
  display: "flex",
  flexDirection: "column",
});

const PrettoSlider = styled(Slider)({});

const SButton = styled(Button)({
  background: "#57be99",
  color: "black",
  fontWeight: 600,
  lineHeight: "120%",
  width: "100%",
  maxWidth: "800px",
  textTransform: "none",
  fontFamily: "Montserrat",
  padding: "10px 16px",
  fontSize: "16px",
  borderRadius: "8px",
  "&:hover": {
    background: "#57be99",
    color: "black",
  },
});

export default class ParameterModal extends ParameterModalController {
  constructor(props: Props) {
    super(props);
  }

  renderAnswerDropdown = (
    type: string,
    question: QuestionFormat,
    setFieldValue: (type: string, value: string) => void,
    value: string
  ) => {
    const data = [{ id: "" as any, name: "Choose Option..." }].concat(
      question?.answers.map((ans) => ({ id: ans.id, name: ans.answer }))
    );
    return (
      <Dropdown
        placeholder="ChooseOption"
        dataList={data}
        name={`${type}-${question.id}`}
        value={value}
        handleChange={(e) => {
          setFieldValue(`${type}-${question.id}`, e.target.value + "");
        }}
      />
    );
  };
  renderAnswerChip = (
    type: string,
    question: QuestionFormat,
    setFieldValue: (type: string, value: string) => void,
    value: string
  ) => {
    const valueSplit = value.split("-");
    const activeCheck = (id: number) =>
      valueSplit.findIndex((val) => val + "" === id + "") !== -1;

    return (
      <div style={{ flexWrap: "wrap", display: "flex" }}>
        {question.answers.map((answer: AnswerFormat) => (
            <div
              data-testid={`${type}-${question.id}-${answer.id}`}
              onClick={() =>
                this.onChangeChips(
                  valueSplit,
                  type,
                  setFieldValue,
                  question,
                  answer.id
                )
              }
              key={answer.id}
              style={{
                ...webStyle.chipWrapper,
                ...this.handleConditionalStyle(
                  activeCheck(answer.id),
                  webStyle.activeChipWrapper,
                  {}
                ),
              }}
            >
              <span style={webStyle.chipText}>{answer.answer}</span>
              <InfoIcon
                iconColor={this.handleConditionalStyle(
                  activeCheck(answer.id),
                  "white",
                  "#3A84C9"
                )}
                size={14}
                htmlContent={answer.hint + ""}
              />
            </div>
          ))}
      </div>
    );
  };

  renderRange = (
    type: string,
    question: QuestionFormat,
    setFieldValue: (type: string, value: string) => void,
    value: string
  ) => {
    return (
      <>
        <Typography style={webStyle.noted}>
          {
            "You can choose only 1 slider. Double click to the elipse to return this question to default state"
          }
        </Typography>
        {question.answers.map((answer: AnswerFormat) => {
          const rangeLable = answer.answer.split(",");
          return (
            <React.Fragment key={answer.id}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p style={webStyle.rangeLeftText}>{rangeLable[0]}</p>
                <p style={webStyle.rangeLeftText}>{rangeLable[1]}</p>
              </div>
              <PrettoSlider
                valueLabelDisplay="auto"
                aria-label="pretto slider"
                defaultValue={50}
                data-test-id="slider-element"
                onChange={(event, value) => {}}
              />
            </React.Fragment>
          );
        })}
      </>
    );
  };

  render() {
    const { initValue, validation } = this.getInitFormikData();

    return (
      <ThemeProvider theme={theme}>
        <Formik
          data-testid="parameter-dialog"
          initialValues={initValue}
          validationSchema={validation}
          isInitialValid={false}
          onSubmit={(values: any) => {}}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <ResponsiveContainer>
                <div style={webStyle.closeBtn}>
                  <img
                    src={close}
                    onClick={this.props.onCloseModal}
                    width={16}
                    height={16}
                  />
                </div>
                <div style={webStyle.contentWrapper}>
                  <div style={webStyle.content}>
                    <Typography style={webStyle.bigTitle}>
                      {"PERSUASION TACTICS"}
                    </Typography>
                    <Typography style={webStyle.title}>
                      Choose a combination{" "}
                      <span style={{ fontWeight: 700 }}>3 options</span> here.
                      The first one is mandatory.
                    </Typography>
                    {this.getFormList("PERSUASION TACTICS").map(
                      (question: any, index: number) => (
                        <div
                          key={question.id}
                          style={{
                            ...webStyle.questionWrapper,
                            ...this.handleConditionalStyle(
                              index === 0,
                              webStyle.requiredQuestionWrapper,
                              {}
                            ),
                          }}
                        >
                          <div style={webStyle.questionTextWrapper}>
                            <span style={webStyle.questionText}>
                              {question.question}
                            </span>
                            {index === 0 && (
                              <div style={webStyle.hintWrapper}>
                                <InfoIcon
                                  size={14}
                                  htmlContent={question.hint}
                                />
                              </div>
                            )}
                          </div>
                          {this.handleConditionalStyle(
                            question.type === "select",
                            this.renderAnswerDropdown(
                              "persuasion",
                              question,
                              setFieldValue,
                              values[`persuasion-${question.id}`]
                            )
                          )}
                          {this.handleConditionalStyle(
                            question.type === "chips",
                            this.renderAnswerChip(
                              "persuasion",
                              question,
                              setFieldValue,
                              values[`persuasion-${question.id}`]
                            )
                          )}
                        </div>
                      )
                    )}
                  </div>
                  <div
                    style={{
                      ...webStyle.content,
                    }}
                  >
                    <Typography style={webStyle.bigTitle}>
                      COMMUNICATION TACTICS
                    </Typography>
                    <Typography style={webStyle.title}>
                      {"Choose a combination of "}
                      <span style={{ fontWeight: 700 }}>3 options</span>
                      {". The first one is mandatory."}
                    </Typography>
                    {this.getFormList('COMMUNICATION  TACTICS').map(
                      (question: any, index: number) => (
                        <div
                          style={{
                            ...webStyle.questionWrapper,
                            ...this.handleConditionalStyle(
                              index === 0,
                              webStyle.requiredQuestionWrapper,
                              {}
                            ),
                          }}
                          key={question.id}
                        >
                          <div style={{ ...webStyle.questionTextWrapper }}>
                            <span style={webStyle.questionText}>
                              {question.question}
                            </span>
                            {index === 0 && (
                              <div style={{ ...webStyle.hintWrapper }}>
                                <InfoIcon
                                  size={14}
                                  htmlContent={question.hint}
                                />
                              </div>
                            )}
                          </div>
                          {this.handleConditionalStyle(
                            question.type === "chips",
                            this.renderAnswerChip(
                              "communication",
                              question,
                              setFieldValue,
                              values[`communication-${question.id}`]
                            )
                          )}
                          {this.handleConditionalStyle(
                            question.type === "select",
                            this.renderAnswerDropdown(
                              "communication",
                              question,
                              setFieldValue,
                              values[`communication-${question.id}`]
                            )
                          )}
                          {this.handleConditionalStyle(
                            question.type === "range",
                            this.renderRange(
                              "communication",
                              question,
                              setFieldValue,
                              values[`communication-${question.id}`]
                            )
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div style={webStyle.buttonWrapper}>
                  <SButton
                    data-test-id="save-persuation"
                    type="submit"
                    style={webStyle.normalButton}
                    onClick={this.props.onSave}
                  >
                    Save parameters
                    <BookmarkBorder
                    />
                  </SButton>

                  <SButton
                    onClick={this.props.onApply}
                    data-test-id="save-and-submit"
                    type="submit"
                  >
                    Apply parameters
                  </SButton>
                </div>
              </ResponsiveContainer>
            </Form>
          )}
        </Formik>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  tacticWrapper: {
    margin: "8px 0",
    marginLeft: "32px",
    columnGap: "16px",
    columnCount: 2,
  },
  closeBtn: {
    display: "flex",
    alignItems: "center",
    top: "20px",
    right: "20px",
    position: "absolute" as "absolute",
    zIndex: 1000,
    background: "#fff",
  },
  contentWrapper: {
    display: "flex",
    flex: 1,
    gap: "10px",
    overflowY: "auto" as "auto",
    overflowX: "hidden" as "hidden",
    paddingRight: "10px",
  },
  disabled: { opacity: 0.5 },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "24px",
    gap: "16px",
  },
  bigTitle: {
    color: "#22507B",
    textAlign: "center" as "center",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "150%",
  },
  title: {
    marginTop: "8px",
    color: "#475569",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "120%",
    textAlign: "center" as "center",
    marginBottom: "12px",
  },
  questionTextWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },
  hintWrapper: { background: "#D9E7F3", borderRadius: 500, padding: 4 },
  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column" as "column",
  },
  questionWrapper: {
    padding: "12px",
    background: "white",
    maxWidth: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "16px",
    display: "flex",
    marginBottom: "12px",
    flexDirection: "column" as "column",
  },
  questionText: { fontFamily: "Montserrat", fontSize: "14px", fontWeight: 600 },
  requiredQuestionWrapper: {
    padding: "16px 20px",
    background: "#F1F5F9",
    border: "1px solid #94A3B8",
  },
  optionalText: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#475569",
  },
  chipWrapper: {
    padding: "4px 8px",
    borderRadius: "8px",
    border: "1px solid #94A3B8",
    color: "#000",
    backgroundColor: "white",
    margin: "2px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  activeChipWrapper: {
    border: "1px solid #000",
    color: "#fff",
    backgroundColor: "#000",
  },
  chipText: {
    textAlign: "left" as "left",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "150%",
    marginRight: "4px",
  },
  noted: {
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "140%",
    color: "#475569",
    marginBottom: "8px",
  },
  rangeLeftText: {
    margin: "0px",
    color: "#000",
    textAlign: "right" as "right",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "100%",
  },
  disabledBtn: {
    background: "#E2E8F0",
    color: "#64748B",
  },
  normalButton: {
    background: "white",
    color: "#000",
    border: "1px solid #CBD5E1",
  },
  normalImg: {
    color: "#000",
    width: "20px",
    height: "20px",
  },
  activeImg: {
    color: "#3A84C9",
    width: "20px",
    height: "20px",
  },
};
// Customizable Area End