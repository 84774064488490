import React from "react";


// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    IconButton,
    TextField,
    Snackbar
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import EmailChanged from '../../info-page/src/EmailChanged.web';
import { checkIcon } from "./assets";
import CloseIcon from "@material-ui/icons/Close";
// Customizable Area End

import SettingsChangeEmailController, {
    Props,
    configJSON,
} from "./SettingsChangeEmailController";

export class SettingsChangeEmail extends SettingsChangeEmailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        return (
            // Customizable Area Start
            <>
            {!this.state.openSuccess ?
            <Box className={classes.mainContainer}>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    open={this.state.errorToast}
                    autoHideDuration={8000}
                    onClose={this.handleCloseToast}
                    message={this.state.errors}
                    action={
                        <React.Fragment>
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleCloseToast}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                />
                <ArrowBackIosIcon className={classes.arrowBack} onClick={this.props.handleCloseEditMail} />
                <Typography className={classes.mainHeading}>{configJSON.changeEmail}</Typography>
                <Box>
                    <Typography className={classes.newMailLabel}>{configJSON.newMailLabel}</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        className={classes.emailTextfield}
                        placeholder={configJSON.newMailPlaceholder}
                        value={this.state.newEmail}
                        onChange={this.handleEmailChange}
                        error={this.state.error}
                        helperText={this.state.errorMessage}
                        disabled={this.state.showVerify}
                        data-testid="email-change"
                    />
                </Box>
                {
                    this.state.showVerify &&
                    <>
                        <Typography className={classes.sentDetails}>{configJSON.justSent}<span className={classes.temporary}>{configJSON.temporaryCode}</span></Typography>
                        <Typography className={classes.sentDetails}>{configJSON.pleaseCheck}<span className={classes.temporary}>{configJSON.inbox}</span><span>{configJSON.pastCode}</span></Typography>
                        <Box>
                            <Typography className={classes.verificationLabel}>{configJSON.verificationCodeLabel}</Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                placeholder={configJSON.codePlaceholder}
                                size="small"
                                value={this.state.verifyCode}
                                className={classes.emailTextfield}
                                onChange={this.handleVerifyCode}
                                error={this.state.verifyError}
                                helperText={this.state.verifyErrorMessage}
                                data-testid="otp-field"
                            />
                        </Box>
                    </>
                }

                <Button variant="contained" className={classes.submitButton} data-testid="submit-button" onClick={this.handleSubmit}>{configJSON.submitButton}</Button>
                {this.state.showVerify &&
                    <>
                        <Typography className={classes.didRecieve}>{configJSON.didnotRecieve}<span className={classes.resendText} onClick={this.getOptVerification}>{configJSON.resend}</span></Typography>
                        <Box>
                            <Typography className={classes.didRecieve}>{configJSON.willBeActive}<span className={classes.resendTime}>{this.state.resendTime}</span></Typography>
                        </Box>
                    </>
                }
            </Box> :
            <EmailChanged data-testid="email-success" {...this.props} openEmail={this.state.openSuccess} handleCloseMainModule={this.props.handleCloseMainModal} checkIcon={checkIcon} handleCloseCurrentModule={this.props.handleCloseEditMail} />
            }
            </>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: {} = () => ({
    mainContainer: {
        position: 'relative',
        marginTop: '25px'
    },
    arrowBack: {
        position: 'absolute',
        top: '13',
        fontSize: '1rem',
        cursor: 'pointer'
    },
    mainHeading: {
        fontSize: '1.8rem',
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: '25px'
    },
    emailTextfield: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            border: '1px solid #94A3B8',
        },
        margin: '5px 0',
        '& .Mui-error':{
            marginLeft:'0px !important'
        }
    },
    newMailLabel: {
        fontWeight: 600
    },
    sentDetails: {
        fontSize: '0.8rem',
        color: '#475569'
    },
    temporary: {
        margin: '0 5px',
        fontWeight: 600
    },
    verificationLabel: {
        fontWeight: 600,
        marginTop: '15px'
    },
    submitButton: {
        background: '#000',
        color: '#fff',
        borderRadius: '8px',
        width: '100%',
        margin: '25px 0 15px',
        fontWeight:600,
        textTransform: 'capitalize' as 'capitalize',
    },
    didRecieve: {
        color: '#475569',
        fontWeight: 500,
        fontSize: '0.8rem',
        textAlign: 'center'
    },
    resendText: {
        color: '#94A3B8',
        fontWeight: 700,
        marginLeft: '5px',
        cursor: 'pointer'
    },
    resendTime: {
        fontSize: '1.2rem',
        marginLeft: '5px',
        fontWeight: 400
    }
});
export default withStyles(webStyle)(SettingsChangeEmail);

// Customizable Area End
