import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
interface MultipleInputItem {
    offeringPlaceholder: string;
    descriptionPlaceholder: string;
    offering: string;
    description: string;
    offeringTitle: string;
    descriptionTitle: string;
    charLength: number;
    charLengthDesc: number;
}

interface TextAreaInput{
    placeholder: string;
    value: string;
    label: string
    charLength: number
}

export const  initialValues:any =  {
    processStep:['',''],
    processDescription:['',''],
    whyExist:[],
    background:"",
    problem:"",
    storySolution1:'',
    storySolution2:'',
    storySolution3:'',
    storyResult1:'',
    storyResult2:'',
    storyResult3:'',
    stage1:'',
    stageDescription1:"",
    stage2:'',
    stageDescription2:"",
    stage3:'',
    stageDescription3:"",
    solutionTitle1:'',
    solutionStatement1:"",
    solutionTitle2:'',
    solutionStatement2:"",
    solutionTitle3:'',
    solutionStatement3:"",
    segmentOverview:[""],
    segmentTitle1:'',
    segmentDescription1:'',
    segmentTitle2:'',
    segmentDescription2:'',
    segmentTitle3:'',
    segmentDescription3:'',
    processSection:[''],
    compareSection:['']
}
const chipDatas = ["AI-native", "Deep learning", "Science-based approach","Research-backed methodologies","Personalised GTM Co-Pilot","Industry best practises"]
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    serviceArray: string[];
    serviceArray1: string[];
    shouldHaveServiceArray: string[];
    inputList: any;
    activeStep: any;
    prevCompletedStep: any;
    mustHaveExpanded: any;
    shouldHaveExpanded: any;
    goodToHaveExpanded: any;
    chipLabels: any;
    alreadyFilledFormValues:{[key:string]:string}
    successStoriesInputList: (TextAreaInput&{name:string})[];  
    successStoriesServiceArr: any;
    companyHeadquarterInputList: any;
    segmentExpertiseServiceArr: any;
    primaryQuestionInputList: any;
    primaryQuestionServiceArr: any;
    companyAchievementsInputList: any;
    companyAchievementsServiceArr: any;
    companyFactsInputList: any;
    companyFactsServiceArr: any;
    segmentExpertiseInputList: TextAreaInput[];
    offeringsServiceArray: string[];
    offeringsInputList: MultipleInputItem[],
    headerSubStep:number;
    theProcessInputList:MultipleInputItem[],
    approachServiceArray: string[];
    approachInputList: MultipleInputItem[],
    storySolutionInputList: TextAreaInput[],
    storyResultInputList:TextAreaInput[],
    keySegmentInputList: MultipleInputItem[];
    keySegmentServiceArray: any;
    highLevelComparisonServiceArr: any;
    mqlServiceArr: any;
    highLevelComparisonInputList: any;
    mqlStatementInputList: any;
    contributionInputList: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AboutYourApproachController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    musthaveApiCallId: string = "";
    shouldhaveApiCallId:string = '';
    goodToHavehaveApiCallId:string = '';
    getInfoMgId:string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area Start
            // Customizable Area End
        ];
        this.receive = this.receive.bind(this);

        this.state = {
            // Customizable Area Start
            headerSubStep:1,
            alreadyFilledFormValues:{},
            mustHaveExpanded: true,
            shouldHaveExpanded: false,
            goodToHaveExpanded: false,
            activeStep: 2,
            prevCompletedStep: 1,
            storySolutionInputList:[
                {
                    placeholder: 'Strategic Roadmap: crafted a specialised go-to-market plan, tailored for their company’s success.',
                    value: '',
                    label: 'Point',
                    charLength: 100,
                },
                {
                    placeholder: 'Deep Market Intelligence: gained a comprehensive understanding of their target markets.',
                    value: '',
                    label: 'Point',
                    charLength: 100,
                },
                {
                    placeholder: 'Qualified Team Support: introduced a team of seasoned professionals to provide strategic guidance and execution.',
                    value: '',
                    label: 'Point',
                    charLength: 100,
                },
            ],
            storyResultInputList:[
                {
                    placeholder: 'The sales cycle drastically shortened from 9 months to 4 weeks.',
                    value: '',
                    label: 'Point',
                    charLength: 100,
                },
                {
                    placeholder: 'They witnessed a 6X revenue increase within 12 months. ',
                    value: '',
                    label: 'Point',
                    charLength: 100,
                },
                {
                    placeholder: 'The company expanded its offering to 3 different geolocations in a rapid expansion move.',
                    value: '',
                    label: 'Point',
                    charLength: 100,
                },
            ],
            theProcessInputList:[
                {
                    offeringPlaceholder: "Mission Brief: gather and review client intelligence.",
                    descriptionPlaceholder: "Description of step 1",
                    offering: '',
                    description: '',
                    offeringTitle: 'Step N',
                    descriptionTitle: 'Description',
                    charLength: 30,
                    charLengthDesc: 70
                },
                {
                    offeringPlaceholder: "Marketing Strategy: produce GTMS™ Source of Truth marketing strategy.",
                    descriptionPlaceholder: "Description of step 2",
                    offering: '',
                    description: '',
                    offeringTitle: 'Step N',
                    descriptionTitle: 'Description',
                    charLength: 30,
                    charLengthDesc: 70
                }
            ],
            offeringsServiceArray: ['Max 3 solutions statements', 'Title length max 30 characters', 'Description length max 300 characters '],
            offeringsInputList: [
                {
                    offeringPlaceholder: "Levelling the GTM Field",
                    descriptionPlaceholder: "We democratise B2B/B2C success using scientific frameworks, industry best practices, and advanced machine learning with a singular focus on GTM. Pebbles Ai is an AI-native, end-to-end GTM platform that supercharges your productivity, propels your business growth, and ensures your long-term success.",
                    offering: '',
                    description: '',
                    offeringTitle: 'Solution Title',
                    descriptionTitle: 'Solution Statement',
                    charLength: 30,
                    charLengthDesc: 300
                },
                {
                    offeringPlaceholder: "Harnessing GTM Wisdom",
                    descriptionPlaceholder: "With our science-based go-to-market wisdom and multi-layer AI architecture, your business can now access its own centralised GTM cognition. Our intelligence as a service offers a comprehensive suite of GTM solutions complete with cross-platform integrations and open APIs for corporate partners and clients alike.",
                    offering: '',
                    description: '',
                    offeringTitle: 'Solution Title',
                    descriptionTitle: 'Solution Statement',
                    charLength: 30,
                    charLengthDesc: 300
                },
                {
                    offeringPlaceholder: "Boosting GTM Workflows",
                    descriptionPlaceholder: "We harness multi-disciplinary sciences to supercharge your MarkSales workflows, letting GTM teams achieve peak productivity and maximise efficacy. This highly personalised, knowledgeable GTM co-pilot is an indispensable intelligence & content generator for any GTM professional.",
                    offering: '',
                    description: '',
                    offeringTitle: 'Solution Title',
                    descriptionTitle: 'Solution Statement',
                    charLength: 30,
                    charLengthDesc: 300

                },
            ],
            approachServiceArray: ['Max 3 stages and 30 characters each', 'Description length max 150 characters each'],
            approachInputList: [
                {
                    offeringPlaceholder: "Marketing Strategy",
                    descriptionPlaceholder: "Effectively bring your products and services to market with a strategic go-to-market roadmap to business success from market entry to expansion.",
                    offering: '',
                    description: '',
                    offeringTitle: 'Stage',
                    descriptionTitle: 'Description',
                    charLength: 30,
                    charLengthDesc: 150
                },
                {
                    offeringPlaceholder: "Market Intelligence",
                    descriptionPlaceholder: "We acquire a comprehensive understanding of your markets covering industry segments, geolocations, persona clusters, and deep persona analyses.",
                    offering: '',
                    description: '',
                    offeringTitle: 'Stage',
                    descriptionTitle: 'Description',
                    charLength: 30,
                    charLengthDesc: 150
                },
                {
                    offeringPlaceholder: "Automated MarTech",
                    descriptionPlaceholder: "We generate significantly more marketing-qualified leads (MQLs) at lower operational costs with automation-driven marketing execution.",
                    offering: '',
                    description: '',
                    offeringTitle: 'Stage',
                    descriptionTitle: 'Description',
                    charLength: 30,
                    charLengthDesc: 150

                },
            ],

            keySegmentServiceArray: ["Max 3 differentiators", "Title length max 15 characters", "Description length max 100 characters" ],
                keySegmentInputList: [
                {
                    offeringPlaceholder: "Science-Led",
                    descriptionPlaceholder: "Strategy models that incorporate multi-disciplinary sciences specifically developed for young enterprises.",
                    offering: '',
                    description: '',
                    offeringTitle: 'Differentiator',
                    descriptionTitle: '',
                    charLength: 15,
                    charLengthDesc: 100
                },
                {
                    offeringPlaceholder: "Innovative",
                    descriptionPlaceholder: "Cutting-edge methodologies that have been empirically researched, tested, and applied for real-world usage.",
                    offering: '',
                    description: '',
                    offeringTitle: 'Differentiator',
                    descriptionTitle: '',
                    charLength: 15,
                    charLengthDesc: 100
                },
                {
                    offeringPlaceholder: "Automated",
                    descriptionPlaceholder: "Marketing execution that reduces GTM-related costs & dramatically increases incoming qualified leads.",
                    offering: '',
                    description: '',
                    offeringTitle: 'Differentiator',
                    descriptionTitle: '',
                    charLength: 15,
                    charLengthDesc: 100

                },
            ],
            segmentExpertiseInputList: [
                {
                    label: "",
                    placeholder: "We serve various sectors, but have particular experience in Tech, Web3, IoT, FMCG, Healthcare, Business and Financial Services.Whether you run a lean 10-person team or a more established 1000-strong business, we bring expertise and tools to set you up for success.",
                    value: "",
                    charLength: 300

                },
            ],
            successStoriesInputList: [
                {
                    label: "Background",
                    placeholder: "In the competitive B2B/B2BC sector, a majority of SMEs struggle due to untapped elite GTM strategies and a vast knowledge chasm.",
                    value: "",
                    name: "background",
                    charLength: 130

                },
                {
                    label: "Problem",
                    placeholder: "Our customer despite their ambition, grappled with an extended sales cycle, and slow revenue growth.",
                    value: "",
                    name: "problem",
                    charLength: 100

                }
            ],
            
            successStoriesServiceArr: ["Background length max 130 characters", "Problem length max 100 characters each", "Solution length max 100 characters each", "Results length max 100 characters each"],
            companyHeadquarterInputList: [
                {
                    label: "",
                    placeholder: "Headquartered in London, we are Europe’s science-driven Marketing Strategy House that has easy access to top-qualified talent.",
                    value: "",
                    name: "",
                    charLength: 150

                },
            ],
            segmentExpertiseServiceArr: ["Max 300 characters "],
            highLevelComparisonServiceArr: ["Max 250 characters "],
            mqlServiceArr: ["Max 200 characters "],
            highLevelComparisonInputList: [
                {
                    label: "",
                    placeholder: "Unlike typical agencies, our foundation is scientific, not creative. Blending psychology, neuromarketing, and data science, we offer formidable advantages. Our edge lies in our proprietary strategy models, cutting-edge GTM technologies, and our elite, seasoned team.",
                    value: "",
                    name: "",
                    charLength: 250

                },
            ],
            mqlStatementInputList: [
                {
                    label: "",
                    placeholder: "We are an enterprise-grade Marketing Strategy House that is specialised in taking small and medium-sized enterprises to market through our science-led marketing strategies, market intel, and marketing automation.",
                    value: "",
                    name: "",
                    charLength: 200

                },
            ],
            contributionInputList: [
                {
                    label: "",
                    placeholder: "We commit to using part of our profits to support children in Ukrainian orphanages by supplying them with educational toys and musical instruments, aiding the development of their spatial reasoning, numeracy skills, and literacy skills.",
                    value: "",
                    name: "",
                    charLength: 200

                },
            ],
            companyAchievementsInputList: [
                {
                    label: "Testimonial",
                    placeholder: "Carl-Henrik Johnson | VP of Sales | Clausematch | B2B SaaS | FinTech The GTMS provided us with a clear, persona-based sales approach with a laser-like focus, which resulted in landing a multinational bank in our 9th month and seeing an overall increase in revenue by 450% YoY.",
                    value: "",
                    name: "testimonial1",
                    charLength: 40

                },
            ],
            companyAchievementsServiceArr: ["Name", 
                "Title", 
                "Company",
                "Industry",
                "Technology",
                "Max 3 testimonials",
                "Max 40 characters each",
            ],
            companyFactsInputList: [
                {
                    label: "Fact",
                    placeholder: "12 industries of combined knowledge",
                    value: "",
                    name: "fact1",
                    charLength: 50
                },
            ],
            companyFactsServiceArr: ["Title ",
                "Company",
                "Industry",
                "Technology",
                "Max 5 testimonials",
                "Max 50 characters each"],
            primaryQuestionInputList: [
                {
                    label: "Question",
                    placeholder: "Feeling anxious about the growth of your company?",
                    value: "",
                    name: "question1",
                    charLength: 30

                },
                {
                    label: "Question",
                    placeholder: "In need of a growth partner that you can truly rely on?",
                    value: "",
                    name: "question2",
                    charLength: 30
                },
                {
                    label: "Question",
                    placeholder: "Worrying about the efficacy of your marketing spend?",
                    value: "",
                    name: "question3",
                    charLength: 30
                }
            ],
            primaryQuestionServiceArr: ['Max 3 questions', ' Max 50 characters each'],
            shouldHaveServiceArray: ["Max 10 steps",
                "Title length max 30 characters",
                "Description length max 70 characters"],
            serviceArray: ['Max 3 questions', 'Max 50 characters each'],
            serviceArray1: ['Min 10 and max 20 words', 'Max 3 words for each designation'],
            inputList: [
                {
                    label: "Step",
                    placeholder: "Mission Brief: gather and review client intelligence.",
                    value: "",
                    name: "step1",
                    charLength: 30
                },
                {
                    label: "Step",
                    placeholder: "Marketing Strategy: produce GTMS™ Source of Truth marketing strategy.",
                    value: "",
                    name: "step2",
                    charLength: 70

                },
            ],
            chipLabels: chipDatas
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.fetchInitData()
        // Customizable Area End
    }
    // Customizable Area Start
    

    saveAndContinue = (values: any) => {
        if (this.state.mustHaveExpanded) {
          this.mustHaveOnSubmit(values);
        } else if (this.state.shouldHaveExpanded) {
          this.shouldHaveOnSubmit(values);
        } else {
          this.goodToHaveOnSubmit(values);
        }
      };

    handleCommonObjectChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
        listName: keyof S, // Use keyof to specify the list name
        propertyName: string
    ) => {
        const { value } = event.target;
        this.setState((prevState): any => {
            const updatedList = [...prevState[listName]];
            updatedList[index][propertyName] = value;
            return { [listName]: updatedList };
        });
    };
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            switch (apiRequestCallId) {
                case this.musthaveApiCallId:
                  if (!responseJson.errors) {
                    this.setState({
                      mustHaveExpanded: false,
                      shouldHaveExpanded: true,
                      headerSubStep: 2,
                    });
                  } else {
                    
                    this.parseApiErrorResponse(responseJson);
                  }
                  break;
                case this.shouldhaveApiCallId:
                  this.handleShouldHaveRes(responseJson)
                  break;
                case this.getInfoMgId:
                    if (!responseJson.errors) {
                        this.handleInitData(responseJson)
                    }
                break;
                case this.goodToHavehaveApiCallId:
                  this.handleGoodtoHaveRes(responseJson);
        
                  this.parseApiCatchErrorResponse(errorReponse);
                  break;
              }
        }
    }

    // Customizable Area End

    // Customizable Area End
    // Customizable Area End

    // web events

    // Customizable Area Start
    handleShouldHaveRes=(responseJson:any)=>{
        if (!responseJson.errors) {
            this.setState({
              shouldHaveExpanded: false,
              goodToHaveExpanded: true,
              headerSubStep: 2,
            });
          } else {
            this.parseApiErrorResponse(responseJson);
          }
    }
    handleGoodtoHaveRes=(responseJson:any)=>{
        if (!responseJson.errors) {
            this.setState({ goodToHaveExpanded: false, headerSubStep: 3 });
            const message: Message = new Message(
              getName(MessageEnum.NavigationMessage)
            );
            message.addData(
              getName(MessageEnum.NavigationTargetMessage),
              "CompanyOffer"
            );
            message.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );
            this.send(message);
          } else {
            
            this.parseApiErrorResponse(responseJson);
          }
    }
    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
        const { name, value } = event.target;
        const list: any = [...this.state.inputList];
        list[index][name] = value;
        this.setState({ inputList: list })
    }
    handleExpand = () => {
        this.setState({ mustHaveExpanded: !this.state.mustHaveExpanded })
    }
    handleExpand1 = () => {
        this.setState({ shouldHaveExpanded: !this.state.shouldHaveExpanded })
    }
    handleExpand2 = () => {
        this.setState({ goodToHaveExpanded: !this.state.goodToHaveExpanded })
    }
    deleteChipByName = (nameToDelete: any) => {
        this.setState((prevState) => ({
            chipLabels: prevState.chipLabels.filter((label: any) => label !== nameToDelete),
        }));
    };
    addChipLabels = (value: string) => {
        const { chipLabels } = this.state;
        if (value.trim() !== '') {
            // Create a new array with the new item added to the end
            const updatedItems = [...chipLabels, value];
            this.setState({
                chipLabels: updatedItems,
            });
        }
    }
    handleFormikChange = (
        setFieldValue: (key: string, value: any) => void,
        value: string,
        index: number,
        key: string,
        oldListValue: string[]
    ) => {
        const newListValue = oldListValue.map((_value,idx)=>{
            if(idx===index) return value;
            return _value
        })
        setFieldValue(key,newListValue)
    }
    checkValidation = (touch: boolean, error:any) => {
        return !!touch && !!error ? error : ''
    }

    addProcess = () => {
        const { theProcessInputList } = this.state;
            // Create a new array with the new item added to the end
            const updatedItems = [...theProcessInputList, {
                offeringPlaceholder: "",
                descriptionPlaceholder: "",
                offering: '',
                description: '',
                offeringTitle: 'Step N',
                descriptionTitle: 'Description',
                charLength: 30,
                charLengthDesc: 70
            }];
            this.setState({
                theProcessInputList: updatedItems,
            });
    }
    handleAddItem = (value: any) => {
        const { inputList } = this.state;
        if (value.placeholder.trim() !== '') {
            // Create a new array with the new item added to the end
            const updatedInputList = [...inputList, value];
            this.setState({
                inputList: updatedInputList,
            });
        }
    }

    fetchInitData=async()=>{
        const headers = {
          "Content-Type": configJSON.validationApiContentType,
          token: await StorageProvider.get("user-token"),
        };
        const getValidationsMsg = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getInfoMgId = getValidationsMsg.messageId;
    
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
        getValidationsMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
    
        getValidationsMsg.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.apiGetGoldPlanInfo
        );
    
    
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
      }

    handleInitData = (responseData: any[]) => {
        console.log('responseData',responseData);
        
        const mustHaveSectionList = responseData.filter((item: any) => item.data.attributes.details_type === 'must' && item.data.attributes.object_type === 'approach');
        const shouldHaveSectionList = responseData.filter((item: any) => item.data.attributes.details_type === 'should' && item.data.attributes.object_type === 'approach');
        const goodToHaveSectionList = responseData.filter((item: any) => item.data.attributes.details_type === 'good' && item.data.attributes.object_type === 'approach');
       
        const mustHaveSection = !!mustHaveSectionList.length ? {
          whyExist: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.keywords,
          solutionTitle1: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.solution_statement[0].title,
          solutionStatement1: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.solution_statement[0].description,
          solutionTitle2: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.solution_statement[1].title,
          solutionStatement2: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.solution_statement[1].description,
          solutionTitle3: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.solution_statement[2].title,
          solutionStatement3: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.solution_statement[2].description,
          stage1: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.high_level_approach[0].title,
          stageDescription1: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.high_level_approach[0].description,
          stage2: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.high_level_approach[1].title,
          stageDescription2: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.high_level_approach[1].description,
          stage3: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.high_level_approach[2].title,
          stageDescription3: mustHaveSectionList[mustHaveSectionList.length - 1].data.attributes.high_level_approach[2].description,
        } : { }
    
        const shouldHaveSection = !!shouldHaveSectionList.length ? {
            processStep:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.process.map((item:any)=>item.title),
            processDescription:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.process.map((item:any)=>item.description),
            processSection:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.process_testimonials_attributes.map((item:any)=>item.testimonial),
            background:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.success_stories[0].background,
            problem:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.success_stories[1].problem,
            storySolution1:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.solution[0].point,
            storySolution2:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.solution[1].point,
            storySolution3:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.solution[2].point,
            storyResult1:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.results[0].point,
            storyResult2:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.results[1].point,
            storyResult3:shouldHaveSectionList[shouldHaveSectionList.length - 1].data.attributes.results[2].point,
        } : { }
    
        const goodHaveSection = !!goodToHaveSectionList.length ? {
            segmentOverview:goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.segment_expertise_overiew[0],
            segmentTitle1:goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.key_segment_differentiators[0].title,
            segmentTitle2:goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.key_segment_differentiators[1].title,
            segmentTitle3:goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.key_segment_differentiators[2].title,
            segmentDescription1:goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.key_segment_differentiators[0].description,
            segmentDescription2:goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.key_segment_differentiators[1].description,
            segmentDescription3:goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.key_segment_differentiators[2].description,
            compareSection:goodToHaveSectionList[goodToHaveSectionList.length - 1].data.attributes.high_level_comparison,
        } : { }
        
        this.setState({
          chipLabels:mustHaveSection.whyExist||chipDatas,
          alreadyFilledFormValues:{
            ...initialValues,
            ...mustHaveSection,
            ...shouldHaveSection,
            ...goodHaveSection
          },
        })
      
      }
    
      

    handleObjectChange = (event: any, index: any) => {
        const { value } = event.target;
        const { inputList } = this.state;

        // Create a copy of the inputList array and update the specific item
        const updatedInputList = [...inputList];
        updatedInputList[index]["value"] = value;

        this.setState({
            inputList: updatedInputList
        });
    }
    handleAddObjectItem = () => {
        const { inputList } = this.state;

        // Create a new item and add it to the inputList
        const newItem = {
            label: "Step",
            placeholder: "New Placeholder",
            value: "",
            name: `step${inputList.length + 1}`,
            charLength: 30
        };

        this.setState({
            inputList: [...inputList, newItem]
        });
    }
    handleObjectChangeCompanyAcheivement = (event: any, index: any) => {
        const {  value } = event.target;
        const { companyAchievementsInputList } = this.state;

        // Create a copy of the inputList array and update the specific item
        const updatedInputList = [...companyAchievementsInputList];
        updatedInputList[index]["value"] = value;

        this.setState({
            companyAchievementsInputList: updatedInputList
        });
    }
    handleAddObjectItemCompanyAcheivement = () => {
        const { companyAchievementsInputList } = this.state;

        // Create a new item and add it to the inputList
        const newItem = {
            label: "Testimonial",
            placeholder: "Published in Europe’s marketing bestseller book by Oxford University Press in 2022",
            value: "",
            name: `testimonial${companyAchievementsInputList.length + 1}`,
            charLength: 30
        };

        this.setState({
            companyAchievementsInputList: [...companyAchievementsInputList, newItem]
        });
    }

    backButton = ()=>{
        const message: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "AboutTheCompany"
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
    
    async mustHaveOnSubmit(formValues:any) {
        await StorageProvider.set("filledFormDataApproach",JSON.stringify(formValues));
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: await StorageProvider.get("user-token"),
          };
          const httpBody = {
            data: {
              attributes: {
                "details_type":"must",
                "object_type": "approach",
                keywords: formValues.whyExist,
                solution_statement: [
                    {
                        title:formValues.solutionTitle1,
                        description:formValues.solutionStatement1,
                    },
                    {
                        title:formValues.solutionTitle2,
                        description:formValues.solutionStatement2,
                    },
                    {
                        title:formValues.solutionTitle3,
                        description:formValues.solutionStatement3,
                    }
                ],
                high_level_approach: [
                    {
                        title:formValues.stage1,
                        description:formValues.stageDescription1,
                    },
                    {
                        title:formValues.stage2,
                        description:formValues.stageDescription2,
                    },
                    {
                        title:formValues.stage3,
                        description:formValues.stageDescription3,
                    }
                ]
              },
            },
          };
          const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.musthaveApiCallId = getValidationsMsg.messageId;
      
          getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
          );
      
          getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
      
          getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.musthaveEndPoint
          );
      
          getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
      
          runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }

    async shouldHaveOnSubmit(formikValues:any) {
        await StorageProvider.set("filledFormDataApproach",JSON.stringify(formikValues));
        const headers = {
          "Content-Type": configJSON.validationApiContentType,
          token: await StorageProvider.get("user-token"),
        };
    
        const httpBody = {
          data: {
            attributes: {
                "details_type":"should",
                "object_type": "approach",
                "process": formikValues.processStep.map((value:string,index:number)=>({
                    title:value,
                    description:formikValues.processDescription[index],
                })),
                "process_testimonials_attributes":formikValues.processSection.map((value:string)=>({
                    testimonial:value
                })),
                "success_stories":[{
                    background:formikValues.background,
                },{
                    problem:formikValues.problem
                }],
                "solution":this.state.storySolutionInputList.map((_,index)=>({
                    point:formikValues[`storySolution${index+1}`]
                })),
                "results":this.state.storyResultInputList.map((_,index)=>({
                    point:formikValues[`storyResult${index+1}`]
                }))
            },
          },
        };
    
        const shouldHaveApi = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.shouldhaveApiCallId = shouldHaveApi.messageId;
    
        shouldHaveApi.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.musthaveEndPoint
          );
        shouldHaveApi.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postApiMethodType
        );
    
        shouldHaveApi.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
          );
        shouldHaveApi.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
    
        runEngine.sendMessage(shouldHaveApi.id, shouldHaveApi);
        return true;
      }

      async goodToHaveOnSubmit(formikValues:any) {
        await StorageProvider.set("filledFormDataApproach",JSON.stringify(formikValues));
        const headers = {
          "Content-Type": configJSON.validationApiContentType,
          token: await StorageProvider.get("user-token"),
        };
        const httpBody = {
          data: {
            attributes: {
              object_type: "approach",
              details_type: "good",
              segment_expertise_overiew:[formikValues.segmentOverview],
                  key_segment_differentiators: this.state.keySegmentInputList.map((_,index) => ({
                      title: formikValues[`segmentTitle${index + 1}`],
                      description: formikValues[`segmentDescription${index + 1}`],
                  })),
              high_level_comparison:formikValues.compareSection,
            },
          },
        };
    
        const goodtoHaveAPI = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.goodToHavehaveApiCallId = goodtoHaveAPI.messageId;
    
    
        goodtoHaveAPI.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
        goodtoHaveAPI.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postApiMethodType
        );
    
        goodtoHaveAPI.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.musthaveEndPoint
          );
        goodtoHaveAPI.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
        runEngine.sendMessage(goodtoHaveAPI.id, goodtoHaveAPI);
        return true;
      }
    // Customizable Area End
}
