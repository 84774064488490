export const rightBar = require("../assets/right_bar_icon.svg");
export const mailIcon = require("../assets/group_img_marketing.svg");
export const mailBox = require("../assets/mail.svg");
export const greenIcon = require("../assets/green_not_hover.png");
export const hoverIcon = require("../assets/green_hover.png");
export const nonPaying = require("../assets/no_paying_symbol.png");
export const paying = require("../assets/paying_symbol.png");
export const redIcon = require("../assets/redIcon.png");
export const redHoverIcon = require("../assets/redHoverIcon.png");
export const shopingCart = require("../assets/shoping.png");
export const blueIcon = require("../assets/blueIcon.png");
export const blueIconOnHover = require("../assets/blueOnHover.png");
