import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Modal,
    Paper,
    TextField,
    Chip
} from "@material-ui/core";
import { withStyles, createTheme, ThemeProvider } from "@material-ui/core/styles";
import { close, download, atSign, word, pdf } from "./assets";
import Backdrop from "@material-ui/core/Backdrop";

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    }
});
// Customizable Area End

import ShareFileModalController, {
    Props,
    configJSON,
} from "./ShareFileModalController";

export class ShareFileModal extends ShareFileModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        return (
            <ThemeProvider theme={theme}>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modalMain}
                    open={this.props.open}
                    onClose={this.props.onClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Paper className={classes.shareMainContainer}>
                        <Box>
                            <Typography
                                className={classes.shareFile}
                                align="center"
                            >
                                {configJSON.shareFile}
                            </Typography>
                            <Typography
                                align="center"
                                className={classes.likeShareText}
                            >
                                {configJSON.likeToshare}
                            </Typography>
                            <Box
                                className={classes.fileBtnDisplay}
                            >
                                <Button
                                    fullWidth
                                    className={classes.fileButton}
                                >
                                    <img
                                        src={word}
                                        className={classes.wordImg}
                                    />
                                    <Typography
                                        className={classes.wordFileText}
                                        data-testid="addWordSElection"
                                        onClick={() => this.handleFileType('word')}
                                    >
                                        {configJSON.wordFile}
                                    </Typography>
                                    {this.state.fileType.includes('word') && (
                                        <Box
                                            className={classes.closeButton}
                                        >
                                            <img
                                                src={close}
                                                data-testid="removeWordSElection"
                                                className={classes.closeIcon}
                                                onClick={() => this.handleCancelFile('word')}
                                            />
                                        </Box>
                                    )}
                                </Button>
                                <Button
                                    fullWidth
                                    className={classes.pdfFileButton}
                                >
                                    <img
                                        src={pdf}
                                        className={classes.pdfImg}
                                    />
                                    <Typography
                                        className={classes.pdfFileText}
                                        data-test-id="addPdfSelection"
                                        onClick={() => this.handleFileType('pdf')}
                                    >
                                        {configJSON.pdfFIleText}
                                    </Typography>
                                    {this.state.fileType.includes('pdf') && (
                                        <Box
                                            className={classes.closeButton}
                                        >
                                            <img
                                                src={close}
                                                data-test-id="removePdfSElection"
                                                className={classes.closeIcon}
                                                onClick={() => this.handleCancelFile('pdf')}
                                            />
                                        </Box>
                                    )}
                                </Button>
                            </Box>
                            <Box
                                className={classes.secondBox}
                            >
                                <Typography
                                    className={classes.targetMailLabel}
                                >
                                    {configJSON.targetMail}
                                </Typography>
                                <TextField
                                    data-testid="emailFieldTestId"
                                    variant="outlined"
                                    fullWidth
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleEmailChange}
                                    onKeyPress={this.handleEmailSubmit}
                                    InputProps={{
                                        className: classes.inputProps
                                    }}
                                    placeholder={configJSON.targetMailPlaceholder}
                                />
                                <Typography
                                    className={classes.seperateMail}
                                >
                                    {configJSON.enterMailAdrress}
                                </Typography>
                                <Box data-testid="chip-box">
                                    {this.state.emailList.map(data => 
                                         <Chip
                                         label={data}
                                         data-testid="email-value"
                                         onDelete={() => this.handleRemoveEmail(data)}
                                         className={classes.chip}
                                     />
                                        )}
                                </Box>
                            </Box>
                            <Box
                                className={classes.displayButtonTwo}
                            >
                                <Button
                                    fullWidth
                                    className={classes.downloadButton}
                                >
                                    <img
                                        src={download}
                                        className={classes.downloadImg}
                                    />
                                    {configJSON.downloadBtn}
                                </Button>
                                <Button
                                    fullWidth
                                    className={classes.sendMailBtn}
                                >
                                    <img
                                        src={atSign}
                                        className={classes.sendMailIcon}
                                    />
                                    {configJSON.sendMailBtn}
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </Modal>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: {} = () => ({
    shareMainContainer: {
        backgroundColor: "white",
        padding: "40px",
        borderRadius: "20px",
        width: "670px",
        outline: "none",
    },
    modalMain: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    closeButton: {
        position: "absolute",
        right: "20px",
        marginRight: "5px",
    },
    shareFile: {
        fontSize: "24px",
        fontWeight: 700,
        fontFamily: "Montserrat",
    },
    likeShareText: {
        fontWeight: 600,
        color: "#475569",
        fontFamily: "Montserrat",
        fontSize: "16px",
    },
    fileBtnDisplay: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
    },
    wordImg: {
        height: "20px",
        width: "20px",
        marginRight: "5px",
    },
    wordFileText: {
        fontWeight: 500,
        fontFamily: "Montserrat",
        fontSize: "14px",
    },
    closeIcon: {
        height: "12px",
        width: "12px",
    },
    pdfFileButton: {
        color: "#000",
        backgroundColor: "#FEE2E2",
        textTransform: "none",
        position: "relative",
    },
    pdfImg: {
        height: "20px",
        width: "20px",
        marginRight: "5px",
    },
    pdfFileText: {
        fontWeight: 500,
        fontFamily: "Montserrat",
        fontSize: "14px"
    },
    secondBox: {
        marginTop: "20px"
    },
    targetMailLabel: {
        fontWeight: 700,
        fontFamily: "Montserrat",
        fontSize: "16px"
    },
    inputProps: {
        borderRadius: "8px",
        height: "44px"
    },
    seperateMail: {
        fontWeight: 500,
        fontFamily: "Montserrat",
        fontSize: "12px",
        marginTop: "5px",
    },
    downloadButton: {
        border: "1px solid #CBD5E1",
        color: "#000",
        fontWeight: 700,
        marginRight: "15px",
        textTransform: "none",
    },
    displayButtonTwo: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
    },
    downloadImg: {
        height: "24px",
        width: "24px",
        marginRight: "5px",
    },
    sendMailBtn: {
        border: "1px solid #CBD5E1",
        color: "#000",
        marginRight: "15px",
        fontWeight: 700,
        textTransform: "none",
    },
    sendMailIcon: {
        height: "20px",
        width: "20px",
        marginRight: "5px",
    },
    fileButton: {
        color: "#000",
        marginRight: "15px",
        backgroundColor: "rgba(58, 132, 201, 0.18)",
        textTransform: "none",
        position: "relative",
    },
    chip: {
        margin: '5px'
    }
});
export default withStyles(webStyle)(ShareFileModal);

// Customizable Area End