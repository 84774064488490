import { Box, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  createStyles,
  createTheme,
  withStyles
} from "@material-ui/core/styles";
import React from "react";
import { Link } from "@material-ui/icons";
import clsx from "clsx";
import ReferralCardController, { Props } from "./ReferralCardController.web";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif"
  }
});

const styles = () =>
  createStyles({
    container: {
      borderRadius: 28,
      padding: 20,
      border: "1px solid #CBD5E1",
      boxSizing: "border-box",
      height: "100%",
      display: "flex",
      flexDirection: "column"
    },

    top: {
      display: "flex",
      justifyContent: "space-between"
    },

    content: {
      marginTop: 34
    },

    subTitle: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: "18.6px"
    },

    title: {
      marginTop: 8,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px"
    },

    innerContent: {
      marginTop: 8,
      fontSize: 14,
      lineHeight: "19.6px",
      marginBottom: 12,
      color: "#475569"
    },

    btn: {
      marginTop: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #CBD5E1",
      borderRadius: 8,
      backgroundColor: "#FFFFFF",
      fontWeight: 600,
      lineHeight: "19.2px",
      fontSize: 16,
      cursor: "pointer",
      padding: "10px 16px"
    },

    btnDisabled: {
      cursor: "default"
    },

    referral: {
      fontSize: 12,
      lineHeight: "14px",
      letterSpacing: "-0.01em",
      display: "flex",
      color: "#475569",
      alignItems: "flex-end",
      fontWeight: 500
    },

    referralNumber: {
      fontSize: 18,
      fontWeight: 600,
      lineHeight: "22px",
      letterSpacing: "-0.01em",
      marginRight: 2
    }
  });

export class ReferralCard extends ReferralCardController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      dataTestId,
      classes,
      icon,
      title,
      subTitle,
      themeColor,
      titleColor,
      referral,
      totalReferral,
      disableReferralLinkButton,
      children
    } = this.props;

    const showReferral =
      referral !== undefined &&
      referral !== null &&
      totalReferral !== undefined &&
      totalReferral !== null;

    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.container} style={{ borderColor: themeColor }}>
          <Box className={classes.top}>
            <img src={icon} />

            {showReferral && (
              <Typography className={classes.referral}>
                <Typography
                  className={classes.referralNumber}
                  style={{ color: themeColor }}
                >
                  {referral}
                </Typography>
                / {totalReferral} referrals
              </Typography>
            )}
          </Box>

          <Box className={classes.content}>
            {subTitle && (
              <Typography
                component="h4"
                className={classes.subTitle}
                style={{ color: titleColor || themeColor }}
              >
                {subTitle}
              </Typography>
            )}
            <Typography
              component="h3"
              className={classes.title}
              style={{ color: titleColor || themeColor }}
            >
              {title}
            </Typography>

            <Box className={classes.innerContent}>{children}</Box>
          </Box>

          <button
            className={clsx(classes.btn, {
              [classes.btnDisabled]: disableReferralLinkButton || this.state.buttonClicked
            })}
            disabled={disableReferralLinkButton || this.state.buttonClicked}
            data-test-id={`get-referral-btn-${dataTestId}`}
            onClick={this.getReferralLink}
          >
            <Link style={{ color: themeColor, marginRight: 8 }} />
            {this.state.buttonClicked ? "Link copied" : "Get a referral link"}
          </button>
        </Box>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(ReferralCard);
