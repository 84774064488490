import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Select,
  InputLabel,
} from "@material-ui/core";
import SwapCallsIcon from '@material-ui/icons/SwapCalls';
import { withStyles, createStyles } from "@material-ui/core/styles";
import {
  bookmark,
  plusIcon,
  maximize,
  share,
  retry,
  d1,
  questionIcon,
  rightBar,
  swapCalls,
} from "../../blocks/chatbot4/src/assets";
import CustomTooltip from "./CustomTooltip.web";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
// Customizable Area End

interface IProps {
  classes?: any;
  showParameters?: any;
  setParameter?: any;
  displayParameters?: any;
  workflowParameters?:any;
  handelonChangeOnWorkFlow?:any;
  states?:any
}

class ChatHistoryAccordian extends React.Component<IProps, {}> {
  state = {
    applyParameter: false,
  };

  render() {
    const {
      classes,
      showParameters,
      setParameter,
      displayParameters = false,
      workflowParameters=[],
      handelonChangeOnWorkFlow,
      states
    } = this.props;
    const { applyParameter } = this.state;
    return (
      <Box className={classes.headerSectionWrapper}>
        <Box>
          <Box className={classes.headerSection}>
            <Box className={classes.headerTitleLabel}>
              <img
                src={questionIcon}
                className={classes.imgStyle}
                alt={"questionIcon"}
              />
              <Typography className={classes.headerMainHeading}>
                Question 1
              </Typography>
            </Box>
            <Box className={classes.headerRightIcons}>
              <CustomTooltip label="Bookmark chat" bgColor="#0B67BC">
                <IconButton>
                  <img
                    src={bookmark}
                    className={classes.btnStyle}
                    alt="bookmark"
                  />
                </IconButton>
              </CustomTooltip>

              <CustomTooltip label="Share chat" bgColor="#0B67BC">
                <IconButton onClick={() => setParameter({ shareModal: true })}>
                  <img src={share} className={classes.btnStyle} alt="share" />
                </IconButton>
              </CustomTooltip>

              <CustomTooltip label="Delete chat" bgColor="#D93855">
                <IconButton onClick={() => setParameter({ deleteModal: true })}>
                  <img src={d1} className={classes.btnStyle} alt="d1" />
                </IconButton>
              </CustomTooltip>
              <Box>
                <IconButton onClick={() => setParameter({ rightDrawer: true })}>
                  <img
                    src={rightBar}
                    className={classes.btnStyle}
                    alt="rightBar"
                  />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <Box className={classes.parameterSelectWrapper} >
            {/* <InputLabel id="select-label">Parameter Workflow</InputLabel> */}
            <Select
            className={classes.parameterSelectStyles}
            variant="outlined"
              data-test-id="parameterWorkflowListDatatestId"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              value={states?.selectedParameterWorkflowId}
              onChange={(e:any)=>{
                // handelonChangeOnWorkFlow(e.target.value)
              }}
            >
            <MenuItem  value={"0"}>Select Workflow</MenuItem>
              {
                workflowParameters.map((params:any,index:any)=>(
              <MenuItem 
              key={params} 
              value={params.id}
            
                  >{params?.attributes?.name}-{params?.attributes?.campaign?.name}</MenuItem>
                ))
              }
            </Select>
                            <Button 
                                     variant="contained"
                                     startIcon={<SwapCallsIcon className={classes.iconColor}/>}
                                     className={classes.swapButton}
                                     onClick={() => setParameter({ openParameter: true })}

                                 >
                                  open parameters
                                 </Button>
          </Box>
            {displayParameters && (
              <>
                <Box />
                {showParameters && (
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={4}>
                        <CustomSelect
                          classes={classes}
                          value={"0"}
                          menuItems={[
                            "Language",
                            "Writing Style",
                            "Tone of voice",
                            "Length",
                          ]}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <CustomSelect
                          value={"1"}
                          classes={classes}
                          menuItems={[
                            "Language",
                            "Writing Style",
                            "Tone of voice",
                            "Length",
                          ]}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <TextField
                          placeholder="Link to target company"
                          fullWidth
                          variant="outlined"
                          InputProps={{
                            style: {
                              height: "40px",
                              borderRadius: "8px",
                              overflow: "hidden",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end">
                                  <img
                                    src={maximize}
                                    className={classes.imgStyle}
                                    alt="maximize"
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <CustomSelect
                          value={"3"}
                          classes={classes}
                          menuItems={[
                            "Language",
                            "Writing Style",
                            "Tone of voice",
                            "Length",
                          ]}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <CustomSelect
                          value={"2"}
                          classes={classes}
                          menuItems={[
                            "Language",
                            "Writing Style",
                            "Tone of voice",
                            "Length",
                          ]}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Select
                              variant="outlined"
                              className={classes.selectTacticsStyles}
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: "bottom",
                                  horizontal: "left",
                                },
                                transformOrigin: {
                                  vertical: "top",
                                  horizontal: "left",
                                },
                                getContentAnchorEl: null,
                              }}
                              value={"0"}
                            >
                              <MenuItem value="0">Tactics</MenuItem>
                              <MenuItem>Option 2</MenuItem>
                              <MenuItem>Option 3</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item>
                            <Button
                              className={classes.selectTacticsStyles}
                              variant="outlined"
                            >
                              <Typography className={classes.btnFontStyle}>
                                Tactics
                              </Typography>
                              <img
                                src={plusIcon}
                                className={classes.plusImgStyle}
                              />
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              className={classes.selectTacticsStyles}
                              variant="outlined"
                            >
                              <Typography className={classes.btnFontStyle}>
                                Tactics
                              </Typography>
                              <img
                                src={plusIcon}
                                className={classes.plusImgStyle}
                              />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    {showParameters && (
                      <>
                        <ButtonWithIcon
                          classes={classes}
                          text="Change Workflow"
                          icon={retry}
                          onClick={() => setParameter({ changeWorkFlow: true })}
                        />
                        <ButtonWithIcon
                          classes={classes}
                          text="Save parameter"
                          icon={bookmark}
                          onClick={() =>
                            setParameter({ saveWorkflowModal: true })
                          }
                        />
                      </>
                    )}
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Typography>
                        Apply Parameters
                        {/* <Switch classes={{ switchBase: classes.switchBase, checked: classes.checked, track: classes.track }} /> */}
                      </Typography>
                      <CustomSwitch
                        classes={classes}
                        isActive={applyParameter}
                        setValue={(obj: any) => this.setState(obj)}
                      />
                    </Box>
                    <Box
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={() => {
                        setParameter({ showParameters: !showParameters });
                      }}
                    >
                      <Typography style={{ color: "#3A84C9" }}>
                        {showParameters ? "Hide" : "Show"} Parameters
                      </Typography>
                      <IconButton>
                        <ArrowForwardIosOutlinedIcon
                          style={{
                            height: "1rem",
                            width: "1rem",
                            color: "#3A84C9",
                            transform: showParameters
                              ? "rotate(-90deg)"
                              : "rotate(90deg)",
                          }}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
        
        </Box>
      </Box>
    );
  }
}

const CustomSwitch = (props: any) => {
  const { classes, isActive, setValue } = props;
  return (
    <Box
      className={
        isActive
          ? classes.customCheckBoxContainerActive
          : classes.customCheckBoxContainerDisable
      }
      onClick={() => {
        setValue({ applyParameter: !isActive });
      }}
    >
      <Box
        className={
          isActive
            ? classes.customCheckBoxThumbActive
            : classes.customCheckBoxThumbDisable
        }
      />
    </Box>
  );
};

const ButtonWithIcon = (props: any) => {
  const { icon = retry, classes, text, onClick } = props;
  return (
    <Button
      variant="outlined"
      className={classes.buttonWithIconStyle}
      onClick={onClick}
    >
      <img src={icon} className={classes.imgStyle} />
      <Typography className={classes.fontStyle}>{text}</Typography>
    </Button>
  );
};

const CustomSelect = (props: any) => {
  const { classes, value, menuItems = [] } = props;
  return (
    <Select
      fullWidth
      variant="outlined"
      className={classes.selectStyles}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
      }}
      value={value || "0"}
    >
      {menuItems.map((item: string, index: number) => (
        <MenuItem value={index} key={index}>
          {item}
        </MenuItem>
      ))}
    </Select>
  );
};

const styles = createStyles({
  imgStyle: {
    height: "24px",
    width: "24px",
    padding: "5px",
  },
  plusImgStyle: {
    height: "12px",
    width: "12px",
    padding: "5px",
  },
  btnStyle: {
    height: "24px",
    width: "24px",
  },
  rightBarBtnStyle: {
    height: "24px",
    width: "24px",
    marginLeft: "25px",
  },
  headerSectionWrapper: {
    background: "#FAF8FC",
    padding: "10px",
  },
  headerSection: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerTitleLabel: {
    display: "flex",
    alignItems: "center",
  },
  headerRightIcons: {
    display: "flex",
    zIndex: 2000,
  },
  selectStyles: {
    height: "40px",
    borderRadius: "8px",
    overflow: "hidden",
    color: "#64748B",
  },
  parameterSelectStyles: {
    height: "40px",
    width:"345px",
    borderRadius: "8px",
    overflow: "hidden",
    color: "#64748B",
    margin:"5px 0px"
  },
  parameterSelectWrapper:{
    // padding:"0px 10px",
    display: "flex",
    alignItems: "center",
    justifyContent:"space-between"
  },
  selectTacticsStyles: {
    height: "32px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    color: "#64748B",
    fontSize: "0.75rem",
  },
  buttonWithIconStyle: {
    height: "32px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    textTransform: "capitalize",
    marginRight: "5px",
  },
  fontStyle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  defaltLabel: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "1.875rem",
  },
  footerSubHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.75rem",
    color: "#475569",
  },
  headerMainHeading: {
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "1rem",
  },
  btnFontStyle: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.75rem",
  },
  switchBase: {
    "&$checked": {
      color: "#FFFFFF",
    },
    "&$checked + $track": {
      backgroundColor: "#3A84C9",
      opacity: 1,
    },
  },
  checked: {},
  iconColor: {
    color: "#3A84C9",
    cursor: 'pointer'
},
swapButton:{
  border:'1px solid #CBD5E1',
  borderRadius:'8px',
  background:'#fff',
  color:'#000',
  fontSize:'0.875rem',
  fontWeight:500,
  boxShadow:'none',
  textTransform: 'inherit',
},
  track: {},
  customCheckBoxContainerActive: {
    cursor: "pointer",
    width: "32px",
    height: "20px",
    borderRadius: "40px",
    backgroundColor: "#3A84C9",
    position: "relative",
    display: "flex",
    alignItems: "center",
    margin: "0px 10px",
    padding: "1px 2px",
  },
  customCheckBoxContainerDisable: {
    cursor: "pointer",
    width: "32px",
    height: "20px",
    borderRadius: "40px",
    backgroundColor: "#94A3B8",
    position: "relative",
    display: "flex",
    alignItems: "center",
    margin: "0px 10px",
    padding: "1px 2px",
  },
  customCheckBoxThumbActive: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    right: "2px",
  },
  customCheckBoxThumbDisable: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    left: "2px",
  },
});

export default withStyles(styles)(ChatHistoryAccordian);
