import React from 'react'
// Customizable Area Start
import {
    Box,
    Typography,
    IconButton,
    // Customizable Area Start
    TextField,
    // Customizable Area End
  } from "@material-ui/core";
import { withStyles, createStyles } from '@material-ui/core/styles';
import { send  } from "./assets";

// Customizable Area End




interface IProps {
  classes?: any;
}

export class FooterSection extends React.Component<IProps, {}> {
  state = {
  };

  render(){
    const { classes } = this.props;
    return (
      <Box className={classes.footerSection}>
        <Box
          style={{
            border: "1px solid #94A3B8",
            borderRadius: "8px",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            multiline
            placeholder="Ask a question to Pebbles Copilot"
            className={classes.footerTextArea}
          />
          <IconButton style={{ position: "absolute", right: 10, bottom: 30 }}>
            <img src={send} />
          </IconButton>
        </Box>
  
        <Typography className={classes.footerSubHeading}>
          Pebbles Copilot is still learning. Verify important information.
        </Typography>
      </Box>
    );
    }
}


const styles = createStyles({
  footerTextArea: {
    height: "91px",
    maxHeight: "164px",
    overflowY: "auto",
    "& fieldset": {
      border: "transparent",
    },
    "& .MuiInputBase-input::placeholder": {
      fontStyle: "italic",
      fontWeight: 400,
      fontFamily: "Montserrat",
      fontSize: "14px",
    },
    "&:hover fieldset": {
      border: "transparent",
    },
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(148, 163, 184, 1)",
      borderRadius: "6px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(148, 163, 184, 1)",
    },
  },
  footerSection: {
    background: "#FAF8FC",
    padding: "15px",
    position: "relative",
    borderRadius: "10px, 10px, 0px, 0px",
  },
  footerSubHeading: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "0.75rem",
    color: "#475569"

  },
});


export default withStyles(styles)(FooterSection);

