import React from "react";

import {
    Button,
    Container,
    // Customizable Area Start
    Dialog,
    Typography,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import UnSubscriptionController from "./UnSubscriptionController";

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
        h1: {
            color: '#000',
            textAlign: 'center',
            fontSize: ' 24px',
            fontStyle: 'normal',
            fontWeight: 700,
            marginRight: '35px'
        },
        h3: {
            color: '#475569',
            fontSize: ' 16px',
            fontStyle: 'normal',
            fontWeight: 600,
            margin: '14px',
            textAlign:'center'
        },
        h4: {
            color: '#000',
            fontSize: ' 16px',
            fontStyle: 'normal',
            fontWeight: 500,
        },
        h5: {
            color: '#475569',
            fontSize: ' 14px',
            fontStyle: 'normal',
            fontWeight: 500,
        },
        subtitle1: {
            color: '#475569',
            fontSize: ' 12px',
            fontStyle: 'normal',
            fontWeight: 500,
        },
        subtitle2: {
            fontSize: ' 16px',
            fontStyle: 'normal',
            fontWeight: 600,
            marginBottom: '5px'
        }
    },
});
// Customizable Area End


export default class UnSubscription extends UnSubscriptionController {

    // Customizable Area Start
    // Customizable Area End

    render() {

        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Dialog PaperProps={{
                    style: { borderRadius: '20px' }
                }} maxWidth='lg' open={this.props.isUnSubscriptionOpen} >
                    <div data-testid="closeModalBtn" style={webStyle.closeBtn} onClick={this.handleCloseUnsubscribePopup}>
                        <Close />
                    </div>
                    <Container maxWidth={"sm"} style={webStyle.mainContainer}>
                       {!this.state.isContinue && !this.state.isUnsubscribe &&
                        <>
                        <Typography variant="h1">Are you sure you want to <span style={{ color: '#DC2626' }}>unsubscribe</span> from your Professional plan?</Typography>
                        <Typography variant="h3">Please be aware that you will no longer have access to any features at the end of the billing month.</Typography>
                        </>}
                        {this.state.isContinue && !this.state.isUnsubscribe &&
                        <>
                        <Typography variant="h1">You are about to <span style={{ color: '#DC2626' }}>unsubscribe</span></Typography>
                        <Typography variant="h3">We are sorry to see you go. Can we interest you in a different plan?</Typography>
                        </>}
                        {!this.state.isContinue && this.state.isUnsubscribe &&
                        <>
                        <Typography variant="h1">You successfully <span style={{ color: '#DC2626' }}>unsubscribe</span> your profile</Typography>
                        <Typography variant="h3">We hope to see you soon. Meanwhile, keep an eye out for the latest Pebble releases, developed to enhance your go-to-market workflow.</Typography>
                        </>}
                        {!this.state.isContinue && !this.state.isUnsubscribe &&
                        <div style={{display:'flex',marginTop:'15px'}}>
                            <Button fullWidth style={webStyle.unsubButton1} type="button" onClick={this.props.onUnsubscribe}>No, I want to stay</Button>
                            <Button fullWidth style={webStyle.unsubButton2} type="button" onClick={this.handleIsContinuePopup}>Continue</Button>
                        </div>}
                        {this.state.isContinue && !this.state.isUnsubscribe &&
                        <div style={{display:'flex',marginTop:'15px'}}>
                            <Button fullWidth style={webStyle.unsubButton1}>Yes, switch plan</Button>
                            <Button fullWidth style={webStyle.unsubButton2} type="button" onClick={this.handleIsUnsubscribePopup}>No, unsubscribe</Button>
                        </div>}
                        {!this.state.isContinue && this.state.isUnsubscribe &&
                        <div style={{marginTop:'15px'}}>
                            <Button fullWidth style={webStyle.unsubButton2}>Log out</Button>                          
                        </div>}
                    </Container>
                </Dialog>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    mainContainer: {
        padding: '40px',
        display: 'flex',
        flexDirection: 'column' as 'column',
        width: '670px',
    },
    closeBtn: {
        position: 'absolute' as 'absolute',
        right: '40px',
        top: '40px',
        cursor: 'pointer'
    },
    unsubButton1: {
        padding: '10px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        background: '#000',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        color:  '#FFF',
        marginRight:'20px',
        height: "44px",
        textTransform: 'none'as'none',
    },
    unsubButton2: {
        padding: '10px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        color:  '#DC2626',
        border: '1px solid #DC2626',
        height: "44px",
        textTransform: 'none'as'none',

    }

};
// Customizable Area End
