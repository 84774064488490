import React from "react";
import { Box, Button, Dialog, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import { createStyles } from "@material-ui/core/styles";

const styles: any = createStyles({
  box: {
    width: "510px",
    background: "#FFF",
    borderRadius: "20px",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    position:'relative',
    padding:'40px'
  },
  icon: {
    position:'absolute',
    top:20,
    right:20,
    cursor:'pointer'
  },

  text: {
    color: "#000",
    width:'90%',
    alignItems: "center",
    fontFamily: "Montserrat",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: 1.4 /* 137.5% */,
    textAlign: "center",
  },
  btnContainer: {
    display: "flex",
    marginTop:'32px',
    width:'100%'
  },
  btn1: {
    display: "flex",
    padding: "0 16px",
    height:'40px',
    flex:1,
    justifyContent: "center",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    background: "#FFF",
    textTransform:'none'
  },
  clearIcon:{
    color:'#475569'
  },
  btn2: {
    display: "flex",
    padding: "0 16px",
    height:'40px',
    flex:1,
    justifyContent: "center",
    borderRadius: "8px",
    border: "1px solid #DC2626",
    background: "#FFF",
    textTransform:'none',
    color:'#DC2626',
    marginLeft:'20px'
  },
  paper:{
    borderRadius:'20px'
  }
});

interface Props {
  showLogout: boolean;
  onClose: () => void;
  onLogout:()=>void;
}
const Logout = ({ showLogout, onClose,onLogout }: Props) => {
  return (
    <Dialog PaperProps={{
      style:styles.paper
    }} open={showLogout} onClose={onClose}>
      <Box style={styles.box}>
        <div style={styles.icon}>
          <ClearIcon style={styles.clearIcon} data-testid='closeBtn' onClick={onClose} />
        </div>
        <Typography style={styles.text}>
        Are you sure you want to log out from your account?
        </Typography>
        <Box style={styles.btnContainer}>
          <Button style={styles.btn1} data-testid='cancelBtn' onClick={onClose}>Cancel</Button>
          <Button style={styles.btn2} data-testid='logoutBtn' onClick={()=>{
            onClose();
            onLogout();
          }}>Log out</Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default Logout;
