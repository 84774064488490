import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  TextField,

  Checkbox,
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import {
  share,
  d1,
  checkedIcon,
  checkIcon,
  selectBtn,
  searchIcon

} from "../../blocks/chatbot4/src/assets";
// Customizable Area End

interface IProps {
  classes?: any;
  selectedFeilds?:any;
  dataLength?:any;
  handelSelectAllWorkFLow?:any;
  handelSelectFeildBtn?:any;
  selectFeildEnable?:any;
  removeSelction?:any;
  activeTab?:any;
  handelTabChange?:any;

}

class HistoryHeader extends React.Component<IProps, {}> {

  render() {
    const {
      classes,
      selectedFeilds,
      dataLength,
  handelSelectAllWorkFLow,
  handelSelectFeildBtn,
  selectFeildEnable,
  removeSelction,
  activeTab=0,
  handelTabChange
    } = this.props;
    return (
  <Box>
              <Box className={classes.parameterContainer}>
            <Box className={classes.tabsContainer}>
              <Box className={classes.activeChatChipcontainer}>
                <ul className={classes.activeChatChiptab}>
                  <li className={activeTab==0?"active":""} onClick={(e)=>handelTabChange({activeTab:0})}>{"Outreach"}</li>
                  <li className={activeTab==1?"active":""} onClick={(e)=>handelTabChange({activeTab:1})}>{"Copilot"}</li>
                </ul>
              </Box>
            </Box>
            <Box className={classes.searchContainer}>
              <Box>
                <TextField
                  variant="outlined"
                  placeholder="Search history"
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      width: "325px",
                      height: "40px",
                      border: "1px solid #94A3B8",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <img src={searchIcon} alt="searchIcon" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {!selectFeildEnable && (
                <Button
                  className={classes.selectField}
                  data-test-id="selectFeildsDatatestIdBtn"
                  onClick={handelSelectFeildBtn}
                >
                  <Typography className={classes.selectFieldText}>
                    Select
                  </Typography>
                  <Box>
                    <img src={selectBtn} alt="selectBtn" />
                  </Box>
                </Button>
              )}
            </Box>
          </Box>
          {selectFeildEnable && (
            <Box className={classes.parameterContainer}>
              <Box className={classes.dFlex}>
                <Box className={classes.dFlex}>
                  <Typography className={classes.selectAllText}>
                    Select All
                  </Typography>
                  <Checkbox
                    data-test-id="selectAllFeildDataTestId"
                    checked={
                      selectedFeilds.length ===
                      dataLength
                    }
                    onClick={(e) => {
                      handelSelectAllWorkFLow(e);
                    }}
                    checkedIcon={
                      <img
                        src={checkedIcon}
                        style={{ height: "20px", width: "20px" }}
                        alt="checkedIcon"
                      />
                    }
                    icon={
                      <img
                        src={checkIcon}
                        style={{ height: "20px", width: "20px" }}
                        alt="checkIcon"
                      />
                    }
                  />
                </Box>
                <Typography
                  className={classes.removeSelectionText}
                  data-test-id="selectFeildEnableDataTestId"
                  onClick={removeSelction}
                >
                  Remove Selection -
                </Typography>
              </Box>
              <Box className={classes.searchContainer}>
                <Button
                  className={classes.shareFeildContainer}
                  data-test-id="ShareFeildsDatatestIdBtn"
                >
                  <Typography className={classes.selectFieldText}>
                    Delete
                  </Typography>
                  <Box>
                    <img src={d1} alt="d1Btn" />
                  </Box>
                </Button>
              </Box>
            </Box>
          )}
  </Box>
    );
  }
}


const styles = createStyles({
    mainWorkFlowContainer: {
      position: "relative",
      height: "100vh",
      padding: "16px 20px 16px 20px",
    },
    headingContainer: {},
    pageHeading: {
      fontFamily: "Montserrat",
      fontSize: "48px",
      fontWeight: 600,
      textAlign: "center",
    },
    subHeadingContainer: {},
    pageSubheading: {
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 500,
      textAlign: "center",
      color: "#475569",
    },
    parameterContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    tabsContainer: {},
    searchContainer: {
      display: "flex",
      alignItems: "center",
    },
    tableContainer: {},
    activeChatChipcontainer: {
      // width: "206px",
      overflowX: "hidden",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    activeChatChiptab: {
      display: "flex",
      listStyle: "none",
      padding: "2px",
      border: "1px solid #CBD5E1",
      background: "#FFF",
      borderRadius: "80px",
      marginTop: "10px",
      "& li": {
        padding: "4px 12px",
        display: "flex",
        fontSize: "12px",
        cursor: "pointer",
        fontWeight: "600",
        lineHeight: "22px",
        fontFamily: "Montserrat",
        //   marginRight: "26px",
      },
      "& li.lastItem": {
        marginRight: 0,
      },
      "& li.active": {
        padding: "4px 12px",
        borderRadius: "80px",
        background: "#000",
        color: "#FFFFFF",
      },
    },
    selectField: {
      borderRadius: "8px",
      height: "40px",
      marginLeft: "10px",
      border: "1px solid #94A3B8",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0px 15px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#e7e7e7",
        borderColor: "#000",
      },
    },
    selectFieldText: {
      fontSize: "14px",
      fontWeight: 600,
      fontFamily: "Montserrat",
      marginRight: "5px",
    },
    tableHeading: {
      color: "#475569",
      fontSize: "12px",
      cursor: "pointer",
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
    tableHeadCell: {
      borderBottom: "none",
    },
    nullTableData: {
      color: "#475569",
      fontSize: "12px",
      cursor: "pointer",
      fontWeight: 500,
      fontFamily: "Montserrat",
      textAlign: "center",
    },
    useBtn: {
      textTransform: "none",
      borderRadius: "8px",
      border: "1px solid #CBD5E1",
      "&:hover": {
        borderColor: "#e7e7e7",
        backgroundColor: "#000",
        color: "#FFFFFF",
      },
    },
    optionsCell: {
      display: "flex",
      alignItems: "center",
    },
    optionsTimeStampDateCell: {
      color: "#475569",
      fontSize: "12px",
      fontWeight: 500,
      fontFamily: "Montserrat",
      marginLeft: "5px",
    },
    optionsConversationCell: {
      display: "flex",
      alignItems: "center",
    },
    optionsConversationHeadingCell: {
      fontSize: "14px",
      fontWeight: 600,
      fontFamily: "Montserrat",
      marginLeft: "5px",
    },
    optionsworkFlowNameCell: {
      display: "flex",
      alignItems: "center",
    },
    optionsworkFlowheadingCell: {},
    sessionDesc: {
      width: "250px",
      overflow: "hidden",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      fontSize: "12px",
      fontWeight: 600,
      fontFamily: "Montserrat",
      color: "#475569",
      lineHeight: "16px",
      maxHeight: "32px",
      textOverflow: "ellipsis",
      whiteSpace: "normal",
    },
    sessionTitle: {
      fontWeight: 600,
    },
    icon: {
      height: "24px",
      width: "24px",
      cursor: "pointer",
    },
    optionsClockCell: {
      display: "flex",
      alignItems: "center",
    },
    optionsCalenderCell: {
      display: "flex",
      alignItems: "center",
    },
    rightBarIcon: {
      position: "absolute",
      right: "10px",
      top: "10px",
      zIndex: 4200,
    },
    dFlex: {
      display: "flex",
      alignItems: "center",
    },
    removeSelectionText: {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Montserrat",
      color: "#D93855",
      cursor: "pointer",
    },
    selectAllText: {
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Montserrat",
    },
    shareFeildContainer: {
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      marginRight: "10px",
    },
  });

export default withStyles(styles)(HistoryHeader);
