import React from "react";


// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    InputAdornment,
    TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import PasswordChanged from '../../info-page/src/PasswordChanged.web';
import {checkIcon} from "./assets";
import Toast from "../../utilities/src/Toast";
// Customizable Area End

import SettingsChangePasswordController, {
    Props,
    configJSON,
} from "./SettingsChangePasswordController";

export class SettingsChangePassword extends SettingsChangePasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderForm = () => {
        const { classes }: any = this.props;
        const { errors } = this.state;
        const { onClose } = this;
        return (
            <Box className={classes.mainContainer}>
            <Toast {...{errors,onClose}} />
            <ArrowBackIosIcon className={classes.arrowBack} onClick={this.props.handleCloseEditPass} />
            <Typography className={classes.mainHeading}>{configJSON.changePass}</Typography>
            <Box>
                <Typography className={classes.oldPassLabel}>{configJSON.oldPass}</Typography>
                <TextField
                    variant="outlined"
                    type="password"
                    fullWidth
                    size="small"
                    className={classes.passTextField}
                    value={this.state.oldPassValue}
                    onChange={this.handleOldPass}
                    error={!this.state.oldPassValue && this.state.oldPassError}
                    helperText={!this.state.oldPassValue && this.state.oldPassErrorMessage}
                    data-testid="old-password"
                />
            </Box>
            <Box>
                <Typography className={classes.verificationLabel}>{configJSON.newPassLabel}</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    type="password"
                    size="small"
                    className={classes.passTextField}
                    value={this.state.newPassValue}
                    onChange={this.handleNewPass}
                    error={!this.state.newPassValue && this.state.newPassError}
                    helperText={!this.state.newPassValue && this.state.newPassErrorMessage}
                    data-testid="new-password"
                />
            </Box>
            <Box>
                <Typography className={classes.verificationLabel}>{configJSON.confirmPassLabel}</Typography>
                <TextField
                    variant="outlined"
                    fullWidth
                    type={
                        this.state.passwordShow ? "text" : "password" 
                      }
                    size="small"
                    className={classes.passTextField}
                    InputProps={{
                        endAdornment:(
                            <InputAdornment
                        position="end"
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        className={classes.eyeIconCursor}
                      >
                        {this.state.passwordShow ? (
                          <VisibilityOutlinedIcon className={classes.pwdIconColor} />
                        ) : (
                          <VisibilityOffOutlinedIcon className={classes.pwdIconColor} />
                        )}
                      </InputAdornment>
                        )
                    }}
                    value={this.state.confirmValue}
                    onChange={this.handleConfirmPass}
                    error={(!this.state.confirmValue || (this.state.newPassValue !== this.state.confirmValue)) && this.state.confirmPassError}
                    helperText={!this.state.confirmValue && this.state.confirmErrorMessage}
                    data-testid="confirm-password"
                />
                <Typography className={classes.confirmError}>{this.state.confirmValidMessage}</Typography>
            </Box>
                {this.state.errors && (
                    <Typography className={classes.validateErrorPass}>
                        {this.state.errors}
                    </Typography>
                )}            
            <Typography className={classes.passContain}>{configJSON.passContain}</Typography>
            <Box className={this.state.upperCaseCheck ? classes.listContainer: classes.validListContainer}>
                <FiberManualRecordIcon className={classes.dotIcon}/>
                <Typography className={classes.titleList}>{configJSON.upperCase}</Typography>
            </Box>
            <Box className={this.state.lowerCaseCheck ? classes.listContainer: classes.validListContainer}>
                <FiberManualRecordIcon className={classes.dotIcon}/>
                <Typography className={classes.titleList}>{configJSON.lowerCase}</Typography>
            </Box>
            <Box className={this.state.numberCheck ? classes.listContainer: classes.validListContainer}>
                <FiberManualRecordIcon className={classes.dotIcon}/>
                <Typography className={classes.titleList}>{configJSON.numberValidate}</Typography>
            </Box>
            <Box className={this.state.lengthCheck ? classes.listContainer: classes.validListContainer}>
                <FiberManualRecordIcon className={classes.dotIcon}/>
                <Typography className={classes.titleList}>{configJSON.chanracterLength}</Typography>
            </Box>
            <Button data-testid="set-password" variant="contained" className={classes.submitButton} onClick={this.handleSetPass}>{configJSON.setPassButton}</Button>
            
        </Box>
        )
    }
    renderPasswordChanged = () => {
        return (
            <PasswordChanged
                data-testid="password-success"
                openPassword={this.state.openPassModule}
                handleCloseModule={this.props.handleCloseMainModal}
                {...this.props}
                checkIcon={checkIcon}
                handleCloseEditModule={this.props.handleCloseEditPass}
            />
        );
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { openPassModule } = this.state;
        return (
            // Customizable Area Start
            <>
            {!openPassModule ? this.renderForm() : this.renderPasswordChanged()}
        </>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: {} = () => ({
    mainContainer: {
        position: 'relative',
        marginTop: '25px'
    },
    arrowBack: {
        position: 'absolute',
        top: '13',
        fontSize: '1rem',
        cursor: 'pointer'
    },
    mainHeading: {
        fontSize: '1.8rem',
        fontWeight: 700,
        textAlign: 'center',
        marginBottom: '30px'
    },
    passTextField: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
            border: '1px solid #94A3B8',
            padding:'5px',
            letterSpacing:'3px'
        },
        margin: '5px 0',
        '& .Mui-error':{
            marginLeft:'0px !important'
        }
    },
    oldPassLabel: {
        fontWeight: 600
    },
    sentDetails: {
        fontSize: '0.8rem',
        color: '#475569'
    },
    temporary: {
        margin: '0 5px',
        fontWeight: 600
    },
    verificationLabel: {
        fontWeight: 600,
        marginTop: '10px'
    },
    submitButton: {
        background: '#000',
        color: '#fff',
        borderRadius: '8px',
        width: '100%',
        margin: '25px 0 15px',
        textTransform:'Capitalize'
    },
    didRecieve: {
        color: '#475569',
        fontWeight: 500,
        fontSize: '0.8rem',
        testAlign: 'center'
    },
    resend: {
        color: '#94A3B8',
        fontWeight: 700,
        marginLeft: '5px'
    },
    resendTime: {
        fontSize: '1.2rem',
        marginLeft: '5px',
        fontWeight: 400
    },
    passContain:{
        fontSize:'0.87rem',
        fontWeight:700
    },
    listContainer:{
        display:'flex',
        gap:'8px',
        alignItems:'center',
        color: '#059669',
    },
    validListContainer:{
        display:'flex',
        gap:'8px',
        alignItems:'center',
        color: 'red',
    },
    dotIcon:{
        fontSize:'0.5rem'
    },
    titleList:{
        fontSize:'0.87rem',
        fontWeight:500,
    },
    titleListValidate:{
        fontSize:'0.87rem',
        fontWeight:500,
    },
    eyeIconCursor:{
        cursor:'pointer',
        color:'#94A3B8'
    },
    confirmError:{
        fontSize:'0.75rem',
        color:'#f44336',
        marginTop:'4px'
    },
    validateErrorPass: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.2,
        color: "#DC2626",
        marginTop: "2px"
      },
});
export default withStyles(webStyle)(SettingsChangePassword);

// Customizable Area End
