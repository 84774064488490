import { Box, Button, Typography, TextField, MenuItem, Select, InputAdornment } from "@material-ui/core";
import React from "react";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import { styles } from '../utils/styles'
import { Formik, ErrorMessage } from "formik";
import Layout from "../../../components/src/Layout";
import CarouselDisplay from "../../../blocks/carouseldisplay/src/CarouselDisplay.web";
import LetsGetStartedController, { Props } from "./LetsGetStartedController";
import { LetgetSchema } from "../utils/schema";
import { meunuProps, theme } from "./About.web";
//Icons
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

export type letsValues = {
  fullname: string;
  role: string;
  email: any,
  password: string
};

// initial formik values
const initialValues: letsValues = { fullname: "", role: "", email: "", password: "" };


export class LetsGetStarted extends LetsGetStartedController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <ThemeProvider theme={theme}>
        <Layout back={this.onBack} carousel={<CarouselDisplay type="company" id="" navigation={{}} />}>
          <Box className={classes.layoutContainer} style={{marginTop:'70px'}}>
            <Box className={classes.viewBox}>
              <Typography className={classes.createAccount}>Let's get started</Typography>
              <Typography className={classes.signingAs}>Could you share a bit about yourself before our meeting?</Typography>
              <Formik
                data-testid="companyletsFormik"
                initialValues={initialValues}
                validationSchema={LetgetSchema}
                onSubmit={values => {
                  this.doSignup(values)
                }}
              >
                {({ values, errors, touched, handleChange, handleSubmit }) => (
                  <form onSubmit={handleSubmit} data-testid="formSubmitlets">
                    <Box className={classes.inputWrapper}>
                      <Typography className={classes.title}>Full name</Typography>
                      <TextField
                        fullWidth
                        data-testid="nameInput"
                        id="fullname"
                        type="text"
                        name="fullname"
                        variant="outlined"
                        placeholder="Enter your full name"
                        className={classes.input1}
                        value={values.fullname}
                        onChange={handleChange}
                        style={{
                          borderColor: touched.fullname && errors.fullname ? "#F87171" : ""
                        }}
                      />
                      <ErrorMessage name="fullname" component="div" className={classes.errorResp} />
                    </Box>
                    <Box className={classes.inputWrapper}>
                      <Typography className={classes.title}>What's your role</Typography>
                      <Select
                        fullWidth
                        name="role"
                        value={values.role}
                        onChange={handleChange}
                        displayEmpty
                        defaultValue={''}
                        disableUnderline
                        placeholder="Select response"
                        inputProps={{ "aria-label": "role" }}
                        MenuProps={meunuProps}
                        className={`${classes.select} ${values.role ? '' : classes.inactiveSelect1}`}
                        data-testid="roleInput"
                        style={{
                          borderColor: touched.role && errors.role ? "#F87171" : ""
                        }}
                      >
                        {this.state.roleList.map(role => (
                          <MenuItem className={classes.setmenuItem} key={role.id} value={role.id}>
                            {role.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <ErrorMessage name="role" component="div" className={classes.errorResp} />
                    </Box>
                    <Box className={classes.inputWrapper}>
                      <Typography className={classes.title}>Email</Typography>
                      <TextField
                        data-testid="emailInput"
                        id="email"
                        type="text"
                        name="email"
                        variant="outlined"
                        placeholder="Provide your company email"
                        fullWidth
                        className={classes.input1}
                        value={values.email}
                        onChange={handleChange}
                        style={{
                          borderColor: touched.email && errors.email ? "#F87171" : ""
                        }}
                      />
                      {!errors.email && <Typography className={classes.errorResp}>{this.state.errorsE}</Typography>}
                      <ErrorMessage name="email" component="div" className={classes.errorResp} />
                    </Box>
                    <Box className={classes.inputWrapper}>
                      <Typography className={classes.title}>Password</Typography>
                      <TextField
                        data-test-id="txtInputPasswords"
                        name="password"
                        fullWidth
                        id="password"
                        variant="outlined"
                        value={values.password}
                        placeholder="Enter Password"
                        onChange={handleChange}
                        className={classes.input1}
                        type={
                          this.state.enablePasswordField ? "password" : "text"
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              aria-label="toggle password visibility"
                              onClick={() => this.handleShowPassword()}
                              className={classes.eyeIcon}
                            >
                              {this.state.enablePasswordField ? (
                                 <VisibilityOffOutlinedIcon className={classes.pwdIconColor} />
                                 ) : (
                                   <VisibilityOutlinedIcon className={classes.pwdIconColor} />
                              )}
                            </InputAdornment>
                          )
                        }}
                        style={{
                          borderColor: touched.password && errors.password ? "#F87171" : ""
                        }}
                      />
                      <ErrorMessage name="password" component="div" className={classes.errorResp} />
                    </Box>
                    <Button
                      type="submit"
                      data-test-id={"btnEmailLogIn"}
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.buttonWrapper}
                    >
                      Continue
                    </Button>

                  </form>
                )}
              </Formik>

            </Box>
          </Box>
        </Layout>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(LetsGetStarted);
