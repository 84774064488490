import React from 'react'
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Modal,
    Paper,
    IconButton,
    // Customizable Area End
  } from "@material-ui/core";
import { withStyles, createStyles } from '@material-ui/core/styles';
import Backdrop from "@material-ui/core/Backdrop";
import {
    close,swapCalls,
  } from "./assets";
// Customizable Area End




interface IProps {
  classes?: any;
  open?:any;
  setOpen?:any;
  parameterWorkflow?:any;
}

export class ChangeWorkflow extends React.Component<IProps, {}> {
  state = {
  };

  render(){
    const { classes, open, setOpen,parameterWorkflow=[] } = this.props;
    return (
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.changeWorkflowModal}
        open={open}
        onClose={() => {
          setOpen({ changeWorkFlow: false })
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.changeWorkflowPaper}
        >
          <Box >
            <Box className={classes.hedingWarpper} >
              <Typography 
              className={classes.changeWorkFlowFormTitle}
                align='center'
              >
              <img src={swapCalls} style={{ height: "24px", width: "24px",  }} />   Choose another workflow
              </Typography>
              <IconButton data-test-id="closeChangeWorkflowflowBtn" className={classes.closeIcon} onClick={()=>setOpen({ changeWorkFlow: false })
}>
              <img src={close} style={{ height: "24px", width: "24px", }} />
              </IconButton>
  
              </Box>
              <Box>
              <Typography
                align='center'
  
                style={{
                  fontWeight: 600,
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  color: "#475569"
                }}
              >
                Selecting this option will delete your current
              </Typography>
              <Typography
                align='center'
  
                style={{
                  fontWeight: 600,
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  color: "#475569"
                }}
              >
                parameters and auto-fill the new workflow.
              </Typography>
            </Box>
  
     
  
          </Box>
          <Box className={classes.changeWorkflowContainer}>
          {
         parameterWorkflow?.map((item:any,index:any)=>(
            <Box key={item} className={classes.changeWorkSlowWrapper}>
            <Box >
              <Box className={classes.dflexcenter}>
                <Box>
                  <img src={swapCalls} style={{ height: "24xp", width: "24xp", marginRight: "10px" }} alt="swapCalls"/>
                </Box>
                <Box>
  
                  <Typography className={classes.changeWorkFlowT1}>{item?.attributes?.name}</Typography>
                  <Typography className={classes.changeWorkFlowtD1}>{item?.attributes?.description}</Typography>
                </Box>
              </Box>
            </Box>
            <Box><Button className={classes.useButton}>Use</Button></Box>
          
        </Box>
          ))
         }

          </Box>
       
        </Paper>
      </Modal>
    );
    }
}
const styles = createStyles({
    closeIcon:{
        position:"absolute",
        right:35,
        top:35
        },
        changeWorkflowModal:{
      display: "flex",
      justifyContent: "center",
      alignItems:"center"
        },
        changeWorkFlowFormTitle:{
        fontWeight: 700,
        fontFamily: "Montserrat",
        fontSize: "24px",
        },
        changeWorkflowContainer:{
          marginTop:"25px",
          maxHeight:"80%",
          overflow:"auto"

        },
        useButton:{
          display: 'none',
          height: "32px",
          borderRadius: "8px",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: 1.2,
          background: "#000",
          textTransform: "none",
          color: "white",
          // margin: "20px 0px",
          '@media (min-width: 1300px)': {
          },
          "&:hover": {
            background: "#000"
          }
        },
        changeWorkFlowT1:{
          fontFamily: "Montserrat",
          fontWeight: 600,
          fontSize: "16px",
        cursor:"default"
        },
      changeWorkFlowtD1:{
        color:"#475569",
        fontFamily: "Montserrat",
        fontWeight: 500,
        fontSize: "12px",
        cursor:"default"
      },
        changeWorkSlowWrapper:{
        margin:"15px 0px",
        padding:"20px 0px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #94A3BB",
        "&:hover": {
          borderBottom: "1px solid #A68B3D"
        },
        '&:hover $button': {
          display: 'flex',
        },
      },
      changeWorkflowPaper: {
        backgroundColor: 'white',
        padding: "40px",
        outline: 'none',
        borderRadius: "20px",
        width: "616px",
        height: "465px",
        position:"relative"
      },
  dflexcenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dflexbetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

});
export default withStyles(styles)(ChangeWorkflow);




