import React from "react";
import ForgotPwdController from "./ForgotPwdController";
import { createStyles, withStyles, Typography } from "@material-ui/core";
import { gtmsLogo } from "./assets";

const styles = () =>
  createStyles({
    successContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    logoImg: {
      position: "absolute",
      left: 0,
      top: 0
    },
    successBox: {
      width: "35%"
    },
    recoveryHeader: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "32px",
      letterSpacing: "-0.12px",
      marginBottom: "35px"
    },
    checkText: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "26px",
      marginBottom: "8px"
    },
    backText: {
      color: "#0F172A",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "22px"
    },
    logInText: {
      color: "#3A84C9",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "22px",
      textDecorationLine: "underline",
      cursor: "pointer"
    },
    sentText: {
      color: "#000",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      marginBottom: "35px"
    },
    btnContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    }
  });

class Success extends ForgotPwdController {
  email = window.localStorage.getItem("user-email");

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.successContainer}>
        <img src={gtmsLogo} className={classes.logoImg} />
        <div className={classes.successBox}>
          <Typography className={classes.recoveryHeader}>
            Password recovery
          </Typography>
          <Typography className={classes.checkText}>
            Check your email
          </Typography>
          <Typography className={classes.sentText} id="sent-text">
            We have sent a password reset link to {this.email}
          </Typography>
          <div className={classes.btnContainer}>
            <span
              id="resend-link"
              className={classes.logInText}
              onClick={() => this.email && this.forgotPasswordApi(this.email)}
            >
              Resend e-mail
            </span>
            <Typography className={classes.backText}>
              Back to{" "}
              <span
                id="login-link"
                className={classes.logInText}
                onClick={() => this.goToLogin()}
              >
                Log in
              </span>
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Success);
