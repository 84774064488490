import React from "react";


// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import moment from 'moment';

// Customizable Area End

import PrivacyTermsConditionController, {
    Props,
    privacyConfigJSON,
} from "./PrivacyTermsConditionController";

export class PrivacyTermsCondition extends PrivacyTermsConditionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        const  privacyPolicy = this.state.privacyDetails.privacy_policy
        const termCondition = this.state.privacyDetails.terms_and_condition
        return (
            // Customizable Area Start
            <Grid container className={classes.mainContainer}>
                {this.props.privacyTerms === "privacy" ?
                    <Grid item xs={12}>
                        <ArrowBackIosIcon className={classes.arrowBack} onClick={this.props.handleBackPrivacy}/>
                        <Typography className={classes.mainHeading}>{privacyConfigJSON.privacyPolicy}</Typography>
                        <Typography className={classes.update}>{privacyConfigJSON.updated}<span className={classes.dateSpan}>{moment(privacyPolicy.updated_at).format('DD MMM YYYY')}</span></Typography>
                        <Box className={classes.detailScroll}>
                            <Typography className={classes.subHeading}>{privacyPolicy.title}</Typography>
                            <Typography className={classes.details}>{privacyPolicy.body}</Typography>
                        </Box>
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <ArrowBackIosIcon className={classes.arrowBack} onClick={this.props.handleBackPrivacy}/>
                        <Typography className={classes.mainHeading}>{privacyConfigJSON.termAndCodition}</Typography>
                        <Typography className={classes.update}>{privacyConfigJSON.updated}<span className={classes.dateSpan}>{moment(termCondition.updated_at).format('DD MMM YYYY')}</span></Typography>
                        <Box className={classes.detailScroll}>
                            <Typography className={classes.subHeading}>{termCondition.title}</Typography>
                            <Typography className={classes.details}>{termCondition.description}</Typography>
                        </Box>
                    </Grid>
                }
            </Grid>

            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: {} = () => ({
    mainContainer: {
        position: 'relative',
        marginTop: '25px'
    },
    arrowBack: {
        position: 'absolute',
        top: '13',
        fontSize: '1rem',
        cursor:'pointer'
    },
    mainHeading: {
        fontSize: '1.8rem',
        fontWeight: 700,
        textAlign: 'center'
    },
    update: {
        fontSize: '0.87rem',
        color: '#475569',
        textAlign: 'center',
        marginTop: '10px',
    },
    dateSpan:{
        marginLeft:'5px'
    },
    subHeading: {
        fontWeight: 600,
        margin: '13px 0 5px'
    },
    details: {
        fontSize: '0.87rem',
        lineHeight: '21px',
        color: '#475569'
    },
    detailScroll:{
        overflowY: 'scroll'
    }
});
export default withStyles(webStyle)(PrivacyTermsCondition);

// Customizable Area End
