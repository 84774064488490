export const userProfile = require("../assets/user_profile.png");
export const gtmLogo = require("../assets/gtms_logo.png");
export const arrowCircle = require("../assets/arrow_circle.png");
export const document = require("../assets/documents.png");
export const user = require("../assets/user.png");
export const openNavigation = require("../assets/menu_open_icon.svg");
export const closeNavigation = require("../assets/menu_close.svg");
export const companyLogo = require("../assets/company_icon.svg");
export const companyName = require("../assets/company_name.svg");
export const pebbles = require("../assets/pebbles.svg");
export const copilot = require("../assets/copilot.svg");
export const tactical = require("../assets/tactical.svg");
export const library = require("../assets/library.svg");
export const workflow = require("../assets/workflow.svg");
export const history = require("../assets/history.svg");
export const explor = require("../assets/explore.svg");
export const companyIntel = require("../assets/companyIntel.svg");
export const giftIcon = require("../assets/icon_gift.svg");
export const settingIcon = require("../assets/setting.svg");
export const pebbleHover = require("../assets/pebbles_hover.svg");
export const copilotHover = require("../assets/copilot_hover.svg");
export const tacticalHover = require("../assets/tactical_hover.svg");
export const libraryHover = require("../assets/library_hover.svg");
export const workflowHover = require("../assets/workflow_hover.svg");
export const historyHover = require("../assets/history_hover.svg");
export const explorHover = require("../assets/explore_hover.svg");
export const companyIntelHover = require("../assets/companyIntel_hover.svg");
export const giftIconHover = require("../assets/icon_gift_hover.svg");
export const edit = require("../assets/edit.svg");
export const editHovered = require("../assets/edit_hovered.svg");
export const upload = require("../assets/upload.svg");
export const uploadHovered = require("../assets/upload_hovered.svg");
export const rightBar = require("../assets/right_bar_icon.svg");


