import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ParameterFormData } from "./ParameterModalController.web";

// Customizable Area Start
import StorageProvider from '../../../framework/src/StorageProvider';
import { Message } from "../../../framework/src/Message";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  rightMenu:boolean;
  applyParameter:boolean;
  mailArray:{
    day:string
    mail:string 
  }[];
  subject:string;
  mailActive:number;
  saveMessage:boolean;
  deletePopup:boolean;
  shareModal: boolean;
  mail:string;
  generateMail:boolean;
  authToken:string;
  campDetails:{
    id: string,
    type: string,
    attributes:{
      id:number,
      subject_line:string,
      campaign:{
        id:number,
        name:string,
        description:string,
        account_id:number,
        favourited:boolean,
        campaign_type_id:number,
        campaign_category_id:number
      },
      prompt:string
    }
  };
  generatedMail:string;
  formData: any[];
  formValueData:any[];
  parameterDialog: boolean
  parameterChangeDialog: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  displayCampDetailsID : string = "";
  getMailDataID : string = "";
  getParamDataID : string = "";
  getQuestionMsgId : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      rightMenu:false,
      applyParameter:false,
      mailArray:[
        {
          day:'day',
          mail:'Mail'
        }
      ],
      subject:'Mail 1',
      mailActive:1,
      saveMessage:false,
      deletePopup:false,
      shareModal: false,
      mail:'',
    generateMail:false,
  authToken:'',
  campDetails:
    {
      id: "",
      type: "",
      attributes:{
        id:0,
        subject_line:"",
        campaign:{
          id:0,
          name:"",
          description:"",
          account_id:0,
          favourited:false,
          campaign_type_id:0,
          campaign_category_id:0
        },
        prompt:""
      }
    },
    generatedMail:"",
    parameterDialog: false,
    parameterChangeDialog: false,
    formData: [],
    formValueData:[],
      // Customizable Area End
};
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      authToken: await StorageProvider.get("user-token"),
    }, () => {
      this.getCampDetails()
      this.getFormData();
    })

  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      await this.handleCampDetails(requestCallId, message);
      await this.handleMailCreated(requestCallId, message);
      await this.handleParamDetails(requestCallId, message);
      await this.handleFormQuestion(requestCallId, message);
    }
  }
  async handleCampDetails(requestCallId: string, message: Message) {
    if (this.displayCampDetailsID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({
          campDetails: responseJson.data[0],
          mail: responseJson.data[0].attributes.prompt,
          subject: responseJson.data[0].attributes.subject_line
        });
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  async handleMailCreated(requestCallId: string, message: Message) {
    if (this.getMailDataID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.answer) {
        this.handleAddMail();
        this.handleSelectMail(this.state.mailActive + 1)
        this.setState({
          mail: responseJson.answer,
          generateMail:true
        });
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  async handleParamDetails(requestCallId: string, message: Message) {
    if (this.getParamDataID === requestCallId) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        const listAnswer = responseJson.data;
        this.setState({
          formValueData: listAnswer
        });
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }

  async handleFormQuestion(requestCallId: string, message: Message) {
    if (this.getQuestionMsgId === requestCallId) {
      this.getParamData();
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({
          formData: responseJson.data
        });
      } else {
        const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorMessage);
      }
    }
  }
  handleRightMenu =()=>{
    this.setState({rightMenu: !this.state.rightMenu})
  }
  handleAddMail = () => {
    this.setState({mailArray: [...this.state.mailArray,{ day:'day', mail:'Mail'}]})
  }
  handleSelectMail = (index:number) => {
    this.setState({subject:`${'Mail ' + index}`,mailActive:index})
  }
  handleSave = () => {
    this.setState({saveMessage:!this.state.saveMessage})
  }
  handleDelete = () => {
    this.setState({deletePopup: !this.state.deletePopup})
  }
  handleShare = () => {
    this.setState({shareModal: !this.state.shareModal})
  };
  handleMailChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    this.setState({mail:event.target.value})
  }
  handleGenerate = () => {
    this.getMail()
  }
  getCampDetails = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.displayCampDetailsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointGetCamp
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getMail = async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    const groupId = await StorageProvider.get("param_group_id");
    const body = {
      "campaign_id": '230',
      "param_group_id":groupId
    }
    this.getMailDataID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointCreateMail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
   handleOpenParameterDialog = () => {
    this.setState({
      parameterDialog: true
    })
  }
  
  handleCloseParameterDialog = () => {
    this.setState({
      parameterDialog: false
    })
  }

  handleOpenParameterDialogChange = () => {
    this.setState({
      parameterChangeDialog: true
    })
  }

  handleCloseParameterDialogChange = () => {
    this.setState({
      parameterChangeDialog: false
    })
  }
  
  getFormData = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.getQuestionMsgId  = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.formCreationEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getParamData = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const groupId = await StorageProvider.get("param_group_id");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    this.getParamDataID  = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointgetParam + groupId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
