import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core";
export const configJSON = require("./config");
// Customizable Area End
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe(
  "pk_test_51NiClTEIRDTrpe9LCq0W7Hw61zxM1olugRlTYDJvHkxWwv4teOdtSkcawaORZlgaEBDkExNOSC4bLtl7hSTbW5SU00BBQM7MkX"
);

// Customizable Area Start

export interface Props extends WithStyles<any> {
  navigation: any;
  onUnsubscribe(): void;
  isUnSubscriptionOpen:boolean;
}

interface S {
  errors: any;
  loading: boolean;
  isContinue: boolean;
  isUnsubscribe: boolean;
}

interface SS {
  id: any;
}

export default class UnSubscriptionController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      errors: "",
      loading: false,
      isContinue: false,
      isUnsubscribe: false
    };

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

 
  componentDidMount = async () => {

  };


  handleIsContinuePopup = () => {
    this.setState({
      isContinue: true,
      isUnsubscribe: false
    });
  };

  handleIsUnsubscribePopup = () => {
    this.setState({
      isUnsubscribe: true,
      isContinue: false,
    });
  };

  handleCloseUnsubscribePopup = () => {
    this.props.onUnsubscribe()
    this.setState({
      isUnsubscribe: false,
      isContinue: false,
    });
  };

}
