import { Box, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles
} from "@material-ui/core/styles";
import React from "react";
import { landingBg, landingInner } from "./assets";

export interface Props extends WithStyles<any> {}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif"
  }
});

const styles = () =>
  createStyles({
    container: {
      height: 235,
      backgroundImage: `url(${landingBg})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      width: "100%",
      overflow: "hidden",

      "@media (min-width: 768px)": {
        borderRadius: "0px 0px 20px 20px"
      }
    },

    inner: {
      width: 200,
      height: "100%",
      padding: "26px 20px",
      display: "flex",
      flexDirection: "column",
      backgroundImage: `url(${landingInner})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      boxSizing: "border-box"
    },

    subHeading: {
      fontWeight: 400,
      fontSize: 6,
      lineHeight: "6px",
      color: "#FFFFFF",
      marginTop: 26
    },

    heading: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: "20px",
      color: "#FFFFFF",
      marginTop: 9
    },

    description: {
      fontWeight: 500,
      fontSize: 10,
      lineHeight: "13px",
      color: "#FFFFFF",
      marginTop: "auto",
      letterSpacing: "0em"
    }
  });

export class LandingHead extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.container}>
          <Box className={classes.inner}>
            <Typography className={classes.subHeading}>
              IMPORTANT UPDATE
            </Typography>

            <Typography className={classes.heading} component="h1">
              Welcome to Pebbles Ai
            </Typography>

            <Typography className={classes.description}>
              Congratulations, you are one of our 1,000 users. Stay tuned for
              early access to new features.
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(LandingHead);
