import React from "react";
import {
  Box,
  Container,
  IconButton,
  Typography,
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import HistoryHeader from "../../../components/src/HistoryHeader.web"
import HistoryTableWithAccodian from "../../../components/src/HistoryTableWithAccodian.web"

//Icons
import {
  rightBar,
} from "../../chatbot4/src/assets";
import HistoryController from "../../multipageforms/src/HistoryController.web.";
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";

const copilotTableHeader = [
  "Conversation",
  "Intelligence",
  "Timestamp",
  "Options",
]
const outreachTableHeader = [
  "Title & Number",
  "Tag & Preview",
  "Timestamp",
  "Options",
]
export class History extends HistoryController {
 
  render() {
    const { classes, id, navigation } = this.props;
    const {
      historyData,
      selectFeildEnable,
      selectedFeilds,
      rightDrawerEnable,
      activeTab,
      historyOutreachData,
      selectedHistoryId
    } = this.state;
    return (
      <NavigationMenuComponent
      handleRightMenu={() => this.setState({ rightDrawerEnable: false })}
      activeIndex={4}
      navigation={navigation}
      rightMenu={rightDrawerEnable}
      id={id}
      data-test-id="navigationMenu"
      >
        <Container  className={classes.mainWorkFlowContainerr}>
          <Box className={classes.sideBarIcon}>
            <IconButton
            data-test-id="rightBarBtnDataTetId"
            onClick={() => this.setState({ rightDrawerEnable: true })}>
              <img src={rightBar} alt="rightBarSide" />
            </IconButton>
          </Box>
          <Box>
            <Typography className={classes.pageHeading1}>
            History
            </Typography>
          </Box>

  <Box  className={classes.tableHeaderWrapper}>
  <HistoryHeader
      data-test-id="HistoryHeader"
      selectedFeilds={selectedFeilds}
      dataLength={historyData.length}
      handelSelectAllWorkFLow={this.handelSelectAllWorkFLow}
      handelSelectFeildBtn={this.handelSelectFeildBtn}
      selectFeildEnable={selectFeildEnable}
      removeSelction={()=>this.setState({selectFeildEnable:false})}
      activeTab={activeTab}
      handelTabChange={(obj:any)=>{this.setState(obj)}}
     />


<HistoryTableWithAccodian
data-test-id="HistoryTableWithAccodian"
data={activeTab==1?historyData:historyOutreachData}
handelFeildCheckBoxClick={this.handelFeildCheckBoxClick}
selectFeildEnable={selectFeildEnable}
selectedFeilds={selectedFeilds}
selectedHistoryId={selectedHistoryId}
handelSelctedHistory={(obj:any)=>{this.setState(obj)}}
tableHeader={activeTab==1?copilotTableHeader:outreachTableHeader}
activeTab={activeTab}
/>


  </Box>
        </Container>
      </NavigationMenuComponent>
    );
  }
}

const styles = createStyles({
  mainWorkFlowContainerr: {
    position: "relative",
    height: "100vh",
    padding: "16px 20px 16px 20px",
  },
  pageHeading1: {
    fontFamily: "Montserrat",
    fontSize: "48px",
    fontWeight: 600,
    textAlign: "center",
  },
  sideBarIcon: {
    position: "absolute",
    right: "10px",
    top: "10px",
    zIndex: 4200,
  },
  tableHeaderWrapper:{
    margin:"0px 50px"
  },
});

export default withStyles(styles)(History);
