import React from "react";
import {  Container, Grid, IconButton, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  createStyles,
  createTheme,
  withStyles
} from "@material-ui/core/styles";
import SubscriptionExploreUpdateController, {
  Props
} from "./SubscriptionExploreUpgradeController.web";
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";
import  SubscriptionPlan from "./SubscriptionPlan.web";
import { rightBar } from "./assets";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif"
  }
});

const styles = () =>
  createStyles({
    heading: {
      fontSize: 48,
      fontWeight: 600,
      lineHeight: "57.6px",
      marginTop: 40,
      textAlign: "center"
    },

    container: {
      position: "relative"
    },

    rightBar: {
      position: "absolute",
      top: -20,
      right: -20,
      zIndex: 99999,
    },

    content: {
      marginTop: 60,
      justifyContent: "center"
    }
  });

export class SubscriptionExploreUpgrade extends SubscriptionExploreUpdateController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, id, navigation } = this.props;

    const  { currentPlan, plans } = this.state

    return (
      <ThemeProvider theme={theme}>
        <NavigationMenuComponent id={id} navigation={navigation} activeIndex={5} handleRightMenu={this.toggleRightMenu}
          rightMenu={this.state.rightMenu}>
          <Container maxWidth="lg" className={classes.container}>
            <Typography className={classes.heading}>
              Explore upgrades
            </Typography>

            {!this.state.rightMenu &&
              <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  data-test-id="right-menu-btn"
                  onClick={this.toggleRightMenu}
                  className={classes.rightBar}
              >
                  <img
                      src={rightBar}
                      alt="right-bar"
                  />
              </IconButton>
            }

              <Grid container spacing={6} className={classes.content}>
                {plans.map((plan) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={plan.id}>
                    <SubscriptionPlan
                      plan={plan}
                      buttonText="Upgrade"
                      disabledButtonText="Your current option"
                      onChoose={this.onChoosePlan}
                      disabledSelect={
                        currentPlan?.id.toString() === plan.id.toString()
                      }
                    />
                  </Grid>
                ))}
              </Grid>    
          </Container>
        </NavigationMenuComponent>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(SubscriptionExploreUpgrade);
