import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import StorageProvider from "../../../framework/src/StorageProvider";
import { chipLabels, goodToHaveServiceArray, inputList, offeringsBenefits, offeringsCorporatePartnersInputList, offeringsCorporatePartnersServiceArray, offeringsProductArray, offeringsProductsInputList, offeringsServicesArray, offeringsServicesInputList, offeringsTeamInputList, performanceAnalyticsQuestionList, performanceAnalyticsServiceArr, productTestimonial, productTestimonialInputList, serviceArray, serviceArray1, shouldHaveServiceArray, offeringsgoodToHaveInputList, offeringsModularProductsServiceArray, offeringsModularProductsInputList } from "../utils/data";

// Customizable Area Start
interface InputItem {
    offering: string;
    description: string;
    offeringTitle: string;
    descriptionTitle: string
}

export const initialOfferValues: any = {
    whyExist: [],
    what_is_it: "",
    performanceAnalystics: ["", "", ""],
    productTestimonials: [""],
    corporatePartners: [
        {
            benifit: "",
            partner: [
                {
                    name: "",
                    description: "",
                    benifit: "",
                },
                {
                    name: "",
                    description: "",
                    benifit: "",
                },
                {
                    name: "",
                    description: "",
                    benifit: "",
                },
            ],
        },
    ],
    productAndFeature: [
        {
            offering: "",
            description: "",
        },
        {
            offering: "",
            description: "",
        },
        {
            offering: "",
            description: "",
        },
    ],
    offeringServices: [
        {
            offering: "",
            description: "",
        },
        {
            offering: "",
            description: "",
        },
        {
            offering: "",
            description: "",
        },
    ],
    theTeam: [
        {
            offering: "",
            description: "",
        },
        {
            offering: "",
            description: "",
        },
        {
            offering: "",
            description: "",
        },
    ],
    modularProducts: [
        {
            name: "",
            description: "",
            price: "",
            currency: "null",
        },
        {
            name: "",
            description: "",
            price: "",
            currency: "null",
        },
        {
            name: "",
            description: "",
            price: "",
            currency: "null",
        },
    ],
    packagesAndPricing: [
        {
            offering: "",
            description: "",
            price: "",
            currency: "null",
        },
        {
            offering: "",
            description: "",
            price: "",
            currency: "null",
        },
        {
            offering: "",
            description: "",
            price: "",
            currency: "null",
        }
    ]
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    serviceArray: string[];
    serviceArray1: string[];
    shouldHaveServiceArray: string[];
    goodToHaveServiceArray: string[];
    inputList: any;
    headeractiveStep: any;
    headerprevCompletedStep: any;
    mustHaveExpanded: any;
    shouldHaveExpanded: any;
    goodToHaveExpanded: any;
    chipLabels: any;
    performanceAnalyticsQuestionList: any;
    performanceAnalyticsServiceArr: any;
    offeringsProductArray: string[];
    offeringsServicesArray: string[];
    offeringsProductsInputList: {
        offeringPlaceholder: string;
        descriptionPlaceholder: string;
        offering: string;
        description: string;
        offeringTitle: string;
        descriptionTitle: string;
        charLength: number;
        charLengthDesc: number
    }[],
    offeringsTeamInputList:{
        offeringPlaceholder: string;
        descriptionPlaceholder: string;
        offering: string;
        description: string;
        offeringTitle: string;
        descriptionTitle: string;
        charLength: number;
        charLengthDesc: number
    }[],
    offeringsServicesInputList: {
        offeringPlaceholder: string;
        descriptionPlaceholder: string;
        offering: string;
        description: string;
        offeringTitle: string;
        descriptionTitle: string;
        charLength: number;
        charLengthDesc: number
    }[],
    productTestimonial: any;
    productTestimonialInputList: any;
    offeringsgoodToHaveInputList: any;
    offeringsModularProductsServiceArray: any;
    offeringsModularProductsInputList: any;
    offeringsCorporatePartnersServiceArray: any;
    offeringsCorporatePartnersInputList: any;
    offeringsBenefits: any;
    progressHeaderSubStep: any;
    progressHeaderTime: any;
    progressHeaderType: any;
    progressHeaderPrimaryHeading: any;
    progressHeaderSecondaryHeading: any;
    alreadyFilledFormValues:any;

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class OfferingsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    offerMusthaveApiCallId: string = "";
    offerShouldhaveApiCallId: string = "";
    offergoodToHavehaveApiCallId: string = ""
    initDataMessageId: string = "";

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            mustHaveExpanded: true,
            shouldHaveExpanded: false,
            goodToHaveExpanded: false,
            headeractiveStep: 3,
            headerprevCompletedStep: 2,
            offeringsProductArray: offeringsProductArray,
            offeringsServicesArray: offeringsServicesArray,
            offeringsProductsInputList: offeringsProductsInputList,
            offeringsTeamInputList: offeringsTeamInputList,
            offeringsServicesInputList: offeringsServicesInputList,
            productTestimonial: productTestimonial,
            productTestimonialInputList: productTestimonialInputList,
            performanceAnalyticsQuestionList: performanceAnalyticsQuestionList,
            performanceAnalyticsServiceArr: performanceAnalyticsServiceArr,
            shouldHaveServiceArray: shouldHaveServiceArray,
            serviceArray: serviceArray,
            serviceArray1: serviceArray1,
            goodToHaveServiceArray: goodToHaveServiceArray,
            offeringsgoodToHaveInputList: offeringsgoodToHaveInputList,
            offeringsModularProductsServiceArray:offeringsModularProductsServiceArray,
            offeringsModularProductsInputList:offeringsModularProductsInputList,
            offeringsCorporatePartnersServiceArray: offeringsCorporatePartnersServiceArray,
            offeringsCorporatePartnersInputList: offeringsCorporatePartnersInputList,
            offeringsBenefits: offeringsBenefits,
            inputList: inputList,
            chipLabels: chipLabels,
            progressHeaderSubStep: "1",
            progressHeaderTime: "5",
            progressHeaderType: "gold",
            progressHeaderPrimaryHeading: "Offerings",
            progressHeaderSecondaryHeading:
              "Finally, describe your suite of products, features and services in more detail.",
            alreadyFilledFormValues:{}
      // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.fetchInitData();
        // Customizable Area End
    }
    // Customizable Area Start

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
           
            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

             const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );        
            
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            ); 

            switch (apiRequestCallId) {
                case this.offerShouldhaveApiCallId:
                    this.shouldHaveErrorCondition(responseJson)
                    break;
                case this.initDataMessageId:
                    if (!responseJson.errors) {
                        this.handleInitData(responseJson)
                    }
                    break;
                case this.offerMusthaveApiCallId:
                    this.mustHaveErrorCondition(responseJson);
                    break;              
                case this.offergoodToHavehaveApiCallId:
                    this.goodToHaveErrorCondition(responseJson, errorReponse)
                    break;
            }
        }
    }

    shouldHaveErrorCondition = (responseJson: any) => {
        if (!responseJson.errors) {
            this.setState({ goodToHaveExpanded: true, progressHeaderSubStep: "3", shouldHaveExpanded: false })

        } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
        }
    }

    mustHaveErrorCondition = (responseJson: any) => {
        if (!responseJson.errors) {
            this.setState({  progressHeaderSubStep: "2", mustHaveExpanded: false, shouldHaveExpanded: true})
        } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
        }
    }

    goodToHaveErrorCondition = (responseJson: any, errorReponse: any) => {
        if (!responseJson.errors) {
            this.setState({ progressHeaderSubStep: "3", goodToHaveExpanded: false })
            this.redirectToChoosePlanPage()
        } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
        }
        this.parseApiCatchErrorResponse(errorReponse);
    }

    // Customizable Area End

    // Customizable Area End

    // web events

    // Customizable Area Start

    backButton = () => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "AboutYourApproach"
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    } 

    handleInitData = (responseData: any[]) => {
        const mustHaveSectionData = responseData.filter((item: any) => item.data.attributes.details_type === 'must' && item.data.attributes.object_type === 'offerings');
        const shouldHaveSectionData = responseData.filter((item: any) => item.data.attributes.details_type === 'should' && item.data.attributes.object_type === 'offerings');
        const goodToHaveSectionData = responseData.filter((item: any) => item.data.attributes.details_type === 'good' && item.data.attributes.object_type === 'offerings');
        
        const mustHaveSection = !!mustHaveSectionData.length ? {
            whyExist: mustHaveSectionData[mustHaveSectionData.length - 1].data.attributes.keywords,
            productAndFeature: mustHaveSectionData[mustHaveSectionData.length - 1].data.attributes.product_features_attributes,
            offeringServices: mustHaveSectionData[mustHaveSectionData.length - 1].data.attributes.offering_services_attributes,
        } : {}
    
        const shouldHaveSection = !!shouldHaveSectionData.length ? {
            theTeam: shouldHaveSectionData[shouldHaveSectionData.length - 1].data.attributes.the_team,
            productTestimonials: shouldHaveSectionData[shouldHaveSectionData.length - 1].data.attributes.product_testimonials,
            performanceAnalystics: shouldHaveSectionData[shouldHaveSectionData.length - 1].data.attributes.performance_analystics,
        } : {}
    
        const goodHaveSection = !!goodToHaveSectionData.length ? {
            corporatePartners: [goodToHaveSectionData[goodToHaveSectionData.length - 1].data.attributes.corporate_partners],
            modularProducts: goodToHaveSectionData[goodToHaveSectionData.length - 1].data.attributes.modular_products,
            packagesAndPricing: goodToHaveSectionData[goodToHaveSectionData.length - 1].data.attributes.packages_and_pricing,
        } : {}
        
        this.setState({
          chipLabels:mustHaveSection.whyExist||chipLabels,
          alreadyFilledFormValues:{
            ...initialOfferValues,
            ...mustHaveSection,
            ...shouldHaveSection,
            ...goodHaveSection
          },
        })      
      }

      priceValidation = (data: any) => {
        return ((data?.toString().split('.')[1] || []).length) <= 3;
      }

    fetchInitData = async () => {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: await StorageProvider.get("user-token"),
        };
        const getValidationsMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.initDataMessageId = getValidationsMessage.messageId;
        getValidationsMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType 
        );
        getValidationsMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.apiGetGoldPlanInfo  
        );
        getValidationsMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers) 
        );
        runEngine.sendMessage(getValidationsMessage.id, getValidationsMessage);
    }

    handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
        const { name, value } = event.target;
        const list: any = [...this.state.inputList];
        list[index][name] = value;
        this.setState({ inputList: list })
    }
    handleExpand = () => {
        this.setState({ mustHaveExpanded: !this.state.mustHaveExpanded,progressHeaderSubStep:1 })
    }
    handleExpand1 = () => {
        this.setState({ shouldHaveExpanded: !this.state.shouldHaveExpanded,progressHeaderSubStep:2 })
    }
    handleExpand2 = () => {
        this.setState({ goodToHaveExpanded: !this.state.goodToHaveExpanded,progressHeaderSubStep:3 })
    }
    deleteChipByName = (nameToDelete: any) => {
        this.setState((prevState) => ({
            chipLabels: prevState.chipLabels.filter((label: any) => label !== nameToDelete),
        }));
    };

    checkValidation = (touch: boolean, error:any) => {
        return !!touch && !!error ? error : ''
    }

    addChipLabels = (value: string) => {
        const { chipLabels } = this.state;
        if (value.trim() !== '') {
            // Create a new array with the new item added to the end
            const updatedItems = [...chipLabels, value];
            this.setState({
                chipLabels: updatedItems,
            });
        }
    }

    handleAddObjectItem = () => {
        const { inputList } = this.state;

        // Create a new item and add it to the inputList
        const newItem = {
            label: "Step",
            placeholder: "New Placeholder",
            value: "",
            name: `step${inputList.length + 1}`,
            charLength: 30
        };

        this.setState({
            inputList: [...inputList, newItem]
        });
    }


    handleCommonObjectChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
        listName: keyof S, // Use keyof to specify the list name
        propertyName: string
    ) => {
        const { value } = event.target;
        this.setState((prevState): any => {
            const updatedList = [...prevState[listName]];
            updatedList[index][propertyName] = value;
            return { [listName]: updatedList };
        });
    };

    addTestimonial = (value: string) => {
        this.setState((prev) => ({
            productTestimonialInputList: [...prev.productTestimonialInputList,
            {
                label: "Testimonial",
                placeholder: "Paul Walker | CEO | Kumoco | B2B | SaaS | Cloud Tech Our GTM strategy and execution capabilities in conjunction with our execution support has pushed our incoming marketing qualified leads by 444%, reduced our marketing execution cost by 76%, and landed our first demo for an Enterprise client in month 6",
                value: "",
                name: "",
                charLength: 200

            },
            ]
        }))
    }

    addProductAnalytics = (value: string) => {
        this.setState((prev) => ({
            performanceAnalyticsQuestionList: [...prev.performanceAnalyticsQuestionList,
                {
                    label: `Analytics`,
                    placeholder: "100% of our B2B / B2B2C Clients witnessed accelerated growth.",
                    value: "",
                    name: `analytics${this.state.performanceAnalyticsQuestionList.length+1}`,
                    charLength: 30
                },
            ]
        }))
    }

    saveAndContinueButton = (formValues:any) => {
        if (this.state.mustHaveExpanded) {
            this.mustHaveOfferOnSubmit(formValues)
        } else if (this.state.shouldHaveExpanded) {
            this.shouldOfferHaveOnSubmit(formValues)
        } else {
            this.goodOfferToHaveOnSubmit(formValues)
        }
    }

    async mustHaveOfferOnSubmit(formValues:any) {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await StorageProvider.get("user-token")

        };
        const httpBody = {
            data: {
                "attributes": {
                    "details_type": "must",
                    "object_type": "offerings",
                    "keywords": formValues.whyExist,
                    "product_features_attributes": formValues.productAndFeature,
                    "offering_services_attributes": formValues.offeringServices
                }
            }
        };
        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.offerMusthaveApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.musthaveEndPoint
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

        return true;
    }

    async shouldOfferHaveOnSubmit(formValues:any) {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await StorageProvider.get("user-token")
        };

        const httpBody = {
            data: {
                "attributes": {
                    "details_type": "should",
                    "object_type": "offerings",
                    "the_team":formValues.theTeam,
                    "product_testimonials":  formValues.productTestimonials,
                    "performance_analystics": formValues.performanceAnalystics,
                }
            }
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.offerShouldhaveApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.musthaveEndPoint
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
        return true;
    }

    async goodOfferToHaveOnSubmit(formValues:any) {
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": await StorageProvider.get("user-token")
        };
        const httpBody = {
            data: {
                "attributes": {
                    "object_type": "offerings",
                    "details_type": "good",
                    "corporate_partners":formValues.corporatePartners[0],
                    "modular_products":formValues.modularProducts,
                    // "the_team":formValues.theTeam,
                    "packages_and_pricing": formValues.packagesAndPricing,
                    // "offering_services": formValues.offeringServices,
                    // "product_and_feature": formValues.productAndFeature,
                    // "product_testimonials": formValues.productTestimonials,
                    // "performance_analystics": formValues.performanceAnalystics,
                    // "why_exist": formValues.whyExist
                }
            }
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.offergoodToHavehaveApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.musthaveEndPoint
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
        return true;
    }

    // Customizable Area End
    async redirectToChoosePlanPage(){
        const redirection = await StorageProvider.get('isEdit');
        await StorageProvider.set('isEdit','')
        if(!!redirection){
            const message: Message = new Message(
                getName(MessageEnum.NavigationMessage)
            );
            message.addData(
                getName(MessageEnum.NavigationTargetMessage),
                "LandingPage"
            );
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
            return;
        }
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "Subscription"
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }
    // Customizable Area End
}
