import { createStyles } from "@material-ui/core/styles";

export const styles = () =>
  createStyles({
    dropdownWrapper: {
      height: "44px",
      marginTop: 0,
    },
    errorBorderStyle: {
      border: "1px solid #F87171",
    },
    layoutContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    viewBox: {
      margin: '0 40px',
      border: "1px solid #CBD5E1",
      background:
        "linear-gradient(145deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
      padding: "20px",
      '@media (min-width: 1300px)': {
        margin: 0,
        width: "438px",
        padding: "40px"
      },
      display: "flex",
      flexDirection: "column",
      borderRadius: "28px",
    },
    headerTitle:{
      fontSize:'40px',
      textAlign:'center',
      fontWeight:600,
      '@media (min-width: 1300px)': {
        fontSize:'48px',
      },
      fontFamily: "Montserrat",
    },
    createAccount: {
      textAlign: "center",
      fontSize: "30px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "10px",
      color: "#000",
      fontFamily: "Montserrat",
    },
    signingAs: {
      textAlign: "center",
      color:'#475569',
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      fontFamily: "Montserrat",
      marginBottom: "20px",
    },
    container: {
      width: "418px",
      display: "flex",
      flexDirection: "column"
    },
    singupText: {
      textAlign: "center",
      fontSize: "24px",
      fontWeight: 700,
      lineHeight: "32px",
      marginBottom: "50px",
      color: "black",
      fontFamily: "Montserrat"
    },
    form: {
      margin: "0"
    },
    form1: {
      display: "flex",
      flexDirection: "column",
      margin: "0"
    },
    label: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "black",
      fontFamily: "Montserrat"
    },
    label1: {
      width: "395px",
      margin: "0",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "black",
      fontFamily: "Montserrat"
    },
    input: {
      height: "40px",
      marginTop: "5px",
      borderRadius: " 8px",
      // border: "1px solid rgba(140, 140, 140, 0.20)",
      background: "#FFF",
      marginBottom: "24px",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "transparent",
          height: "40px",
          border: 'none'
        },
        "&:hover fieldset": {
          borderColor: "transparent"
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent"
        },
        "& .MuiOutlinedInput-input": {
          padding: "10px 33px 10px 8px",
          lineHeight: "22px",
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "Montserrat",
        },

        "& .MuiInputBase-input::placeholder": {
          opacity: 1
        },

        "& .MuiInputBase-input": {
          height: "auto"
        }
      }
    },
    input1: {
      marginTop: "4px",
      height: "40px",
      borderRadius: " 8px",
      border: '1px solid #CBD5E1',
      background: "#FFF",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "transparent",
          height: "44px",
          border: 'none'
        },
        "&:hover fieldset": {
          borderColor: "transparent"
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent"
        },
        "& .MuiOutlinedInput-input": {
          height: "40px",
          padding: "10px 33px 10px 8px",
          lineHeight: "22px",
          fontSize: "14px",
          fontWeight: 500,
          fontFamily: "Montserrat",
          color: "#000",
        },

        "& .MuiInputBase-input::placeholder": {
          opacity: 1,
          color:'#64748B'
        },

        "& .MuiInputBase-input": {
          height: "auto"
        }
      },
      "& .MuiOutlinedInput-adornedEnd": {
        paddingRight: 0,
        padding:"0px !important"
      }
    },
    eyeIcon: {
      paddingRight: "7px",
      color: "#94A3B8",
      cursor: "pointer"
    },
    select: {
      marginTop: "5px",
      height: "40px",
      borderRadius: " 8px",
      border: '1px solid #CBD5E1',
      overflow: 'hidden',
      background: "#FFF",
      '& .MuiSelect-nativeInput': {
        top: 0,
        height: '100%'
      },
      '& .MuiSelect-icon':{
        color:"#000"
      },
      '& .MuiSelect-select': {
        '& fieldset': {
          borderColor: 'transparent',
          height: "40px",
        },
        '&:hover fieldset': {
          borderColor: 'transparent',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent',
        },
      },
      "& .MuiSelect-select.MuiSelect-select": {
        padding: "10px 8px",
        color: "#000",
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: "Montserrat",
        background:"#fff"
      },
      '& .MuiSelect-select:hover': {
      },
      '& .MuiSelect-select.Mui-focus': {
        color: "#000",
        background:"#fff"
      },
    },
    inactiveSelect1: {
      "& .MuiSelect-select.MuiSelect-select": {
        color: "#64748B",
        background:"#fff"
      },
      '& .MuiSelect-select.Mui-focus': {
        color: "#64748B",
        background:"#fff"
      },
    },
    
    button: {
      height: "44px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      background: "#000",
      boxShadow: " 0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
      textTransform: "none",
      color: "white",
      fontFamily: "Montserrat",
      fontStyle: "normal",
    },
    button1: {
      width: "395px",
      margin: "0 auto",
      height: "45px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      background: "#000",
      boxShadow: " 0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
      textTransform: "none",
      fontFamily: "Montserrat",
      color: "white"
    },
    validationtxt: {
      color: "#64748B",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      fontFamily: "Montserrat",
      marginBottom: "40px"
    },
    hr: {
      background: "rgba(100, 116, 139, 0.20)",
      height: "1px",
      width: "312px",
      margin: "24px auto"
    },
    googlebtn: {
      height: "45px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      textTransform: "none",
      border: "1px solid rgba(140, 140, 140, 0.20)",
      background: "#FFF",
      boxShadow: "none",
      width: "100%",
      "&:hover": {
        background: "#FFF"
      }
    },
    googleTxt: {
      color: "#000",
      marginLeft: "8px",
      fontWeight: 600,
      fontStyle: "normal",
      fontFamily: "Montserrat"
    },
    terms: {
      display: "flex",
      alignItems: "center",
      marginTop: "33px",
      marginBottom: "3%"
    },
    checkbox: {
      width: "20px",
      height: "20px",
      "& .MuiCheckbox-root": {
        color: "#64748B",
      }
    },
    agreement: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "22px",
      fontStyle: "normal",
      fontFamily: "Inter"
    },
    link: {
      color: "#3A84C9",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      textDecoration: "underline",
      paddingBottom: "3px",
      cursor: "pointer"
    },
    sentSignupText: {
      color: "#64748B",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "22px",
      margin: "16px 0",
      fontStyle: "normal",
      fontFamily: "Montserrat"
    },
    code: {
      color: "#8C8C8C",
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "22px",
      textAlign: "center",
      fontStyle: "normal",
      fontFamily: "Montserrat",
      marginTop: "24px",
    },
    resend: {
      color: "#3A84C9",
      fontWeight: 600,
      lineHeight: "22px",
      textDecorationLine: "underline",
      cursor: "pointer",
    },
    fieldWrapper: {
      margin: "auto",
      marginBottom: "24px"
    },
    inputWrapper: {
      marginBottom: "24px"
    },
    errorText: {
      color: "rgb(179, 23, 23)",
      fontSize: "13px",
      marginTop: "2px",
      display: "block"
    },
    backbutton: {
      fontSize: "16px",
      fontWeight: 600,
      textTransform: "capitalize",
      fontStyle: "normal",
      fontFamily: "Montserrat",
      marginTop: "10px",
    },
    buttonWrapper: {
      height: "44px",
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
      background: "#000",
      boxShadow: " 0px 6px 15px -3px rgba(0, 0, 0, 0.15)",
      textTransform: "none",
      fontFamily: "Montserrat",
      color: "#fff",
      fontStyle: "normal",
      marginTop: "16px",
      '@media (min-height: 1000px)': {
        marginTop: "32px",
      },
      '&:hover':{
        background: "#000",
      }
    },
    title: {
      margin: "0",
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "black",
      fontFamily: "Montserrat"
    },
    locationSearch: {
      position: 'relative',
    },
    inputAutoComplete: {
      display: "flex",
      justifyContent:"center" ,
      height: "40px ",
      marginTop: "5px ",
      borderRadius: " 8px ",
      border: '1px solid #CBD5E1',
      background: "#FFF",
      overflow: 'hidden',
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "transparent !important",
          height: "40px !important",
          border: 'none !important'
        },
        "&:hover fieldset": {
          borderColor: "transparent !important"
        },
        "&.Mui-focused fieldset": {
          borderColor: "transparent !important"
        },
        "& .MuiOutlinedInput-input": {
          height: "40px !important",
          padding: "10px 33px 10px 8px !important",
          lineHeight: "22px !important",
          fontSize: "14px !important",
          fontWeight: 500,
          fontFamily: "Montserrat !important",
          color: "#64748B !important",
        },

        "& .MuiInputBase-input::placeholder": {
          opacity: 1,
          color:'#64748B'
        },

        "& .MuiInputBase-input": {
          height: "auto",
          position: "absolute !important",
          left: "33px",
          width: "100%",
          color: '#000 !important'

        },
        
        "& .MuiSvgIcon-fontSizeSmall": {
          color: "transparent !important"
        },
        "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon": {
          display: "flex !important",
          flexDirection: "column !important",
        },

        "& .MuiAutocomplete-input:first-child": {
          width: "73% !important"
        },
        "& .MuiSvgIcon-root": {
          color: "transparent"
        }
      }
    },
    
    locationIcons: {
      width: '24px',
      height: '24px',
      position: 'absolute',
      left: '12px',
      top: '9px'
    },
    searchIcons: {
      width: '24px',
      height: '24px',
      position: 'absolute',
      right: '12px',
      top: '9px'
    },
    validateError: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: 1.2,
      color: "#DC2626",
      marginTop: "2px",
      fontFamily: "Montserrat",
      
    },
    errorResp: {                                            
      color: "#DC2626",
      fontFamily: "Montserrat",
      marginTop: "2px",
      fontSize: "12px",
      lineHeight: 1.2,
      fontWeight: 500,
    },
    setmenuItem: {
      borderTop: '1px solid #CBD5E1 !important',
      minHeight: '44px !important',
      boxShadow: 'none !important',
      display: 'flex !important',
      justifyContent: 'flex-start !important',
      padding: '10px 12px !important',
      fontFamily: "Montserrat",
      color:'#000 !important',
      fontSize:'14px !important',
      fontWeight:500,
      lineHeight:1.5,
    },

    aboutmenuItem: {
      borderTop: '1px solid #CBD5E1 !important',
      minHeight: '44px !important',
      boxShadow: 'none !important',
      display: 'flex !important',
      justifyContent: 'flex-start !important',
      padding: '10px 12px !important',
      fontFamily: "Montserrat",
      color:'#000 !important',
      fontSize:'14px !important',
      fontWeight:500,
      lineHeight:1.5,
    },

    autocompleteOption:{
      fontFamily: "Montserrat",
      fontSize:'14px !important',
      fontWeight:500,
      width:'100% !important',
      padding:'10px 12px',
      borderBottom: '1px solid #CBD5E1 !important'
    },
    autocompleteListBox:{
      padding:0,
      border: '1px solid #CBD5E1 !important'
    },    
    pwdIconColor:{
      color:"#94A3B8",
      cursor:"pointer",
    },
    priceCurrencyInput: {
      "& fieldset": {
        border: "none",
        borderLeft: "solid 1px",
        borderColor: "rgba(0,0,0,0.23)",
        borderRadius: 0
      },
      "& #currency": {
        padding: "10px",
      }
    }
  });


