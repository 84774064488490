import React from "react";


// Customizable Area Start
import {
    Box,
    Button,
    Typography
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import CopilotMenu from "./CopilotMenu.web";

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat',
    },
});
// Customizable Area End

import RightMenuController, {
    Props,
    configJSON,
} from "./RightMenuController";

export class RightMenu extends RightMenuController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }: any = this.props;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                {this.state.menuType === 1 ?
                        <Box className={classes.mainContainerCopilot}>
                        <Box>
                            <Box className={classes.displayMainConatiner}>
                                <Box className={classes.toggleDisplayCopilot}>
                                    <Box
                                        className={classes.activeMenu}
                                        data-testid="copilot-select"
                                        onClick={() => this.handleSelectMenu(1)}
                                    >
                                        {configJSON.Copilot}
                                    </Box>
                                    <Box
                                        className={classes.inActiveMenu}
                                        data-testid="email-select"
                                        onClick={() => this.handleSelectMenu(2)}
                                    >
                                        {configJSON.Emails}
                                    </Box>
                                </Box>
                                <CloseIcon className={classes.closeIcon} onClick={this.props.handleCloseRightMenu} />
                            </Box>
                            <CopilotMenu {...this.props}/>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                className={classes.buttonCopilot}
                                endIcon={<AddRoundedIcon />}
                                fullWidth
                            >
                                {configJSON.createNewChat}
                            </Button>
                        </Box>
                    </Box> 
                    :
                    <Box className={classes.mainContainer}>
                        <Box>
                            <Box className={classes.displayMainConatiner}>
                                <Box className={classes.toggleDisplay}>
                                    <Box
                                        className={classes.inActiveMenu}
                                        data-testid="copilot-select"
                                        onClick={() => this.handleSelectMenu(1)}
                                    >
                                        {configJSON.Copilot}
                                    </Box>
                                    <Box
                                        className={classes.activeMenu}
                                        data-testid="email-select"
                                        onClick={() => this.handleSelectMenu(2)}
                                    >
                                        {configJSON.Emails}
                                    </Box>
                                </Box>
                                <CloseIcon className={classes.closeIcon} onClick={this.props.handleCloseRightMenu} />
                            </Box>
                        </Box>
                        <Box>
                            <Typography className={classes.noCaampText}>{configJSON.noCamp}</Typography>
                            <Typography className={classes.chooseCamp}>{configJSON.chooseType}</Typography>
                            <Typography className={classes.chooseCamp}>{'campaign in tactical outreach'}</Typography>
                            <Typography className={classes.chooseCamp}>{configJSON.startCamp}</Typography>
                            <Button
                                variant="contained"
                                className={classes.button}
                                endIcon={<AddRoundedIcon />}
                                onClick={this.handleCreateNewCamp}
                                data-testid="create-camp"
                            >
                                {configJSON.createNewCamp}
                            </Button>
                        </Box>
                    </Box>}
            </ThemeProvider>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle: {} = () => ({
    mainRightMenuBox:{
        position:'relative',
        
    },
    displayMainConatiner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '54px'
    },
    toggleDisplay: {
        display: "flex",
        borderRadius: "30px",
        justifyContent: "space-between",
        alignItems: 'center',
        background: '#fff',
        padding: '2px'

    },
    toggleDisplayCopilot: {
        display: "flex",
        borderRadius: "30px",
        justifyContent: "space-between",
        alignItems: 'center',
        border: '1px solid #CBD5E1',
        padding: '2px'

    },
    activeMenu: {
        color: "#fff",
        background: "#000",
        fontWeight: 600,
        borderRadius: "30px",
        padding: "12px 15px",
        textTransform: "inherit",
        fontFamily: 'Montserrat',
        fontSize:'12px'
    },
    inActiveMenu: {
        fontWeight: 600,
        color: "#000",
        cursor: "pointer",
        textTransform: "inherit",
        padding: '10px 15px',
        fontFamily: 'Montserrat',
        fontSize:'12px'
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        maxHeight: '95vh',
        padding: '15px',
        zIndex: '999',
        background: '#CBD5E1'
    },
    mainContainerCopilot: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        height: '95vh',
        padding: '10px',
        zIndex: '999',
        background: '#fff',
        borderLeft: '1px solid #CBD5E1'
    },
    button: {
        background: '#fff',
        color: '#000',
        borderRadius: '8px',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        marginTop: '15px',
        textTransform: 'inherit'
    },
    buttonCopilot: {
        color: '#000',
        borderRadius: '8px',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        marginTop: '15px',
        textTransform: 'inherit',
        border: '1px solid #CBD5E1',
        background: '#FFF'
    },
    noCaampText: {
        color: '#334155',
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: '1.05rem',
        marginBottom: '5px'
    },
    chooseCamp: {
        color: '#334155',
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: '1.05rem',
    },
    closeIcon: {
        cursor: 'pointer'
    }
});
export default withStyles(webStyle)(RightMenu);

// Customizable Area End
