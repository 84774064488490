import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";

import { WithStyles } from "@material-ui/core";
import StorageProvider from "../../../framework/src/StorageProvider";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
}

interface S {
}

interface SS {
  navigation: any;
}

export default class BeforeWeStartController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
    };

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    return Promise.resolve()
  }
  
  componentDidMount=async()=>{
    return Promise.resolve()
  }
   aboutCompany = async()=>{
    await StorageProvider.set("filledFormData", JSON.stringify({}));
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BulkUploading"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  ChooseYourPath = ()=>{
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ChooseYourPath"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
}
