import React from "react";
import { Box, IconButton, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import {
  ArrowBackIos,
  DeleteOutline,
  InsertDriveFile,
  SaveAlt,
} from "@material-ui/icons";
import clsx from "clsx";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Dropdown from "../../../components/src/Dropdown.web";
import Toast from "../../utilities/src/Toast";
import BulkUploadingCompanyInfoController, {
  Props,
} from "./BulkUploadingCompanyInfoController.web";
import BulkUploadingCompanyInfoDialog from "./BulkUploadingCompanyInfoDialog.web";
import BulkUploadingCompanyInfoHighlightIcon from "./BulkUploadingCompanyInfoHighlightIcon.web";
import { background, logo } from "./assets";
import { v4 as uuidv4 } from "uuid";

const importValidationSchema = Yup.object().shape({
  data: Yup.object().test(
    "has-non-empty-property",
    "",
    (objectData: { [key: string]: number }) => {
      const errors: any = {};
      Object.entries(objectData).forEach(([key, value]) => {
        if (value <= 0) {
          errors[key] = "Please choose file type";
        }
      });
      if (Object.keys(errors).length === 0) return true;
      throw new Yup.ValidationError(errors, objectData, "data");
    }
  ),
});

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif",
  },
});

const styles = () =>
  createStyles({
    font16: {
      fontSize: "16px",
      lineHeight: "22px",
    },

    font12: {
      fontSize: "12px",
      lineHeight: "22px",
      fontWeight: 400,
    },

    fontWeight600: {
      fontWeight: 600,
    },

    importContainer: {
      height: "100%",
      width: "100%",
      padding: "200px 0",
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    },

    importLogo: {
      position: "absolute",
      top: 20,
      mixBlendMode: "multiply",
      left: 40,
    },

    disabledBtn: {
      backgroundColor: "#E2E8F0 !important",
      color: "#64748B !important",
      cursor: "none !important",
    },

    importBackBtn: {
      borderRadius: "50%",
      backgroundColor: "transparent",
      border: "1px solid #E2E8F0",
      cursor: "pointer",
      padding: 12,
      position: "absolute",
      top: 84,
      left: 40,
    },

    importBackBtnIcon: {
      fontSize: 16,
    },

    importInner: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      zIndex: 1,
      width: "100%",
      height: "100%",
    },

    importForm: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      boxSizing: "border-box",
      padding: 40,
      borderRadius: 28,
      maxWidth: 940,
      width: "100%",
      background:
        "linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
    },

    importFormTop: {
      position: "relative",
    },

    importFormHeading: {
      lineHeight: "32px",
      fontWeight: 700,
      fontSize: 30,
    },

    importFormHeadingIcon: {
      right: -42,
      position: "absolute",
      bottom: -12,
    },

    importFormDescription: {
      marginTop: 12,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: "21px",
      textAlign: "center",
      maxWidth: 746,
    },

    importFileSectionWrapper: {
      display: "flex",
      marginTop: 32,
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },

    importFileInput: {
      width: "100%",
    },

    importDropfileWrapper: {
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: 20,
      width: "100%",
      border: "1px solid #CBD5E1",
      background: "#3A84C90D",
      height: 136,
      cursor: "pointer",
    },

    importUploadIcon: {
      fontSize: 24,
      color: "#3A84C9",
    },

    importUploadTitle: {
      marginTop: 8,
      fontWeight: 500,
      lineHeight: "22px",
      display: "flex",
      fontSize: 16,
    },

    importChooseFile: {
      color: "#3A84C9",
      marginLeft: 4,
      textDecorationLine: "underline",
      fontWeight: 600,
    },

    importInput: {
      display: "none",
    },

    importFileNoted: {
      width: "100%",
      padding: "0 6px",
      marginTop: 8,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    importNoted: {
      color: "#8C8C8C",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "22px",
    },

    importAction: {
      marginTop: 32,
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },

    importCancelBtn: {
      borderRadius: 8,
      width: "100%",
      cursor: "pointer",
      backgroundColor: "#FFFFFF",
      border: "1px solid #CBD5E1",
      fontWeight: 600,
      fontSize: 16,
      lineheight: "19.2px",
      textTransform: "capitalize",
      padding: "10px 16px",
    },

    importSubmitBtn: {
      lineheight: "19.2px",
      textTransform: "capitalize",
      padding: "10px 16px",
      width: "100%",
      cursor: "pointer",
      borderRadius: 8,
      backgroundColor: "#000000",
      marginLeft: 20,
      color: "#FFFFFF",
      fontWeight: 600,
      fontSize: 16,
      border: "none"
    },

    importFileWrapper: {
      display: "flex",
      flexDirection: "column",
      marginTop: 24,
      width: "100%",
    },

    importItemWrapper: {
      padding: "8px 0 8px 35px",
      marginRight: "22px",
      borderBottom: "1px solid #CBD5E1",
      alignItems: "center",
      minHeight: "46px",
      display: "flex",
    },

    importFileScrollview: {
      overflowY: "auto",
      overflowX: "hidden",
      maxHeight: "142px",
      marginRight: "13px",

      "&::-webkit-scrollbar": {
        width: "6px",
        borderRadius: 8,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#CBD5E1",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#64748B",
        borderRadius: 8,
      },
    },

    importFileIcon: {
      color: "#E04E4E",
      marginRight: "15px",
      width: 35,
      height: 35,
    },

    importFileContent: {
      display: "flex",
      alignItems: "center",
    },

    importFileInfo: {
      width: 194,
    },

    importDeleteBtn: {
      marginLeft: "auto",
    },

    importDropdown: {
      borderRadius: 8,
      marginTop: 0,
      width: 200,
    },

    importFileSelectInner: {
      marginLeft: 12,
      display: "flex",
      flexDirection: "column",
    },

    importErrorMessage: {
      color: "#DC2626",
      fontSize: "12px",
      lineHeight: 1.2,
      fontWeight: 500,
      marginTop: "2px",
    },

    importDeleteBtnIcon: {
      fontSize: 16,
    },
  });

export class BulkUploadingCompanyImport extends BulkUploadingCompanyInfoController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.importContainer}>
          <img src={logo} className={classes.importLogo} />

          <Box className={classes.importInner}>
            <Formik
              validateOnChange={false}
              initialValues={{
                data: {},
              }}
              validationSchema={importValidationSchema}
              onSubmit={this.importMultipleFile}
            >
              {({
                errors,
                values,
                submitCount,
                touched,
                setTouched,
                validateField,
                setFieldValue,
              }) => (
                <Box className={classes.importForm}>
                  <Box className={classes.importFormTop}>
                    <Typography
                      component="h1"
                      className={classes.importFormHeading}
                    >
                      Upload your company information
                    </Typography>

                    <Box className={classes.importFormHeadingIcon}>
                      <BulkUploadingCompanyInfoHighlightIcon
                        highlight={this.state.highlightInfo}
                        onClick={this.toggleInfoDialog}
                        onSubmit={this.toggleHighlightInfo}
                      />
                    </Box>
                  </Box>

                  <Typography
                    component="span"
                    className={classes.importFormDescription}
                  >
                    This will train our proprietary AI engine, familiarising it
                    with your business context. The more you use Pebbles Copilot, the
                    more powerful it becomes in providing you with intelligence,
                    guidance and content.
                  </Typography>

                  <Box className={classes.importFileSectionWrapper}>
                    <label
                      className={classes.importFileInput}
                      htmlFor="file-input"
                    >
                      <Box
                        onDragEnter={this.handleDrag}
                        onDragLeave={this.handleDrag}
                        onDragOver={this.handleDrag}
                        onDrop={this.handleDrop}
                        data-testid="dragArea"
                        className={classes.importDropfileWrapper}
                      >
                        <SaveAlt className={classes.importUploadIcon} />
                        <Typography className={classes.importUploadTitle}>
                          Drag and Drop file here or
                          <Typography
                            component="span"
                            className={classes.importChooseFile}
                          >
                            Choose file
                          </Typography>
                        </Typography>
                      </Box>
                    </label>

                    <input
                      data-testid="fileInput"
                      onChange={(e) => {
                        const files = e.target.files;
                        if (!files) return;
                        Array.from(files).forEach((file) => {
                          (file as any).id = uuidv4();
                        });
                        let newData = { ...values.data };
                        Array.from(files).forEach((file: any) => {
                          newData = { ...newData, [file.id]: 0 };
                        });
                        this.onChangeFile(files);
                        setFieldValue("data", newData);
                      }}
                      className={classes.importInput}
                      type="file"
                      id="file-input"
                      accept=".pdf, .docx"
                      multiple
                    />

                    <Box className={classes.importFileNoted}>
                      <Typography
                        component="span"
                        className={classes.importNoted}
                      >
                        Supported formats: PDF, DOCX
                      </Typography>
                      <Typography
                        component="span"
                        className={classes.importNoted}
                      >
                        Maximum file size: 25 MB
                      </Typography>
                    </Box>
                  </Box>

                  <Form
                    className={classes.importFileWrapper}
                    translate={undefined}
                  >
                    <Box className={classes.importFileScrollview}>
                      {this.state.webFiles.map((file) => {
                        return (
                          <Box
                            className={classes.importItemWrapper}
                            key={file.id}
                            data-test-id={"file-" + file.id}
                          >
                            <InsertDriveFile
                              className={classes.importFileIcon}
                              style={{
                                color: this.getColor(file.name),
                              }}
                            />
                            <Box className={classes.importFileContent}>
                              <Box className={classes.importFileInfo}>
                                <Typography
                                  className={clsx(
                                    classes.font16,
                                    classes.fontWeight600,
                                    classes.fileName
                                  )}
                                >
                                  {file.name}
                                </Typography>

                                <Typography className={classes.font12}>
                                  {this.getFileType(file.type) +
                                    " • " +
                                    this.convertByteToReadable(file.size)}
                                </Typography>
                              </Box>

                              <Box className={classes.importFileSelectInner}>
                                <Dropdown
                                  containerClassName={classes.importDropdown}
                                  name={file.id}
                                  dataTestId={`select-${file.id}`}
                                  value={(values.data as any)[file.id] || 0}
                                  dataList={this.state.fileOptions}
                                  hasError={
                                    errors.data &&
                                    (touched.data as any)[file.id]
                                      ? (errors.data as any)[file.id]
                                      : false
                                  }
                                  handleChange={(e) => {
                                    const newData: any = values.data;
                                    newData[file.id] = Number(e.target.value);
                                    setFieldValue("data", newData);
                                    setTouched(
                                      {
                                        data: {
                                          ...touched.data,
                                          [file.id]: true,
                                        },
                                      },
                                      false
                                    );
                                    if (submitCount) {
                                      validateField("data");
                                    }
                                    this.handleChangeFileInfo(
                                      file.id,
                                      Number(e.target.value)
                                    );
                                  }}
                                />

                                {errors.data &&
                                (touched.data as any)[file.id] ? (
                                  <Typography
                                    data-test-id={`error-${file.id}`}
                                    component="span"
                                    className={classes.importErrorMessage}
                                  >
                                    {(errors.data as any)[file.id]}
                                  </Typography>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            </Box>

                            <IconButton
                              className={classes.importDeleteBtn}
                              data-test-id={"delete-file-" + file.id}
                              onClick={() => {
                                const newData: any = values.data;
                                delete newData[file.id];
                                setFieldValue("data", newData);
                                this.removeFileWeb(file.id);
                              }}
                            >
                              <DeleteOutline
                                className={classes.importDeleteBtnIcon}
                              />
                            </IconButton>
                          </Box>
                        );
                      })}
                    </Box>

                    <Box className={classes.importAction}>
                      <button
                        className={classes.importCancelBtn}
                        onClick={this.onCancel}
                        data-test-id="cancel-btn"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        data-test-id="submit-btn"
                        disabled={!this.state.webFiles.length}
                        className={clsx({
                          [classes.disabledBtn]: !this.state.webFiles.length
                        }, classes.importSubmitBtn)}
                      >
                        Import
                      </button>
                    </Box>
                  </Form>
                </Box>
              )}
            </Formik>
          </Box>

          <button className={classes.importBackBtn} onClick={this.onCancel}>
            <ArrowBackIos className={classes.importBackBtnIcon} />
          </button>
        </Box>

        <BulkUploadingCompanyInfoDialog
          open={this.state.infoDialog}
          onSubmit={this.toggleInfoDialog}
          onClose={this.toggleInfoDialog}
        />

        <Toast
          data-test-id="toast"
          errors={this.state.error}
          message={this.state.message}
          onClose={this.closeToast}
        />
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(BulkUploadingCompanyImport);
