import { Box, Grid, Paper, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import React from "react";

export interface Props extends WithStyles<any> {
  title: string;
  content: string;
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif",
  },
});

const styles = () =>
  createStyles({
    outerPaper: {
      padding: 16,
      borderRadius: "28px",
      backgroundColor: "#F1F5F9",
      boxSizing: "border-box",

      "@media (min-width: 768px)": {
        padding: 20,
      },
    },

    innerBlock: {},

    leftBlock: {
      display: "flex",
      marginBottom: 12,

      "@media (min-width: 768px)": {
        paddingRight: 26,
      },

      "@media (min-width: 1200px)": {
        marginBottom: 0,
      },
    },

    leftBlockTitle: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "21.6px",
      letterSpacing: "-0.005em",
      color: "#475569",
    },

    leftBlockDescription: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "14.4px",
      textAlign: "left" as "left",
      color: "#475569",
      marginTop: 8,
    },

    leftBlockContent: {
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "space-between",
    },

    rightBlock: {},
  });

export class LandingBlock extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { title, content, classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Paper className={classes.outerPaper} elevation={0}>
          <Grid container className={classes.innerBlock}>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={3}
              xl={3}
              className={classes.leftBlock}
            >
              <Box className={classes.leftBlockContent}>
                <Typography align="left" className={classes.leftBlockTitle}>
                  {title}
                </Typography>

                <Typography
                  align="left"
                  className={classes.leftBlockDescription}
                >
                  {content}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={9}
              xl={9}
              className={classes.rightBlock}
            >
              {this.props.children}
            </Grid>
          </Grid>
        </Paper>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(LandingBlock);
