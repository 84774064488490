import React from "react";


// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    TextField,
    Grid,
    Dialog,
    DialogContent
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import Toast from "../../utilities/src/Toast";
// Customizable Area End

import AccountDeletionModalController, {
    Props,
    AccountconfigJSON,
} from "./AccountDeletionModalController";

export class AccountDeletionModal extends AccountDeletionModalController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }:any  = this.props;
        const { errors } = this.state;
        const { onClose } = this;
        return (
            // Customizable Area Start
                <Dialog onClose={this.props.handleDeleteModal} aria-labelledby="simple-dialog-title" open={this.props.open} className={classes.dialogContainer}>
                <Toast {...{errors,onClose}} />
                    <DialogContent>
                        <Grid container className={classes.mainContainer}>
                            {this.state.deleteModal ?
                                <Grid item xs={12} data-testid="delete-confirmation">
                                    <CloseIcon onClick={this.handleDeleteOpen} fontSize="small" className={classes.closeIcon} />
                                    <Typography className={classes.accountDeletionHead}>{AccountconfigJSON.accountDeletion}</Typography>
                                    <Typography className={classes.dataLoseText}>{AccountconfigJSON.inputMailText}</Typography>
                                    <Typography className={classes.labelText}>{AccountconfigJSON.currentMailLabel}</Typography>
                                        <TextField
                                            variant="outlined"
                                            className={classes.textField}
                                            size="small"
                                            InputProps={{
                                                className: classes.inputProps
                                            }}
                                            value={this.state.currentMail}
                                            onChange={this.handleChange} 
                                            error={this.state.error}
                                            helperText={this.state.errorMessage}
                                            data-testid="email-field"
                                        />
                                         <Box className={classes.buttonSaveCancelBox}>
                                        <Button
                                            variant="outlined"
                                            className={classes.cancelButton}
                                            onClick={this.props.handleDeleteModal}
                                        >
                                            {AccountconfigJSON.cancelButton}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            className={classes.yesDeleteButton}
                                            color="secondary"
                                            onClick={this.handleDeleteAccount}
                                            data-testid="delete-account"
                                        >
                                            {AccountconfigJSON.deleteButton}
                                        </Button>
                                    </Box>
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    <CloseIcon onClick={this.props.handleDeleteModal} fontSize="small" className={classes.closeIcon} />
                                    <Typography className={classes.areYouSureText}>{AccountconfigJSON.areYouSure}<span className={classes.deleteText}>{AccountconfigJSON.delete}</span><span className={classes.account}>{AccountconfigJSON.yourAccount}</span></Typography>
                                    <Typography className={classes.dataLoseText}>{AccountconfigJSON.dataLose}</Typography>
                                    <Box className={classes.buttonSaveCancelBox}>
                                        <Button
                                            variant="contained"
                                            className={classes.saveButton}
                                            onClick={this.props.handleDeleteModal}
                                        >
                                            {AccountconfigJSON.keepData}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            className={classes.yesDeleteButton}
                                            color="secondary"
                                            onClick={this.handleDeleteOpen}
                                            data-testid="yes-delete"
                                        >
                                            {AccountconfigJSON.yesDelete}
                                        </Button>
                                    </Box>
                                </Grid>
                            }

                        </Grid>
                    </DialogContent>

                </Dialog>
            // Customizable Area End
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle:{} = () => ({
    mainContainer: {
        position: 'relative'
    },
    closeIcon: {
        position: 'absolute',
        right: 0,
        cursor: 'pointer'
    },
    labelText: {
        fontWeight: 600,
        paddingBottom: '5px',
        marginTop:'10px'
    },
    textField: {
        width: '100%',
        marginBottom:'10px',
        '& .MuiFormHelperText-contained':{
            marginLeft:'0'
        }
    },
    inputProps: {
        borderRadius: '8px',
        fontSize: '0.87rem'
    },
    saveButton: {
        background: '#000',
        color: '#fff',
        borderRadius: '8px',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        width: '49%',
        textTransform:'capitalize'
    },
    cancelButton: {
        border: '1px solid #94A3B8',
        borderRadius: '8px',
        fontWeight: 600,
        fontFamily: 'Montserrat',
        width: '49%',
        textTransform:'capitalize'
    },
    yesDeleteButton: {
        border: '1px solid red',
        borderRadius: '8px',
        fontWeight: 600,
        fontFamily: 'Montserrat',
        width: '49%',
        color: 'red',
        textTransform:'capitalize'
    },
    buttonSaveCancelBox: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 0 20px',
        flexWrap: 'wrap' as 'wrap'
    },
    areYouSureText: {
        fontSize: '1.5rem',
        fontWeight: 700,
        textAlign: 'center'
    },
    deleteText: {
        color: 'red',
        marginLeft: '5px'
    },
    accountDeletionHead:{
        fontSize: '1.5rem',
        fontWeight: 700,
        textAlign: 'center',
        color: 'red'
    },
    dataLoseText: {
        fontWeight: 600,
        color: '#475569',
        lineHeight: '24px',
        textAlign: 'center' as 'center',
        padding: '10px 0'
    },
    account: {
        marginLeft: '5px'
    },
    dialogContainer:{
        '& .MuiDialog-paperWidthSm-100':{
            borderRadius:'20px'
        },
        '& .MuiPaper-elevation24':{
            boxShadow:'none'
        }
    }
});
export default withStyles(webStyle)(AccountDeletionModal);

// Customizable Area End
