import React from "react";
// Customizable Area Start
import CustomTextArea from "../../../components/src/CustomTextArea.web";
// Customizable Area End

import UniqueSellingController, {
    Props,
    configJSON,
} from "./UniqueSellingController";

export default class UniqueSelling extends UniqueSellingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const {handleChange,values,errors,name} = this.props
        return (
            // Customizable Area Start
            <CustomTextArea
            data-testid="unique-selling"
            title={configJSON.uniqueSelling}
            maxCharacters={configJSON.uniqueMaxCharacters}
            onChange={(e:any) => handleChange(e)} 
            characterLimit={configJSON.uniqueCharacterLimit}
            placeholder={configJSON.uniquePlaceholder}
            minRows={3}
            modalContent={configJSON.uniqueSellInfo}
            errors={errors}
            // value={this.props.textValue}
            value={values}
            name={name}
            />
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
