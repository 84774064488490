import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  textValue?:any;
  handleChange?: any;
  touched:any,values:any,errors:any,
  name:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  uniqueTextValue:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UniqueSellingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      uniqueTextValue:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

    // Customizable Area Start
    // Customizable Area End
    // Customizable Area End

  // web events

  // Customizable Area Start
  // handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   if(event.target.value.length <= 200){
  //   this.setState({uniqueTextValue:event.target.value})
  //   }
  // }

  // Customizable Area End
}
