import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCloseEditMail:() => void;
  handleCloseMainModal:() => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  authToken:string;
  resendTime:string;
  openSuccess:boolean;
  newEmail:string;
  error:boolean;
  errorMessage:string;
  errors:string | undefined;
  showVerify:boolean;
  errorToast:boolean;
  verifyCode:string;
  verifyError:boolean;
  verifyErrorMessage:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SettingsChangeEmailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getVerificationCodeID : string;
  updateEmailID: string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getVerificationCodeID = ""
    this.updateEmailID = ""
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      authToken:"",
      resendTime:"0:30",
      openSuccess:false,
      newEmail:"",
      error:false,
      errorMessage:"",
      errors:"",
      showVerify:false,
      errorToast:false,
      verifyCode:'',
      verifyError:false,
      verifyErrorMessage:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const requestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        )
        this.getVerifyCodeResponse(requestCallId,message)
        this.updateEmailResponse(requestCallId,message)
        
        
      }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.setState({
        authToken: await StorageProvider.get("user-token")
      })
  }

getVerifyCodeResponse = (requestCallId:any,message:Message) => {
  if (this.getVerificationCodeID === requestCallId ) {
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.verifyResponseJson(responseJson)
    
  }
}

verifyResponseJson = (responseJson:any) =>{
  if (responseJson) {
    if(!responseJson?.data?.error){
      this.setState({showVerify: true})
    }else{
      this.setState({errors:responseJson.data.error})
    }
  }
}

updateEmailResponse = (requestCallId:any,message:Message) => {
  if(this.updateEmailID === requestCallId){
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.updateEmailResponseJson(responseJson)
  }
}

updateEmailResponseJson = (responseJson:any) => {
  if (responseJson) {
    if(!responseJson?.errors){
      this.handleModule();
    }else{
      this.setState({
        verifyError:true,
        verifyErrorMessage:responseJson.errors
      })
    }
  }
}

  handleSubmit = () => {
    
    if(this.state.showVerify){
     this.handleShwoVerifySubmit()
    }else{
      this.handleHideVerifySubmit()
     
    }
  }
  handleShwoVerifySubmit = () => {
    if(!this.state.verifyCode){
      this.setState({
        verifyError:true,
        verifyErrorMessage:"Enter verification code"
      })
     }else{
      this.updateEmail()
      this.setState({
        verifyError:false,
        verifyErrorMessage:""
      })
     }
  }
  handleHideVerifySubmit = () => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if(!this.state.newEmail){
      this.setState({
        error:true,
        errorMessage:"Email Id should not be blank"
      })
    }else if(!emailRegex.test(this.state.newEmail)){
      this.setState({
        error:true,
        errorMessage:"Enter Valid Email ID"
      })
    }else{
      this.setState({
        error:false,
        errorMessage:""
      })
    this.getOptVerification();
    }
  }
  handleModule = () => {
    this.setState({
      openSuccess: !this.state.openSuccess
    })
  }
  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({
      newEmail: event.target.value,
      error:false,
      errorMessage:""
    })
  }

  getOptVerification = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
  
    this.getVerificationCodeID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointGetVerifyCode + `?email=${this.state.newEmail}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCloseToast = () => {
    this.setState({
      errorToast: !this.state.errorToast
    })
  }
  handleVerifyCode = (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({verifyCode:event.target.value,verifyError:false,verifyErrorMessage:""})
  }

  updateEmail = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.state.authToken
    };
    const body = {
      "email":this.state.newEmail,
      "otp":this.state.verifyCode
    }
  
    this.updateEmailID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.apiEndPointUpdateEmail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.pathTypeMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 
  // Customizable Area End
}
