import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Modal,
  Paper,
  IconButton,
  TextField,
  // Customizable Area End
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import { close } from "./assets";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// Customizable Area End

interface IProps {
  classes?: any;
  open?: any;
  setOpen?: any;
}

const initialValues: any = {
  name: "",
  description: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[^\s].*$/, "Cannot start with spaces")
    .max(40, "Maximum 40 characters allowed")
    .required("Name should not be blank."),
  description: Yup.string()
    .matches(/^[^\s].*$/, "Cannot start with spaces")
    .max(150, "Maximum 200 characters allowed")
    .required("Description should not be blank."),
});
export class SaveWorkflow extends React.Component<IProps, {}> {
  state = {};

  render() {
    const { classes, open, setOpen } = this.props;

    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.dflexcenter}
        open={open}
        onClose={() => {
          setOpen({ saveWorkflowModal: false });
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper className={classes.saveWorkflowPaper}>
          <Box>
            <Box className={classes.dflexbetween}>
              <Box>
                <Typography
                  className={classes.saveWorkflowTitle}
                  align="center"
                >
                  Name and describe your workflow
                </Typography>
              </Box>
              <Box>
                <IconButton
                  data-test-id="closeSaveWorkflowBtn"
                  onClick={() => {
                    setOpen({ saveWorkflowModal: false });
                  }}
                >
                  <img src={close} style={{ height: "24px", width: "24px" }} />
                </IconButton>
              </Box>
            </Box>
            <Box style={{ marginTop: "20px" }}>
              <ul className={classes.listContainer}>
                <li className={classes.fontProvider}>
                  Name: max 40 characters
                </li>
                <li className={classes.fontProvider}>
                  Description: max 200 characters
                </li>
              </ul>
            </Box>
          </Box>

          <Box style={{ marginTop: "25px" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {}}
            >
              {({
                isValid,
                isSubmitting,
                errors,
                touched,
                setFieldValue,
                values,
                setFieldError,
                setFieldTouched,
              }) => (
                <Form translate={undefined}>
                  <Box style={{ margin: "10px 0px" }}>
                    <Typography className={classes.saveWorkflowFeild}>
                      Name
                    </Typography>
                      <Field
                      data-testid="nameFieldTestId"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        style: { borderRadius: "8px", height: "44px" },
                      }}
                      placeholder="Enter the name here"
                      as={TextField}
                      error={!!(errors.name && touched.name)}
                      name="name"
                      />
                      <ErrorMessage
                        data-test-id="FormikErrorMessageDataTestId"
                        name="name"
                        component="div"
                        render={message => (
                          <div
                            style={{
                              color: "red",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                              fontSize: "12px",
                              margin: "5px"
                            }}
                          >
                            {message}
                          </div>
                        )}
                      />
                    <Typography className={classes.characterLimit}>
                      {values.name.length}/40 characters
                    </Typography>
                  </Box>
                  <Box style={{ margin: "10px 0px" }}>
                    <Typography className={classes.saveWorkflowFeild}>
                      Description
                    </Typography>
                    <Field
                        data-testid="nameFieldTestId"
                        variant="outlined"
                        fullWidth
                        as={TextField}
                        error={!!(errors.name && touched.name)}
                        name="description"
                      InputProps={{
                        style: { borderRadius: "8px", height: "44px" },
                      }}
                      placeholder="Enter the description here"
                      />
                      <ErrorMessage
                        data-test-id="FormikErrorMessageDataTestId"
                        name="description"
                        component="div"
                        render={message => (
                          <div
                            style={{
                              color: "red",
                              fontFamily: "Montserrat",
                              fontWeight: 500,
                              fontSize: "12px",
                              margin: "5px"
                            }}
                          >
                            {message}
                          </div>
                        )}
                      />
                    <Typography className={classes.characterLimit}>
                    {values.description.length}/200 characters
                    </Typography>
                  </Box>

                  <Button type="submit" fullWidth className={classes.saveButton}>
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Paper>
      </Modal>
    );
  }
}
const styles = createStyles({
  listContainer: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "flexStart",
    marginLeft: "20px",
  },
  fontProvider: {
    fontSize: "16px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    color: "rgba(71, 85, 105, 1)",
  },
  feildsLabelStyles: {
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
  characterLimit: {
    textAlign: "end" as "end",
    fontSize: "0.75rem",
    fontWeight: 500,
    color: "#475569",
    paddingTop: "5px",
  },
  saveButton: {
    height: "44px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: 1.2,
    background: "#000",
    textTransform: "none",
    color: "white",
    margin: "20px 0px",
    "@media (min-width: 1300px)": {},
    "&:hover": {
      background: "#000",
    },
  },
  deletePaper: {
    backgroundColor: "white",
    padding: "40px",
    outline: "none",
    borderRadius: "20px",
    maxWidth: "670px",
  },
  saveWorkflowTitle: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "16px",
  },
  saveWorkflowFeild: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px",
  },
  saveWorkflowPaper: {
    backgroundColor: "white",
    padding: "40px",
    outline: "none",
    borderRadius: "20px",
    width: "808px",
    height: "430px",
  },
  dflexcenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dflexbetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputFeild: {
    height: "44px",
    borderRadius: "8px",
  },
});
export default withStyles(styles)(SaveWorkflow);
