import { Box, Grid, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import { ArrowBackIos } from "@material-ui/icons";
import React from "react";
import SubscriptionPlan, { ISubscriptionPlan } from "./SubscriptionPlan.web";
import { ICurrentSubscriptionPlan } from "../../customisableuserprofiles/src/ProfileController";

export interface Props extends WithStyles<any> {
  currentPlan: ICurrentSubscriptionPlan | null;
  plans: Array<ISubscriptionPlan>;

  onChoose(plan: ISubscriptionPlan): void;
  onBack(): void;
}

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif",
  },
});

const styles = () =>
  createStyles({
    container: {
      position: "relative",
    },
    heading: {
      letterSpacing: "-0.005em",
      textAlign: "center",
      color: "#000000",
      fontWeight: 700,
      fontSize: 30,
      lineHeight: "32px",
    },

    backButton: {
      padding: 12,
      border: "none",
      cursor: "pointer",
      position: "absolute",
      top: -4,
      left: 0,
      borderRadius: "50%",
      backgroundColor: "transparent",
    },

    backButtonIcon: {
      fontSize: 16,
    },

    content: {
      marginTop: 40,
    },
  });

export class SubscriptionFlexibleOptions extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, plans, currentPlan, onBack, onChoose } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.container}>
          <Typography component="h3" className={classes.heading}>
            Flexible options
          </Typography>

          <button
            className={classes.backButton}
            onClick={onBack}
            data-test-id="back-btn"
          >
            <ArrowBackIos className={classes.backButtonIcon} />
          </button>

          <Box className={classes.content}>
            <Grid container spacing={6}>
              {plans.map((plan) => (
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={plan.id}>
                  <SubscriptionPlan
                    plan={plan}
                    onChoose={onChoose}
                    disabledSelect={
                      currentPlan?.id.toString() === plan.id.toString()
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(SubscriptionFlexibleOptions);
