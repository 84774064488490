import { Box, Container, IconButton, Typography } from "@material-ui/core";
import {
  ThemeProvider,
  createStyles,
  createTheme,
  withStyles
} from "@material-ui/core/styles";
import LandingOnDemandIntelligence from "./LandingOnDemandIntelligence.web";
import LandingController, { Props } from "./LandingPageController.web";
import LandingSalesAssets from "./LandingSalesAssets.web";
import LandingTaticalOutReach from "./LandingTaticalOutReach.web";
import LandingWorkFlow from "./LandingLibraryAndWorkflow.web";
import LandingHead from "./LandingHead.web";

import React from "react";
import { NavigationMenuComponent } from "../../navigationmenu/src/NavigationMenu.web";
import { rightBar } from "./assets";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif"
  }
});

const styles = () =>
  createStyles({
    heading: {
      fontSize: 48,
      fontWeight: 600,
      lineHeight: "57.6px",
      marginTop: 40,

      "@media (max-width: 767.98px)": {
        fontSize: 28,
        lineHeight: "32px"
      }
    },

    block: {
      marginTop: 20
    }
  });

export class LandingPage extends LandingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <NavigationMenuComponent
          id={this.props.id}
          navigation={this.props.navigation}
          activeIndex={-1}
          handleRightMenu={this.handleRightMenu}
          rightMenu={this.state.rightMenu}
          children={
            <Container>
              <div style={webStyle.headerContainer}>
                <LandingHead />
                {!this.state.rightMenu && (
                  <IconButton
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    data-testid="right-menu"
                    onClick={this.handleRightMenu}
                    style={webStyle.rightBar}
                  >
                    <img src={rightBar} alt="right-bar" />
                  </IconButton>
                )}
              </div>
              <Typography className={classes.heading}>Dashboard</Typography>

              <Box className={classes.block}>
                <LandingOnDemandIntelligence
                  data-testid="on-demand-intelligence"
                  onClick={() => this.handleRedirectChatHistory()}
                />
              </Box>

              <Box className={classes.block}>
                <LandingTaticalOutReach
                  data-testid="tactical-outreach"
                  onClick={(activeStep: number) =>
                    this.handleRedirectTactical(activeStep)
                  }
                />
              </Box>

              <Box className={classes.block}>
                <LandingSalesAssets />
              </Box>

              <Box className={classes.block}>
                <LandingWorkFlow
                  data-testid="workflow"
                  onClick={() => this.handleRedirectWorkFlow()}
                  onClick1={() => this.handleRedirectLibrary()}
                />
              </Box>
            </Container>
          }
        />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  headerContainer: {
    display: "flex",
    alignItems: "start"
  },
  rightBar: {
    // position: "absolute" as "absolute",
    // right: "60px",
    zIndex: "9999",
    height: "max-content"
  }
};

export default withStyles(styles)(LandingPage);
