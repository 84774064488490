import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface InputItem {
  offering: string;
  description: string;
  offeringTitle: string;
  descriptionTitle: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  serviceArray:string[];
  inputList:{
    offering:string;
    description:string;
    offeringTitle: string;
    descriptionTitle: string
  }[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ServicesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      serviceArray:['Min 3 services', 'Title length max 30 characters', 'Description length max 130 characters'],
      inputList:[
        {
            offering:'',
            description:'',
            offeringTitle:'Offering',
            descriptionTitle:'Description'
        },
        {
            offering:'',
            description:'',
            offeringTitle:'Offering',
            descriptionTitle:'Description'
        }
      ] as InputItem[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    
    this.handleAdd();
    // Customizable Area End
  }
    // Customizable Area Start
    // Customizable Area End
    // Customizable Area End

  // web events

  // Customizable Area Start
  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>,index:number) => {
    const { name, value } = event.target;
    const list:any = [...this.state.inputList];
    list[index][name] = value;
    if(name === "offering" && value.length <= 30){
            this.setState({ inputList: list})
    }
    if(name === "description" && value.length <= 130){
            this.setState({ inputList: list })
    }
    
  }
  handleAdd = () => {
    this.setState({ inputList: [...this.state.inputList, {offering:"",description:"",offeringTitle:"Offering",descriptionTitle:"Description"}] })
  }

  // Customizable Area End
}
