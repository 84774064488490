import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Link,
  TextField,
  Divider,
  Checkbox,
} from "@material-ui/core";
import React from "react";
import SignUpController, { Props } from "./SignUpController";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { verifySignUpCodeSchema } from "../utils/schema";
import {
  ThemeProvider,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core/styles";
import Layout from "../../../components/src/Layout";
import CarouselDisplay from "../../../blocks/carouseldisplay/src/CarouselDisplay.web";
import { GoogleImg ,checkedIcon,checkIcon} from "./assets";
import * as Yup from "yup";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat,sans-serif",
  },
});

type Values = {
  email: string;
  termAndCondition: boolean;
};

export class VerifySignUpCode extends SignUpController {


  render() {
    const { classes } = this.props;
    const { privacyPolicyLink,termAndConditionLink } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <Layout 
        back={this.onBackSignUp}
        carousel={<CarouselDisplay id="" navigation={{}} type="individual"/>}
        >
          <Container className={classes.containerStyle}>
            <Box className={classes.signupform}>
              <Formik
                initialValues={{
                  email: localStorage.getItem("user-email"),
                  signupCode: "",
                  termsAndConditions: false,
                }}
                validationSchema={verifySignUpCodeSchema}
                onSubmit={(values) => {
                  this.handleSubmitVerificationCode(values);
                }}
              >
                {({ errors, touched }) => (
                  <Form translate={undefined}>
                    <Typography
                      align="center"
                      className={classes.typographyStyle1}
                      variant="h6"
                    >
                      Sign up
                    </Typography>
                    <Typography
                      align="center"
                      className={classes.typographyStyle2}
                      variant="h6"
                    >
                      Create an account to access Pebbles Ai.
                    </Typography>
                    <Typography
                      align="center"
                      className={classes.typographyStyle2}
                      variant="h6"
                    >
                      Already have an account?{" "}
                      <Link className={classes.link}
                       data-test-id='loginBtn' onClick={()=>this.goToLogin()}
                      >Log in</Link>
                    </Typography>
                    <Box>
                      <Box 
                      className={classes.emailOuterBox}>
                       
                        <Typography
                          align="left"
                          className={classes.inputFeildText}
                          variant="h6"
                        >
                          Email
                        </Typography>
                        <Field
                          data-testid="emailFieldTestId"
                          variant="outlined"
                          fullWidth
                          placeholder="Provide an email address "
                          as={TextField}
                          // InputProps={{
                          //   style: {
                          //     height: "40px",
                          //     borderRadius: 8,
                          //     overflow:"hidden",
                          //   },
                          // }}
                          className={classes.input}
                          error={(!!(errors.email && touched.email))}
                          name="email"
                          disabled={true}
                        />
                       {
                       ((!!touched.email && !!errors.email) || this.state.errors?.email) && (
                        <Typography className={classes.errorValidate}>
                          {errors.email ?? this.state.errors?.email}
                        </Typography>
                      )
                      }
                      </Box>
                      <Box className={classes.subHeadingWrapper}>
                        <Typography
                          className={classes.subHeadingtypographyStyle1}
                        >
                          We just sent you a <b>temporary sign up code</b>.
                          <Typography
                            className={classes.subHeadingtypographyStyle1}
                          >
                            Please check your <b>inbox</b> and paste the sign up
                            code below.
                          </Typography>
                        </Typography>
                      </Box>
                      <Box 
                         className={classes.inputFeildBox}
                      >
                        <Typography
                          align="left"
                          className={classes.inputFeildText}
                          variant="h6"
                        >
                          Sign up code
                        </Typography>
                        <Field
                          data-testid="emailFieldTestId"
                          variant="outlined"
                          fullWidth
                          placeholder="Paste sign up code"
                          as={TextField}
                          // InputProps={{
                          //   style: {
                          //     height: "40px",
                          //     borderRadius: 8,
                          //     overflow:"hidden"
                          //   },
                          // }}
                          className={classes.input}
                          error={( !!this.state.errors?.signupCode || !!(errors.signupCode && touched.signupCode))}
                          name="signupCode"
                          
                        />
                          {((!!touched.signupCode && !!errors.signupCode) || this.state.errors?.signupCode) && (
                        <Typography className={classes.errorValidate}>
                          {errors.signupCode ?? this.state.errors?.signupCode}
                        </Typography>
                      )}
                      </Box>
                      <Button
                        type="submit"
                        data-test-id="VerifySignUpCodeSubmitBtnDataTestId"
                        className={classes.submitBtn}
                        fullWidth
                      >
                        Continue with email
                      </Button>

                      <Box>
                        <Typography
                        data-test-id="VerifySignUpCodeResendBtnDataTestId"
                          align="center"
                          className={classes.receiveCode}
                          onClick={()=>{this.resendOtp()}}
                        >
                          Didn’t receive code?{" "}
                          <Link className={classes.link}>Resend</Link>
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      className={classes.inputFeildBox2}
                    >
                       <Box className={classes.divider}>
                        <div className={classes.hr} />
                        <Box className={classes.dividerTextWrapper}>
                          <Typography className={classes.dividerText}>
                            or
                          </Typography>
                        </Box>
                        <div className={classes.hr} />
                      </Box>
                    </Box>
                    <Button variant="contained" className={classes.googlebtn} onClick={this.socialGoogleLogin}>
                      <Typography
                        className={classes.googleImg}
                        data-testid="googleLoginBtn"
                      >
                        <img src={GoogleImg} />
                        <Typography className={classes.googleTxt}>
                        Continue with Google
                        </Typography>
                      </Typography>
                    </Button>
                    <Typography className={classes.tnc}>
                      <Field
                        as={Checkbox}
                        error={
                          !!(
                            errors.termsAndConditions &&
                            touched.termsAndConditions
                          )
                        }
                        name="termsAndConditions"
                        checkedIcon={<img src={checkedIcon}/>}//custom checknbox
                        icon={<img src={checkIcon}/>}//customcheckbox icon
                      />
                      <Typography>
                        I agree to the
                        <Link 
                         className={classes.link}
                         href={privacyPolicyLink}
                         target="_blank" 
                         rel="noopener noreferrer" 
                        > Privacy Policy </Link>
                        {" & "}
                        <Link 
                           className={classes.link}
                           href={termAndConditionLink}
                           target="_blank" 
                           rel="noopener noreferrer" 
                        >
                          {" "}
                          Terms and Conditions{" "}
                        </Link>
                      </Typography>
                    </Typography>
                    {!!touched.termsAndConditions && !!errors.termsAndConditions && (
                        <Typography className={classes.errorValidate}>
                          {errors.termsAndConditions}
                        </Typography>
                      )}
                    {/* <ErrorMessage
                      data-test-id="FormikErrorMessagesignupCodeDataTestId"
                      name="termsAndConditions"
                      component="div"
                      render={(message: any) => (
                        <div
                          style={{
                            color: "red",
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                            fontSize: "12px",
                          }}
                        >
                          {message}
                        </div>
                      )}
                    /> */}
                  </Form>
                )}
              </Formik>
            </Box>
          </Container>
        </Layout>
      </ThemeProvider>
    );
  }
}

const pageStyles: any = createStyles({
  containerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: "100vh",
  },
  paperStyle: {
    padding: "20px",
    width: "520px",
    borderRadius: "28px",
    height: "273px",
    background:
      "linear-gradient(151.76deg, rgba(255, 255, 255, 0) -34.68%, rgba(255, 255, 255, 0.8) 113.6%)",
  },
  typographyStyle1: {
    margin: "10px  0px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "30px",
  },
  typographyStyle2: {
    // margin: '16px  0px',
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    color: "rgba(71, 85, 105, 1)",
  },
  signupform: {
    maxWidth: "520px",
    // height: "696px",
    width: "100%",
    borderRadius: "28px",
    padding: "40px",
    background:
      "linear-gradient(144.88deg, rgba(255, 255, 255, 0.52) -7.94%, rgba(255, 255, 255, 0.79) 102.73%)",
    border: "1px solid rgba(203, 213, 225, 1)",
  },
  inputFeildText: {
    fontWeight: 600,
    fontFamily: "Montserrat",
    fontSize:"1rem"
  },
  googlebtn: {
    height: "45px",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
    marginTop: "20px",
    border: "1px solid rgba(140, 140, 140, 0.20)",
    background: "#FFF",
    boxShadow: "none",
    width: "100%",
    "&:hover": {
      background: "#FFF",
    },
  
  },
  googleImg:{
    color: "#000",
    marginLeft: "8px",
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily: "Montserrat",
    textTransform: "capitalize",
    display:"flex",
    alignItems:"center"
  },
  googleTxt: {
   marginLeft:"6px",
   fontWeight: 600,

  },
  submitBtn: {
    margin: "10px 0px ",
    color: "white",
    backgroundColor: "black",
    height: "44px",
    marginRight: "5px",
    cursor: "pointer",
    borderRadius: "8px",
    border: " 1px solid rgba(203, 213, 225, 1)",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px",
    "&:hover": {
      background: "#000"
    }
  },
  link: {
    cursor: "pointer",
    color: "#3A84C9",
    fontWeight:700
  },
  subHeadingtypographyStyle1: {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "12px",
    color:"#475569"
    // marginTop: "10px",
  },
  inputFeilds: {
    margin: "15px 0px",
  },
  tnc: {
    display: "flex",
    alignItems: "center",
    marginTop: "35px",
  },
  receiveCode: {
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: 400,
    color: "rgba(71, 85, 105, 1)",
  },
  divider: {
    width: "100%",
    display: "flex",
    height: 21,
    justifyContent: "center",
    position: "relative",
    alignItems: "center"
  },
  dividerTextWrapper: {
    padding: "0 37.5px",
    position: "relative"
  },
  dividerText: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#475569"
  },
  hr: {
    background: "#CBD5E1",
    height: "1px",
    flex: 1
  },
  errorValidate: {
    fontFamily:"Montserrat",
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
    color: "#DC2626",
    marginTop: "2px",
  },
  inputFeildBox :{ margin: "30px 0px" },
  inputFeildBox2 :{ margin: "25px 0px" },
  emailOuterBox:{ marginTop: "30px" },
  validateError: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
    color: "#DC2626",
    marginTop: "2px"
  },
  input: {
    height: "42px",
    marginTop: "4px",
    borderRadius: " 8px",
    border: "1px solid rgba(140, 140, 140, 0.20)",
    background: "#FFF",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
        height: "44px",
        
      },
      "&.Mui-disabled fieldset": {
        borderColor: "transparent",
        opacity: 1, 
      },
      "&:hover fieldset": {
        borderColor: "transparent"
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent"
      },
      "& .MuiOutlinedInput-input": {
        padding: "0 12px",
        lineHeight: "21px",
        color: "#64748B",
        fontSize: "14px",
        fontWeight: 500
      },

      "& .MuiInputBase-input::placeholder": {
        opacity: 1
      },

      "& .MuiInputBase-input": {
        height: "44px"
      }
    }
  },
  subHeadingWrapper:{
    marginTop:"5px"
  }
});

export default withStyles(pageStyles)(VerifySignUpCode);
