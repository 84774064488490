import React from "react";
// Customizable Area Start
import CustomList from "../../../components/src/CustomList.web";
import CustomInput from "../../../components/src/CustomInput.web";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    Grid,
    Box,
    Typography
} from "@material-ui/core";

const theme = createTheme({
    palette: {
        primary: {
            main: "#475569",
        },
        secondary: {
            main: "#3A84C9"
        }
    },
    typography: {
        fontFamily: 'Montserrat',
    },
});
// Customizable Area End

import SuccessStoriesController, {
    Props,
    configJSON,
} from "./SuccessStoriesController.web";

export default class SuccessStories extends SuccessStoriesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { handleChange,tooltipData,inputList, serviceArray, title } = this.props
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Grid container style={webStyle.servicesMainContainer}>
                    <Grid item xs={12} style={webStyle.servicesContainer}>
                        <CustomList
                            title={title}
                            servicesList={serviceArray}
                            modalContent={tooltipData||configJSON.serviceModal}
                        />
                        <Box style={webStyle.offeringBox}>
                            {inputList.map((data: any, index: any) => {
                                return (
                                    <Box key={index}>
                                        <CustomInput
                                            width="100%"
                                            textLabel=""
                                            customeLabel={data.label}
                                            labelCount={index}
                                            placeholder={data.placeholder}
                                            rowsCount={2}
                                            data-testid={"field-" + index}
                                            value={data.value}
                                            error={data.error}
                                            name={data.name}
                                            onChange={(e:any)=>handleChange && handleChange(e,index)}
                                            maxCharacterLenght={data.charLength}
                                        />
                                    </Box>
                                )
                            }
                            )}
                        </Box>
                        <Typography style={webStyle.subTitle}>{this.props.secondTitle}</Typography>
                        <Box style={webStyle.solutionBox}>
                            {this.props.secondList?.map((data: any, index: any) => {
                                return (
                                    <Box key={index} style={webStyle.rowWidth}>
                                        <CustomInput
                                            width="100%"
                                            textLabel={data.label}
                                            labelCount={index}
                                            placeholder={data.placeholder}
                                            rowsCount={5}
                                            data-testid={"solution-field-" + index}
                                            value={data.value}
                                            error={data.error}
                                            name={data.name}
                                            onChange={(e:any)=>this.props.onChangeTitle && this.props.onChangeTitle(e,index)}
                                            maxCharacterLenght={data.charLength}
                                        />
                                    </Box>
                                )
                            }
                            )}
                        </Box>
                        <Typography style={webStyle.subTitle}>{this.props.thirdTitle}</Typography>
                        <Box style={webStyle.solutionBox}>
                            {this.props.thirdList?.map((data: any, index: any) => {
                                return (
                                    <Box key={index} style={webStyle.rowWidth}>
                                        <CustomInput
                                            width="100%"
                                            textLabel={data.label}
                                            labelCount={index}
                                            placeholder={data.placeholder}
                                            rowsCount={5}
                                            data-testid={"result-field-" + index}
                                            value={data.value}
                                            error={data.error}
                                            name={data.name}
                                            onChange={(e:any)=>this.props.onChangeDescription && this.props.onChangeDescription(e,index)}
                                            maxCharacterLenght={data.charLength}
                                        />
                                    </Box>
                                )
                            }
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </ThemeProvider>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    servicesMainContainer: {
        background: '#EEE7D9',
        padding: '2% 0'
    },
    servicesContainer: {
        background: '#fff',
        borderRadius: '28px',
        padding: '32px 40px',
        height: 'max-content'
    },
    offeringBox: {
        flexDirection: 'column' as 'column',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap' as 'wrap',
        marginBottom: '2%'
    },
    solutionBox: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap' as 'wrap',
        marginBottom: '2%'
    },
    rowWidth:{
        width:'32%'
    },
    subTitle:{
        fontWeight: 600,
        marginTop:'18px',
        marginBottom:'16px'
    }
}
// Customizable Area End
