import React from "react";

// Customizable Area Start

import {
  Box,
  Button,
  Typography,
  Paper,
  createStyles
} from "@material-ui/core";
import { PRCheckIcon, PRSuccess } from "../src/assets";
// Customizable Area End

import ForgotPasswordController from "./ForgotPasswordController";

export default class PasswordRecoverySuccess extends ForgotPasswordController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      <Box style={PRSuccessPageStyles.mainContainerStyle}>
        <Paper style={PRSuccessPageStyles.mainform}>
          <Box>
            <img src={PRCheckIcon} alt="PRCheckIcon" />
          </Box>

          <Box>
            <Box>
              <Typography style={PRSuccessPageStyles.pwdMsg} align="center">
                Password was updated successfully!
              </Typography>
              <Typography style={PRSuccessPageStyles.pwdMsg2} align="center">
                Great, now you are able to login and enjoy Pebbles AI.
              </Typography>
            </Box>
            <Box>
              <Button style={PRSuccessPageStyles.LogInNowBtn} fullWidth
              onClick={this.goToLogin}
              >
                Log in now
              </Button>
              <Button />
            </Box>
          </Box>
        </Paper>
      </Box>
    );
  }
}

// Customizable Area Start

const PRSuccessPageStyles: any = createStyles({
  mainContainerStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundImage: `url(${PRSuccess})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat"
  },
  pwdMsg: {
    margin: "10px  0px",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: "30px"
  },
  pwdMsg2: {
    // margin: '16px  0px',
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
    color: "rgba(71, 85, 105, 1)"
  },
  mainform: {
    width: "100%",
    height: "100%",
    maxWidth: "648px",
    maxHeight: "506px",
    borderRadius: "28px",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: " space-around",
    alignItems: "center",
    background:"#EBD8C1",
    // background:
    //   "linear-gradient(0deg, rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 0.72))"
  },
  LogInNowBtn: {
    margin: "25px 0px ",
    color: "white",
    backgroundColor: "black",
    height: "44px",
    marginRight: "5px",
    cursor: "pointer",
    borderRadius: "8px",
    border: " 1px solid rgba(203, 213, 225, 1)",
    textTransform: "none",
    fontFamily: "Montserrat",
    fontWeight: 600,
    fontSize: "14px"
  }
});

// Customizable Area End
