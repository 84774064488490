import React from "react";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
    Grid,
    Box
} from "@material-ui/core";
import CustomList from "../../../components/src/CustomList.web";
import CustomInput from "../../../components/src/CustomInput.web";
import CustomButton from "../../../components/src/CustomButton.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#475569",
        },
        secondary: {
            main: "#3A84C9"
        }
    },
    typography: {
        fontFamily: 'Montserrat',
    },
});
// Customizable Area End

import ProductsAndFeatureController, {
    Props,
    configJSON,
} from "./ProductsAndFeatureController";

export default class ProductsAndFeature extends ProductsAndFeatureController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Grid container style={webStyle.productaMainContainer}>
                    <Grid item xs={12} style={webStyle.servicesContainer}>
                        <CustomList
                            title={configJSON.productFeature}
                            servicesList={this.state.productArray}
                            modalContent={configJSON.productModal}
                        />
                        <Box style={webStyle.productsBox}>
                            {this.state.inputData.map((data, index) =>{
                                return(
                                    <Box key={index}>
                                        <CustomInput
                                            textLabel={data.offeringLabel}
                                            labelCount={index}
                                            width="100%"
                                            placeholder={data.offerPlaceholder}
                                            rowsCount={1}
                                            data-testid={"product-offering-" + index}
                                            value={data.productOffering}
                                            name="productOffering"
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => this.handleProductChange(event, index)}
                                            maxCharacterLenght={30}
                                        />
                                        <CustomInput
                                            textLabel={data.descriptionLabel}
                                            labelCount={index}
                                            width="100%"
                                            placeholder={data.descriptionPlaceholder}
                                            rowsCount={5}
                                            value={data.productDescription}
                                            name="productDescription"
                                            data-testid={"product-description-" + index}
                                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => this.handleProductChange(event, index)}
                                            maxCharacterLenght={130}
                                        />
                                    </Box>
                                )
                            }
                            )}
                        </Box>
                        <CustomButton
                            onClick={this.handleAddProduct}
                            buttonName={configJSON.addProduct}
                        />
                    </Grid>
                </Grid>
            </ThemeProvider>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    productaMainContainer: {
        background: '#EEE7D9',
        padding: '5%'
    },
    servicesContainer: {
        background: '#fff',
        borderRadius: '28px',
        padding: '32px 40px',
        height: 'max-content'
    },
    productsBox: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap' as 'wrap',
        marginBottom: '2%'
    },
}
// Customizable Area End
