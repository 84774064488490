import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";
import { WithStyles } from "@material-ui/core";
import { Message } from "../../../framework/src/Message";
import StorageProvider from "../../../framework/src/StorageProvider";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start

export interface Props extends WithStyles<any> {
  navigation: any;
}

export interface IAPIKey {
  id: string;
  type: string;
  attributes: {
    name: string;
    key: string;
    created_at: string;
    last_used: string;
  };
}
interface S {
  error: string;
  loading: boolean;
  isCreate: boolean;
  isKey: boolean;
  apiKeyList: Array<IAPIKey>;
  apiKeyValue: string;
  name: string;
  isEditting: boolean;
  isCopied: boolean;
}

interface SS {
  id: any;
}

export default class ApiKeyController extends BlockComponent<Props, S, SS> {
  fetchAllAPIKeysMessageId: string = "";
  createAPIKeyMessageId: string = "";
  showAPIKeyMessageId: string = "";
  token: string = "";

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      error: "",
      loading: false,
      isCreate: false,
      isKey: false,
      apiKeyList: [],
      apiKeyValue: "",
      name: "Secret key",
      isEditting: false,
      isCopied: false,
    };

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    this.token = await StorageProvider.get("user-token");

    if (!this.token) {
      const navigationMessage = new Message(
        getName(MessageEnum.NavigationMessage)
      );

      navigationMessage.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "WelcomePage"
      );

      navigationMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );

      this.send(navigationMessage);

      return;
    }

    this.fetchAllAPIKeys();
  };

  receive(from: string, message: Message): void {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchAllAPIKeysMessageId:
        this.handleFetchAllAPIKeys(responseJson);

        return;

      case this.createAPIKeyMessageId:
        this.handleCreateAPIKey(responseJson);

        return;

      case this.showAPIKeyMessageId:
        this.handleShowAPIKey(responseJson);

        return;

      default:
        return;
    }
  }

  handleIsCreate = () => {
    this.setState({
      isCreate: !this.state.isCreate,
      isKey: false,
    });
  };

  handleIsKey = () => {
    this.createAPIKey();

    this.setState({
      isKey: true,
      isCreate: false,
    });
  };

  handleCreateBack = () => {
    this.setState({
      isKey: false,
      isCreate: false,
      isEditting: false,
      name: "Secret key",
      isCopied: false,
      apiKeyValue: "",
    });
  };

  handleOpenEditting = () => {
    this.setState({
      isEditting: true,
    });
  };

  handleSaveEditting = () => {
    this.setState({
      isEditting: false,
    });
  };

  handleKeyBack = () => {
    this.setState({
      name: "Secret key",
      isKey: false,
      isCreate: true,
      isCopied: false,
      apiKeyValue: "",
    });
  };

  handleCopyKey = () => {
    navigator.clipboard.writeText(this.state.apiKeyValue);

    this.setState({
      isCopied: true,
    });
  };

  fetchAllAPIKeys = () => {
    const header = {
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchAllAPIKeysMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllAPIKeysEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFetchAllAPIKeys = (response: any) => {
    if(response.error) {
      this.handleError(response.error)

      return
    }

    if (!response.data) return;

    this.setState({
      apiKeyList: response.data,
    });
  };

  createAPIKey = () => {
    const header = {
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAPIKeyMessageId = requestMessage.messageId;

    const formData = new FormData();

    formData.append("name", this.state.name);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAPIKeyEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCreateAPIKey = (response: any) => {
    if(response.error) {
      this.handleError(response.error)

      return
    }

    if (!response.data) return;

    this.fetchShowAPIKey(response.data.id);
    this.fetchAllAPIKeys();
  };

  fetchShowAPIKey = (id: string) => {
    const header = {
      token: this.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showAPIKeyMessageId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllAPIKeysEndpoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleShowAPIKey = (response: any) => {
    if(response.error) {
      this.handleError(response.error)

      return
    }

    if (!response.data) return;

    this.setState({
      apiKeyValue: response.data.attributes.key,
    });
  };

  handleFormatKey = (key: string) => {
    return key.slice(0, 3) + "-".repeat(3) + key.slice(-3);
  };


  closeToast = () => {
    this.setState({
      error: ""
    });
  };

  handleError = (error: string) => {
    this.setState({
      error,
    });

    setTimeout(() => {
      this.setState({
        error: "",
      });
    }, 2000);
  }
}
