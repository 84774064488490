// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import * as Yup from "yup";

export const configJSON = require("./config");

export interface Answer {
  id: string | number;
  answer: string;
  hint: string | null;
  is_sub: boolean | null;
  nested_questions?: Question[];
}

export interface Question {
  id: string | number;
  field_name: string;
  field_type: string;
  hint: string | null;
  required: boolean;
  order: number | null;
  info: string | null;
  is_sub: boolean;
  answers: Answer[];
}

export interface ParameterFormData {
  id:string | number
    type:string
    attributes:{
      associated_question:{
        id:number,
        field_name:string,
        field_type:string,
        form_creation_id:number,
        hint:string,
        archived: boolean,
        required: boolean,
        order: number,
        info: string,
        main_answer_id: string | number | null,
        is_sub: boolean
      },
      associated_answer:{
        id: number,
        question_id: number,
        answer: string,
        prompt_section_id: number,
        parent_id: null | string | number,
        section: null | string,
        is_sub: boolean,
        hint: string
      }
    }
}

export interface Props {
  navigation: any;
  id: string;
  formData: any[];
  formValueData?:any[];
  onCloseModal: () => void;
  onSave(): void;
  onApply(): void
}

interface S {}

interface SS {
  id: any;
}

export interface QuestionFormat {
  id: number;
  question: string;
  required: boolean;
  field_type: string;
  hint: string;
  order: number;
  info: any;
  is_sub: boolean;
  answers: AnswerFormat[];
}

export interface AnswerFormat {
  id: number;
  answer: string;
  hint?: string;
  is_sub: boolean;
  nested_questions?: QuestionFormat[];
}

export default class ParameterModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
    ];

    this.state = {};
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleConditionalStyle = (
    condition: boolean,
    trueStyle: any,
    falseStyle?: any
  ) => {
    return condition ? trueStyle : falseStyle || null;
  };

  async componentDidMount() {
    super.componentDidMount();
  }

  getInitFormikData = () => {
    let validation: any = {}
    let _initValue: any = {
        rangeChosen: '',
    }
    const communication = this.props.formData.find((form) => form.attributes.section === 'COMMUNICATION  TACTICS')?.attributes.questions || []
    const persuasion = this.props.formData.find((form) => form.attributes.section === 'PERSUASION TACTICS')?.attributes.questions || []
    persuasion.forEach((item:any, idx:number) => {
        if (idx === 0) {
            validation[`persuasion-${item.id}`] = Yup.string()
        }
        _initValue[`persuasion-${item.id}`] = ''
    })
    communication.forEach((item:any, idx:number) => {
        if (idx === 0) {
            validation[`communication-${item.id}`] = Yup.string()
        }
        _initValue[`communication-${item.id}`] = ''
    })
    return {
      validation: Yup.object().shape(validation),
        initValue:_initValue,
    }
}

  checkBlur = (value: any) => {
  };

  checkActiveButton = (value: any) => {
  };

  onChangeChips = (
    currentValue: string[],
    type: string,
    setFieldValue: (type: string, value: string) => void,
    question: QuestionFormat,
    chosenId: string | number
  ) => {
    const formikKey = `${type}-${question.id}`;
    const isExisted =
      currentValue.findIndex((val) => val + "" === chosenId + "") !== -1;
    if (isExisted) {
      const newArr = currentValue.filter((val) => val + "" !== chosenId + "");
      setFieldValue(formikKey, newArr.join("-"));
      return;
    }
    setFieldValue(formikKey, currentValue.join("-") + "-" + chosenId);
  };

  getFormList = (type: 'PERSUASION TACTICS' | 'COMMUNICATION  TACTICS') => {
    return (this.props.formData.find((form) => form.attributes.section === type)?.attributes.questions || []).sort((a: any, b: any) => a.order - b.order)
}
}
// Customizable Area End