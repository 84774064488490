import React from "react";
import SubscriptionPlanAndBillingDetail from "./SubscriptionPlanAndBillingDetail.web";
import SubscriptionPaymentHistory from "./SubscriptionPaymentHistory.web";
import SubscriptionFlexibleOptions from "./SubscriptionFlexibleOptions.web";
import {
  ICurrentSubscriptionPlan,
  SubscriptionPlanAndBillingContent,
} from "../../customisableuserprofiles/src/ProfileController";
import { ISubscriptionPlan } from "./SubscriptionPlan.web";

interface Props {
  content: SubscriptionPlanAndBillingContent | null;
  currentPlan: ICurrentSubscriptionPlan | null;
  otherPlans: any[];
  history: any[];
  plans: Array<ISubscriptionPlan>;

  onUnsubscribe(): void;
  onViewAllPayments(): void;
  onViewDetails(): void;
  onBack(): void;
  onChoosePlan(plan: ISubscriptionPlan): void;
  isUnSubscriptionOpen: boolean;
}

export default class SubscriptionPlanAndBilling extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      currentPlan,
      otherPlans,
      content,
      history,
      plans,
      onUnsubscribe,
      onViewAllPayments,
      onViewDetails,
      onBack,
      onChoosePlan,
      isUnSubscriptionOpen,
    } = this.props;

    return (
      <>
        {(() => {
          switch (content) {
            case SubscriptionPlanAndBillingContent.Detail:
              return (
                <SubscriptionPlanAndBillingDetail
                  currentPlan={currentPlan}
                  otherPlans={otherPlans}
                  onUnsubscribe={onUnsubscribe}
                  onViewAllPayments={onViewAllPayments}
                  onViewDetails={onViewDetails}
                  isUnSubscriptionOpen={isUnSubscriptionOpen}
                />
              );

            case SubscriptionPlanAndBillingContent.History:
              return (
                <SubscriptionPaymentHistory history={history} onBack={onBack} />
              );

            case SubscriptionPlanAndBillingContent.Option:
              return (
                <SubscriptionFlexibleOptions
                  currentPlan={currentPlan}
                  plans={plans}
                  onChoose={onChoosePlan}
                  onBack={onBack}
                />
              );

            default:
              return <></>;
          }
        })()}
      </>
    );
  }
}
